import { Button, ButtonVariantsEnum, Textarea } from "@repo/ui";
import { LoadingText } from "@repo/ui/Buttons/loadingText";
import { ModalCloseButton } from "@repo/ui/Modal/modalCloseButton";
import { modalHeader } from "@repo/ui/Modal/modalHeader";
import type { InterviewSectionSettings } from "app-types/entities/interviewSection/settings";
import { FC, useState } from "react";
import { Dialog, Header, Heading } from "react-aria-components";
import { useGenerateRolePlayInterviewSectionSettingsMutation } from "../../../../api/redux";

export interface RolePlayInterviewSectionConfigureDialogProps {
  jobTypeId: string;
  onCreate: (settings: InterviewSectionSettings) => void;
}

export const RolePlayInterviewSectionConfigureDialog: FC<
  RolePlayInterviewSectionConfigureDialogProps
> = (props) => {
  const [generateRolePlayInterviewSectionSettings, { isLoading }] =
    useGenerateRolePlayInterviewSectionSettingsMutation();

  const [participantRoleDescription, setParticipantRoleDescription] =
    useState("");
  const [participantRoleDescriptionError, setParticipantRoleDescriptionError] =
    useState<string | undefined>(undefined);
  const validateRoleDescription = (): boolean => {
    if (participantRoleDescription.trim().length === 0) {
      setParticipantRoleDescriptionError(
        "Please provide a role description for the candidate.",
      );
      return false;
    } else {
      setParticipantRoleDescriptionError(undefined);
      return true;
    }
  };

  const [agentRoleDescription, setAgentRoleDescription] = useState("");
  const [agentRoleDescriptionError, setAgentRoleDescriptionError] = useState<
    string | undefined
  >(undefined);
  const validateAgentRoleDescription = (): boolean => {
    if (agentRoleDescription.trim().length === 0) {
      setAgentRoleDescriptionError(
        "Please provide a role description for the AI agent.",
      );
      return false;
    } else {
      setAgentRoleDescriptionError(undefined);
      return true;
    }
  };

  const validate = (): boolean => {
    return [validateRoleDescription(), validateAgentRoleDescription()].every(
      (isValid) => isValid,
    );
  };

  const [exampleScenarios, setExampleScenarios] = useState("");

  const onGenerate = async () => {
    // TODO: Refactor validation to use browser and react-aria facilities.
    if (!validate()) {
      return;
    }

    // TODO: Handle errors.
    const { data: settings } = await generateRolePlayInterviewSectionSettings({
      jobTypeId: props.jobTypeId,
      participantRoleDescription,
      agentRoleDescription,
      exampleScenarios,
    });

    if (settings) {
      props.onCreate(settings);
    }
  };

  return (
    <Dialog>
      {({ close }) => (
        <>
          <Header
            className={modalHeader().header({
              className: "px-4 pt-4 sm:px-6 sm:pt-6",
            })}
          >
            <Heading slot="title" className={modalHeader().title()}>
              Add role play interview
            </Heading>
            <ModalCloseButton />
          </Header>

          <div className="p-4 sm:p-6 max-w-lg space-y-4">
            <div>
              <Textarea
                label="What role do you want the candidate to play?"
                placeholder="A customer service representative of Acme, a bluetooth headphones manufacturer"
                height="4rem"
                value={participantRoleDescription}
                onChange={setParticipantRoleDescription}
                errorDescription={participantRoleDescriptionError}
                isDisabled={isLoading}
              />
            </div>

            <div>
              <Textarea
                label="What role should the AI agent play?"
                placeholder="An upset customer requesting a refund after their bluetooth headphones stopped working"
                height="4rem"
                value={agentRoleDescription}
                onChange={setAgentRoleDescription}
                errorDescription={agentRoleDescriptionError}
                isDisabled={isLoading}
              />
            </div>

            <div>
              <Textarea
                label="Example call scripts (optional)"
                placeholder={
                  "Customer: Hello?\nAgent: Hi, you've reached Acme support. How can I help?\nCustomer: Yes, I would like to request a refund…"
                }
                description="If you have example call scripts of nuanced scenarios that the candidate needs to handle, add them here to help generate a detailed role play exercise. No need to worry about text formatting or styling."
                height="8rem"
                value={exampleScenarios}
                onChange={setExampleScenarios}
                isDisabled={isLoading}
              />
            </div>
          </div>

          <div className="px-4 pb-4 sm:px-6 sm:pb-6 flex justify-end space-x-2">
            <Button
              slot="close"
              variant={ButtonVariantsEnum.Secondary}
              isDisabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              variant={ButtonVariantsEnum.Primary}
              isLoading={isLoading}
              onClick={async () => {
                await onGenerate();
                close();
              }}
            >
              <LoadingText
                isLoading={isLoading}
                stages={[
                  "Analyzing roles...",
                  "Creating a scenario...",
                  "Developing a character...",
                  "Rehearsing lines...",
                  "Setting the stage...",
                  "Preparing dramatic entrance...",
                  "Generating role play interview...",
                ]}
              >
                Generate role play interview
              </LoadingText>
            </Button>
          </div>
        </>
      )}
    </Dialog>
  );
};
