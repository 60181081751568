import { InterviewSectionType } from "app-types/entities/interviewSection/types";
import { FeatureFlagKey } from "app-types/featureFlags/keys";
import {
  allInterviewSectionTemplateIds,
  interviewSectionTemplateListings,
  type InterviewSectionTemplateId,
} from "app-types/templates/interviewSection";
import type { ReactNode } from "react";
import { useIsFeatureFlagEnabled } from "../../../../helpers/featureFlags/hooks";
import type { VisibleInterviewSectionDefinition } from "../state";

interface BaseInterviewSectionPresetDefinition {
  key: string;
  title: string;
  description: ReactNode;
  skills: readonly ReactNode[];
  checkIsAvailable: (args: InterviewSectionPresetAvailabilityArgs) => boolean;
  checkIsUsed: (
    existingSections: readonly VisibleInterviewSectionDefinition[],
  ) => boolean;
  templateId?: InterviewSectionTemplateId;
  sectionType?: InterviewSectionType;
}

interface TemplateInterviewSectionPresetDefinition
  extends BaseInterviewSectionPresetDefinition {
  templateId: InterviewSectionTemplateId;
}

interface CustomInterviewSectionPresetDefinition
  extends BaseInterviewSectionPresetDefinition {
  sectionType: InterviewSectionType;
}

export type InterviewSectionPresetDefinition =
  | TemplateInterviewSectionPresetDefinition
  | CustomInterviewSectionPresetDefinition;

interface InterviewSectionPresetAvailabilityArgs {
  featureFlags: {
    [FeatureFlagKey.RolePlaySections]: boolean;
  };
}

export const presets: readonly InterviewSectionPresetDefinition[] = [
  {
    key: "screening-interview",
    title: "Screening interview",
    description:
      "Ask candidates about their qualifications and previous work experience.",
    skills: ["Communication", "Experience", "Culture fit", "CEFR English"],
    checkIsAvailable: () => true,
    // This one is implied by the job type, so you can't add one explicitly.
    checkIsUsed: () => true,
    sectionType: InterviewSectionType.Questions,
  },
  ...allInterviewSectionTemplateIds.map(
    (templateId): InterviewSectionPresetDefinition => {
      const template = interviewSectionTemplateListings[templateId];

      return {
        key: `template:${templateId}`,
        title: template.title,
        description: template.description,
        skills: template.skills,
        checkIsAvailable: ({ featureFlags }) =>
          featureFlags[FeatureFlagKey.RolePlaySections],
        // You can add as many of these as you want.
        checkIsUsed: () => false,
        templateId,
      };
    },
  ),
  {
    key: "role-play",
    title: "Custom role play",
    description:
      "Simulate a conversation that candidates will need to do on the job.",
    skills: ["Communication", "Attention to detail", "Adaptability"],
    checkIsAvailable: ({ featureFlags }) =>
      featureFlags[FeatureFlagKey.RolePlaySections],
    // You can add as many of these as you want.
    checkIsUsed: () => false,
    sectionType: InterviewSectionType.RolePlay,
  },
  {
    key: "data-labeling-exercise",
    title: "Data labeling exercise",
    description: "Walk through a data annotation task with candidates.",
    skills: ["Problem-solving", "Critical thinking", "Attention to detail"],
    checkIsAvailable: () => true,
    // You can only add one of these.
    checkIsUsed: (existingSections) =>
      existingSections.some(
        (section) => section.settings.type === InterviewSectionType.Case,
      ),
    sectionType: InterviewSectionType.Case,
  },
];

export function useInterviewSectionPresetAvailability(): (
  preset: InterviewSectionPresetDefinition,
) => boolean {
  const availabilityArgs: InterviewSectionPresetAvailabilityArgs = {
    featureFlags: {
      [FeatureFlagKey.RolePlaySections]:
        useIsFeatureFlagEnabled(FeatureFlagKey.RolePlaySections) ?? false,
    },
  };

  return (preset) => preset.checkIsAvailable(availabilityArgs);
}
