import * as React from "react";

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
  sameTab?: boolean;
  href: string;
}

export function Link({
  children,
  href,
  sameTab,
  ...other
}: LinkProps): JSX.Element {
  return (
    <a
      href={href}
      rel={sameTab ? undefined : "noreferrer"}
      target={sameTab ? undefined : "_blank"}
      {...other}
      className="font-medium text-blue-600 hover:text-blue-500"
    >
      {children}
    </a>
  );
}
