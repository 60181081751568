import { TrashIcon } from "@heroicons/react/24/outline";
import {
  ButtonVariantsEnum,
  IconButton,
  ModalVariantsEnum,
  Pill,
  PillColorsEnum,
  SimpleModal,
  SimpleSelect,
  SizesEnum,
  TableSkeleton,
} from "@repo/ui";
import {
  allTeammateRoleTypes,
  CompanyInvitation,
  isPredefinedTeammateRoleType,
  PermissionActions,
  PermissionSubjects,
  predefinedRoleNameByType,
  Teammate,
  TeammateRoleTypes,
  type PredefinedTeammateRoleType,
} from "app-types";
import { format } from "date-fns";
import { FC, useState } from "react";
import { Can } from "../../../helpers/teammateAuthorizationContext";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { selectIsRecruitingModeCompany } from "../../company/companySlice";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { selectTeammate } from "../../teammate/teammateSlice";
import { updateTeammate } from "../../teammates/teammatesSlice";
import { SettingsTable } from "../shared/settingsTable";
import { SettingsTableBodySection } from "../shared/settingsTableBodySection";
import { SettingsTableDataCell } from "../shared/settingsTableDataCell";
import { SettingsTableErrorBodyContent } from "../shared/settingsTableErrorBodyContent";
import { SettingsTableHeaderCell } from "../shared/settingsTableHeaderCell";
import { SettingsTableHeaderSection } from "../shared/settingsTableHeaderSection";

const roleOptions = allTeammateRoleTypes
  .filter(isPredefinedTeammateRoleType)
  .map((role) => ({
    label: predefinedRoleNameByType[role],
    value: role,
  }));

interface TeammatesTableProps {
  teammates: Teammate[];
  pendingInvitations: CompanyInvitation[];
  onDeleteInvitation: (invitationId: string) => void;
  isLoading: boolean;
  hasError: boolean;
}

export const TeammatesTable: FC<TeammatesTableProps> = ({
  pendingInvitations,
  teammates,
  onDeleteInvitation,
  isLoading,
  hasError,
}) => {
  const isRecruitingModeCompany = useAppSelector(selectIsRecruitingModeCompany);
  const currentTeammate = useAppSelector(selectTeammate);
  const dispatch = useAppDispatch();
  const [invitationToDelete, setInvitationToDelete] = useState<string | null>(
    null,
  );

  // Filter out any invitations for existing teammates
  const pendingInvitationsToRender = pendingInvitations.filter((i) => {
    return !teammates.find((t) => t.email === i.recipient_email);
  });

  const invitationRows = pendingInvitationsToRender.map((i) => {
    return {
      id: i.id,
      isInvitation: true,
      email: i.recipient_email,
      role: i.metadata.permissions.role.type ?? TeammateRoleTypes.EDITOR,
      status: "Invited",
      dateAddedTimestamp: new Date(i.created_at).getTime(),
    };
  });

  const teammateRows = teammates.map((t) => {
    return {
      id: t.id,
      isInvitation: false,
      email: t.email,
      role: t.permissions.role.type,
      status: "Active",
      dateAddedTimestamp: new Date(t.created_at).getTime(),
    };
  });

  // Display rows sorted from newest to oldest
  const rows = [...invitationRows, ...teammateRows].sort(
    (a, b) => b.dateAddedTimestamp - a.dateAddedTimestamp,
  );

  const updateTeammateRole = async (
    teammateId: string,
    role: PredefinedTeammateRoleType,
  ) => {
    try {
      await dispatch(
        updateTeammate({
          id: teammateId,
          updates: { permissions: { role: { type: role } } },
        }),
      ).unwrap();

      showNotification(dispatch, {
        id: `teammate-role-updated-${teammateId}`,
        primaryMessage: `Teammate role updated`,
        type: NotificationTypeEnum.SUCCESS,
      });
    } catch (error) {
      showNotification(dispatch, {
        id: `teammate-role-updated-${teammateId}`,
        primaryMessage: `Teammate role update failed`,
        type: NotificationTypeEnum.FAILURE,
      });
    }
  };

  const renderTableRows = () => {
    if (isLoading) return <TableSkeleton />;

    if (hasError)
      return (
        <SettingsTableErrorBodyContent colSpan={3}>
          An error occurred. Please refresh the page and try again.
        </SettingsTableErrorBodyContent>
      );

    return (
      <>
        {rows.map((row) => (
          <tr className="group relative" key={row.id}>
            <SettingsTableDataCell>{row.email}</SettingsTableDataCell>
            {isRecruitingModeCompany && (
              <Can
                I={PermissionActions.UPDATE}
                a={PermissionSubjects.TEAMMATES}
                passThrough={true}
              >
                {(allowed) => (
                  <SettingsTableDataCell>
                    <SimpleSelect
                      label="Role"
                      isLabelHidden
                      value={row.role}
                      onChange={(option) => {
                        // Casting is safe because we filter the options to only include predefined roles
                        // TODO: remove cast when we introduce custom roles.
                        const changedRole =
                          option as PredefinedTeammateRoleType;
                        if (changedRole !== row.role) {
                          void updateTeammateRole(row.id, changedRole);
                        }
                      }}
                      options={roleOptions}
                      isDisabled={
                        !allowed ||
                        row.isInvitation ||
                        row.id === currentTeammate?.id
                      }
                    />
                  </SettingsTableDataCell>
                )}
              </Can>
            )}
            <SettingsTableDataCell>
              <Pill
                label={row.status}
                size={SizesEnum.SMALL}
                color={
                  row.isInvitation
                    ? PillColorsEnum.YELLOW
                    : PillColorsEnum.GREEN
                }
              />
            </SettingsTableDataCell>
            <SettingsTableDataCell>
              {format(new Date(row.dateAddedTimestamp), "MMM dd yyyy")}
              {row.isInvitation && (
                <div className="text-gray-500 absolute right-2 top-3 cursor-pointer hidden group-hover:block">
                  <IconButton
                    variant={ButtonVariantsEnum.Secondary}
                    icon={<TrashIcon className="h-4 w-4" />}
                    onClick={() => {
                      setInvitationToDelete(row.id);
                    }}
                  />
                </div>
              )}
            </SettingsTableDataCell>
          </tr>
        ))}
      </>
    );
  };

  return (
    <>
      <SettingsTable>
        <SettingsTableHeaderSection>
          {isRecruitingModeCompany ? (
            <tr>
              <SettingsTableHeaderCell className="w-[33%]">
                Email
              </SettingsTableHeaderCell>
              <SettingsTableHeaderCell className="w-[22%]">
                Role
              </SettingsTableHeaderCell>
              <SettingsTableHeaderCell className="w-[19%]">
                Status
              </SettingsTableHeaderCell>
              <SettingsTableHeaderCell className="w-[26%]">
                Date added
              </SettingsTableHeaderCell>
            </tr>
          ) : (
            <tr>
              <SettingsTableHeaderCell className="w-[55%]">
                Email
              </SettingsTableHeaderCell>
              <SettingsTableHeaderCell className="w-[20%]">
                Status
              </SettingsTableHeaderCell>
              <SettingsTableHeaderCell className="w-[25%]">
                Date added
              </SettingsTableHeaderCell>
            </tr>
          )}
        </SettingsTableHeaderSection>
        <SettingsTableBodySection>{renderTableRows()}</SettingsTableBodySection>
      </SettingsTable>
      <SimpleModal
        isOpen={Boolean(invitationToDelete)}
        onCancel={() => {
          setInvitationToDelete(null);
        }}
        variant={ModalVariantsEnum.Warning}
        title="Revoke invitation"
        subtitle="Are you sure you want to cancel this invitation?"
        confirmButtonText="Cancel invitation"
        onConfirm={() => {
          invitationToDelete && onDeleteInvitation(invitationToDelete);
          setInvitationToDelete(null);
        }}
      />
    </>
  );
};
