import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { XCircleIcon } from "@heroicons/react/24/outline";
import * as React from "react";
import { twJoin } from "tailwind-merge";
import { tv } from "tailwind-variants";

export interface SelectOption {
  name: string;
  disabled?: boolean;
  value?: string | number;
}

interface SelectProps {
  options: SelectOption[];
  currentSelection: SelectOption | undefined;
  onChange: (value: SelectOption) => void;
  onClick?: () => void;
  placeholder?: string;
  label?: React.ReactNode;
  required?: boolean;
  onClear?: () => void;
  loadingMessage?: string;
  tabIndex?: number;
  leftIcon?: React.ReactNode;
  isDisabled?: boolean;
}

// Styles shared between this component and SimpleSelect.
export const select = tv({
  slots: {
    button:
      "w-full cursor-default min-h-[36px] min-w-[100px] rounded-md bg-white py-1.5 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 text-sm sm:leading-6 disabled:opacity-50 disabled:cursor-not-allowed",
    placeholder: "text-gray-400",
    chevron: "size-5 shrink-0 text-gray-400",
    popover:
      "max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
  },
});

export const Select: React.FC<SelectProps> = (props) => {
  const {
    options,
    onChange,
    currentSelection,
    label,
    placeholder,
    onClear,
    loadingMessage,
    required,
    tabIndex,
    leftIcon,
    isDisabled,
  } = props;
  const buttonRef = React.useRef<HTMLButtonElement>(null); // Ref for the dropdown button

  const [shouldOpenUpwards, setShouldOpenUpwards] = React.useState(false);

  const handleButtonClick = (): void => {
    // Determine if the dropdown should open upwards or downwards
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceNeeded = 200; // Assuming the dropdown needs 200px to render properly
      setShouldOpenUpwards(spaceBelow < spaceNeeded);
    }

    // Call any additional onClick logic if provided
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <Listbox
      onChange={onChange}
      value={currentSelection ?? null}
      disabled={isDisabled}
    >
      {({ open }: { open: boolean }) => (
        <div className="relative">
          {label ? (
            <label
              className="block text-sm font-medium leading-6 text-gray-900 mb-2"
              htmlFor="location"
            >
              {label}
              {required ? <span className="text-red-500 ml-1">*</span> : null}
            </label>
          ) : null}
          <div className="relative">
            <Listbox.Button
              className={select().button({
                className: twJoin(
                  "relative",
                  leftIcon ? "pl-8" : "pl-3",
                  "pr-7",
                ),
              })}
              onClick={handleButtonClick}
              ref={buttonRef}
              tabIndex={tabIndex}
            >
              {leftIcon ? (
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2.5">
                  {leftIcon}
                </div>
              ) : null}
              {loadingMessage ? (
                <span className={select().placeholder()}>{loadingMessage}</span>
              ) : (
                <>
                  {currentSelection ? (
                    <span className="block truncate">
                      {currentSelection.name}
                    </span>
                  ) : null}
                  {!currentSelection && placeholder ? (
                    <span className={select().placeholder()}>
                      {placeholder}
                    </span>
                  ) : null}
                </>
              )}
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  aria-hidden="true"
                  className={select().chevron()}
                />
              </span>
            </Listbox.Button>

            {onClear && currentSelection ? (
              <button
                className="absolute inset-y-0 right-8 flex items-center"
                onClick={(e) => {
                  e.stopPropagation();
                  onClear();
                }}
                type="button"
              >
                <XCircleIcon className="h-4 w-4 text-gray-700" />
              </button>
            ) : null}

            <Transition
              as={React.Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              show={open}
            >
              <Listbox.Options
                className={select().popover({
                  className: twJoin(
                    "absolute z-20 mt-1 w-full",
                    shouldOpenUpwards ? "bottom-full" : "top-full",
                  ),
                })}
              >
                {options.map((option) => (
                  <Listbox.Option
                    className={({ active }) =>
                      twJoin(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                      )
                    }
                    key={option.name}
                    value={option}
                  >
                    {({
                      selected,
                      active,
                    }: {
                      selected: boolean;
                      active: boolean;
                    }) => (
                      <>
                        <span
                          className={twJoin(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate",
                          )}
                        >
                          {option.name}
                        </span>

                        {selected ? (
                          <span
                            className={twJoin(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                            )}
                          >
                            <CheckIcon aria-hidden="true" className="h-5 w-5" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};
