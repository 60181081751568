import { FC } from "react";
import { twJoin } from "tailwind-merge";

export interface TabsProps {
  items: string[];
  currentTab: string;
  onSelectTab: (newTab: string) => void;
}

export const Tabs: FC<TabsProps> = ({ items, currentTab, onSelectTab }) => {
  return (
    <nav className="-mb-px flex space-x-8">
      {items.map((tab) => (
        <button
          className={twJoin(
            tab === currentTab
              ? "border-indigo-500 text-indigo-600"
              : "border-transparent text-gray-700 hover:border-gray-500 hover:text-gray-900",
            "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-semibold",
          )}
          disabled={tab === currentTab}
          key={tab}
          onClick={() => {
            onSelectTab(tab);
          }}
          type="button"
        >
          {tab}
        </button>
      ))}
    </nav>
  );
};
