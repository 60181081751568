import { ShieldCheckIcon } from "@heroicons/react/24/outline";
import {
  Button,
  ButtonVariantsEnum,
  InfoBanner,
  Modal,
  ModalHeader,
  ModalVariantsEnum,
  Select,
} from "@repo/ui";
import { Company } from "app-types";
import { FC, useState } from "react";
import {
  DEFAULT_REFILL_AMOUNT_OPTION,
  DEFAULT_REFILL_THRESHOLD_OPTION,
  NO_BALANCE_REFILL_OPTION,
  REFILL_AMOUNT_OPTIONS,
  REFILL_AMOUNT_OPTION_TO_VALUE,
  REFILL_THRESHOLD_OPTIONS,
  REFILL_THRESHOLD_OPTION_TO_VALUE,
  findUnitAmountOption,
} from "../../../helpers/transactionBalanceHelpers";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  selectCompanyLoadingState,
  updateCompanyData,
} from "../../company/companySlice";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";

interface AutoRefillModalProps {
  isOpen: boolean;
  onClose: () => void;
  company: Company;
}

export const AutoRefillModal: FC<AutoRefillModalProps> = ({
  isOpen,
  onClose,
  company,
}) => {
  const dispatch = useAppDispatch();

  const companyLoadingState = useAppSelector(selectCompanyLoadingState);

  const { balance_refill_unit_amount, balance_refill_threshold_unit_amount } =
    company.settings;

  // Set the initial value for the refill amount
  const refillAmountInitialValue =
    (balance_refill_unit_amount &&
      findUnitAmountOption(
        balance_refill_unit_amount || 0,
        REFILL_AMOUNT_OPTION_TO_VALUE,
      )) ||
    NO_BALANCE_REFILL_OPTION;
  const [balanceRefillAmount, setBalanceFillAmount] = useState<string>(
    refillAmountInitialValue,
  );

  // Set the initial value for the refill threshold
  const refillThresholdInitialValue =
    (balance_refill_threshold_unit_amount &&
      findUnitAmountOption(
        balance_refill_threshold_unit_amount,
        REFILL_THRESHOLD_OPTION_TO_VALUE,
      )) ||
    DEFAULT_REFILL_THRESHOLD_OPTION;
  const [balanceThresholdAmount, setBalanceThresholdAmount] = useState<string>(
    refillThresholdInitialValue,
  );

  const isRefillDisabled = balanceRefillAmount === NO_BALANCE_REFILL_OPTION;

  const onClickSubmit = async () => {
    try {
      // Update company settings
      dispatch(
        updateCompanyData({
          id: company.id,
          updates: {
            settings: {
              ...company.settings,
              balance_refill_unit_amount: isRefillDisabled
                ? 0
                : REFILL_AMOUNT_OPTION_TO_VALUE[balanceRefillAmount] * 100, // Convert to unit_amount (cents)
              balance_refill_threshold_unit_amount: isRefillDisabled
                ? null
                : REFILL_THRESHOLD_OPTION_TO_VALUE[balanceThresholdAmount] *
                  100,
            },
          },
        }),
      );

      showNotification(dispatch, {
        id: `balance-refill-${new Date().getTime()}`,
        primaryMessage: `Auto-refill preferences updated`,
        type: NotificationTypeEnum.SUCCESS,
      });

      onClose();
    } catch (err) {
      showNotification(dispatch, {
        id: `balance-refill-error-${new Date().getTime()}`,
        primaryMessage: `Sorry, an error occurred. Please try again.`,
        type: NotificationTypeEnum.FAILURE,
      });

      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onCancel={onClose}>
      <ModalHeader onClose={onClose}>Auto-Refill Preferences</ModalHeader>

      <div className="flex flex-col space-y-4 mt-4">
        <div className="flex flex-row items-end space-x-4">
          <div className="w-[150px]">
            <Select
              label={"Refill amount (USD)"}
              onChange={(option) => {
                setBalanceFillAmount(option.name);
              }}
              options={REFILL_AMOUNT_OPTIONS}
              currentSelection={{ name: balanceRefillAmount }}
            />
          </div>
          {isRefillDisabled ? (
            <div>
              <Button
                variant={ButtonVariantsEnum.Primary}
                onClick={() => {
                  setBalanceFillAmount(DEFAULT_REFILL_AMOUNT_OPTION);
                  setBalanceThresholdAmount(DEFAULT_REFILL_THRESHOLD_OPTION);
                }}
              >
                Enable Auto-Refill
              </Button>
            </div>
          ) : (
            <div className="w-[150px]">
              <Select
                label="Refill threshold (USD)"
                onChange={(option) => {
                  setBalanceThresholdAmount(option.name);
                }}
                options={REFILL_THRESHOLD_OPTIONS}
                currentSelection={{ name: balanceThresholdAmount }}
              />
            </div>
          )}
        </div>
        {isRefillDisabled ? (
          <InfoBanner
            title="Auto-Refill Disabled"
            description={
              <>
                {`Disabling auto-refill can cause a poor customer experience if users get blocked from completing interviews due to an insufficient balance. Instead of disabling auto-refill, consider using the "Monthly interview limit" Project setting.`}
              </>
            }
            variant={ModalVariantsEnum.Warning}
          />
        ) : (
          <>
            <div className="text-sm text-gray-600">
              {`When gift cards are being sent and the balance reaches ${balanceThresholdAmount} or less, ${balanceRefillAmount} will be added and the billing contact will be notified through an email invoice.`}
            </div>
            <InfoBanner
              title="Balance Protection Built-in"
              description={
                <>
                  {`Each completed interview is human-reviewed for authenticity
            before a gift card is sent using your balance. You can also cap the number of interviews you receive using the "Monthly interview limit" Project setting.`}
                </>
              }
              icon={<ShieldCheckIcon className="h-5 w-5 text-blue-400" />}
            />
          </>
        )}
      </div>

      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <div className="ml-3">
          <Button
            variant={
              isRefillDisabled
                ? ButtonVariantsEnum.Warning
                : ButtonVariantsEnum.Primary
            }
            isDisabled={companyLoadingState === "loading"}
            onClick={() => onClickSubmit()}
            isLoading={companyLoadingState === "loading"}
          >
            Save Preferences
          </Button>
        </div>
        <Button
          variant={ButtonVariantsEnum.Secondary}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
