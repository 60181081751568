import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { noop } from "lodash";
import React, { FC, Fragment, ReactNode, useState } from "react";
import { tv } from "tailwind-variants";
import { Button, ButtonVariantsEnum } from "../Buttons/button";
import { SizesEnum } from "../helpers/helpers";
import { ModalBackdrop } from "./modalBackdrop";
import { modalOverlay } from "./modalOverlay";
import { ModalStage } from "./modalStage";

interface ModalProps {
  isOpen: boolean;
  onCancel?: () => void;
  children?: React.ReactNode;
  size?: SizesEnum.LARGE | SizesEnum.SMALL;
}

export enum SimpleModalVariantsEnum {
  Standard = "STANDARD",
  Warning = "WARNING",
}

interface SimpleModalProps extends ModalProps {
  variant: SimpleModalVariantsEnum;
  icon?: ReactNode;
  title: string;
  subtitle: string | ReactNode;
  confirmButtonText: string;
  onConfirm: () => void;
  isPrimaryButtonLoading?: boolean;
}

/** Styles for the modal dialog itself. */
export const modal = tv({
  // The entering/exiting data attributes work when used with react-aria-components.
  base: "rounded-lg bg-white shadow-xl sm:my-8 data-[entering]:animate-popover-enter data-[exiting]:animate-popover-exit",
  variants: {
    size: {
      [SizesEnum.LARGE]: "sm:max-w-4xl",
      [SizesEnum.SMALL]: "sm:max-w-lg",
    },
  },
  defaultVariants: {
    size: SizesEnum.SMALL,
  },
});

export const Modal: React.FC<ModalProps> = (props) => {
  const { isOpen, onCancel, size = SizesEnum.SMALL, children } = props;

  // To avoid rendering stale content during the leave animation. Inspired by react-spectrum's DialogContainer and framer-motion's AnimatePresence.
  const [openChildren, setOpenChildren] = useState<ReactNode>(null);

  if (isOpen && openChildren !== children) {
    setOpenChildren(children);
    // Short-circuit this render.
    return null;
  }

  const afterLeave = (): void => {
    setOpenChildren(null);
  };

  return (
    <Transition.Root afterLeave={afterLeave} as={Fragment} show={isOpen}>
      <Dialog className={modalOverlay()} onClose={onCancel || noop}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ModalBackdrop />
        </Transition.Child>

        <ModalStage>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel
              className={modal({
                size,
                className: `relative transform px-4 pb-4 pt-5 text-left transition-all sm:p-6`,
              })}
            >
              {openChildren}
            </Dialog.Panel>
          </Transition.Child>
        </ModalStage>
      </Dialog>
    </Transition.Root>
  );
};

export const SimpleModal: FC<SimpleModalProps> = (props) => {
  const {
    isOpen,
    variant,
    icon,
    title,
    subtitle,
    confirmButtonText,
    onCancel,
    onConfirm,
    isPrimaryButtonLoading,
    size,
  } = props;

  const renderIcon = (): JSX.Element | null => {
    if (variant === SimpleModalVariantsEnum.Warning) {
      return (
        <div className="mb-3 m:mt-5 mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
          <ExclamationTriangleIcon
            aria-hidden="true"
            className="h-6 w-6 text-red-600"
          />
        </div>
      );
    }

    if (!icon) return null;

    return <div className="mb-4">{icon}</div>;
  };

  const renderPrimaryButton = (): JSX.Element => {
    if (variant === SimpleModalVariantsEnum.Warning)
      return (
        <Button
          isLoading={isPrimaryButtonLoading}
          onClick={onConfirm}
          variant={ButtonVariantsEnum.Warning}
        >
          {confirmButtonText}
        </Button>
      );

    return (
      <Button
        isLoading={isPrimaryButtonLoading}
        onClick={onConfirm}
        variant={ButtonVariantsEnum.Primary}
      >
        {confirmButtonText}
      </Button>
    );
  };

  const renderButtons = (): JSX.Element => {
    if (onCancel)
      return (
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <Button onClick={onCancel} variant={ButtonVariantsEnum.Secondary}>
            Cancel
          </Button>
          {renderPrimaryButton()}
        </div>
      );

    // If no cancel handler, only render the primary button
    return (
      <div className="mt-5 sm:mt-6 flex justify-center">
        {renderPrimaryButton()}
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} onCancel={onCancel} size={size}>
      <>
        <div>
          {renderIcon()}
          <div className="text-center">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              {title}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{subtitle}</p>
            </div>
            {props.children}
          </div>
        </div>
        {renderButtons()}
      </>
    </Modal>
  );
};
