// Sentry must be first.
import * as Sentry from "@sentry/react";

import { setupListeners } from "@reduxjs/toolkit/query";
import { escapeRegExp } from "lodash";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./app";
import store from "./app/store";
import "./index.css";

// Enable Sentry only in production, unless VITE_SENTRY_ENABLED is set.
const isSentryEnabled =
  import.meta.env.PROD || import.meta.env.VITE_SENTRY_ENABLED;

if (isSentryEnabled) {
  // TODO: Use Posthog <> Sentry integration for error reporting.
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_ADMIN_DSN,
    // TODO: Set up component tracing, React Router, and Redux integrations.
    integrations: [Sentry.browserTracingIntegration()],
    environment: import.meta.env.PROD ? "production" : "development",
    // Capture 100% of the transactions for tracing
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
      /^\//,
      new RegExp(`^${escapeRegExp(import.meta.env.VITE_API_BASE_URL)}`, "i"),
    ],
  });
}

const el = document.getElementById("root");
if (el) {
  const root = createRoot(el);

  const app = (
    <Provider store={store}>
      <App />
    </Provider>
  );

  root.render(
    import.meta.env.PROD ? app : <React.StrictMode>{app}</React.StrictMode>,
  );

  setupListeners(store.dispatch);
} else {
  throw new Error("Could not find root element");
}
