import {
  Button,
  ButtonVariantsEnum,
  DefaultSkeleton,
  InfoBanner,
  Label,
  SearchableSelect,
  SearchableSelectModesEnum,
  SearchableSelectOption,
  SizesEnum,
} from "@repo/ui";
import {
  LoadingStatesEnum,
  PermissionActions,
  PermissionSubjects,
  SlackChannel,
  SlackIntegrationSettings,
} from "app-types";
import { FC, useEffect, useState } from "react";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { Can } from "../../../helpers/teammateAuthorizationContext";
import { openOAuthSigninPopupForIntegration } from "../../../helpers/urlHelpers";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { selectCompany } from "../../company/companySlice";
import {
  selectSlackIntegration,
  updateIntegration,
} from "../../integrations/integrationsSlice";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { OAuthIntegrationButtonsRow } from "./oauthIntegrationCtas";

export const fetchSlackChannelsForIntegration = async (
  integrationId: string,
): Promise<SlackChannel[]> => {
  const axios = await getAxiosInstanceWithAuth();
  const { data } = await axios.get(`/slack/${integrationId}/channels`);

  return data.map((channel: any) => ({
    name: `# ${channel.name}`,
    id: channel.id,
  }));
};

export const SlackIntegrationSection: FC = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  const slackIntegration = useAppSelector(selectSlackIntegration);

  const [listChannelsLoadingState, setListChannelsLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADED);
  const [slackChannels, setSlackChannels] = useState<SlackChannel[]>([]);

  const listSlackChannels = async () => {
    if (!slackIntegration) return;

    setListChannelsLoadingState(LoadingStatesEnum.LOADING);
    try {
      const channels = await fetchSlackChannelsForIntegration(
        slackIntegration.id,
      );

      setSlackChannels(channels);

      setListChannelsLoadingState(LoadingStatesEnum.LOADED);
    } catch (err) {
      setListChannelsLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  const onChannelSelect = (option: SearchableSelectOption | null) => {
    if (!slackIntegration) return;

    dispatch(
      updateIntegration({
        id: slackIntegration.id,
        changes: {
          settings: {
            ...slackIntegration.settings,
            channel_id: option ? option.id : null,
          },
        },
      }),
    );

    showNotification(dispatch, {
      id: `slack-channel-select-${new Date().getTime()}`,
      primaryMessage: "Slack channel updated",
      type: NotificationTypeEnum.SUCCESS,
    });
  };

  useEffect(() => {
    listSlackChannels();
  }, [slackIntegration]);

  const renderContent = () => {
    if (!slackIntegration)
      return (
        <Can
          I={PermissionActions.CREATE}
          a={PermissionSubjects.INTEGRATIONS}
          passThrough={true}
        >
          {(allowed) => (
            <Button
              onClick={() => openOAuthSigninPopupForIntegration("slack")}
              variant={ButtonVariantsEnum.Primary}
              isDisabled={!allowed}
            >
              Login with Slack
            </Button>
          )}
        </Can>
      );

    return (
      <div className="flex flex-col space-y-5">
        <OAuthIntegrationButtonsRow integration={slackIntegration} />

        {listChannelsLoadingState === LoadingStatesEnum.LOADING ? (
          <DefaultSkeleton />
        ) : (
          <div>
            <div className="w-72">
              <Can
                I={PermissionActions.UPDATE}
                a={PermissionSubjects.INTEGRATIONS}
                passThrough={true}
              >
                {(allowed) => (
                  <SearchableSelect
                    label="Default Slack channel"
                    mode={SearchableSelectModesEnum.SINGLE}
                    placeholder="Search for a public channel..."
                    selection={
                      slackChannels.find(
                        (c: SlackChannel) =>
                          c.id ===
                          (
                            slackIntegration.settings as SlackIntegrationSettings
                          ).channel_id,
                      ) || null
                    }
                    options={slackChannels || []}
                    onChange={onChannelSelect}
                    shouldDisableCustomOptions
                    isDisabled={!allowed}
                  />
                )}
              </Can>
            </div>
            <div className="mt-2 text-sm text-gray-600">
              The default Slack channel to send notifications to when an
              interview is completed. We recommend choosing a popular channel so
              that insights can be shared across your team. You can also
              customize Slack notifications for a particular project from that
              project's settings page.
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="mb-4">
        <Label size={SizesEnum.MEDIUM}>Slack</Label>
      </div>
      <div className="mb-4">
        <InfoBanner
          title="How the Slack integration works"
          description={
            <ul className="list-disc ml-4">
              <li className="mb-2">
                When an interview is completed, a summary of the interview and a
                link to the full transcript will be sent to Slack
              </li>
              <li className="mb-2">
                You can pick a different Slack channel for each project
              </li>
              {company && company.has_sensitive_data ? (
                <li className="mb-2">
                  If you expect interviews to contain sensitive information,
                  ensure that your Slack account is allowed to store that
                  information.
                </li>
              ) : null}
            </ul>
          }
        />
      </div>

      {renderContent()}
    </div>
  );
};
