import {
  Button,
  ButtonVariantsEnum,
  Checkbox,
  DefaultSkeleton,
  Label,
  SearchableSelect,
  SearchableSelectModesEnum,
  SearchableSelectOption,
  SizesEnum,
} from "@repo/ui";
import {
  LoadingStatesEnum,
  PermissionActions,
  PermissionSubjects,
  Project,
  ProjectUpdate,
  SlackChannel,
  SlackIntegrationSettings,
} from "app-types";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Can } from "../../helpers/teammateAuthorizationContext";
import { useAppSelector } from "../../hooks/hook";
import { fetchSlackChannelsForIntegration } from "../SettingsPage/integrationSettings/slackIntegrationSection";
import { selectSlackIntegration } from "../integrations/integrationsSlice";

// Cache the Slack channels to avoid fetching them multiple times.
let slackChannels: SlackChannel[] = [];

interface SlackProjectNotificationSectionProps {
  project: Project;
  onSave: (changes: ProjectUpdate) => void;
}

export const SlackProjectNotificationsSection: FC<
  SlackProjectNotificationSectionProps
> = ({ project, onSave }) => {
  const slackIntegration = useAppSelector(selectSlackIntegration);
  const navigate = useNavigate();

  const [slackChannelsLoadingState, setSlackChannelsLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADED);

  useEffect(() => {
    const fetchSlackChannels = async () => {
      if (slackIntegration && slackChannels.length === 0) {
        try {
          setSlackChannelsLoadingState(LoadingStatesEnum.LOADING);
          const channels = await fetchSlackChannelsForIntegration(
            slackIntegration.id,
          );

          slackChannels = channels;
          setSlackChannelsLoadingState(LoadingStatesEnum.LOADED);
        } catch (err) {
          setSlackChannelsLoadingState(LoadingStatesEnum.ERROR);
          console.log("Error fetching Slack channels", err);
        }
      }
    };

    fetchSlackChannels();
  }, [slackIntegration]);

  const renderContent = () => {
    if (!slackIntegration) {
      return (
        <div>
          <Can
            I={PermissionActions.UPDATE}
            a={PermissionSubjects.JOB_OPENINGS}
            passThrough={true}
          >
            {(allowed) => (
              <Button
                variant={ButtonVariantsEnum.Secondary}
                onClick={() => {
                  navigate(`/settings/integrations`);
                }}
                isDisabled={!allowed}
              >
                Enable Slack integration
              </Button>
            )}
          </Can>
        </div>
      );
    }

    const maybeRenderSelect = () => {
      if (!project.settings.is_slack_enabled) return null;

      if (slackChannelsLoadingState === LoadingStatesEnum.LOADING) {
        return <DefaultSkeleton />;
      }

      if (slackChannelsLoadingState === LoadingStatesEnum.ERROR) {
        return (
          <div className="text-sm text-gray-800">
            An unexpected error occurred loading Slack channels
          </div>
        );
      }

      if (!slackChannels.length) return null;

      const options = [...slackChannels];

      const companyLevelSlackChannelId = (
        slackIntegration.settings as SlackIntegrationSettings
      ).channel_id;

      const formattedCompanyWideChannelIndex = options.findIndex(
        (c) => c.id === companyLevelSlackChannelId,
      );

      if (formattedCompanyWideChannelIndex !== -1) {
        // If the company-wide channel can be found, format it with (Default) and place it at the start of the options array
        const formattedCompanyWideChannel = {
          ...options[formattedCompanyWideChannelIndex],
          name: `${options[formattedCompanyWideChannelIndex].name} (Default)`,
        };

        // Remove the original channel from the array
        options.splice(formattedCompanyWideChannelIndex, 1);
        // Prepend the formatted channel to the start of the array
        options.unshift(formattedCompanyWideChannel);
      }

      return (
        <div>
          <div className="w-72 mt-2">
            <Can
              I={PermissionActions.UPDATE}
              a={PermissionSubjects.JOB_OPENINGS}
              passThrough={true}
            >
              {(allowed) => (
                <SearchableSelect
                  label="Target Slack channel"
                  mode={SearchableSelectModesEnum.SINGLE}
                  placeholder="Search for a public channel..."
                  selection={
                    // Default to the project specific channel, then fallback to company-wide channel
                    options.find(
                      (o) => o.id === project.settings.slack_channel_id,
                    ) ||
                    options.find((o) => o.id === companyLevelSlackChannelId) ||
                    null
                  }
                  options={options}
                  onChange={(option: SearchableSelectOption | null) => {
                    onSave({
                      id: project.id,
                      settings: {
                        ...project.settings,
                        slack_channel_id: option ? option.id : null,
                      },
                    });
                  }}
                  shouldDisableCustomOptions
                  isDisabled={!allowed}
                />
              )}
            </Can>
          </div>
          <div className="mt-2 text-sm text-gray-600">
            An interview summary and link to the full transcript will be sent
            for each completed interview.
          </div>
        </div>
      );
    };

    return (
      <>
        <Can
          I={PermissionActions.UPDATE}
          a={PermissionSubjects.JOB_OPENINGS}
          passThrough={true}
        >
          {(allowed) => (
            <Checkbox
              isChecked={project.settings.is_slack_enabled}
              onChange={(isChecked) => {
                onSave({
                  id: project.id,
                  settings: {
                    ...project.settings,
                    is_slack_enabled: isChecked,
                  },
                });
              }}
              label="Send Slack notifications when interviews are completed"
              isDisabled={!allowed}
            />
          )}
        </Can>
        {maybeRenderSelect()}
      </>
    );
  };

  return (
    <div className="flex flex-col justify-between mb-3">
      <Label size={SizesEnum.SMALL} className="mb-2">
        Slack
      </Label>
      {renderContent()}
    </div>
  );
};
