import { FC, useMemo } from "react";

interface CheckboxProps {
  isChecked: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  isDisabled?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({
  isChecked,
  onChange,
  label,
  isDisabled,
}) => {
  // Generate a unique ID for each checkbox to support multiple instances
  const id = useMemo(
    () => `checkbox-${Math.random().toString(36).substr(2, 9)}`,
    [],
  );

  return (
    <label className="flex items-center cursor-pointer" htmlFor={id}>
      <input
        checked={isChecked}
        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 focus:border-indigo-500"
        id={id}
        onChange={({ target: { checked } }) => {
          onChange(checked);
        }}
        type="checkbox"
        disabled={isDisabled}
      />
      {label ? (
        <span className="ml-2 text-sm text-gray-800">{label}</span>
      ) : null}
    </label>
  );
};
