import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { BasicQuestion } from "app-types";
import { useReducer, type Dispatch, type Reducer } from "react";

export interface QuestionsState {
  questions: readonly QuestionWithTemporaryId[];
  hasMadeChanges: boolean;
}

export type QuestionsAction = ReturnType<
  (typeof questionsActions)[keyof typeof questionsActions]
>;

export type QuestionsDispatch = Dispatch<QuestionsAction>;

export interface QuestionWithTemporaryId extends BasicQuestion {
  id: string;
}

/**
 * For stable react keys, we append random IDs to questions.
 */
function appendTemporaryIdToQuestions(
  questions: readonly BasicQuestion[],
): QuestionWithTemporaryId[] {
  return questions.map((q) => ({
    ...q,
    id: crypto.randomUUID(),
  }));
}

function createEmptyQuestion(): QuestionWithTemporaryId {
  return {
    question: "",
    id: crypto.randomUUID(),
  };
}

const initialState: QuestionsState = {
  questions: [],
  hasMadeChanges: false,
};

// Only used with `useReducer` below.
const slice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    initialized: (state, action: PayloadAction<readonly BasicQuestion[]>) => {
      const basicQuestions = action.payload;

      state.questions =
        basicQuestions.length > 0
          ? appendTemporaryIdToQuestions(basicQuestions)
          : [createEmptyQuestion()];
      state.hasMadeChanges = false;
    },

    imported: (state, action: PayloadAction<readonly BasicQuestion[]>) => {
      state.questions = appendTemporaryIdToQuestions(action.payload);
      state.hasMadeChanges = true;
    },

    reordered: (
      state,
      action: PayloadAction<readonly QuestionWithTemporaryId[]>,
    ) => {
      state.questions = [...action.payload];
      state.hasMadeChanges = true;
    },

    added: (state) => {
      state.questions.push(createEmptyQuestion());
      state.hasMadeChanges = true;
    },

    inserted: (state, action: PayloadAction<{ index: number }>) => {
      state.questions.splice(action.payload.index, 0, createEmptyQuestion());
      state.hasMadeChanges = true;
    },

    updated: (
      state,
      action: PayloadAction<{ index: number; basicQuestion: BasicQuestion }>,
    ) => {
      const { index, basicQuestion } = action.payload;

      state.questions[index] = {
        ...basicQuestion,
        id: state.questions[index].id,
      };
      state.hasMadeChanges = true;
    },

    deleted: (state, action: PayloadAction<{ index: number }>) => {
      state.questions = state.questions.filter(
        (_, i) => i !== action.payload.index,
      );
      state.hasMadeChanges = true;
    },
  },
});

export const questionsActions = slice.actions;

export function useQuestionsStore(
  initialBasicQuestions: readonly BasicQuestion[],
) {
  return useReducer<
    Reducer<QuestionsState, QuestionsAction>,
    readonly BasicQuestion[]
  >(slice.reducer, initialBasicQuestions, (basicQuestions) =>
    slice.reducer(undefined, questionsActions.initialized(basicQuestions)),
  );
}
