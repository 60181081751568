import { FC, useState } from "react";
import { ImportQuestionsModal } from "../surveyQuestions/importQuestionsModal";
import { QuestionsManagerTable } from "./questionsManagerTable";
import {
  questionsActions,
  type QuestionsDispatch,
  type QuestionWithTemporaryId,
} from "./questionsStore";

export interface QuestionsManagerProps {
  projectId: string;
  questions: readonly QuestionWithTemporaryId[];
  onQuestionsChange: QuestionsDispatch;
}

export const QuestionsManager: FC<QuestionsManagerProps> = (props) => {
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  return (
    <>
      <QuestionsManagerTable
        questions={props.questions}
        onQuestionsChange={props.onQuestionsChange}
        onImportClick={() => {
          setIsImportModalOpen(true);
        }}
      />
      <ImportQuestionsModal
        isOpen={isImportModalOpen}
        onClose={() => {
          setIsImportModalOpen(false);
        }}
        onImport={(basicQuestions) => {
          props.onQuestionsChange(questionsActions.imported(basicQuestions));
          setIsImportModalOpen(false);
        }}
        projectId={props.projectId}
      />
    </>
  );
};
