import { InterviewRankingThresholdsSummary } from "app-types";
import { FC } from "react";

interface PercentileIndicatorProps {
  rankingScore: number | null;
  rankingThresholds: InterviewRankingThresholdsSummary | null;
}

export const InterviewRankIndicator: FC<PercentileIndicatorProps> = ({
  rankingScore,
  rankingThresholds,
}) => {
  // Don't render anything if we're missing either the Elo score or the percentile thresholds
  if (!rankingScore || !rankingThresholds) {
    return null;
  }

  // Determine which percentile the ranking score falls into
  const percentile = getPercentile(rankingScore, rankingThresholds);

  if (percentile === null) {
    return null;
  }

  return (
    <div className="inline-block w-20">
      {/* Background container */}
      <div className="relative h-5 rounded-md bg-green-100 ring-1 ring-green-600/30 overflow-hidden">
        {/* Progress fill - adding rounded-md to match container */}
        <div
          className="absolute inset-y-0 left-0 bg-green-500/40 transition-all duration-500"
          style={{ width: `${(100 - percentile).toString()}%` }}
        />
        {/* Text content */}
        <div className="relative h-full px-2 py-1 flex items-center justify-center text-xs font-medium text-green-800 truncate">
          {`Top ${percentile.toString()}%`}
        </div>
      </div>
    </div>
  );
};

function getPercentile(
  rankingScore: number,
  rankingThresholds: InterviewRankingThresholdsSummary,
): number | null {
  if (
    rankingThresholds["10th_percentile"] &&
    rankingScore >= rankingThresholds["10th_percentile"]
  ) {
    return 10;
  } else if (
    rankingThresholds["20th_percentile"] &&
    rankingScore >= rankingThresholds["20th_percentile"]
  ) {
    return 20;
  } else if (
    rankingThresholds["30th_percentile"] &&
    rankingScore >= rankingThresholds["30th_percentile"]
  ) {
    return 30;
  }

  return null;
}
