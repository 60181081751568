import { Input, Label, SaveAndCancelButtons, SizesEnum } from "@repo/ui";
import {
  checkMessageTemplateIncludesNextStepsVariable,
  CustomField,
  customFieldDataSchemaByType,
  CustomFieldTypesEnum,
  MessageTemplateType,
  PermissionActions,
  PermissionSubjects,
  Project,
  SpecialProjectCustomFieldNamesEnum,
} from "app-types";
import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  isFetchBaseQueryError,
  useAllMessageTemplatesQuery,
  useLazyProjectCustomFieldQuery,
  useUpdateProjectCustomFieldMutation,
} from "../../../api/redux";
import { Can } from "../../../helpers/teammateAuthorizationContext";
import { useAppDispatch } from "../../../hooks/hook";
import { MessageTemplateField } from "../../messageTemplates/messageTemplateField";
import { updateProject } from "../projectsSlice";

export interface MessagingAutomationSectionProps {
  project: Project;
}

export const MessagingAutomationSection: FC<
  MessagingAutomationSectionProps
> = ({ project }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [acceptanceTemplateId, setAcceptanceTemplateId] = useState<
    string | null
  >(project.settings.acceptance_template_id);
  const [rejectionTemplateId, setRejectionTemplateId] = useState<string | null>(
    project.settings.rejection_template_id,
  );
  const [isSaving, setIsSaving] = useState(false);

  const { data: allMessageTemplates, isLoading } =
    useAllMessageTemplatesQuery();

  const acceptanceTemplates =
    allMessageTemplates?.filter(
      (template) => template.type === MessageTemplateType.Advanced,
    ) ?? [];
  const rejectionTemplates =
    allMessageTemplates?.filter(
      (template) => template.type === MessageTemplateType.Rejected,
    ) ?? [];

  const advancedNextSteps = useAdvancedNextSteps(project.id);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await Promise.all([
        dispatch(
          updateProject({
            projectId: project.id,
            changes: {
              settings: {
                ...project.settings,
                acceptance_template_id: acceptanceTemplateId,
                rejection_template_id: rejectionTemplateId,
              },
            },
          }),
        ).unwrap(),
        advancedNextSteps.save(),
      ]);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setAcceptanceTemplateId(project.settings.acceptance_template_id);
    setRejectionTemplateId(project.settings.rejection_template_id);
    advancedNextSteps.reset();
  };

  const hasChanges =
    acceptanceTemplateId !== project.settings.acceptance_template_id ||
    rejectionTemplateId !== project.settings.rejection_template_id ||
    advancedNextSteps.hasChanges;

  const currentAcceptanceTemplate = acceptanceTemplates.find(
    (t) => t.id === acceptanceTemplateId,
  );
  const currentRejectionTemplate = rejectionTemplates.find(
    (t) => t.id === rejectionTemplateId,
  );

  const currentAcceptanceTemplateIncludesNextStepsVariable = useMemo(
    () =>
      currentAcceptanceTemplate !== undefined &&
      checkMessageTemplateIncludesNextStepsVariable(currentAcceptanceTemplate),
    [currentAcceptanceTemplate],
  );

  return (
    <Can
      I={PermissionActions.UPDATE}
      a={PermissionSubjects.JOB_OPENINGS}
      passThrough={true}
    >
      {(allowed) => (
        <>
          <div>
            <Label size={SizesEnum.LARGE}>Messaging automation</Label>
            <div className="flex flex-col space-y-3">
              <div className="text-sm text-gray-600">
                Notify candidates automatically when they are advanced or
                rejected. Manage your message templates in{" "}
                <span
                  className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
                  onClick={() => {
                    navigate("/settings/message-templates");
                  }}
                >
                  settings
                </span>
                .
              </div>
              <MessageTemplateField
                label="Advancement message"
                template={currentAcceptanceTemplate}
                templates={acceptanceTemplates}
                onChange={(template) => {
                  setAcceptanceTemplateId(template?.id ?? null);
                }}
                isDisabled={!allowed}
              />
              {currentAcceptanceTemplateIncludesNextStepsVariable ? (
                <div className="flex flex-col space-y-2">
                  <Label size={SizesEnum.SMALL}>
                    Instructions for advanced candidates
                  </Label>
                  <Input
                    value={advancedNextSteps.value ?? ""}
                    onChange={(event) =>
                      advancedNextSteps.onChange(event.target.value)
                    }
                    placeholder="Please schedule your final round interview using this scheduling link: ____"
                    isDisabled={
                      !allowed || advancedNextSteps.value === undefined
                    }
                    maxLength={1000}
                  />
                  <div className="mt-2 text-sm text-gray-600">
                    Next step instructions that will be inserted into the
                    message template.
                  </div>
                </div>
              ) : null}
              <MessageTemplateField
                label="Rejection message"
                template={currentRejectionTemplate}
                templates={rejectionTemplates}
                onChange={(template) => {
                  setRejectionTemplateId(template?.id ?? null);
                }}
                isDisabled={!allowed}
              />
              {hasChanges ? (
                <SaveAndCancelButtons
                  onSave={handleSave}
                  onCancel={handleCancel}
                  isSaving={isSaving}
                  isSaveDisabled={!hasChanges}
                />
              ) : null}
            </div>
          </div>
        </>
      )}
    </Can>
  );
};

// TODO: This was way too hard. Refactor and simplify.
function useAdvancedNextSteps(projectId: string) {
  // The value shown in the UI. undefined until we've initialized it.
  const [value, setValue] = useState<string | undefined>(undefined);

  // The value from the server. undefined until we've fetched it.
  const [fetchedValue, setFetchedValue] = useState<string | undefined>(
    undefined,
  );

  // Use a lazy query, because we need to initialize our form field with a fresh value.
  const [query] = useLazyProjectCustomFieldQuery();
  const [mutate] = useUpdateProjectCustomFieldMutation();

  const onFetch = (customField: CustomField | null) => {
    // Parse the custom field data, defaulting to an empty value.
    const data = customField
      ? customFieldDataSchemaByType[CustomFieldTypesEnum.Text].parse(
          customField.data,
        )
      : { value: null };
    const value = data.value ?? "";

    setFetchedValue(value);
    setValue(value);
  };

  useEffect(() => {
    // Reinitialize whenever the project ID changes.
    setFetchedValue(undefined);
    setValue(undefined);

    // Fetch the value from the server.
    let isCancelled = false;
    query({
      projectId,
      customFieldName: SpecialProjectCustomFieldNamesEnum.AdvancedNextSteps,
    })
      .unwrap()
      .then((customField) => {
        if (isCancelled) {
          return;
        }

        onFetch(customField);
      })
      .catch((error) => {
        // This is expected when the custom field definition hasn't been provisioned yet.
        if (isFetchBaseQueryError(error) && error.status === 404) {
          return;
        }

        // TODO: Handle errors.
        console.error("Error fetching advanced next steps", error);
      });

    return () => {
      isCancelled = true;
    };
  }, [projectId]);

  return {
    value,
    onChange: (nextValue: string) => {
      setValue((prevValue) => {
        if (prevValue === undefined) {
          throw new Error("useAdvancedNextSteps is not initialized");
        }

        return nextValue;
      });
    },
    hasChanges: value !== undefined && value !== fetchedValue,
    reset: () => {
      setValue(fetchedValue);
    },
    save: async () => {
      // Don't do anything if nothing was ever fetched.
      if (value === undefined) {
        return;
      }

      // Avoid doing any work in the common case where the value is empty and unchanged.
      if (!value.trim() && value === fetchedValue) {
        return;
      }

      try {
        const customField = await mutate({
          projectId,
          customFieldName: SpecialProjectCustomFieldNamesEnum.AdvancedNextSteps,
          data: { value },
        }).unwrap();
        onFetch(customField);
      } catch (error) {
        // TODO: Handle errors.
        console.error("Error saving advanced next steps", error);
      }
    },
  };
}
