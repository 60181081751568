import {
  InlineNode,
  MultilineContent,
  ParagraphNode,
  SingleLineContent,
} from "./types";

export function* collectPlaceholderNames(
  node: SingleLineContent | MultilineContent | ParagraphNode | InlineNode,
): Iterable<string> {
  if ("type" in node) {
    switch (node.type) {
      case "paragraph":
        for (const child of node.content) {
          yield* collectPlaceholderNames(child);
        }
        break;

      case "placeholder":
        yield node.attrs.name;
        break;

      case "text":
      default:
        break;
    }
  } else if ("content" in node) {
    for (const child of node.content) {
      yield* collectPlaceholderNames(child);
    }
  }
}

interface SubstitutionOptions {
  substituteVariable: (name: string) => InlineNode[];
}

function substituteVariablesInInlineContent(
  content: readonly InlineNode[],
  options: SubstitutionOptions,
): InlineNode[] {
  return content.flatMap((child) => {
    if (child.type === "placeholder") {
      return options.substituteVariable(child.attrs.name);
    } else {
      return [child];
    }
  });
}

function substituteVariablesInParagraphNode(
  node: ParagraphNode,
  options: SubstitutionOptions,
): ParagraphNode {
  return {
    type: "paragraph",
    content: substituteVariablesInInlineContent(node.content, options),
  };
}

export function substituteVariablesInSingleLineContent(
  subject: SingleLineContent,
  options: SubstitutionOptions,
): SingleLineContent {
  return {
    content: substituteVariablesInInlineContent(subject.content, options),
  };
}

export function substituteVariablesInMultilineContent(
  body: MultilineContent,
  options: SubstitutionOptions,
): MultilineContent {
  return {
    content: body.content.map((node) =>
      substituteVariablesInParagraphNode(node, options),
    ),
  };
}

export function createSubstituteVariableWithText(
  substituteVariable: (name: string) => string | undefined,
) {
  return (name: string): InlineNode[] => {
    const text = substituteVariable(name);
    return text ? [{ type: "text", text }] : [];
  };
}
