import { FC, ReactNode, useContext, useId } from "react";
import { twJoin } from "tailwind-merge";
import { SimpleRadioGroupContext } from "./simpleRadioGroupContext";

export interface SimpleRadioOptionProps {
  value: string;
  isDisabled?: boolean;
  children: ReactNode;
}

export const SimpleRadioOption: FC<SimpleRadioOptionProps> = (props) => {
  const id = useId();
  const contextValue = useContext(SimpleRadioGroupContext);

  return (
    <label className="flex items-center space-x-2" htmlFor={id}>
      <input
        checked={contextValue?.value === props.value}
        className="h-4 w-4 rounded-full border-gray-300 text-indigo-600 focus:ring-indigo-500 focus:border-indigo-500 disabled:cursor-not-allowed"
        disabled={props.isDisabled}
        id={id}
        name={contextValue?.name}
        onChange={() => contextValue?.onChange(props.value)}
        type="radio"
      />
      <span
        className={twJoin(
          "text-sm",
          props.isDisabled ? "text-gray-300" : "text-gray-800",
        )}
      >
        {props.children}
      </span>
    </label>
  );
};
