import type { RolePlayInterviewSectionSettings } from "app-types/entities/interviewSection/settings";
import { FC } from "react";
import { BaseInterviewSectionDefinitionCard } from "../card";
import { RolePlayInstructionsField } from "./instructionsField";

export interface RolePlayInterviewSectionDefinitionCardProps {
  interviewSectionDefinitionId: string;
  settings: RolePlayInterviewSectionSettings;
  onChange: (
    interviewSectionDefinitionId: string,
    newSettings: RolePlayInterviewSectionSettings,
  ) => void;
  onDelete?: (interviewSectionDefinitionId: string) => void;
}

export const RolePlayInterviewSectionDefinitionCard: FC<
  RolePlayInterviewSectionDefinitionCardProps
> = (props) => {
  const isTemplate = props.settings.template !== undefined;

  return (
    <BaseInterviewSectionDefinitionCard
      interviewSectionDefinitionId={props.interviewSectionDefinitionId}
      title={props.settings.title || "Role play interview"}
      subtitle="Simulates a conversation that candidates will need to do on the job."
      onDelete={props.onDelete}
    >
      <div className="p-3 space-y-4">
        <RolePlayInstructionsField
          isReadOnly={isTemplate}
          label="Candidate instructions"
          description="These instructions will be shown to the candidate for the entire role play interview."
          content={props.settings.participant_instructions}
          onChange={(newContent) =>
            props.onChange(props.interviewSectionDefinitionId, {
              ...props.settings,
              participant_instructions: newContent,
            })
          }
        />

        <RolePlayInstructionsField
          isReadOnly={isTemplate}
          label="AI agent instructions"
          description="These instructions define the behavior of the AI agent in the role play interview."
          content={props.settings.agent_instructions}
          onChange={(newContent) =>
            props.onChange(props.interviewSectionDefinitionId, {
              ...props.settings,
              agent_instructions: newContent,
            })
          }
        />
      </div>
    </BaseInterviewSectionDefinitionCard>
  );
};
