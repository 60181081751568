import { createContextualCan } from "@casl/react";
import type { TeammateAuthorization } from "app-types";
import { buildTeammateAuthorization } from "app-types";
import React, { createContext, useContext, useMemo } from "react";
import { selectTeammateRole } from "../features/miscellaneous/authorizationSlice";
import { useAppSelector } from "../hooks/hook";

// Create the context with teammate authorization
const defaultTeammateAuthorization = buildTeammateAuthorization([]);
const TeammateAuthorizationContext = createContext<TeammateAuthorization>(
  defaultTeammateAuthorization,
);

// Custom hook to use the teammate authorization
export function useTeammateAuthorization() {
  return useContext(TeammateAuthorizationContext);
}

// Create the Can component for permission checks in JSX
export const Can = createContextualCan(TeammateAuthorizationContext.Consumer);

// Provider component
export function TeammateAuthorizationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // Get role access from Redux
  const teammateRole = useAppSelector(selectTeammateRole);

  // Use useMemo instead of useEffect + useState
  const teammateAuthorization = useMemo(
    () => buildTeammateAuthorization(teammateRole?.role_access ?? null),
    [teammateRole],
  );

  return (
    <TeammateAuthorizationContext.Provider value={teammateAuthorization}>
      {children}
    </TeammateAuthorizationContext.Provider>
  );
}
