import { PlusIcon } from "@heroicons/react/24/outline";
import { Button, ButtonVariantsEnum } from "@repo/ui/Buttons/button";
import { Label } from "@repo/ui/Label/label";
import { CenteredModal } from "@repo/ui/Modal/centeredModal";
import { SizesEnum } from "@repo/ui/helpers/helpers";
import { PermissionActions, PermissionSubjects } from "app-types";
import type { InterviewSectionSettings } from "app-types/entities/interviewSection/settings";
import { InterviewSectionType } from "app-types/entities/interviewSection/types";
import { FC, useMemo, useState, type ReactNode } from "react";
import { DialogTrigger, DisclosureGroup } from "react-aria-components";
import {
  useGenerateTemplateInterviewSectionSettingsMutation,
  useInterviewSectionDefinitionsByJobTypeIdQuery,
} from "../../../api/redux";
import { Can } from "../../../helpers/teammateAuthorizationContext";
import type {
  QuestionsDispatch,
  QuestionWithTemporaryId,
} from "../../projects/recruitingQuestions/questionsStore";
import { BaseInterviewSectionDefinitionCard } from "./card";
import { InterviewSectionCardSkeleton } from "./cardSkeleton";
import { CaseInterviewSectionDefinitionCard } from "./case/card";
import { InterviewSectionPickPresetDialog } from "./create/pickPresetDialog";
import type { InterviewSectionPresetDefinition } from "./create/presets";
import { QuestionsInterviewSectionDefinitionCard } from "./questions/card";
import { RolePlayInterviewSectionDefinitionCard } from "./rolePlay/card";
import { RolePlayInterviewSectionConfigureDialog } from "./rolePlay/configureDialog";
import {
  applyInterviewSectionDefinitionChanges,
  type ChangedInterviewSectionDefinition,
  type VisibleInterviewSectionDefinition,
} from "./state";

export interface InterviewSectionDefinitionListPanelProps {
  projectId: string;
  jobTypeId: string;
  changedSections: readonly ChangedInterviewSectionDefinition[];
  onSectionCreate: (settings: InterviewSectionSettings) => string;
  onSectionUpdate: (id: string, settings: InterviewSectionSettings) => void;
  onSectionDelete: (id: string) => void;
  questions: readonly QuestionWithTemporaryId[];
  onQuestionsChange: QuestionsDispatch;
}

type ConfigurableSectionType = typeof InterviewSectionType.RolePlay;

const implicitQuestionsInterviewSectionDefinitionId = "__job_type_questions";

/**
 * Right-side panel after the introduction of interview sections.
 */
export const InterviewSectionDefinitionListPanel: FC<
  InterviewSectionDefinitionListPanelProps
> = (props) => {
  const { data: fetchedSections, isLoading: isFetchingSections } =
    useInterviewSectionDefinitionsByJobTypeIdQuery({
      jobTypeId: props.jobTypeId,
    });
  const [generateTemplateSection, { isLoading: isGeneratingTemplateSection }] =
    useGenerateTemplateInterviewSectionSettingsMutation();

  const [expandedId, setExpandedId] = useState<string | undefined>(
    implicitQuestionsInterviewSectionDefinitionId,
  );
  const [configuringSectionType, setConfiguringSectionType] = useState<
    ConfigurableSectionType | undefined
  >(undefined);

  // Reconcile what we fetched from the server and the changes made by the user.
  const sections = useMemo(
    () =>
      applyInterviewSectionDefinitionChanges(
        fetchedSections,
        props.changedSections,
      ),
    [fetchedSections, props.changedSections],
  );

  const onSectionCreate = (settings: InterviewSectionSettings) => {
    const id = props.onSectionCreate(settings);
    setExpandedId(id);
  };

  const onSectionPresetSelect = async (
    preset: InterviewSectionPresetDefinition,
  ) => {
    if (preset.templateId) {
      // Collapse the accordion so the skeleton loader is visible.
      setExpandedId(undefined);

      // TODO: Handle errors.
      const { data: settings } = await generateTemplateSection({
        jobTypeId: props.jobTypeId,
        templateId: preset.templateId,
      });

      if (settings) {
        onSectionCreate(settings);
      }
      return;
    }

    if (preset.sectionType === InterviewSectionType.RolePlay) {
      setConfiguringSectionType(preset.sectionType);
      return;
    }

    onSectionCreate({
      type: preset.sectionType,
    });
  };

  const renderSectionCard = (
    section: VisibleInterviewSectionDefinition,
  ): ReactNode => {
    switch (section.settings.type) {
      case InterviewSectionType.Case:
        return (
          <CaseInterviewSectionDefinitionCard
            key={section.id}
            interviewSectionDefinitionId={section.id}
            onDelete={props.onSectionDelete}
          />
        );

      case InterviewSectionType.RolePlay:
        return (
          <RolePlayInterviewSectionDefinitionCard
            key={section.id}
            interviewSectionDefinitionId={section.id}
            settings={section.settings}
            onChange={props.onSectionUpdate}
            onDelete={props.onSectionDelete}
          />
        );

      // Shouldn't happen in practice.
      default:
        return (
          <BaseInterviewSectionDefinitionCard
            key={section.id}
            interviewSectionDefinitionId={section.id}
            title="Unknown section"
            subtitle=""
            onDelete={props.onSectionDelete}
          >
            <div className="p-3 text-sm text-gray-900">
              This section type is not supported.
            </div>
          </BaseInterviewSectionDefinitionCard>
        );
    }
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="px-3 py-2 flex items-center justify-between  bg-gray-50">
        <div>
          <div className="flex flex-row items-center space-x-2">
            <Label size={SizesEnum.MEDIUM}>Interview plan</Label>
          </div>
          <div className="text-gray-600 text-xs">
            The AI interviewer will conduct the interview based on this plan.
          </div>
        </div>

        <DialogTrigger>
          <Can
            I={PermissionActions.UPDATE}
            a={PermissionSubjects.JOB_OPENINGS}
            passThrough={true}
          >
            {(allowed) => (
              <Button
                variant={ButtonVariantsEnum.Secondary}
                icon={<PlusIcon className="size-4" />}
                isDisabled={!allowed}
              >
                Add section
              </Button>
            )}
          </Can>

          <CenteredModal isDismissable size={SizesEnum.LARGE}>
            <InterviewSectionPickPresetDialog
              existingSections={sections}
              onSelect={onSectionPresetSelect}
            />
          </CenteredModal>
        </DialogTrigger>

        <CenteredModal
          isOpen={configuringSectionType !== undefined}
          onOpenChange={(isOpen) => {
            if (!isOpen) {
              setConfiguringSectionType(undefined);
            }
          }}
          size={SizesEnum.SMALL}
        >
          <RolePlayInterviewSectionConfigureDialog
            jobTypeId={props.jobTypeId}
            onCreate={onSectionCreate}
          />
        </CenteredModal>
      </div>

      <DisclosureGroup
        expandedKeys={expandedId === undefined ? [] : [expandedId]}
        onExpandedChange={(keys) => {
          const key = keys.values().next().value;
          setExpandedId(typeof key === "string" ? key : undefined);
        }}
        className="overflow-y-auto flex-1 p-3 space-y-3 bg-gray-50"
      >
        <QuestionsInterviewSectionDefinitionCard
          // TODO: Feed a real interview section definition ID here.
          interviewSectionDefinitionId={
            implicitQuestionsInterviewSectionDefinitionId
          }
          projectId={props.projectId}
          questions={props.questions}
          onQuestionsChange={props.onQuestionsChange}
        />

        {sections?.map(renderSectionCard)}

        {isGeneratingTemplateSection ? <InterviewSectionCardSkeleton /> : null}

        {isFetchingSections ? (
          <>
            <InterviewSectionCardSkeleton />
            <InterviewSectionCardSkeleton />
          </>
        ) : null}
      </DisclosureGroup>
    </div>
  );
};
