import { Input, Textarea } from "@repo/ui";
import { MessageTemplateType } from "app-types";
import { FC } from "react";
import { useMessageVariables } from "./useMessageTemplateVariables";

export interface MessageTemplateFormProps {
  subject: string;
  body: string;
  type: MessageTemplateType;
  onSubjectChange: (value: string) => void;
  onBodyChange: (value: string) => void;
}

export const MessageTemplateForm: FC<MessageTemplateFormProps> = ({
  subject,
  body,
  type,
  onSubjectChange,
  onBodyChange,
}) => {
  const { ref: subjectRef, widget: subjectVariablesWidget } =
    useMessageVariables<HTMLInputElement>({
      type,
      value: subject,
      onChange: onSubjectChange,
    });

  const { ref: bodyRef, widget: bodyVariablesWidget } =
    useMessageVariables<HTMLTextAreaElement>({
      type,
      value: body,
      onChange: onBodyChange,
    });

  return (
    <div className="space-y-4">
      <div>
        <Input
          ref={subjectRef}
          autoFocus
          label="Subject"
          placeholder={
            type === MessageTemplateType.Advanced
              ? "Next steps for your application"
              : "An update on your application"
          }
          description="Used when sending an email message."
          value={subject}
          onChange={(e) => onSubjectChange(e.target.value)}
        />
        <div className="mt-2">{subjectVariablesWidget}</div>
      </div>
      <div>
        <Textarea
          ref={bodyRef}
          label="Body"
          description="Sent via SMS if interview was conducted over the phone - otherwise sent via email."
          value={body}
          onChange={onBodyChange}
          height="200px"
        />
        <div className="mt-2">{bodyVariablesWidget}</div>
      </div>
    </div>
  );
};
