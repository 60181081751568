import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  Assessment,
  CustomFieldDefinition,
  CustomFieldDefinitionInsertClient,
  LoadingStatesEnum,
} from "app-types";
import { Selector } from "react-redux";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { RootState } from "../../app/store";
import { createProject } from "../projects/projectsSlice";

export const assessmentsAdapter = createEntityAdapter<Assessment>();

const initialState = assessmentsAdapter.getInitialState<{
  status: LoadingStatesEnum;
  error: string | null;
}>({
  status: LoadingStatesEnum.LOADING,
  error: null,
});

export const updateAssessment = createAsyncThunk<
  Assessment,
  {
    assessmentId: string;
    changes: {
      custom_field_definitions: (
        | CustomFieldDefinition
        | CustomFieldDefinitionInsertClient
      )[];
    };
  },
  { rejectValue: string }
>(
  "assessments/updateAssessment",
  async ({ assessmentId, changes }, thunkAPI) => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      const response = await axios.post(
        `/assessments/${assessmentId}`,
        changes,
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchAssessmentById = createAsyncThunk(
  "assessments/fetchAssessmentById",
  async (assessmentId: string, thunkAPI) => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      const response = await axios.get(`/assessments/${assessmentId}`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

const assessmentsSlice = createSlice({
  name: "assessments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateAssessment.pending, (state) => {
        state.status = LoadingStatesEnum.LOADING;
      })
      .addCase(updateAssessment.fulfilled, (state, action) => {
        state.status = LoadingStatesEnum.LOADED;
        assessmentsAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      .addCase(updateAssessment.rejected, (state, action) => {
        state.status = LoadingStatesEnum.ERROR;
        state.error = action.payload || null;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        if (action.payload.assessment) {
          assessmentsAdapter.addOne(state, action.payload.assessment);
        }
      })
      .addCase(fetchAssessmentById.pending, (state) => {
        state.status = LoadingStatesEnum.LOADING;
      })
      .addCase(fetchAssessmentById.fulfilled, (state, action) => {
        state.status = LoadingStatesEnum.LOADED;
        assessmentsAdapter.addOne(state, action.payload);
      })
      .addCase(fetchAssessmentById.rejected, (state, action) => {
        state.status = LoadingStatesEnum.ERROR;
        state.error = action.error.message || null;
      });
  },
});

export default assessmentsSlice.reducer;

export const selectAssessmentById = (
  id: string | undefined | null,
): Selector<RootState, Assessment | undefined> =>
  createSelector(
    [(state: RootState) => state.assessments],
    (assessmentsState) =>
      id
        ? assessmentsAdapter.getSelectors().selectById(assessmentsState, id)
        : undefined,
  );
