import React, { ReactNode } from "react";

export const TableSkeleton: React.FC = () => {
  // Use a high colspan to ensure the skeleton takes up the full width of the table
  return (
    <tr>
      <td colSpan={100}>
        <div
          className="p-4 space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse"
          role="status"
        >
          <div className="flex items-center justify-between">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-400" />
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-12" />
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-400" />
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-12" />
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-400" />
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-12" />
          </div>
          <span className="sr-only">Loading...</span>
        </div>
      </td>
    </tr>
  );
};

export const DefaultSkeleton: React.FC = () => {
  return (
    <div className="max-w-sm animate-pulse" role="status">
      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-48 mb-4" />
      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 max-w-[360px] mb-2.5" />
      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 mb-2.5" />
      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 max-w-[330px] mb-2.5" />
      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 max-w-[300px] mb-2.5" />
      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 max-w-[360px]" />
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export const TextSkeleton: React.FC = () => {
  return (
    <div className="space-y-2.5 animate-pulse max-w-lg" role="status">
      <div className="flex items-center w-full space-x-2">
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-32" />
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-24" />
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-full" />
      </div>
      <div className="flex items-center w-full space-x-2 max-w-[480px]">
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-full" />
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-full" />
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-24" />
      </div>
      <div className="flex items-center w-full space-x-2 max-w-[400px]">
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-full" />
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-80" />
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-full" />
      </div>
      <div className="flex items-center w-full space-x-2 max-w-[480px]">
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-full" />
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-full" />
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-24" />
      </div>
      <div className="flex items-center w-full space-x-2 max-w-[440px]">
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-32" />
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-24" />
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-full" />
      </div>
      <div className="flex items-center w-full space-x-2 max-w-[360px]">
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-full" />
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-80" />
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-full" />
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export const FullPageSkeleton = (): ReactNode => {
  return (
    <div className="mx-auto max-w-4xl p-6 space-y-11">
      <DefaultSkeleton />
      <TextSkeleton />
    </div>
  );
};
