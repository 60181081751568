import {
  ArrowPathIcon,
  BellAlertIcon,
  BuildingOfficeIcon,
  CodeBracketIcon,
  CreditCardIcon,
  DocumentMagnifyingGlassIcon,
  EnvelopeIcon,
  UserCircleIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { PermissionActions, PermissionSubjects } from "app-types";
import type { FC } from "react";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { useTeammateAuthorization } from "../../helpers/teammateAuthorizationContext";
import { useAppSelector } from "../../hooks/hook";
import { selectIsRecruitingModeCompany } from "../company/companySlice";
import { BillingSettingsSection } from "./billingSettings/billingSettingsSection";
import CompanySettingsSection from "./companySettings/companySettingsSection";
import { DeveloperSettingsSection } from "./developerSettings/developerSettingsSection";
import { IntegrationSettingsSection } from "./integrationSettings/integrationSettingsSection";
import { KeywordTrackersSettingsSection } from "./keywordTrackersSettingsSection";
import { MessageTemplateSettingsSection } from "./messageTemplateSettings/messageTemplateSettingsSection";
import { default as ProfileSettingsSection } from "./profileSettingsSection";
import { TeammatesSettingsSection } from "./teammateSettings/teammatesSettingsSection";
import { WebhooksSettingsSection } from "./webhooksSettings/webhooksSettingsSection";

enum SectionsEnum {
  Profile = "Profile",
  Organization = "Organization",
  Teammates = "Teammates",
  KeywordTrackers = "Keyword trackers",
  MessageTemplates = "Message templates",
  Billing = "Billing",
  Integrations = "Integrations",
  Developers = "Developers",
  Webhooks = "Webhooks",
}

const SettingsPage: FC = () => {
  const { pathname } = useLocation();
  const isRecruitingModeCompany = useAppSelector(selectIsRecruitingModeCompany);

  const teammateAuthorization = useTeammateAuthorization();
  const canTeammateAccessBillingSection = teammateAuthorization.can(
    PermissionActions.READ,
    PermissionSubjects.BILLING,
  );
  const canTeammateAccessDevelopersSection = teammateAuthorization.can(
    PermissionActions.READ,
    PermissionSubjects.DEVELOPERS,
  );
  const canTeammateAccessWebhooksSection = teammateAuthorization.can(
    PermissionActions.READ,
    PermissionSubjects.WEBHOOKS,
  );

  return (
    <div className="flex min-h-full h-full">
      {renderSidebar({
        pathname,
        accessOptions: {
          isRecruitingModeCompany,
          canTeammateAccessBillingSection,
          canTeammateAccessDevelopersSection,
          canTeammateAccessWebhooksSection,
        },
      })}
      <div className="flex-grow overflow-y-scroll overflow-hidden">
        <Routes>
          <Route path="/profile" element={<ProfileSettingsSection />} />
          <Route path="/organization" element={<CompanySettingsSection />} />
          <Route path="/teammates" element={<TeammatesSettingsSection />} />
          {!isRecruitingModeCompany && (
            <Route
              path="/keyword-trackers"
              element={<KeywordTrackersSettingsSection />}
            />
          )}
          {isRecruitingModeCompany && (
            <Route
              path="/message-templates"
              element={<MessageTemplateSettingsSection />}
            />
          )}
          {canTeammateAccessBillingSection && (
            <Route path="/billing" element={<BillingSettingsSection />} />
          )}
          <Route
            path="/integrations"
            element={<IntegrationSettingsSection />}
          />
          {canTeammateAccessDevelopersSection && (
            <Route path="/developers" element={<DeveloperSettingsSection />} />
          )}
          {canTeammateAccessWebhooksSection && (
            <Route path="/webhooks" element={<WebhooksSettingsSection />} />
          )}
        </Routes>
      </div>
    </div>
  );
};

function renderSidebar({
  pathname,
  accessOptions,
}: {
  pathname: string;
  accessOptions: {
    isRecruitingModeCompany: boolean;
    canTeammateAccessBillingSection: boolean;
    canTeammateAccessDevelopersSection: boolean;
    canTeammateAccessWebhooksSection: boolean;
  };
}) {
  const sections = [
    {
      name: SectionsEnum.Profile,
      icon: UserCircleIcon,
      path: "/settings/profile",
    },
    {
      name: SectionsEnum.Organization,
      icon: BuildingOfficeIcon,
      path: "/settings/organization",
    },
    {
      name: SectionsEnum.Teammates,
      icon: UsersIcon,
      path: "/settings/teammates",
    },
    ...(!accessOptions.isRecruitingModeCompany
      ? [
          {
            name: SectionsEnum.KeywordTrackers,
            icon: DocumentMagnifyingGlassIcon,
            path: "/settings/keyword-trackers",
          },
        ]
      : []),
    ...(accessOptions.isRecruitingModeCompany
      ? [
          {
            name: SectionsEnum.MessageTemplates,
            icon: EnvelopeIcon,
            path: "/settings/message-templates",
          },
        ]
      : []),
    {
      name: SectionsEnum.Integrations,
      icon: ArrowPathIcon,
      path: "/settings/integrations",
    },
    ...(accessOptions.canTeammateAccessBillingSection
      ? [
          {
            name: SectionsEnum.Billing,
            icon: CreditCardIcon,
            path: "/settings/billing",
          },
        ]
      : []),
    ...(accessOptions.canTeammateAccessWebhooksSection
      ? [
          {
            name: SectionsEnum.Webhooks,
            icon: BellAlertIcon,
            path: "/settings/webhooks",
          },
        ]
      : []),
    ...(accessOptions.canTeammateAccessDevelopersSection
      ? [
          {
            name: SectionsEnum.Developers,
            icon: CodeBracketIcon,
            path: "/settings/developers",
          },
        ]
      : []),
  ];

  return (
    <div className="flex flex-col flex-shrink-0 flex-grow-0 w-[220px] border-r border-gray-200 bg-white px-6 py-6 min-h-full h-full">
      <ul className="flex flex-1 flex-col gap-y-7" role="list">
        <li>
          <ul className="-mx-2 space-y-1" role="list">
            {sections.map((item) => (
              <li key={item.path}>
                <NavLink
                  to={item.path}
                  className={twJoin(
                    pathname === item.path
                      ? "bg-gray-50 text-indigo-600"
                      : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                  )}
                >
                  <item.icon
                    aria-hidden="true"
                    className={twJoin(
                      pathname === item.path
                        ? "text-indigo-600"
                        : "text-gray-400 group-hover:text-indigo-600",
                      "h-6 w-6 shrink-0",
                    )}
                  />
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default SettingsPage;
