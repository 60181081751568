import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { FC, Fragment } from "react";
import { twJoin } from "tailwind-merge";

interface SlideoverProps {
  onClickClose: () => void;
  shouldShow: boolean;
  children: React.ReactNode;
  buttons?: React.ReactNode;
  title: string;
  /** Hack that can be used to workaround z-index issues. */
  skipsZIndex?: boolean;
}

export const Slideover: FC<SlideoverProps> = ({
  onClickClose,
  title,
  children,
  buttons,
  shouldShow,
  skipsZIndex,
}) => {
  return (
    <Transition.Root as={Fragment} show={shouldShow}>
      <Dialog
        className={twJoin("relative", skipsZIndex ? undefined : "z-10")}
        onClose={onClickClose}
      >
        <div className="fixed inset-x-0 top-[50px] bottom-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed bottom-0 top-[50px] right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-150 sm:duration-150"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-150 sm:duration-150"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-6xl">
                  <div
                    className="grid h-full bg-white shadow-xl"
                    style={{
                      gridTemplateRows: buttons
                        ? "60px minmax(0, 1fr) 60px"
                        : "60px minmax(0, 1fr)",
                    }}
                  >
                    {/* Header */}
                    <div className="bg-gray-50 px-4 py-4 sm:px-6 border-b border-gray-200">
                      <div className="flex items-start justify-between space-x-3">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          {title}
                        </Dialog.Title>
                        <div className="flex h-7 items-center">
                          <button
                            className="relative text-gray-400 hover:text-gray-500"
                            onClick={onClickClose}
                            type="button"
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Body */}
                    <div className="flex flex-col overflow-y-auto h-full">
                      {children}
                    </div>
                    {/* Action buttons */}
                    {buttons ? (
                      <div className="flex justify-end space-x-3 px-4 py-3 border-t border-gray-200 bg-white">
                        {buttons}
                      </div>
                    ) : null}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
