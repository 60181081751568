import type { FC, ReactNode } from "react";
import { Button as AriaButton } from "react-aria-components";
import { tv } from "tailwind-variants";
import { ButtonVariantsEnum } from "./button";

interface IconButtonProps {
  icon: ReactNode;
  variant: ButtonVariantsEnum.Secondary | ButtonVariantsEnum.Tertiary;
  onClick?: () => void;
  isDisabled?: boolean;
  excludeFromTabOrder?: boolean;
}

export const iconButton = tv({
  base: "cursor-pointer p-1 rounded-md text-gray-900 disabled:bg-slate-300 disabled:text-slate-500",
  variants: {
    variant: {
      [ButtonVariantsEnum.Secondary]:
        "ring-1 ring-gray-300 bg-white shadow-sm hover:bg-gray-50",
      [ButtonVariantsEnum.Tertiary]: "hover:bg-slate-200",
    },
  },
  defaultVariants: {
    variant: ButtonVariantsEnum.Secondary,
  },
});

export const IconButton: FC<IconButtonProps> = ({
  icon,
  onClick,
  isDisabled,
  variant,
  excludeFromTabOrder,
}) => {
  return (
    <AriaButton
      className={iconButton({ variant })}
      isDisabled={isDisabled}
      onPress={onClick ? () => void onClick() : undefined}
      excludeFromTabOrder={excludeFromTabOrder}
    >
      {icon}
    </AriaButton>
  );
};
