import type { InterviewSectionDefinition } from "app-types/entities/interviewSection/definition";

/** Interview section definition without unsaved changes. */
export interface UnchangedInterviewSectionDefinition
  extends InterviewSectionDefinition {
  changeType?: undefined;
}

/** Interview section definition with unsaved changes. */
export type ChangedInterviewSectionDefinition =
  | CreatedInterviewSectionDefinition
  | UpdatedInterviewSectionDefinition
  | DeletedInterviewSectionDefinition;

/** Interview section definition that is pending creation. */
export interface CreatedInterviewSectionDefinition
  extends Pick<InterviewSectionDefinition, "id" | "settings"> {
  changeType: "created";
}

/** Interview section definition that is pending updates. */
export interface UpdatedInterviewSectionDefinition
  extends Pick<InterviewSectionDefinition, "id" | "settings"> {
  changeType: "updated";
}

/** Interview section definition that is pending deletion. */
export interface DeletedInterviewSectionDefinition
  extends Pick<InterviewSectionDefinition, "id"> {
  changeType: "deleted";
}

/** Interview section definition that is not pending deletion. */
export type VisibleInterviewSectionDefinition =
  | UnchangedInterviewSectionDefinition
  | CreatedInterviewSectionDefinition
  | UpdatedInterviewSectionDefinition;

export function applyInterviewSectionDefinitionChanges(
  fetchedSections: undefined,
  changedSections: readonly ChangedInterviewSectionDefinition[],
): undefined;
export function applyInterviewSectionDefinitionChanges(
  fetchedSections: readonly UnchangedInterviewSectionDefinition[],
  changedSections: readonly ChangedInterviewSectionDefinition[],
): readonly VisibleInterviewSectionDefinition[];
export function applyInterviewSectionDefinitionChanges(
  fetchedSections: readonly UnchangedInterviewSectionDefinition[] | undefined,
  changedSections: readonly ChangedInterviewSectionDefinition[],
): readonly VisibleInterviewSectionDefinition[] | undefined;
export function applyInterviewSectionDefinitionChanges(
  fetchedSections: readonly UnchangedInterviewSectionDefinition[] | undefined,
  changedSections: readonly ChangedInterviewSectionDefinition[],
): readonly VisibleInterviewSectionDefinition[] | undefined {
  // If we haven't fetched a ground truth we cannot apply changes.
  if (!fetchedSections) {
    return undefined;
  }

  // If there are no changes we can return early.
  if (changedSections.length === 0) {
    return fetchedSections;
  }

  const sections: VisibleInterviewSectionDefinition[] = [...fetchedSections];

  for (const changedSection of changedSections) {
    switch (changedSection.changeType) {
      case "created": {
        sections.push(changedSection);
        break;
      }
      case "updated": {
        const index = sections.findIndex(
          (section) => section.id === changedSection.id,
        );

        if (index !== -1) {
          sections[index] = changedSection;
        }
        break;
      }
      case "deleted": {
        const index = sections.findIndex(
          (section) => section.id === changedSection.id,
        );

        if (index !== -1) {
          sections.splice(index, 1);
        }
        break;
      }
    }
  }

  return sections;
}

export function insertInterviewSectionDefinitionChange(
  prevChanges: readonly ChangedInterviewSectionDefinition[],
  newChange: ChangedInterviewSectionDefinition,
): readonly ChangedInterviewSectionDefinition[] {
  const existingIndex = prevChanges.findIndex(
    (prevChange) => prevChange.id === newChange.id,
  );

  // If there is no existing change for the same section, we can just append.
  if (existingIndex === -1) {
    return [...prevChanges, newChange];
  }

  const existingChange = prevChanges[existingIndex];

  // If the existing change is a deletion, we can ignore the new change.
  if (existingChange.changeType === "deleted") {
    return prevChanges;
  }

  // If the new change is a deletion, we can replace the existing change.
  if (newChange.changeType === "deleted") {
    const replacementChanges =
      existingChange.changeType === "created" ? [] : [newChange];
    return prevChanges.toSpliced(existingIndex, 1, ...replacementChanges);
  }

  // Otherwise, keep the existing insertion or update and just update the values.
  return prevChanges.toSpliced(existingIndex, 1, {
    ...existingChange,
    ...newChange,
    changeType: existingChange.changeType,
  });
}
