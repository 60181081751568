import { noop } from "lodash";
import { forwardRef, ReactNode, useEffect, useRef } from "react";
import { Label } from "../Label/label";
import { SizesEnum } from "../helpers/helpers";

export enum TextareaModesEnum {
  // Textarea will default to a read-only state, with an on hover button to
  // enter editing state
  ReadAndEdit,
  // Textarea will only be in editing state - no cancel/save buttons
  EditOnly,
}

export enum TextareaVariantsEnum {
  DEFAULT = "default",
  MINIMAL = "minimal",
}

interface TextareaProps {
  id?: string;
  label?: string;
  labelAccessory?: ReactNode;
  placeholder?: string;
  description?: string | React.ReactNode;
  value: string;
  errorDescription?: string;
  onChange: (newValue: string) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  isDisabled?: boolean;
  autoFocus?: boolean;
  shouldDisableNewline?: boolean; // New prop to optionally disable newline insertion
  minLength?: number;
  maxLength?: number;
  height?: string;
  leftIcon?: ReactNode;
  className?: string;
  variant?: TextareaVariantsEnum;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const {
      id,
      placeholder,
      description,
      value,
      errorDescription,
      onChange,
      onPaste,
      onKeyDown,
      onBlur,
      isDisabled,
      autoFocus,
      shouldDisableNewline,
      label,
      labelAccessory,
      maxLength,
      minLength,
      height,
      leftIcon,
      className,
      variant = TextareaVariantsEnum.DEFAULT,
    } = props;

    const localTextareaRef = useRef<HTMLTextAreaElement>(null);
    // Use provided ref if available, otherwise use local ref
    const textareaRef =
      (ref as React.RefObject<HTMLTextAreaElement>) || localTextareaRef;

    const hasError = Boolean(errorDescription);

    // Automatically expand the textarea to fit content height
    const adjustHeight = (): void => {
      const textareaElement = textareaRef.current;
      if (textareaElement) {
        // Set the height to 'auto' first to allow it to shrink or grow
        textareaElement.style.height = "auto";
        // Set the height to the scroll height, the intrinsic content height
        textareaElement.style.height = `${textareaElement.scrollHeight}px`;
      }
    };
    const shouldAutoAdjustHeight = height === undefined;

    // Whenever localValue changes, we need to adjust the height
    useEffect(() => {
      // Only automatically adjust the height to fit the content if the height
      // hasn't been explicitly set.
      if (shouldAutoAdjustHeight) {
        adjustHeight();
      }
    }, [value, shouldAutoAdjustHeight]);

    // Adjust our height when our width changes (including when we become visible).
    useEffect(() => {
      const textareaElement = textareaRef.current;
      if (shouldAutoAdjustHeight && textareaElement) {
        let width: number | undefined = undefined;

        const observer = new ResizeObserver((records) => {
          const prevWidth = width;
          const nextWidth = records.at(0)?.contentRect.width;
          width = nextWidth;

          // Adjust only when the width changed to avoid a resize observer loop.
          if (nextWidth && prevWidth !== nextWidth) {
            adjustHeight();
          }
        });

        observer.observe(textareaRef.current);

        return () => {
          observer.disconnect();
        };
      }

      return noop;
    }, [shouldAutoAdjustHeight]);

    const textColor = hasError ? "text-red-900" : "text-gray-900";
    const ringColor = hasError ? "ring-red-300" : "ring-gray-300";
    const placeholderColor = hasError
      ? "placeholder-red-400"
      : "placeholder-gray-400";

    const wrapperFocusStyle = hasError
      ? "focus-within:ring-red-500"
      : "focus-within:ring-indigo-500";

    const getBackgroundColor = (): string => {
      if (isDisabled) return "bg-slate-50";
      if (variant === TextareaVariantsEnum.MINIMAL) return "";
      return "bg-white";
    };

    const textareaWrapperClassNames = `group whitespace-pre-wrap relative block
    ${
      variant === TextareaVariantsEnum.MINIMAL
        ? "p-0 ring-0"
        : `py-1.5 px-3 rounded-md border-0 text-sm shadow-sm ring-1 ring-inset
           ${ringColor} focus-within:ring-inset focus-within:ring-2 ${wrapperFocusStyle}`
    }
    ${textColor} ${placeholderColor}
    ${label ? "mt-2" : ""}
    ${leftIcon ? "pl-8" : ""}
    ${getBackgroundColor()} ${className}`;

    const descriptionClassNames = `
    mt-2 text-sm ${hasError ? "text-red-600" : "text-gray-600"}
  `;
    const descriptionText = errorDescription || description;

    // Handle key down to potentially prevent new lines
    const handleKeyDown = (
      event: React.KeyboardEvent<HTMLTextAreaElement>,
    ): void => {
      if (shouldDisableNewline && event.key === "Enter") {
        event.preventDefault();
      }
      if (onKeyDown) {
        onKeyDown(event);
      }
    };

    return (
      <>
        <div className="flex items-center space-x-2">
          {label ? <Label size={SizesEnum.SMALL}>{label}</Label> : null}
          {labelAccessory}
        </div>
        <div className={textareaWrapperClassNames}>
          {leftIcon ? (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2.5">
              {leftIcon}
            </div>
          ) : null}
          <textarea
            aria-describedby={`${id}-description`}
            aria-invalid={hasError}
            autoFocus={autoFocus}
            className={`leading-6 block w-full ${
              variant === TextareaVariantsEnum.MINIMAL
                ? "border-none outline-none focus:outline-none focus:ring-0 p-0"
                : "border-0 p-0 focus:ring-0"
            } ${textColor} ${placeholderColor} text-sm resize-none ${
              isDisabled ? "bg-slate-50" : ""
            }`}
            disabled={isDisabled}
            id={id}
            maxLength={maxLength || 10000}
            minLength={minLength || 0}
            onBlur={onBlur}
            onChange={(event) => onChange(event.target.value)}
            onKeyDown={handleKeyDown}
            onPaste={onPaste}
            placeholder={placeholder}
            ref={textareaRef}
            rows={1}
            style={{ height: height || "auto" }}
            value={value}
          />
        </div>
        {descriptionText ? (
          <p className={descriptionClassNames} id={`${id}-description`}>
            {descriptionText}
          </p>
        ) : null}
      </>
    );
  },
);
