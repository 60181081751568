import {
  ArrowsUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { Input, Select } from "@repo/ui";
import { SelectOption } from "@repo/ui/Select/select";
import {
  buildProjectUrlPath,
  InterviewAgentTypesEnum,
  InterviewRankingThresholdsSummary,
  Project,
  ProjectDetailsTabPathsEnum,
} from "app-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { AssessmentDistributionBar } from "./assessmentDistributionBar";
import {
  fetchNonEmptyInterviewsForProject,
  selectInterviewsForCurrentParametersWithContactsAndActivities,
} from "./interviewsSlice";
import { InterviewsTable } from "./interviewsTable";

const minAssessmentScoreSelectOptions = [
  { name: "Any score", value: 1 },
  { name: "2 or higher", value: 2 },
  { name: "3 or higher", value: 3 },
  { name: "4 or higher", value: 4 },
  { name: "5", value: 5 },
];

const sortSelectOptions = [
  { name: "Sort by: most recent", value: "recent" },
  {
    name: "Sort by: ranking",
    value: "ranking",
  },
];

interface ProjectInsights {
  assessment_distribution: Record<number, number>;
  ranking_thresholds: InterviewRankingThresholdsSummary;
}

interface InterviewsTabProps {
  project: Project;
}

export const InterviewsTab: React.FC<InterviewsTabProps> = ({ project }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [minAssessmentScore, setMinAssessmentScore] = useState<number>();
  const [sortOption, setSortOption] = useState<"recent" | "ranking">("recent");
  const [insights, setInsights] = useState<ProjectInsights | null>(null);
  const [isLoadingInsights, setIsLoadingInsights] = useState(true);

  const interviews = useAppSelector(
    selectInterviewsForCurrentParametersWithContactsAndActivities,
  );

  const interviewsStatus = useAppSelector((state) => state.interviews.status);

  const isRecruitingProject =
    project.settings.interview_agent_type ===
    InterviewAgentTypesEnum.RecruitingInterviewer;

  useEffect(() => {
    const fetchInsights = async (): Promise<void> => {
      try {
        const axios = await getAxiosInstanceWithAuth();
        const response = await axios.get(`/projects/${project.id}/insights`);
        setInsights(response.data as ProjectInsights);
      } catch (error) {
        console.error("Failed to fetch insights:", error);
      } finally {
        setIsLoadingInsights(false);
      }
    };

    if (isRecruitingProject) {
      void fetchInsights();
    } else {
      setIsLoadingInsights(false);
    }

    return () => {
      setInsights(null);
    };
  }, [project.id, isRecruitingProject]);

  const onSearchTextChange = (newSearchQuery: string) => {
    setSearchQuery(newSearchQuery);
    setMinAssessmentScore(undefined);
    dispatch(
      fetchNonEmptyInterviewsForProject({
        projectId: project.id,
        page: 1,
        search_text: newSearchQuery,
        min_assessment_score: undefined,
      }),
    );
  };

  const onMinAssessmentScoreChange = (newMinAssessmentScore?: number) => {
    setSearchQuery("");
    setMinAssessmentScore(newMinAssessmentScore);
    dispatch(
      fetchNonEmptyInterviewsForProject({
        projectId: project.id,
        page: 1,
        search_text: "",
        min_assessment_score: newMinAssessmentScore,
      }),
    );
  };

  const onSortOptionChange = (option: SelectOption) => {
    setSortOption(option.value as "recent" | "ranking");
    void dispatch(
      fetchNonEmptyInterviewsForProject({
        projectId: project.id,
        page: 1,
        search_text: searchQuery,
        min_assessment_score: minAssessmentScore,
        should_sort_by_ranking: option.value === "ranking",
      }),
    );
  };

  return (
    <div className="h-full pt-4 flex flex-col">
      {isRecruitingProject ? (
        <AssessmentDistributionBar
          onScoreClick={(score) => {
            if (score === minAssessmentScore) {
              onMinAssessmentScoreChange(undefined);
            } else {
              onMinAssessmentScoreChange(score);
            }
          }}
          scoreDistribution={insights?.assessment_distribution ?? null}
          isLoading={isLoadingInsights}
        />
      ) : null}
      <div className="flex flex-row justify-between mb-4">
        <div className="flex flex-row space-x-2">
          <div className="w-[300px]">
            <Input
              leftIcon={
                <MagnifyingGlassIcon className="h-4 w-4 text-gray-700" />
              }
              maxLength={200}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              onClear={() => {
                setSearchQuery("");
                onSearchTextChange("");
              }}
              onKeyDown={(evt) => {
                if (evt.key === "Enter") {
                  evt.preventDefault();
                  onSearchTextChange(searchQuery);
                  (evt.target as HTMLElement).blur();
                }
              }}
              placeholder="Search transcripts and contacts..."
              value={searchQuery}
            />
          </div>
          {isRecruitingProject ? (
            <div className="w-[180px]">
              <Select
                currentSelection={minAssessmentScoreSelectOptions.find(
                  (o) => o.value === minAssessmentScore,
                )}
                onChange={(option) => {
                  const value = option.value as number;
                  onMinAssessmentScoreChange(value);
                }}
                onClear={() => {
                  onMinAssessmentScoreChange(undefined);
                }}
                options={minAssessmentScoreSelectOptions}
                placeholder="Minimum score"
              />
            </div>
          ) : null}
        </div>
        {isRecruitingProject ? (
          <div className="w-[200px]">
            <Select
              currentSelection={sortSelectOptions.find(
                (option) => option.value === sortOption,
              )}
              onChange={onSortOptionChange}
              options={sortSelectOptions}
              leftIcon={<ArrowsUpDownIcon className="h-4 w-4 text-gray-700" />}
            />
          </div>
        ) : null}
      </div>
      <InterviewsTable
        interviews={interviews}
        isLoading={interviewsStatus === "loading" || isLoadingInsights}
        onInterviewClick={(
          interviewId: string,
          transcriptFragmentId?: string,
        ) => {
          navigate(
            buildProjectUrlPath(
              project.id,
              ProjectDetailsTabPathsEnum.Interviews,
              interviewId,
              transcriptFragmentId,
            ),
          );
        }}
        project={project}
        rankingThresholds={insights?.ranking_thresholds ?? null}
      />
    </div>
  );
};
