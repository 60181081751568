import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { DropdownMenu, ModalVariantsEnum, SimpleModal } from "@repo/ui";
import {
  isNotNullNotUndefined,
  PermissionActions,
  PermissionSubjects,
  Project,
} from "app-types";
import copy from "copy-to-clipboard";
import { FC, useState } from "react";
import { useTeammateAuthorization } from "../../helpers/teammateAuthorizationContext";
import { useAppDispatch } from "../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../notificationsOverlay/notificationsSlice";
import { DuplicateJobOpeningModal } from "./projectSettings/recruiting/duplicateJobOpeningModal";
import { updateProject } from "./projectsSlice";

interface RecruitingProjectMoreMenuProps {
  project: Project;
  onClickDeleteProject: () => void;
  onClickCsvImport: () => void;
  onClickCsvExport: () => void;
  onOpenSharingTemplatesModal: () => void;
  onClickRegenerateInterviewLink: () => void;
}

interface MenuItem {
  label: string;
  onClick: () => void;
}

export const RecruitingProjectMoreMenu: FC<RecruitingProjectMoreMenuProps> = ({
  onClickDeleteProject,
  onClickCsvImport,
  onClickCsvExport,
  project,
  onOpenSharingTemplatesModal,
  onClickRegenerateInterviewLink,
}) => {
  const dispatch = useAppDispatch();
  const [isDuplicateJobOpeningModalOpen, setIsDuplicateJobOpeningModalOpen] =
    useState(false);
  const [isPauseModalOpen, setIsPauseModalOpen] = useState<boolean>(false);

  const teammateAuthorizationContext = useTeammateAuthorization();
  const teammateCanCreate = teammateAuthorizationContext.can(
    PermissionActions.CREATE,
    PermissionSubjects.JOB_OPENINGS,
  );
  const teammateCanDelete = teammateAuthorizationContext.can(
    PermissionActions.DELETE,
    PermissionSubjects.JOB_OPENINGS,
  );
  const teammateCanUpdate = teammateAuthorizationContext.can(
    PermissionActions.UPDATE,
    PermissionSubjects.JOB_OPENINGS,
  );

  const menuItems: MenuItem[] = [
    teammateCanUpdate
      ? {
          label: "Pause",
          onClick: () => {
            setIsPauseModalOpen(true);
          },
        }
      : null,
    {
      label: "View sharing templates",
      onClick: () => {
        onOpenSharingTemplatesModal();
      },
    },
    /* Don't support duplication for legacy projects without job types */
    teammateCanCreate && project.job_type
      ? {
          label: "Duplicate job opening",
          onClick: () => {
            setIsDuplicateJobOpeningModalOpen(true);
          },
        }
      : null,
    { label: "Import candidates from CSV", onClick: onClickCsvImport },
    { label: "Export interviews to CSV", onClick: onClickCsvExport },
    teammateCanUpdate
      ? {
          label: "Refresh interview link",
          onClick: () => {
            onClickRegenerateInterviewLink();
          },
        }
      : null,
    {
      label: "Copy job opening ID",
      onClick: () => {
        copy(project.id);
        showNotification(dispatch, {
          id: `project-${project.id}-copy-id-${new Date().getTime().toString()}`,
          primaryMessage: `Job opening ID copied to clipboard`,
          type: NotificationTypeEnum.SUCCESS,
        });
      },
    },
    teammateCanDelete
      ? {
          label: "Delete job opening",
          onClick: onClickDeleteProject,
        }
      : null,
  ].filter(isNotNullNotUndefined);

  return (
    <>
      <Menu as="div" className="relative ml-3">
        <Menu.Button className="relative flex items-center ring-1 ring-inset ring-gray-300 bg-white p-1.5 hover:bg-gray-50 rounded-md shadow-sm text-gray-900 disabled:bg-slate-300 disabled:text-slate-500">
          <span className="absolute -inset-1.5" />
          <span className="sr-only">Open user menu</span>
          <EllipsisVerticalIcon className="w-6 h-6" />
        </Menu.Button>
        <DropdownMenu items={menuItems} />
      </Menu>
      <SimpleModal
        isOpen={isPauseModalOpen}
        variant={ModalVariantsEnum.Warning}
        title="Pause job opening"
        subtitle="If you pause this job opening, candidates will be blocked from interviewing but existing interviews will remain. You can relaunch this job opening at any time."
        confirmButtonText="Pause job opening"
        onCancel={() => {
          setIsPauseModalOpen(false);
        }}
        onConfirm={() =>
          void (async () => {
            try {
              await dispatch(
                updateProject({
                  projectId: project.id,
                  changes: { is_live: false },
                }),
              );

              showNotification(dispatch, {
                id: `job-opening-${project.id}-paused-${new Date().getTime()}`,
                primaryMessage: `Job opening paused`,
                type: NotificationTypeEnum.SUCCESS,
              });
            } catch (err) {
              console.error("error updating job opening", err);
              showNotification(dispatch, {
                id: `job-opening-${project.id}-pause-failed-${new Date().getTime()}`,
                primaryMessage: `Failed to pause job opening`,
                type: NotificationTypeEnum.FAILURE,
              });
            }

            setIsPauseModalOpen(false);
          })()
        }
      />
      {isDuplicateJobOpeningModalOpen ? (
        <DuplicateJobOpeningModal
          isOpen
          onClose={() => {
            setIsDuplicateJobOpeningModalOpen(false);
          }}
          project={project}
        />
      ) : null}
    </>
  );
};
