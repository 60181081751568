import { LockClosedIcon } from "@heroicons/react/24/outline";
import { SaveAndCancelButtons, Textarea } from "@repo/ui";
import { getCompanyHasFeatureAccess } from "app-types";
import { FC, useState } from "react";
import { useAppSelector } from "../../../hooks/hook";
import { UpgradeText } from "../../billing/upgradeText";
import { selectCompany } from "../../company/companySlice";

interface WelcomeAndOutroMessagesEditorProps {
  welcomeMessage: string | null;
  outroMessage: string | null;
  onWelcomeMessageSave: (message: string | null) => void;
  onOutroMessageSave: (message: string | null) => void;
  isProjectLevel?: boolean;
  isDisabled?: boolean;
}

export const WelcomeAndOutroMessagesEditor: FC<
  WelcomeAndOutroMessagesEditorProps
> = ({
  welcomeMessage: initialWelcomeMessage,
  outroMessage: initialOutroMessage,
  onWelcomeMessageSave,
  onOutroMessageSave,
  isProjectLevel,
  isDisabled,
}) => {
  const company = useAppSelector(selectCompany);
  const [welcomeMessage, setWelcomeMessage] = useState(
    initialWelcomeMessage ?? "",
  );
  const [outroMessage, setOutroMessage] = useState(initialOutroMessage ?? "");

  if (!company) return null;

  const isMissingFeatureAccess = !getCompanyHasFeatureAccess(
    company,
    "whitelabeling",
  );

  return (
    <>
      <label
        className="block text-sm font-semibold leading-6 text-gray-900 mt-2"
        htmlFor="welcome-message"
      >
        Welcome message
      </label>
      <div className="mt-2 w-[500px]">
        <Textarea
          id="welcome-message"
          value={welcomeMessage}
          onChange={setWelcomeMessage}
          placeholder="We're excited to get your responses!"
          leftIcon={
            isMissingFeatureAccess ? (
              <LockClosedIcon className="h-4 w-4 text-gray-700" />
            ) : undefined
          }
          isDisabled={isMissingFeatureAccess || isDisabled}
          maxLength={800}
        />
      </div>
      {welcomeMessage !== (initialWelcomeMessage ?? "") && (
        <SaveAndCancelButtons
          onSave={() => {
            onWelcomeMessageSave(welcomeMessage || null);
          }}
          onCancel={() => {
            setWelcomeMessage(initialWelcomeMessage ?? "");
          }}
        />
      )}
      <div className="mt-2 text-sm text-gray-600">
        A custom greeting (up to 800 characters) to show to participants before
        they start their interview.
        {isProjectLevel ? (
          <span className="text-gray-600">
            {" "}
            This message will override the welcome message set in your
            organization settings.
          </span>
        ) : null}
        {isMissingFeatureAccess ? (
          <UpgradeText planFeatureName="whitelabeling" />
        ) : null}
      </div>

      <label
        className="block text-sm font-semibold leading-6 text-gray-900 mt-4"
        htmlFor="outro-message"
      >
        Outro message
      </label>
      <div className="mt-2 w-[500px]">
        <Textarea
          id="outro-message"
          value={outroMessage}
          onChange={setOutroMessage}
          placeholder="We really appreciate your responses. You'll hear from the team soon."
          leftIcon={
            isMissingFeatureAccess ? (
              <LockClosedIcon className="h-4 w-4 text-gray-700" />
            ) : undefined
          }
          isDisabled={isMissingFeatureAccess || isDisabled}
          maxLength={800}
        />
      </div>
      {outroMessage !== (initialOutroMessage ?? "") && (
        <SaveAndCancelButtons
          onSave={() => {
            onOutroMessageSave(outroMessage || null);
          }}
          onCancel={() => {
            setOutroMessage(initialOutroMessage ?? "");
          }}
        />
      )}
      <div className="mt-2 text-sm text-gray-600">
        A custom message (up to 800 characters) to show to participants after
        they complete their interview.
        {isProjectLevel ? (
          <span className="text-gray-600">
            {" "}
            This message will override the outro message set in your
            organization settings.
          </span>
        ) : null}
        {isMissingFeatureAccess ? (
          <UpgradeText planFeatureName="whitelabeling" />
        ) : null}
      </div>
    </>
  );
};
