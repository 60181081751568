import { renderMultilineContent } from "@repo/ui/content/renderers";
import { SizesEnum } from "@repo/ui/helpers/helpers";
import { Textarea } from "@repo/ui/Inputs/textarea";
import { Label } from "@repo/ui/Label/label";
import { PermissionActions, PermissionSubjects } from "app-types";
import {
  parseMultilineContent,
  stringifyMultilineContent,
  stringifyVariableAsPlaceholder,
} from "app-types/content/plaintext";
import type { MultilineContent } from "app-types/content/types";
import { FC, useMemo, useState, type ReactNode } from "react";
import { Can } from "../../../../helpers/teammateAuthorizationContext";

export interface RolePlayInstructionsFieldProps {
  isReadOnly: boolean;
  label: string;
  description: ReactNode;
  content: MultilineContent;
  onChange: (newContent: MultilineContent) => void;
}

export const RolePlayInstructionsField: FC<RolePlayInstructionsFieldProps> = (
  props,
) => {
  const [text, onTextChange] = useMultilineContent(
    props.content,
    props.onChange,
  );

  return (
    // We need a container for Textarea's multiple children.
    <div>
      {props.isReadOnly ? (
        <>
          <Label size={SizesEnum.SMALL}>{props.label}</Label>
          <div className="text-sm leading-6 text-gray-900">
            {renderMultilineContent(props.content)}
          </div>
        </>
      ) : (
        <Can
          I={PermissionActions.UPDATE}
          a={PermissionSubjects.JOB_OPENINGS}
          passThrough={true}
        >
          {(allowed) => (
            <Textarea
              label={props.label}
              description={props.description}
              value={text}
              height="12rem"
              onChange={onTextChange}
              isDisabled={!allowed}
            />
          )}
        </Can>
      )}
    </div>
  );
};

function useMultilineContent(
  content: MultilineContent,
  onContentChange: (newContent: MultilineContent) => void,
) {
  // Once the user changes the text, we'll use that to render the component just in case parsing and re-stringifying is lossy.
  const [changedText, setChangedText] = useState<string | undefined>(undefined);
  const text = useMemo(
    () =>
      changedText ??
      stringifyMultilineContent(content, {
        stringifyVariable: stringifyVariableAsPlaceholder,
      }),
    [changedText, content],
  );

  const onTextChange = (newValue: string) => {
    setChangedText(newValue);
    onContentChange(
      parseMultilineContent(newValue, {
        parsesVariables: false,
      }),
    );
  };

  return [text, onTextChange] as const;
}
