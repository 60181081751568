import {
  TeammateRoleTypes,
  type BillingPlan,
  type CompanySettings,
  type TablesInsertWithFixedTypes,
  type TablesUpdateWithFixedTypes,
  type TablesWithFixedTypes,
} from "..";

export type CompanyDB = TablesWithFixedTypes<"company">;
export type CompanyInsertDB = TablesInsertWithFixedTypes<"company">;
export type CompanyUpdateDB = Omit<
  TablesUpdateWithFixedTypes<"company">,
  "created_at" | "has_sensitive_data"
>;

export interface Company {
  id: string;
  name: string;
  description: string | null;
  billing_company_id: string | null;
  billing_current_plan: BillingPlan | null;
  billing_usage_fee_percent: number;
  has_sensitive_data: boolean;
  created_at: string;
  updated_at: string | null;
  trial_expires_at: string | null;
  settings: CompanySettings;
  slug: string | null;
}

const DEFAULT_USAGE_FEE_PERCENT = 30;

// How we fill properties that an existing company does not have
function getDefaultCompanySettings() {
  return {
    balance_refill_unit_amount: null,
    balance_refill_threshold_unit_amount: null,
    is_sending_invites_blocked: false,
    logo_file_name: null,
    favicon_file_name: null,
    interview_color: null,
    mode: null,
    whitelabel_domain: null,
    whitelabel_contact_email: null,
    welcome_message: null,
    outro_message: null,
    identity_provider_id: null,
    is_cheating_detection_enabled: false,
    ext_account_id: null,
    domain_signup_teammate_permissions: {
      role: {
        type: TeammateRoleTypes.EDITOR,
      },
    },
  };
}

export function mapCompanyDBToCompany(c: CompanyDB) {
  const company: Company = {
    id: c.id,
    name: c.name,
    description: c.description,
    billing_company_id: c.billing_company_id,
    billing_current_plan: c.billing_current_plan
      ? c.billing_current_plan
      : null,
    billing_usage_fee_percent:
      typeof c.billing_usage_fee_percent === "number"
        ? c.billing_usage_fee_percent
        : DEFAULT_USAGE_FEE_PERCENT,
    has_sensitive_data: Boolean(c.has_sensitive_data),
    settings: {
      ...getDefaultCompanySettings(),
      ...(typeof c.settings === "object" ? c.settings : {}),
    },
    created_at: c.created_at,
    updated_at: c.updated_at,
    trial_expires_at: c.trial_expires_at,
    slug: c.slug,
  };

  return company;
}

export type CompanyDomainDB = TablesWithFixedTypes<"company_domain">;

export interface CompanyDomain {
  domain: string;
  company_id: string;
}

export function mapCompanyDomainDBToCompanyDomain(c: CompanyDomainDB) {
  return {
    domain: c.domain,
    company_id: c.company_id,
  };
}
