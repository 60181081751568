import { Button, ButtonVariantsEnum, InfoBanner } from "@repo/ui";
import { FC } from "react";

export const PaywallBanner: FC = () => {
  return (
    <InfoBanner
      title={"Please upgrade to continue using Alpharun"}
      description={
        <>
          {
            "Your trial has expired and your company does not have an active subscription. Please upgrade to run new interviews and access insights. "
          }
          <div className="flex space-x-4 mt-4">
            <Button
              variant={ButtonVariantsEnum.Primary}
              onClick={() => {
                window.open("mailto:support@alpharun.com");
              }}
            >
              Contact us
            </Button>
            <Button
              variant={ButtonVariantsEnum.Secondary}
              onClick={() => {
                window.open("https://cal.frontapp.com/alpharun/paul/7fc09a3a");
              }}
            >
              Book a meeting
            </Button>
          </div>
        </>
      }
    />
  );
};
