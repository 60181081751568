import type { ProjectWithInterviewCount } from "app-types";
import { Searcher, type MatchData } from "fast-fuzzy";
import parsePhoneNumberFromString from "libphonenumber-js";
import { chain, compact, memoize } from "lodash";

export function extractNameParts(values: {
  firstName: string | undefined;
  lastName: string | undefined;
  fullName: string | undefined;
}): { firstName: string | undefined; lastName: string | undefined } {
  // If a first name and last name are provided, use them.
  if (values.firstName && values.lastName) {
    return {
      firstName: values.firstName,
      lastName: values.lastName,
    };
  }

  if (values.firstName && values.fullName?.startsWith(values.firstName)) {
    const lastName = values.fullName.substring(values.firstName.length).trim();
    return { firstName: values.firstName, lastName };
  }

  if (values.lastName && values.fullName?.endsWith(values.lastName)) {
    const firstName = values.fullName
      .substring(0, values.fullName.length - values.lastName.length)
      .trim();
    return { firstName, lastName: values.lastName };
  }

  const commaSplit = values.fullName?.split(",").map((name) => name.trim());
  if (commaSplit?.length === 2) {
    const [lastName, firstName] = commaSplit;
    return { firstName, lastName };
  }

  const spaceSpace = values.fullName?.split(" ", 2).map((name) => name.trim());
  if (spaceSpace?.length === 2) {
    const [firstName, lastName] = spaceSpace;
    return { firstName, lastName };
  }

  return {
    firstName: values.firstName || values.fullName,
    lastName: values.lastName,
  };
}

type ProjectSearchFunction = (
  queries: readonly string[],
) => ProjectWithInterviewCount | undefined;

/** Creates a function to perform a fuzzy search against projects with caching and other optimizations. */
export function createProjectSearcher(
  projects: readonly ProjectWithInterviewCount[],
): ProjectSearchFunction {
  const searcher = new Searcher([...projects], {
    // TODO: Tune this default threshold.
    // threshold: 0.6,
    keySelector: (project) =>
      compact([
        // Match against the project name.
        project.name,
        // Match against the job type name and location combo, if applicable.
        project.job_type && project.location
          ? `${project.job_type.name} - ${project.location.description}`
          : undefined,
      ]),
  });

  // Memoize search queries, since a CSV file is likely going to have many repeat values.
  const search = memoize((query: string) =>
    searcher.search(query, {
      returnMatchData: true,
    }),
  );

  return (queries) => {
    // Early return if no search queries were provided.
    if (queries.length === 0) {
      return undefined;
    }

    // Find the match with the highest score or greatest length.
    const bestMatch: MatchData<ProjectWithInterviewCount> | undefined = chain(
      queries,
    )
      .flatMap(search)
      .orderBy(
        [(match) => match.score, (match) => match.match.length],
        ["desc", "desc"],
      )
      .first()
      .value();

    return bestMatch?.item;
  };
}

export function extractProject(
  projects: readonly ProjectWithInterviewCount[],
  searchProjects: ProjectSearchFunction,
  values: {
    id: string | undefined;
    name: string | undefined;
    role: string | undefined;
    location: string | undefined;
  },
): ProjectWithInterviewCount | undefined {
  // If an ID was provided, use that.
  if (values.id) {
    return projects.find((project) => project.id === values.id);
  }

  // If any of these fields were provided, perform a fuzzy search.
  if (values.name || values.role || values.location) {
    const searchQueries = [];

    // A job opening name was provided.
    if (values.name) {
      searchQueries.push(values.name);
    }

    // A role and location combination were provided.
    if (values.role && values.location) {
      searchQueries.push(`${values.role} ${values.location}`);
    }

    return searchProjects(searchQueries);
  }

  return undefined;
}

export function extractEmailAddress(
  email: string | undefined,
): string | undefined {
  // Bare minimum validation.
  if (!email?.includes("@")) {
    return undefined;
  }

  // Bare minimum normalization.
  return email.trim().toLowerCase();
}

export function extractPhoneNumber(
  phoneNumber: string | undefined,
): string | undefined {
  if (!phoneNumber) {
    return undefined;
  }

  const parsed = parsePhoneNumberFromString(phoneNumber, "US");
  return parsed?.formatInternational();
}
