import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { LoadingStatesEnum, Teammate, type TeammateUpdate } from "app-types";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { RootState } from "../../app/store";

const teammatesAdapter = createEntityAdapter<Teammate>();

const initialState = teammatesAdapter.getInitialState<{
  status: LoadingStatesEnum;
  error: string | null;
}>({
  status: LoadingStatesEnum.LOADED,
  error: null,
});

export const fetchTeammates = createAsyncThunk(
  "teammates/fetchAll",
  async (_parameters, thunkAPI) => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      const response = await axios.get("/teammates");

      return thunkAPI.fulfillWithValue(response.data.teammates);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

interface UpdateTeammateArgs {
  id: string;
  updates: TeammateUpdate;
}

export const updateTeammate = createAsyncThunk<
  { data: { teammate: Teammate } },
  UpdateTeammateArgs
>("teammates/updateTeammate", async (args: UpdateTeammateArgs, thunkAPI) => {
  try {
    const axios = await getAxiosInstanceWithAuth();
    const response = await axios.patch(`/teammates/${args.id}`, args.updates);

    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

const teammatesSlice = createSlice({
  name: "teammates",
  initialState: initialState,
  reducers: {
    teammateAdded: teammatesAdapter.addOne,
    teammateRemoved: teammatesAdapter.removeOne,
    teammateUpdated: teammatesAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeammates.pending, (state) => {
        state.status = LoadingStatesEnum.LOADING;
      })
      .addCase(fetchTeammates.fulfilled, (state, action) => {
        state.status = LoadingStatesEnum.LOADED;
        teammatesAdapter.setAll(state, action.payload);
      })
      .addCase(fetchTeammates.rejected, (state, action) => {
        state.status = LoadingStatesEnum.ERROR;
        state.error = action.error.message || null;
      })
      .addCase(updateTeammate.pending, (state, action) => {
        state.status = LoadingStatesEnum.LOADING;
        // Optimistic update
        teammatesAdapter.updateOne(state, {
          id: action.meta.arg.id,
          changes: action.meta.arg.updates as Partial<Teammate>,
        });
      })
      .addCase(updateTeammate.fulfilled, (state, action) => {
        state.status = LoadingStatesEnum.LOADED;
        teammatesAdapter.updateOne(state, {
          id: action.payload.data.teammate.id,
          changes: action.payload.data.teammate,
        });
      })
      .addCase(updateTeammate.rejected, (state, action) => {
        state.status = LoadingStatesEnum.ERROR;
        state.error = action.error.message || null;
      });
  },
});

export const selectAllTeammates = teammatesAdapter.getSelectors().selectAll;
export const selectTeammatesLoadingState = (state: RootState) =>
  state.teammates.status;

export default teammatesSlice.reducer;
