import { FC } from "react";
import {
  Modal,
  ModalOverlay,
  type ModalOverlayProps,
} from "react-aria-components";
import { BufferWhileExiting } from "../helpers/bufferWhileExiting";
import { SizesEnum } from "../helpers/helpers";
import { modal } from "./modal";
import { ModalBackdrop } from "./modalBackdrop";
import { modalOverlay } from "./modalOverlay";
import { ModalStage } from "./modalStage";

export interface CenteredModalProps extends ModalOverlayProps {
  size?: SizesEnum.SMALL | SizesEnum.LARGE;
}

export const CenteredModal: FC<CenteredModalProps> = (props) => {
  const { size, children, ...modalOverlayProps } = props;

  return (
    <ModalOverlay {...modalOverlayProps} className={modalOverlay()}>
      <ModalBackdrop />
      <ModalStage>
        <Modal className={modal({ size })}>
          {(modalProps) => (
            <BufferWhileExiting
              isOpen={modalProps.state.isOpen}
              isExiting={modalProps.isExiting}
            >
              {typeof children === "function" ? children(modalProps) : children}
            </BufferWhileExiting>
          )}
        </Modal>
      </ModalStage>
    </ModalOverlay>
  );
};
