import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import {
  Button,
  ButtonVariantsEnum,
  InfoTooltip,
  ModalVariantsEnum,
  SimpleModal,
  SizesEnum,
} from "@repo/ui";
import { renderSingleLineContent } from "@repo/ui/content/renderers";
import {
  InterviewDecision,
  isRecruitingModeProject,
  MessageTemplateType,
} from "app-types";
import { InterviewLoaded } from "app-types/entities/interview";
import { FC, useState } from "react";
import { useAllMessageTemplatesQuery } from "../../../api/redux";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { selectProjectById } from "../../projects/projectsSlice";
import { updateInterview } from "../interviewsSlice";

interface InterviewDecisionButtonsProps {
  interview: InterviewLoaded;
}

export const InterviewDecisionButtons: FC<InterviewDecisionButtonsProps> = ({
  interview,
}) => {
  const dispatch = useAppDispatch();
  const [showUndoDialog, setShowUndoDialog] = useState(false);

  const project = useAppSelector(selectProjectById(interview.project_id));
  const { data: allMessageTemplates } = useAllMessageTemplatesQuery();

  if (!project || !isRecruitingModeProject(project)) {
    return null;
  }

  const acceptanceTemplate = allMessageTemplates?.find(
    (t) =>
      t.id === project.settings.acceptance_template_id &&
      t.type === MessageTemplateType.Advanced,
  );

  const rejectionTemplate = allMessageTemplates?.find(
    (t) =>
      t.id === project.settings.rejection_template_id &&
      t.type === MessageTemplateType.Rejected,
  );

  const onDecisionChange = (decision: InterviewDecision | null) => {
    dispatch(
      updateInterview({
        projectId: interview.project_id,
        interviewId: interview.id,
        update: {
          decision,
        },
      }),
    );

    if (decision === null) {
      setShowUndoDialog(false);
    }
  };

  const renderAdvanceButton = () => {
    return (
      <Button
        icon={
          <CheckCircleIcon
            aria-hidden="true"
            className="h-4 w-4 text-green-600"
          />
        }
        onClick={() => {
          onDecisionChange("advanced");
        }}
        size={SizesEnum.MEDIUM}
        variant={ButtonVariantsEnum.Secondary}
      >
        Advance
      </Button>
    );
  };

  const renderRejectButton = () => {
    return (
      <Button
        icon={
          <XCircleIcon aria-hidden="true" className="h-4 w-4 text-red-600" />
        }
        onClick={() => {
          onDecisionChange("rejected");
        }}
        size={SizesEnum.MEDIUM}
        variant={ButtonVariantsEnum.Secondary}
      >
        Reject
      </Button>
    );
  };

  if (interview.decision === "advanced") {
    return (
      <>
        <Button
          icon={
            <CheckCircleIcon
              aria-hidden="true"
              className="h-4 w-4 text-green-600"
            />
          }
          onClick={() => {
            setShowUndoDialog(true);
          }}
          size={SizesEnum.MEDIUM}
          variant={ButtonVariantsEnum.Secondary}
        >
          Advanced
        </Button>
        <SimpleModal
          confirmButtonText="Undo"
          isOpen={showUndoDialog}
          onCancel={() => {
            setShowUndoDialog(false);
          }}
          onConfirm={() => {
            onDecisionChange(null);
          }}
          subtitle="Are you sure you want to undo advancing this candidate?"
          title="Undo Advancement"
          variant={ModalVariantsEnum.Standard}
        />
      </>
    );
  }

  if (interview.decision === "rejected") {
    return (
      <>
        <Button
          icon={
            <XCircleIcon aria-hidden="true" className="h-4 w-4 text-red-600" />
          }
          onClick={() => {
            setShowUndoDialog(true);
          }}
          size={SizesEnum.MEDIUM}
          variant={ButtonVariantsEnum.Secondary}
        >
          Rejected
        </Button>
        <SimpleModal
          confirmButtonText="Undo"
          isOpen={showUndoDialog}
          onCancel={() => {
            setShowUndoDialog(false);
          }}
          onConfirm={() => {
            onDecisionChange(null);
          }}
          subtitle="Are you sure you want to undo rejecting this candidate?"
          title="Undo Rejection"
          variant={ModalVariantsEnum.Standard}
        />
      </>
    );
  }

  return (
    <>
      {acceptanceTemplate ? (
        <InfoTooltip
          place="bottom"
          id="advance-tooltip"
          content={
            <div className="text-xs">
              Advance the candidate and send the "
              {renderSingleLineContent(acceptanceTemplate.subject)}" advancement
              message.
            </div>
          }
        >
          {renderAdvanceButton()}
        </InfoTooltip>
      ) : (
        renderAdvanceButton()
      )}

      {rejectionTemplate ? (
        <InfoTooltip
          place="bottom"
          id="reject-tooltip"
          content={
            <div className="text-xs">
              Reject the candidate and send the "
              {renderSingleLineContent(rejectionTemplate.subject)}" rejection
              message.
            </div>
          }
        >
          {renderRejectButton()}
        </InfoTooltip>
      ) : (
        renderRejectButton()
      )}
    </>
  );
};
