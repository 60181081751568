import { z } from "zod";
import { type MultilineContent } from "..";

export const textNodeSchema = z.object({
  type: z.literal("text"),
  text: z.string(),
});

export const placeholderNodeSchema = z.object({
  type: z.literal("placeholder"),
  attrs: z.object({ name: z.string() }),
});

export const inlineNodeSchema = z.union([
  textNodeSchema,
  placeholderNodeSchema,
]);

export const paragraphNodeSchema = z.object({
  type: z.literal("paragraph"),
  content: z.array(inlineNodeSchema),
});

export const singleLineContentSchema = z.object({
  content: z.array(inlineNodeSchema),
});

export const multilineContentSchema = z.object({
  content: z.array(paragraphNodeSchema),
});

export function isValidMultilineContent(
  content: unknown,
): content is MultilineContent {
  return multilineContentSchema.safeParse(content).success;
}
