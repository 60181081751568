export enum InterviewLanguagesEnum {
  ENGLISH = "en-US",
  ENGLISH_AND_SPANISH = "en-US,es-ES",
  FRENCH = "fr-FR",
  GERMAN = "de-DE",
  HINDI = "hi-IN",
  ITALIAN = "it-IT",
  PORTUGUESE = "pt-PT",
  SPANISH = "es-ES",
}

export const allTranslationLanguages = [
  InterviewLanguagesEnum.ENGLISH,
  InterviewLanguagesEnum.GERMAN,
] as const;

export type TranslationLanguage = (typeof allTranslationLanguages)[number];

export const LANGUAGE_DISPLAY_NAMES: Record<InterviewLanguagesEnum, string> = {
  [InterviewLanguagesEnum.ENGLISH]: "English",
  [InterviewLanguagesEnum.ENGLISH_AND_SPANISH]:
    "English & Spanish Multilingual",
  [InterviewLanguagesEnum.GERMAN]: "German",
  [InterviewLanguagesEnum.FRENCH]: "French",
  [InterviewLanguagesEnum.HINDI]: "Hindi",
  [InterviewLanguagesEnum.ITALIAN]: "Italian",
  [InterviewLanguagesEnum.PORTUGUESE]: "Portuguese",
  [InterviewLanguagesEnum.SPANISH]: "Spanish",
};

export const LANGUAGE_TO_FLAG = {
  [InterviewLanguagesEnum.ENGLISH]: "🇺🇸",
  [InterviewLanguagesEnum.ENGLISH_AND_SPANISH]: "🇺🇸🇪🇸",
  [InterviewLanguagesEnum.FRENCH]: "🇫🇷",
  [InterviewLanguagesEnum.GERMAN]: "🇩🇪",
  [InterviewLanguagesEnum.HINDI]: "🇮🇳",
  [InterviewLanguagesEnum.ITALIAN]: "🇮🇹",
  [InterviewLanguagesEnum.PORTUGUESE]: "🇵🇹",
  [InterviewLanguagesEnum.SPANISH]: "🇪🇸",
};

export function getAgentLanguageInstructions(language: InterviewLanguagesEnum) {
  if (language === InterviewLanguagesEnum.ENGLISH_AND_SPANISH) {
    return "Respond in English or Spanish, whichever the candidate is speaking.";
  }

  return `Always respond in ${LANGUAGE_DISPLAY_NAMES[language]}`;
}

export function getCandidateLanguageDescription(
  language: InterviewLanguagesEnum,
) {
  if (language === InterviewLanguagesEnum.ENGLISH_AND_SPANISH) {
    return "This interview can be conducted in English or Spanish, whichever you prefer.";
  }

  return `This interview will be conducted in ${LANGUAGE_DISPLAY_NAMES[language]}`;
}

/**
 * English voices
 */
export const EnglishVoices = {
  American: "american",
  British: "british",
  Filipino: "filipino",
  Indian: "indian",
  SouthAfrican: "south_african",
} as const;

const allEnglishVoices = [
  EnglishVoices.American,
  EnglishVoices.British,
  EnglishVoices.Filipino,
  EnglishVoices.Indian,
  EnglishVoices.SouthAfrican,
] as const;

export type EnglishVoice = (typeof allEnglishVoices)[number];

export type LanguageVoice = EnglishVoice;

export function getVoicesForLanguage(
  language: InterviewLanguagesEnum,
): readonly LanguageVoice[] {
  switch (language) {
    case InterviewLanguagesEnum.ENGLISH:
      return allEnglishVoices;
    default:
      return [];
  }
}

export function getDefaultVoiceForLanguage(
  language: InterviewLanguagesEnum,
): LanguageVoice | null {
  switch (language) {
    case InterviewLanguagesEnum.ENGLISH:
      return EnglishVoices.American;
    default:
      return null;
  }
}

export const languageVoiceDisplayNames: Record<LanguageVoice, string> = {
  [EnglishVoices.American]: "American accent (female)",
  [EnglishVoices.British]: "British accent (female)",
  [EnglishVoices.Filipino]: "Filipino accent (male)",
  [EnglishVoices.Indian]: "Indian accent (female)",
  [EnglishVoices.SouthAfrican]: "South African accent (female)",
};
