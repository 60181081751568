import { CalendarIcon } from "@heroicons/react/24/outline";
import {
  Button,
  ButtonVariantsEnum,
  InfoBanner,
  Input,
  Label,
  Link,
  RadioButtonsGroup,
  SizesEnum,
} from "@repo/ui";
import {
  Company,
  CompanyInvitation,
  Teammate,
  extractDomainFromEmail,
} from "app-types";
import { FC, useState } from "react";
import { useAppDispatch } from "../../hooks/hook";
import { postCompanyData } from "../company/companySlice";
import { SALES_DEMO_LINK } from "../signin/signInPage";
import { putTeammateData } from "../teammate/teammateSlice";

interface OnboardingFormProps {
  teammate: Teammate;
  company: Company | null;
  companyInvitation: CompanyInvitation | null;
  onAcceptCompanyInvitation: () => Promise<void>;
  onJoinCompanyViaDomainLogin: () => Promise<void>;
  domainLoginCompanyName: string | undefined;
  isDomainAvailable: boolean;
  isJoiningMatchingDomainCompany: boolean;
  setIsJoiningMatchingDomainCompany: (value: boolean) => void;
}

/*
 * This component is responsible for collecting information required before using the app:
 * - First and last name (whenever any user signs up)
 * - Accepting an invitation to join a company or creating a new company name (first user in a company OR user was removed from previous company)
 */

const OnboardingForm: FC<OnboardingFormProps> = ({
  teammate,
  company,
  companyInvitation,
  onAcceptCompanyInvitation,
  onJoinCompanyViaDomainLogin,
  domainLoginCompanyName,
  isDomainAvailable,
  isJoiningMatchingDomainCompany,
  setIsJoiningMatchingDomainCompany,
}) => {
  const dispatch = useAppDispatch();

  const [firstName, setFirstName] = useState(teammate.first_name || "");
  const [lastName, setLastName] = useState(teammate.last_name || "");
  const [companyName, setCompanyName] = useState(company?.name || "");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");

  const isCreatingNewCompany =
    !companyInvitation && !company && !isJoiningMatchingDomainCompany;

  // Check the URL to see if it looks like a link to an interview or project.
  // If it is and the user is creating a new company, show a warning that they should ask for an invite
  const pathname = window.location.pathname;
  const isViewingProjectOrInterviewLink =
    pathname.includes("/project/") || pathname.includes("/interviews/");

  const [
    shouldEnableDomainLoginForNewCompany,
    setShouldEnableDomainLoginForNewCompany,
  ] = useState(true);

  const onClickSave = async () => {
    const missingCompanyName = isCreatingNewCompany && !companyName;

    // We need a first name, last name, and organization name
    if (!firstName) setFirstNameError("First name is required");
    if (!lastName) setLastNameError("Last name is required");
    if (missingCompanyName)
      setCompanyNameError("Please provide your organization name");

    // If any of the fields are missing, let user fill in
    if (!firstName || !lastName || missingCompanyName) return;

    // Update the teammate first name and last name if necessary
    if (teammate.first_name !== firstName || teammate.last_name !== lastName)
      dispatch(
        putTeammateData({
          ...teammate,
          first_name: firstName,
          last_name: lastName,
        }),
      );

    // If we're just accepting an invitation, join that company.
    if (companyInvitation) {
      return onAcceptCompanyInvitation();
    }

    // If we're creating a new company, create the company with the user specified name
    // and enable domain login if necessary.
    if (isCreatingNewCompany) {
      // Create a new company if necessary
      return dispatch(
        postCompanyData({
          companyName: companyName,
          shouldEnableDomainLogin:
            isDomainAvailable && shouldEnableDomainLoginForNewCompany,
        }),
      );
    }

    // Otherwise, we must be joining an existing company via domain login
    if (isJoiningMatchingDomainCompany && domainLoginCompanyName) {
      return onJoinCompanyViaDomainLogin();
    }

    // We'll reach here if the user logged in with SSO.
  };

  const renderOrganizationOptions = () => {
    if (companyInvitation || company) {
      return null;
    }

    const shouldShowNewOrganizationConfigurationOptions =
      !domainLoginCompanyName ||
      (domainLoginCompanyName && !isJoiningMatchingDomainCompany);

    return (
      <>
        {domainLoginCompanyName && (
          <div className="mt-5">
            <RadioButtonsGroup
              label="Organization"
              options={[
                {
                  name: `${domainLoginCompanyName} (recommended)`,
                  description: `Join the existing organization for @${extractDomainFromEmail(
                    teammate.email,
                  )} users.`,
                },
                {
                  name: "New organization",
                  description: "Create your own organization.",
                },
              ]}
              value={
                isJoiningMatchingDomainCompany
                  ? `${domainLoginCompanyName} (recommended)`
                  : "New organization"
              }
              onChange={(value) => {
                setIsJoiningMatchingDomainCompany(
                  value === `${domainLoginCompanyName} (recommended)`,
                );
              }}
            />
          </div>
        )}
        {shouldShowNewOrganizationConfigurationOptions && (
          <div className="mt-5 flex flex-col space-y-4">
            {isViewingProjectOrInterviewLink && (
              <InfoBanner
                title={`Trying to join an existing Alpharun organization?`}
                description={`Ask a teammate to invite you to Alpharun to avoid creating a duplicate organization.`}
              />
            )}

            <Label size={SizesEnum.SMALL}>
              Please book a demo to get started with Alpharun.
            </Label>
            <div className="max-w-[300px] mx-auto">
              <Button
                variant={ButtonVariantsEnum.Primary}
                onClick={() => (window.location.href = SALES_DEMO_LINK)}
                icon={<CalendarIcon className="h-5 w-5" />}
                className="w-full"
                size={SizesEnum.LARGE}
              >
                Schedule a demo
              </Button>
            </div>
            <div className="text-sm text-gray-500">
              Questions? Email us at{" "}
              <Link href="mailto:hello@alpharun.com">hello@alpharun.com</Link>
              {"."}
            </div>

            {/* Restore the options below when we enable self-serve signup
             <div className="sm:col-span-4">
              <Input
                label="Organization name"
                value={companyName}
                onChange={(evt) => {
                  setCompanyNameError("");
                  setCompanyName(evt.target.value);
                }}
                placeholder="Acme"
                errorDescription={companyNameError}
                description={`The name of your company.${
                  domainLoginCompanyName
                    ? ""
                    : " To join an existing organization, request an invite from a teammate first."
                }`}
              />
            </div>

            {isDomainAvailable && (
              <div>
                <label className="block text-sm mb-1 font-medium leading-6 text-gray-900">
                  Domain login
                </label>
                <Checkbox
                  isChecked={shouldEnableDomainLoginForNewCompany}
                  onChange={setShouldEnableDomainLoginForNewCompany}
                  label={`Allow teammates with an @${extractDomainFromEmail(
                    teammate.email
                  )} email address to automatically join your organization.`}
                />
              </div>
            )} */}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="mx-auto max-w-2xl">
      <form>
        <div>
          <h1 className="text-2xl font-semibold text-gray-800">
            {companyInvitation
              ? `${companyInvitation.sender_full_name} invited you to join the ${companyInvitation.company_name} team on Alpharun`
              : "Welcome to Alpharun"}
          </h1>
          {companyInvitation && company && (
            <div className="mt-4 text-sm text-orange-600">
              {`Heads up: you're accepting an invite to join ${companyInvitation.company_name} but you already belong to the ${company.name} organization. If you continue you'll lose access to your previous organization.`}
            </div>
          )}
          <p className="mt-2 text-sm leading-6 text-gray-600">
            {"Provide a few details to get started."}
          </p>
          <div className="pb-8">
            <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <Input
                  label="Email address"
                  onChange={() => {
                    /* noop for disabled input*/
                  }}
                  value={teammate.email}
                  readOnly
                  isDisabled
                />
              </div>
            </div>
            <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <Input
                  label="First name"
                  value={firstName}
                  onChange={(evt) => {
                    setFirstNameError("");
                    setFirstName(evt.target.value);
                  }}
                  autoComplete="given-name"
                  errorDescription={firstNameError}
                />
              </div>
              <div className="sm:col-span-3">
                <Input
                  label="Last name"
                  value={lastName}
                  onChange={(evt) => {
                    setLastNameError("");
                    setLastName(evt.target.value);
                  }}
                  autoComplete="family-name"
                  errorDescription={lastNameError}
                />
              </div>
            </div>
            {renderOrganizationOptions()}
          </div>
        </div>

        {!isCreatingNewCompany && (
          <div className="mt-4 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={onClickSave}
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {"Save"}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default OnboardingForm;
