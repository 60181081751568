import { Dialog } from "@headlessui/react";
import { FC, ReactNode } from "react";
import { tv } from "tailwind-variants";
import { ModalCloseButton } from "./modalCloseButton";

export interface ModalHeaderProps {
  children: ReactNode;
  onClose?: () => void;
}

export const modalHeader = tv({
  slots: {
    header: "flex items-center justify-between space-x-4",
    title: "text-xl font-semibold leading-5 text-gray-900",
  },
});

// Use this only with Dialog from Headless UI.
export const ModalHeader: FC<ModalHeaderProps> = (props) => {
  return (
    <div className={modalHeader().header()}>
      <Dialog.Title
        as="h3"
        className={modalHeader().title({ className: "leading-6" })}
      >
        {props.children}
      </Dialog.Title>

      {props.onClose ? <ModalCloseButton onClick={props.onClose} /> : null}
    </div>
  );
};
