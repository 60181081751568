import {
  Button,
  ButtonVariantsEnum,
  Modal,
  ModalHeader,
  Select,
} from "@repo/ui";
import {
  allMessageTemplateTypes,
  MessageTemplateType,
  MessageTemplateTypeToLabel,
  parseMultilineContent,
  parseSingleLineContent,
} from "app-types";
import { FC, useState } from "react";
import { useCreateMessageTemplateMutation } from "../../../api/redux";
import { MessageTemplateForm } from "./messageTemplateForm";

export interface MessageTemplateCreateDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface MessageTemplateValues {
  subject: string;
  body: string;
}

const messageTemplateTypeOptions = allMessageTemplateTypes.map((t) => ({
  name: MessageTemplateTypeToLabel[t],
  value: t,
}));

export const MessageTemplateCreateDialog: FC<
  MessageTemplateCreateDialogProps
> = (props) => {
  const [createMessageTemplate, { isLoading: isCreating }] =
    useCreateMessageTemplateMutation();

  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [type, setType] = useState<MessageTemplateType | null>(null);

  // TODO: This is an annoying pattern. Find a better way to do this.
  const resetState = () => {
    setType(null);
    setSubject("");
    setBody("");
  };

  const onClose = () => {
    resetState();
    props.onClose();
  };

  const onCreate = async () => {
    if (!type) {
      return;
    }

    await createMessageTemplate({
      type: type,
      subject: parseSingleLineContent(subject, {
        parsesVariables: true,
      }),
      body: parseMultilineContent(body, {
        parsesVariables: true,
      }),
    }).unwrap();
    onClose();
  };

  const isValid = !!subject.trim() && !!body.trim() && type;

  return (
    <Modal isOpen={props.isOpen} onCancel={onClose}>
      <ModalHeader onClose={onClose}>New message template</ModalHeader>
      <div className="space-y-4 my-4">
        <div className="flex flex-col space-y-2">
          <div className="w-[250px]">
            <Select
              label="Message type"
              currentSelection={messageTemplateTypeOptions.find(
                (t) => t.value === type,
              )}
              placeholder="Select a type"
              options={messageTemplateTypeOptions}
              onChange={(e) => setType(e.value as MessageTemplateType)}
            />
          </div>
        </div>
        <MessageTemplateForm
          subject={subject}
          body={body}
          type={type ?? MessageTemplateType.Advanced}
          onSubjectChange={setSubject}
          onBodyChange={setBody}
        />
      </div>
      <div className="flex justify-end space-x-2">
        <Button variant={ButtonVariantsEnum.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant={ButtonVariantsEnum.Primary}
          isLoading={isCreating}
          isDisabled={!isValid}
          onClick={onCreate}
        >
          Create message template
        </Button>
      </div>
    </Modal>
  );
};
