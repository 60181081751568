import { skipToken } from "@reduxjs/toolkit/query";
import {
  Button,
  ButtonVariantsEnum,
  Label,
  SizesEnum,
  Slideover,
} from "@repo/ui";
import { useConfirmationModal } from "@repo/ui/hooks/useConfirmationModal";
import {
  Assessment,
  BasicQuestion,
  CustomFieldDefinition,
  CustomFieldDefinitionInsertClient,
  getErrorMessageForAssessmentCriteriaValue,
  getHaveAssessmentFieldsChanged,
  InterviewLanguagesEnum,
  PermissionActions,
  PermissionSubjects,
  ProjectUpdate,
  sortAssessmentFields,
} from "app-types";
import type { InterviewSectionSettings } from "app-types/entities/interviewSection/settings";
import { FeatureFlagKey } from "app-types/featureFlags/keys";
import { FC, useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";
import { v4 as uuid } from "uuid";
import {
  api,
  useProjectJobAssessmentFeedbackByIdQuery,
  useProjectJobDescriptionFeedbackByIdQuery,
} from "../../api/redux";
import { useIsFeatureFlagEnabled } from "../../helpers/featureFlags/hooks";
import { Can } from "../../helpers/teammateAuthorizationContext";
import { useAppDispatch } from "../../hooks/hook";
import { AssessmentEditor } from "../assessments/assessmentEditor";
import { updateAssessment } from "../assessments/assessmentsSlice";
import { InterviewSectionDefinitionListPanel } from "../interviewSections/definitions/listPanel";
import {
  insertInterviewSectionDefinitionChange,
  type ChangedInterviewSectionDefinition,
} from "../interviewSections/definitions/state";
import { SuggestionsWidget } from "../projects/feedback/suggestionsWidget";
import { JobDescriptionTextArea } from "../projects/projectSettings/jobDescriptionSetting";
import { QuestionsPanel } from "../projects/recruitingQuestions/questionsPanel";
import {
  questionsActions,
  useQuestionsStore,
} from "../projects/recruitingQuestions/questionsStore";

interface JobTypeEditorSlideoverProps {
  projectId: string;
  jobTypeId: string | undefined;
  basicQuestions: BasicQuestion[];
  assessment: Assessment | null;
  language: InterviewLanguagesEnum;
  onSaveProject: (changes: ProjectUpdate) => void;
  isOpen: boolean;
  onClose: () => void;
  jobDescription: string | null;
}

export const JobTypeEditorSlideover: FC<JobTypeEditorSlideoverProps> = (
  props,
) => {
  const { projectId, assessment, language, onSaveProject, isOpen, onClose } =
    props;
  const dispatch = useAppDispatch();

  const isInterviewSectionsEnabled = useIsFeatureFlagEnabled(
    FeatureFlagKey.InterviewSections,
  );

  const [jobDescription, setJobDescription] = useState(
    props.jobDescription ?? "",
  );
  // State for managing basic questions
  const [
    { questions, hasMadeChanges: hasMadeChangesToQuestions },
    onQuestionsChange,
  ] = useQuestionsStore(props.basicQuestions);

  const [changedSections, setChangedSections] = useState<
    readonly ChangedInterviewSectionDefinition[]
  >([]);

  const onSectionCreate = (settings: InterviewSectionSettings) => {
    const id = `client:${uuid()}`;
    setChangedSections((prev) =>
      insertInterviewSectionDefinitionChange(prev, {
        changeType: "created",
        id,
        settings,
      }),
    );
    return id;
  };

  const onSectionUpdate = (id: string, settings: InterviewSectionSettings) => {
    setChangedSections((prev) =>
      insertInterviewSectionDefinitionChange(prev, {
        changeType: "updated",
        id,
        settings,
      }),
    );
  };

  const onSectionDelete = (id: string) => {
    setChangedSections((prev) =>
      insertInterviewSectionDefinitionChange(prev, {
        changeType: "deleted",
        id,
      }),
    );
  };

  const [hasMadeChangesHere, setHasMadeChanges] = useState(false);
  const hasMadeChanges =
    hasMadeChangesHere ||
    hasMadeChangesToQuestions ||
    changedSections.length > 0;

  // State for managing assessment criteria
  const sortedOriginalFields = sortAssessmentFields(
    assessment?.custom_field_definitions ?? [],
  );
  const [assessmentFields, setAssessmentFields] =
    useState<(CustomFieldDefinition | CustomFieldDefinitionInsertClient)[]>(
      sortedOriginalFields,
    );
  const [assessmentFieldErrors, setAssessmentFieldErrors] = useState<string[]>(
    [],
  );

  const { renderConfirmationModal, onConfirmThenClose } = useConfirmationModal({
    needsConfirmation: hasMadeChanges,
    onClose,
  });

  const jobDescriptionFeedback =
    useProjectJobDescriptionFeedbackByIdQuery(projectId);
  const jobAssessmentFeedback = useProjectJobAssessmentFeedbackByIdQuery(
    assessment ? projectId : skipToken,
  );

  // Reset state when slideover is opened/closed
  useEffect(() => {
    onQuestionsChange(questionsActions.initialized(props.basicQuestions));
    setAssessmentFields(sortedOriginalFields);
    setAssessmentFieldErrors([]);
    setHasMadeChanges(false);
    setJobDescription(props.jobDescription ?? "");
    setChangedSections([]);
  }, [isOpen]);

  const validateAssessmentFields = (
    fieldsToValidate: (
      | CustomFieldDefinition
      | CustomFieldDefinitionInsertClient
    )[],
  ) => {
    const newErrors = fieldsToValidate.map((field) =>
      getErrorMessageForAssessmentCriteriaValue(field.display_name),
    );
    setAssessmentFieldErrors(newErrors);
    return newErrors.every((error) => error === "");
  };

  const sectionsJobTypeId = isInterviewSectionsEnabled
    ? props.jobTypeId
    : undefined;

  const onSave = () => {
    const nonEmptyFields = assessmentFields.filter(
      (field) => field.display_name.trim() !== "",
    );

    if (validateAssessmentFields(nonEmptyFields)) {
      const shouldUpdateAssessment =
        assessment &&
        getHaveAssessmentFieldsChanged(sortedOriginalFields, nonEmptyFields);

      if (shouldUpdateAssessment) {
        dispatch(
          updateAssessment({
            assessmentId: assessment.id,
            changes: {
              custom_field_definitions: nonEmptyFields,
            },
          }),
        );
      }

      if (sectionsJobTypeId) {
        // TODO: Handle errors.
        for (const changedSection of changedSections) {
          switch (changedSection.changeType) {
            case "created":
              dispatch(
                api.endpoints.createInterviewSectionDefinition.initiate({
                  jobTypeId: sectionsJobTypeId,
                  settings: changedSection.settings,
                }),
              );
              break;

            case "updated":
              dispatch(
                api.endpoints.updateInterviewSectionDefinition.initiate({
                  jobTypeId: sectionsJobTypeId,
                  id: changedSection.id,
                  settings: changedSection.settings,
                }),
              );
              break;

            case "deleted":
              dispatch(
                api.endpoints.deleteInterviewSectionDefinition.initiate({
                  jobTypeId: sectionsJobTypeId,
                  id: changedSection.id,
                }),
              );
              break;
          }
        }
      }

      setHasMadeChanges(false);

      onSaveProject({
        questions: questions
          .filter((q) => q.question)
          .map((q) => ({
            question: q.question,
          })), // Filter out empty questions and remove the temporary IDs
        job_description: jobDescription,
      });
      onClose();
    }
  };

  return (
    <>
      {renderConfirmationModal()}
      <Slideover
        buttons={
          <>
            <Can
              I={PermissionActions.UPDATE}
              a={PermissionSubjects.JOB_OPENINGS}
              passThrough={true}
            >
              {(allowed) => (
                <Button
                  isDisabled={!allowed || !hasMadeChanges}
                  onClick={() => {
                    onSave();
                  }}
                  size={SizesEnum.MEDIUM}
                  variant={ButtonVariantsEnum.Primary}
                >
                  Save changes
                </Button>
              )}
            </Can>
            <Button
              onClick={onClose}
              size={SizesEnum.MEDIUM}
              variant={ButtonVariantsEnum.Secondary}
            >
              Discard changes
            </Button>
          </>
        }
        onClickClose={onConfirmThenClose}
        shouldShow={isOpen}
        title="Interview editor"
      >
        <div className="flex h-full bg-gray-50 flex-row">
          {assessment ? (
            <div className="w-[500px] border-r border-gray-200 flex flex-col">
              <div
                className={twJoin(
                  "px-3 py-2 flex flex-col space-between bg-gray-50",
                  // The new right-side panel does not have a border under the header.
                  !sectionsJobTypeId ? "border-b border-gray-200" : "",
                )}
              >
                <Label size={SizesEnum.MEDIUM}>Assessment criteria</Label>
                <div className="text-gray-600 text-xs">
                  Interviews will be scored based on the JD and specified
                  criteria.
                </div>
              </div>
              <div className="overflow-y-auto flex-1">
                <div className="px-3 py-2 flex flex-col space-between">
                  <JobDescriptionTextArea
                    labelAccessory={
                      <SuggestionsWidget
                        isLoading={jobDescriptionFeedback.isLoading}
                        isFetching={jobDescriptionFeedback.isFetching}
                        summary={jobDescriptionFeedback.data?.overall_feedback}
                        suggestions={jobDescriptionFeedback.data?.suggestions.map(
                          (suggestion) => suggestion.suggestion,
                        )}
                        onRefreshClick={jobDescriptionFeedback.refetch}
                      />
                    }
                    value={jobDescription}
                    onChange={(newValue) => {
                      setHasMadeChanges(true);
                      setJobDescription(newValue);
                    }}
                    description="A detailed description of the job responsibilities and qualifications."
                    height="200px"
                  />
                </div>
                <div className="px-3 py-2 flex flex-row items-center space-x-2 border-b border-gray-200">
                  <Label size={SizesEnum.SMALL}>Criteria</Label>
                  <SuggestionsWidget
                    isLoading={jobAssessmentFeedback.isLoading}
                    isFetching={jobAssessmentFeedback.isFetching}
                    suggestions={jobAssessmentFeedback.data?.suggestions.map(
                      (suggestion) => suggestion.suggestion,
                    )}
                    onRefreshClick={jobAssessmentFeedback.refetch}
                  />
                </div>
                <AssessmentEditor
                  fieldErrors={assessmentFieldErrors}
                  fields={assessmentFields}
                  interviewLanguage={language}
                  setFieldErrors={setAssessmentFieldErrors}
                  setFields={(fields) => {
                    setHasMadeChanges(true);
                    setAssessmentFields(fields);
                  }}
                />
              </div>
            </div>
          ) : null}
          {sectionsJobTypeId ? (
            <InterviewSectionDefinitionListPanel
              projectId={props.projectId}
              jobTypeId={sectionsJobTypeId}
              changedSections={changedSections}
              onSectionCreate={onSectionCreate}
              onSectionUpdate={onSectionUpdate}
              onSectionDelete={onSectionDelete}
              questions={questions}
              onQuestionsChange={onQuestionsChange}
            />
          ) : (
            <QuestionsPanel
              projectId={props.projectId}
              questions={questions}
              onQuestionsChange={onQuestionsChange}
            />
          )}
        </div>
      </Slideover>
    </>
  );
};
