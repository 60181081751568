import {
  createMongoAbility,
  ForcedSubject,
  type CreateAbility,
  type PureAbility,
} from "@casl/ability";
import {
  type PermissionAction,
  type PermissionSubject,
  type RoleAccess,
} from "../entities/teammatePermissions";

/**
 * Our business classes ("Company", "Project", "Interview", ...) are interfaces. They don't have a constructor or a class name CASL can rely on to determine their type.
 * Using "ForcedSubject" tells CASL that for those business entities, it should be looking for a specific "__caslSubjectType__" property to determine the type.
 * That property is set by using the CASL "subject" helper when trying to check for authorization(see https://casl.js.org/v6/en/guide/subject-type-detection#subject-helper)
 */
type TeammateAbilities = [
  PermissionAction,
  PermissionSubject | ForcedSubject<PermissionSubject>,
];

export type TeammateAuthorization = PureAbility<TeammateAbilities>;

/**
 * Build a CASL ability for the given "roleAccess".
 */
export function buildTeammateAuthorization(roleAccess: RoleAccess | null) {
  return (createMongoAbility as CreateAbility<TeammateAuthorization>)(
    roleAccess || [],
  );
}
