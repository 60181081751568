import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Button,
  ButtonVariantsEnum,
  IconButton,
  Input,
  SimpleSelect,
} from "@repo/ui";
import {
  allTeammateRoleTypes,
  isPredefinedTeammateRoleType,
  LoadingStatesEnum,
  PosthogEventTypesEnum,
  predefinedRoleNameByType,
  TeammateRoleTypes,
  type PredefinedTeammateRoleType,
  type TeammateRoleType,
} from "app-types";
import { isAxiosError } from "axios";
import posthog from "posthog-js";
import { FC, useRef, useState } from "react";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { selectIsUsingSSO } from "../../company/companySlice";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { selectTeammate } from "../../teammate/teammateSlice";

const MAX_INVITE_EMAILS = 100;

const defaultSelectedRole = {
  label: predefinedRoleNameByType[TeammateRoleTypes.EDITOR],
  value: TeammateRoleTypes.EDITOR,
};

interface InviteTeammatesProps {
  onInviteTeammates: () => void;
}

export const InviteTeammatesSection: FC<InviteTeammatesProps> = ({
  onInviteTeammates,
}) => {
  const dispatch = useAppDispatch();

  const currentTeammate = useAppSelector(selectTeammate);

  // TODO: Better filter for not-allowed role selection
  const roleOptions = allTeammateRoleTypes
    .filter(isPredefinedTeammateRoleType)
    .filter((roleOption) => {
      switch (roleOption) {
        case TeammateRoleTypes.EDITOR:
          // Only allow editors to invite other editors
          return (
            currentTeammate?.permissions.role.type === TeammateRoleTypes.EDITOR
          );
        default:
          return true;
      }
    })
    .map((role) => ({
      label: predefinedRoleNameByType[role],
      value: role,
    }));

  const [inviteEmails, setInviteEmails] = useState<
    { email: string; role: TeammateRoleType }[]
  >([
    { email: "", role: roleOptions[0].value },
    { email: "", role: roleOptions[0].value },
  ]);
  const [sendInvitationsLoadingState, setSendInvitationsLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADED);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const isUsingSSO = useAppSelector(selectIsUsingSSO);

  const onClickInvite = async () => {
    const emailsToInvite = inviteEmails.filter(
      (invite) => invite.email.trim() !== "",
    );

    if (emailsToInvite.length === 0) return;

    setSendInvitationsLoadingState(LoadingStatesEnum.LOADING);

    try {
      const axios = await getAxiosInstanceWithAuth();
      const invitesPayload = emailsToInvite.map((invite) => ({
        email: invite.email,
        permissions: {
          role: { type: invite.role },
        },
      }));

      const { data } = await axios.post(`/company-invitations/invite`, {
        invites: invitesPayload,
      });

      const { company_invitations, invalid_emails } = data.data;

      if (company_invitations.length > 0) {
        showNotification(dispatch, {
          id: `invites-sent-${new Date().getTime()}`,
          primaryMessage: `${company_invitations.length} invite${
            company_invitations.length > 1 ? "s were" : " was"
          } successfully sent`,
          type: NotificationTypeEnum.SUCCESS,
        });

        posthog.capture(PosthogEventTypesEnum.TeammatesInvite, {
          num_teammates_invited: company_invitations.length,
        });
      }

      if (invalid_emails.length > 0) {
        showNotification(dispatch, {
          id: `invites-invalid-emails-${new Date().getTime()}`,
          primaryMessage: `${invalid_emails.length} invalid email${
            invalid_emails.length > 1 ? "s were" : " was"
          } skipped`,
          type: NotificationTypeEnum.FAILURE,
        });
      }

      setInviteEmails([
        { email: "", role: roleOptions[0].value },
        { email: "", role: roleOptions[0].value },
      ]);

      // Refetch the invitations
      onInviteTeammates();

      setSendInvitationsLoadingState(LoadingStatesEnum.LOADED);
    } catch (err) {
      // Handle rate limiting
      if (isAxiosError(err) && err.response?.status === 429) {
        showNotification(dispatch, {
          id: `invite-rate-limited-${new Date().getTime()}`,
          primaryMessage: `Max of 1000 invites per day exceeded. Please email support if you need assistance.`,
          type: NotificationTypeEnum.FAILURE,
        });
        setSendInvitationsLoadingState(LoadingStatesEnum.LOADED);
        return;
      }

      if (
        isAxiosError(err) &&
        err.response?.status === 400 &&
        err.response?.data.code === "role_not_authorized"
      ) {
        showNotification(dispatch, {
          id: `invite-role-not-authorized-${new Date().getTime()}`,
          primaryMessage: err.response?.data.error,
          type: NotificationTypeEnum.FAILURE,
        });
        setSendInvitationsLoadingState(LoadingStatesEnum.LOADED);
        return;
      }

      setSendInvitationsLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  const handleEmailChange =
    (index: number) => (evt: React.ChangeEvent<HTMLInputElement>) => {
      const newEmails = [...inviteEmails];
      newEmails[index].email = evt.target.value;
      setInviteEmails(newEmails);
    };

  const handlePaste =
    (index: number) => (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      const pastedData = event.clipboardData.getData("Text");
      const emails = pastedData
        .split(/[\n,]+/)
        .map((email) => email.trim())
        .filter((email) => email !== "");

      // Calculate the number of elements to keep after the pasted data
      const remainingSpace = 100 - index;
      const numToKeep = remainingSpace - emails.length;

      // Check if the total number exceeds the limit
      if (index + emails.length > 100) {
        showNotification(dispatch, {
          id: `invite-limit-exceeded-${new Date().getTime()}`,
          primaryMessage: `You cannot invite more than 100 people at once.`,
          type: NotificationTypeEnum.FAILURE,
        });
        return;
      }

      setInviteEmails([
        ...inviteEmails.slice(0, index),
        ...emails.map((email) => ({ email, role: roleOptions[0].value })),
        ...inviteEmails.slice(index + 1, index + 1 + numToKeep),
      ]);
    };

  const addInviteField = (index?: number) => {
    if (inviteEmails.length > MAX_INVITE_EMAILS) {
      return;
    }

    const newEmails = [...inviteEmails];
    const insertAtIndex =
      (index !== undefined ? index : inviteEmails.length) + 1;

    newEmails.splice(insertAtIndex, 0, {
      email: "",
      role: TeammateRoleTypes.EDITOR,
    });
    setInviteEmails(newEmails);
    setTimeout(() => {
      inputRefs.current[insertAtIndex]?.focus();
    }, 0);
  };

  if (isUsingSSO) {
    return (
      <div className="text-sm pb-2 text-gray-600">
        Your organization is using SSO to manage access to Alpharun. Please add
        and remove teammates via your SSO provider.
      </div>
    );
  }

  return (
    <>
      <div className="text-sm pb-2 text-gray-600">
        Your plan includes unlimited teammates. Add emails individually, or
        paste comma or newline separated emails.
      </div>
      {inviteEmails.map((invite, index) => (
        <div
          className="mb-2 max-w-md relative group flex gap-2 items-start"
          key={index}
        >
          <div className="w-[65%] relative">
            <Input
              key={index}
              placeholder="johndoe@example.com"
              value={invite.email}
              onChange={handleEmailChange(index)}
              onPaste={handlePaste(index)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  addInviteField(index);
                }
              }}
              ref={(ref) => {
                inputRefs.current[index] = ref;
              }}
            />
            <div className="text-gray-500 absolute right-1 top-0 mt-1 cursor-pointer hidden group-hover:block">
              <IconButton
                variant={ButtonVariantsEnum.Secondary}
                icon={<XMarkIcon className="h-4 w-4" />}
                onClick={() => {
                  const newEmails = [...inviteEmails];
                  newEmails.splice(index, 1);
                  setInviteEmails(newEmails);
                }}
                isDisabled={inviteEmails.length === 1}
              />
            </div>
          </div>
          <div className="w-[25%]">
            <SimpleSelect
              label="Role"
              isLabelHidden
              value={invite.role}
              onChange={(value) => {
                const newEmails = [...inviteEmails];
                newEmails[index].role = value as PredefinedTeammateRoleType;
                setInviteEmails(newEmails);
              }}
              options={roleOptions}
            />
          </div>
        </div>
      ))}
      <Button
        variant={ButtonVariantsEnum.Secondary}
        onClick={() => addInviteField()}
        isDisabled={inviteEmails.length === MAX_INVITE_EMAILS}
      >
        Add email
      </Button>
      <div className="mt-4">
        <Button
          variant={ButtonVariantsEnum.Primary}
          onClick={onClickInvite}
          isLoading={sendInvitationsLoadingState === LoadingStatesEnum.LOADING}
          isDisabled={sendInvitationsLoadingState === LoadingStatesEnum.LOADING}
        >
          Invite teammates
        </Button>
      </div>
    </>
  );
};
