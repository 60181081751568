import {
  ArrowDownTrayIcon,
  DocumentIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

interface FileCardProps {
  name: string;
  onDelete?: () => void;
  onDownload?: () => void;
}

export const FileCard: React.FC<FileCardProps> = ({
  name,
  onDelete,
  onDownload,
}) => {
  return (
    <div className="w-72 flex rounded-md shadow-sm">
      <div className="flex flex-1 items-center justify-between truncate rounded-md border border-gray-200 bg-white select-none">
        <div className="px-2 py-2">
          <DocumentIcon aria-hidden="true" className="h-3 w-3 text-gray-400" />
        </div>

        <div className="flex-1 truncate py-2 text-sm font-medium text-gray-900">
          {name}
        </div>
        <div className="flex-shrink-0">
          {onDownload ? (
            <button
              className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={onDownload}
              type="button"
            >
              <span className="sr-only">Download file</span>
              <ArrowDownTrayIcon aria-hidden="true" className="h-4 w-4" />
            </button>
          ) : null}
          {onDelete ? (
            <button
              className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={onDelete}
              type="button"
            >
              <span className="sr-only">Delete file</span>
              <XMarkIcon aria-hidden="true" className="h-5 w-5" />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
