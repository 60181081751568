import { FC, ReactNode } from "react";
import { twJoin } from "tailwind-merge";

export interface DraggableItemProps {
  children: ReactNode;
  index: number;
  isDraggable?: boolean;
  isSelected?: boolean;
  dragOverIndex?: number | null;
  onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragOver?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDrop?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragLeave?: (e: React.DragEvent<HTMLDivElement>) => void;
  onClick?: () => void;
}

export const DraggableItem: FC<DraggableItemProps> = ({
  children,
  index,
  isDraggable = true,
  dragOverIndex,
  onDragStart,
  onDragOver,
  onDrop,
  onDragEnd,
  onDragLeave,
  onClick,
}) => {
  // Only vertical drag & drop is supported for now
  return (
    <div
      className={twJoin(
        "flex w-full",
        dragOverIndex === index + 1 ? "border-b-2 border-b-blue-500" : "",
        dragOverIndex === index ? "border-t-2 border-t-blue-500" : "",
      )}
      onClick={onClick}
      draggable={isDraggable}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragEnd={onDragEnd}
      onDragLeave={onDragLeave}
      data-item-index={index}
    >
      {children}
    </div>
  );
};
