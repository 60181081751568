import { Pill, PillColorsEnum, SizesEnum } from "@repo/ui";
import {
  checkIsInterviewSectionAssessmentCustomFieldName,
  SpecialInterviewCustomFieldNamesEnum,
} from "app-types";
import { FC } from "react";
import { AssessmentScore } from "../../insights/assessmentScore";

interface InterviewAssessmentRatingProps {
  rating: any;
  fieldName?: string;
}

export const InterviewAssessmentRating: FC<InterviewAssessmentRatingProps> = ({
  rating,
  fieldName,
}) => {
  // Handle data labeling assessment field - we expect a 1-5 number score
  if (
    fieldName ===
      SpecialInterviewCustomFieldNamesEnum.DataLabelingSectionAssessment ||
    checkIsInterviewSectionAssessmentCustomFieldName(fieldName)
  ) {
    // If the rating is a number or can be converted to a number, use AssessmentScore
    if (rating !== null && rating !== undefined) {
      const numericRating = Number(rating);
      if (!isNaN(numericRating)) {
        return <AssessmentScore score={numericRating} />;
      }
    }
  }

  // Handle boolean values
  if (typeof rating === "boolean") {
    return (
      <Pill
        label={rating ? "Yes" : "No"}
        size={SizesEnum.SMALL}
        color={rating ? PillColorsEnum.GREEN : PillColorsEnum.RED}
      />
    );
  }

  // Currently only used for CEFR language level.
  if (typeof rating === "string") {
    return (
      <Pill label={rating} size={SizesEnum.SMALL} color={PillColorsEnum.GRAY} />
    );
  }

  return (
    <Pill label="Unknown" size={SizesEnum.SMALL} color={PillColorsEnum.GRAY} />
  );
};
