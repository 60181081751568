import { type z, type ZodTypeDef } from "zod";
import { fromError, isValidationError } from "zod-validation-error";

// Parses/validates data against the schema.
// Returns the validated data or throws a ValidationError if validation is not successful.
export function validateData<
  Output = any,
  Def extends ZodTypeDef = ZodTypeDef,
  Input = Output,
>(data: unknown, schema: z.ZodSchema<Output, Def, Input>): Output {
  const parsedResult = schema.safeParse(data);
  if (parsedResult.success) {
    return parsedResult.data;
  }

  throw fromError(parsedResult.error);
}

export function dataRespectsSchema<
  Output = any,
  Def extends ZodTypeDef = ZodTypeDef,
  Input = Output,
>(data: unknown, schema: z.ZodSchema<Output, Def, Input>): data is Output {
  return schema.safeParse(data).success;
}

export function safeParseJSONWithSchema<
  Output = any,
  Def extends ZodTypeDef = ZodTypeDef,
  Input = Output,
>(jsonString: string, schema: z.ZodType<Output, Def, Input>): Output | null {
  try {
    const parsed = JSON.parse(jsonString) as unknown;

    // Validate parsed result against provided schema
    try {
      return validateData(parsed, schema);
    } catch (error) {
      console.error(
        "Error validating JSON after parsing",
        isValidationError(error) ? error.toString() : error,
      );

      return null;
    }
  } catch (error) {
    console.error("Error parsing JSON", error);
    return null;
  }
}

export function parseJSONWithSchema<
  Output = any,
  Def extends ZodTypeDef = ZodTypeDef,
  Input = Output,
>(jsonString: string, schema: z.ZodType<Output, Def, Input>): Output {
  const parsed = JSON.parse(jsonString) as unknown;

  // Validate parsed result against provided schema
  return validateData(parsed, schema);
}
