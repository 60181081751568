import { createContext } from "react";

export interface SimpleRadioGroupContextValue {
  name: string;
  value: string;
  onChange: (value: string) => void;
}

export const SimpleRadioGroupContext = createContext<
  SimpleRadioGroupContextValue | undefined
>(undefined);
