import { Searcher } from "fast-fuzzy";

/** Fields that are assigned/mapped to the columns in a CSV file. */
export const SourceField = {
  FirstName: "first-name",
  LastName: "last-name",
  FullName: "full-name",
  Email: "email",
  Phone: "phone",
  JobOpeningId: "job-opening-id",
  JobOpeningName: "job-opening-name",
  JobTitle: "job-title",
  JobLocation: "job-location",
} as const;

export const projectSourceFields = [
  SourceField.JobOpeningId,
  SourceField.JobOpeningName,
  SourceField.JobTitle,
  SourceField.JobLocation,
];

export const allSourceFields = [
  SourceField.FirstName,
  SourceField.LastName,
  SourceField.FullName,
  SourceField.Email,
  SourceField.Phone,
  ...projectSourceFields,
] as const;

export type SourceField = (typeof allSourceFields)[number];

/** Assignment of column indexes to fields. */
export type SourceMapping = Partial<Record<SourceField, number>>;

/** Fields will be auto-assigned in this order by doing a fuzzy search on the CSV headers. */
const sourceFieldSearchTerms = [
  {
    field: SourceField.FirstName,
    searchTerms: ["first name", "given name", "forename"],
  },
  {
    field: SourceField.LastName,
    searchTerms: ["last name", "family name", "surname"],
  },
  {
    field: SourceField.FullName,
    searchTerms: ["name", "full name"],
  },
  {
    field: SourceField.Email,
    searchTerms: ["email", "email address"],
  },
  {
    field: SourceField.Phone,
    searchTerms: ["phone", "phone number"],
  },
  {
    field: SourceField.JobOpeningId,
    searchTerms: ["id", "job opening id"],
  },
  {
    field: SourceField.JobOpeningName,
    searchTerms: ["opening", "job opening", "job opening name"],
  },
  {
    field: SourceField.JobTitle,
    searchTerms: ["job title", "role"],
  },
  {
    field: SourceField.JobLocation,
    searchTerms: ["location", "job location"],
  },
];

export function generateSourceMapping(
  header: readonly string[],
  ignoredColumnIndexes: Set<number> = new Set(),
): SourceMapping {
  const mapping: SourceMapping = {};
  const mappedColumnIndexes = new Set<number>();

  const searcher = new Searcher(
    header
      .map((label, index) => ({
        index,
        label,
      }))
      .filter(({ index }) => !ignoredColumnIndexes.has(index)),
    {
      keySelector: (column) => column.label,
      threshold: 0.8,
      // No substring matching.
      useSellers: false,
    },
  );

  for (const { field, searchTerms } of sourceFieldSearchTerms) {
    // Skip fields that have already been mapped.
    if (mapping[field] !== undefined) {
      continue;
    }

    for (const searchTerm of searchTerms) {
      const match = searcher
        .search(searchTerm, {
          returnMatchData: true,
        })
        // Remove columns that have already been mapped.
        .filter((match) => !mappedColumnIndexes.has(match.item.index))
        .at(0);

      if (match) {
        mapping[field] = match.item.index;
        mappedColumnIndexes.add(match.item.index);
        break;
      }
    }
  }

  return mapping;
}

export function getMappedSourceFields(mapping: SourceMapping): SourceField[] {
  return allSourceFields.filter((field) => mapping[field] !== undefined);
}

export function getSourceFieldForColumnIndex(
  mapping: SourceMapping,
  columnIndex: number,
): SourceField | null {
  return (
    allSourceFields.find((field) => mapping[field] === columnIndex) ?? null
  );
}

export function setSourceFieldForColumnIndex(
  mapping: SourceMapping,
  columnIndex: number,
  field: SourceField | null,
): SourceMapping {
  const nextMapping = { ...mapping };

  for (const sourceField of allSourceFields) {
    if (nextMapping[sourceField] === columnIndex) {
      delete nextMapping[sourceField];
    }
  }

  if (field !== null) {
    nextMapping[field] = columnIndex;
  }

  return nextMapping;
}
