import { SaveAndCancelButtons, Textarea } from "@repo/ui";
import {
  PermissionActions,
  PermissionSubjects,
  QuestionsImportSourcesEnum,
} from "app-types";
import { FC, ReactNode, useState } from "react";
import { Can } from "../../../helpers/teammateAuthorizationContext";
import { questionsImportTypeToPlaceholder } from "../surveyQuestions/importQuestionsModal";

export const MIN_JD_LENGTH = 20;
export const MAX_JD_LENGTH = 10000;

interface JobDescriptionSetting {
  jobDescription: string | null;
  onSave: (newJobDescription: string | null) => void;
}

export const JobDescriptionSetting: FC<JobDescriptionSetting> = (props) => {
  const { onSave, jobDescription } = props;
  const [newJobDescription, setNewJobDescription] = useState(
    jobDescription ?? "",
  );

  const onClickSave = (): void => {
    onSave(newJobDescription);
  };

  return (
    <div className="flex flex-col space-y-3">
      <JobDescriptionTextArea
        value={newJobDescription}
        onChange={setNewJobDescription}
        description="The job description for this role. Should include details about the employer, role responsibilites, location, benefits etc.."
      />
      {jobDescription !== newJobDescription ? (
        <SaveAndCancelButtons
          onSave={onClickSave}
          onCancel={() => {
            setNewJobDescription(jobDescription ?? "");
          }}
          isSaveDisabled={
            !newJobDescription ||
            newJobDescription.length < MIN_JD_LENGTH ||
            newJobDescription.length > MAX_JD_LENGTH
          }
        />
      ) : null}
    </div>
  );
};

interface JobDescriptionTextAreaProps {
  labelAccessory?: ReactNode;
  value: string;
  onChange: (newValue: string) => void;
  description: string;
  errorText?: string;
  height?: string;
}

export const JobDescriptionTextArea: FC<JobDescriptionTextAreaProps> = ({
  labelAccessory,
  value,
  onChange,
  errorText,
  description,
  height = "250px",
}) => {
  const [error, setError] = useState("");

  return (
    <Can
      I={PermissionActions.UPDATE}
      a={PermissionSubjects.JOB_OPENINGS}
      passThrough={true}
    >
      {(allowed) => (
        <Textarea
          label="Job description"
          labelAccessory={labelAccessory}
          value={value}
          onChange={(newValue) => {
            setError(""); // Clear error on change
            onChange(newValue);
          }}
          onBlur={(evt) => {
            const newValue = evt.target.value;
            if (newValue.length > 0 && newValue.length < MIN_JD_LENGTH) {
              setError(
                `Job description must be at least ${MIN_JD_LENGTH.toString()} characters long`,
              );
            } else if (newValue.length > MAX_JD_LENGTH) {
              setError(
                `Job description must be less than ${MAX_JD_LENGTH.toString()} characters long`,
              );
            } else {
              setError("");
            }
          }}
          maxLength={MAX_JD_LENGTH}
          placeholder={
            questionsImportTypeToPlaceholder[
              QuestionsImportSourcesEnum.JOB_DESCRIPTION
            ]
          }
          errorDescription={error || errorText}
          description={description}
          className="w-full"
          height={height}
          isDisabled={!allowed}
        />
      )}
    </Can>
  );
};
