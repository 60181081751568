import {
  ComplexAnswer,
  ComplexInterviewQuestion,
  getRatingsFromSurveyScale,
  InterviewQuestion,
  isComplexInterviewQuestion,
  isRatingSurveyResponseList,
  isSimpleSurveyResponse,
} from "app-types";
import { FC } from "react";
import {
  Label,
  RadioButtonsGroup,
  SizesEnum,
  SurveyQuestionTable,
} from "@repo/ui";

interface SurveyResponsesSectionProps {
  interviewQuestions: InterviewQuestion[];
  complexAnswers: ComplexAnswer[];
}

export const SurveyResponsesSection: FC<SurveyResponsesSectionProps> = ({
  interviewQuestions,
  complexAnswers,
}) => {
  if (complexAnswers.length === 0) return null;

  // Display interview questions that have answers
  const surveyAnswersToDisplay = interviewQuestions
    .map((q) => {
      return (
        isComplexInterviewQuestion(q) &&
        complexAnswers.find((r) => r.question_id === q.id)
      );
    })
    .filter((q) => q !== undefined) as ComplexAnswer[];

  const renderResponseData = (
    question: ComplexInterviewQuestion,
    answer: ComplexAnswer,
  ) => {
    switch (question.type) {
      case "rating": {
        if (!isRatingSurveyResponseList(answer.response)) return null;

        return (
          <div className="flex flex-col space-y-4">
            <SurveyQuestionTable
              columnNames={getRatingsFromSurveyScale(question.scale).map(
                (r) => r.name,
              )}
              rowNames={answer.response.map((r) => r.name)}
              isReadOnly={true}
              currentRatings={answer.response}
            />
          </div>
        );
      }
      case "multiple_choice": {
        if (!isSimpleSurveyResponse(answer.response)) return null;

        return (
          <div>
            <RadioButtonsGroup
              options={question.options}
              value={answer.response.value}
              size={SizesEnum.MEDIUM}
              isDisabled
            />
          </div>
        );
      }
    }
  };

  return (
    <>
      <Label className="mt-2 block" size={SizesEnum.SMALL}>
        Survey responses
      </Label>
      <div className="flex flex-col space-y-4 mt-2">
        {surveyAnswersToDisplay.map((answer, index) => {
          const question = interviewQuestions.find(
            (q) => isComplexInterviewQuestion(q) && q.id === answer.question_id,
          );
          if (!question || !isComplexInterviewQuestion(question)) return null;

          return (
            <div key={`survey-answer-${answer.id}`}>
              <div className="text-gray-700 mb-2">{question.question}</div>
              {renderResponseData(question, answer)}
            </div>
          );
        })}
      </div>
    </>
  );
};
