import { PlanFeatureName, getLowestPlanNameWithFeatureAccess } from "app-types";
import { FC } from "react";
import { Button, ButtonVariantsEnum, InfoBanner } from "@repo/ui";

interface UpsellBannerProps {
  planFeatureName: PlanFeatureName;
}

export const UpsellBanner: FC<UpsellBannerProps> = ({ planFeatureName }) => {
  const planToUpgradeTo = getLowestPlanNameWithFeatureAccess(planFeatureName);

  return (
    <InfoBanner
      title={`Upgrade to ${planToUpgradeTo} to access this feature`}
      variant="UPSELL"
      description={
        <>
          {
            "Want to upgrade or need help deciding what plan is best for your team?"
          }
          <div className="flex space-x-4 mt-4">
            <Button
              variant={ButtonVariantsEnum.Primary}
              onClick={() => {
                window.open("mailto:support@alpharun.com");
              }}
            >
              Contact us
            </Button>
            <Button
              variant={ButtonVariantsEnum.Secondary}
              onClick={() => {
                window.open("https://cal.frontapp.com/alpharun/paul/7fc09a3a");
              }}
            >
              Book a meeting
            </Button>
          </div>
        </>
      }
    />
  );
};
