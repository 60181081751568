import { differenceInDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonVariantsEnum,
  FullPageSkeleton,
  InfoBanner,
  Link,
  ModalVariantsEnum,
} from "@repo/ui";
import { fetchNonEmptyInterviewsForProject } from "../features/interviews/interviewsSlice";
import {
  fetchProjectById,
  selectProjectsDictionary,
} from "../features/projects/projectsSlice";
import { selectTeammate } from "../features/teammate/teammateSlice";
import { useAppDispatch, useAppSelector } from "../hooks/hook";
import {
  ProjectDetailsPage,
  ProjectDetailsTabsEnum,
} from "./projectDetailsPage";

const ProjectDetailsDataProvider: React.FC<{
  project_id: string;
  tab: ProjectDetailsTabsEnum;
}> = ({ project_id, tab }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const teammate = useAppSelector(selectTeammate);
  const project = useAppSelector(selectProjectsDictionary)[project_id];
  const projectStatus = useAppSelector((state) => state.projects.status);
  const projectError = useAppSelector((state) => state.projects.error);
  const interviewsStatus = useAppSelector((state) => state.interviews.status);
  const interviewsError = useAppSelector((state) => state.interviews.error);

  useEffect(() => {
    dispatch(fetchProjectById(project_id));
  }, [project_id, dispatch]);

  const [previousTab, setPreviousTab] = useState<
    ProjectDetailsTabsEnum | undefined
  >(undefined);
  useEffect(() => {
    setPreviousTab(tab);
  }, [tab]);

  // Prefetch interviews when opening project details, so that they're loaded
  // when user clicks Interviews tab. Fetch them again when navigating to the
  // interviews tab, to make sure they're fresh.
  const shouldFetchInterviews =
    previousTab === undefined ||
    (previousTab !== tab && tab === ProjectDetailsTabsEnum.Interviews);
  useEffect(() => {
    if (shouldFetchInterviews) {
      dispatch(
        fetchNonEmptyInterviewsForProject({
          projectId: project_id,
          page: 1,
        }),
      );
    }
  }, [project_id, shouldFetchInterviews, dispatch]);

  if (
    (projectStatus === "failed" && projectError) ||
    (interviewsStatus === "failed" && interviewsError)
  ) {
    const isRecentSignup =
      teammate &&
      teammate.created_at &&
      differenceInDays(new Date(), new Date(teammate.created_at)) < 7;

    return (
      <div className="p-4">
        <InfoBanner
          title={`Unable to load ${projectError ? "project" : "interview"}`}
          description={
            <>
              {isRecentSignup ? (
                <>
                  You may need an invite to join your team's existing Alpharun
                  organization. Ask your admin to send you an invite or reach
                  out to{" "}
                  <Link href="mailto:support@alpharun.com">
                    support@alpharun.com
                  </Link>
                  {" for help."}
                </>
              ) : (
                <>
                  Please try again or contact{" "}
                  <Link href="mailto:support@alpharun.com">
                    support@alpharun.com
                  </Link>{" "}
                  if the issue persists.
                </>
              )}
              <div className="flex space-x-4 mt-4">
                <Button
                  variant={ButtonVariantsEnum.Secondary}
                  onClick={() => {
                    navigate("/projects");
                  }}
                >
                  Back Home
                </Button>
              </div>
            </>
          }
          variant={ModalVariantsEnum.Warning}
        />
      </div>
    );
  }

  // If we're loading but already have a project, don't show loader to avoid UI flash
  if (projectStatus !== "succeeded" && !project) {
    return <FullPageSkeleton />;
  }

  if (!project) {
    return <div>Project not found</div>;
  }

  return <ProjectDetailsPage project={project} tab={tab} />;
};

interface ProjectDetailsPageContainerProps {
  tab: ProjectDetailsTabsEnum;
}
export const ProjectDetailsPageContainer: React.FC<
  ProjectDetailsPageContainerProps
> = ({ tab }) => {
  const { project_id } = useParams();

  if (!project_id) {
    return <div>Invalid project ID</div>;
  }

  return <ProjectDetailsDataProvider project_id={project_id} tab={tab} />;
};
