import { forwardRef } from "react";

/** Background that sits behind modal dialogs. */
export const ModalBackdrop = forwardRef<HTMLDivElement>((_props, ref) => (
  <div
    ref={ref}
    // The entering/exiting data attributes work when used with react-aria-components.
    className="absolute inset-0 bg-gray-500 bg-opacity-75 opacity-100 group-data-[entering]:opacity-0 group-data-[exiting]:opacity-0 transition-opacity"
  />
));
