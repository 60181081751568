import {
  InfoTooltip,
  Pill,
  PillColorsEnum,
  SizesEnum,
  SmallTimeStamp,
  TableSkeleton,
} from "@repo/ui";
import { buildProjectUrlPath, ProjectWithInterviewCount } from "app-types";
import { partition } from "lodash";
import * as React from "react";
import { FC, ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { selectIsRecruitingModeCompany } from "../company/companySlice";
import { TeammateAvatarPile } from "../teammate/teammateAvatarPile";
import { selectTeammate } from "../teammate/teammateSlice";
import {
  fetchTeammates,
  selectAllTeammates,
} from "../teammates/teammatesSlice";
import { fetchAllProjects, selectAllProjects } from "./projectsSlice";

export const ProjectsTable: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllProjects());
    dispatch(fetchTeammates());
  }, [dispatch]);

  const navigate = useNavigate();
  const isRecruitingModeCompany = useAppSelector(selectIsRecruitingModeCompany);
  const teammate = useAppSelector((state) => selectTeammate(state));
  const projects = useAppSelector((state) => selectAllProjects(state.projects));
  const { status: projectsLoadStatus } = useAppSelector(
    (state) => state.projects,
  );

  const teammates = useAppSelector((state) =>
    selectAllTeammates(state.teammates),
  );

  const renderTableRows = () => {
    // Show a skeleton only if we're loading and there are no projects previously fetched
    if (projectsLoadStatus !== "succeeded" && projects.length === 0) {
      return <TableSkeleton />;
    }

    if (!projects.length) {
      return (
        <tr>
          <td
            className="px-3 py-9 text-center text-sm font-semibold text-gray-700"
            colSpan={100}
          >
            No projects created yet. Create one to get started.
          </td>
        </tr>
      );
    }

    const sortedProjects = projects.sort(
      (a, b) =>
        new Date(b.updated_at!).getTime() - new Date(a.updated_at!).getTime(),
    );

    const [ownProjects, otherProjects] = partition(
      sortedProjects,
      (project) => teammate && project.owner_teammate_ids.includes(teammate.id),
    );

    const needsCategoryRows =
      ownProjects.length > 0 && otherProjects.length > 0;

    return (
      <>
        {needsCategoryRows
          ? renderTableRowCategory(
              isRecruitingModeCompany ? "My job openings" : "My Projects",
            )
          : null}
        {ownProjects.map(renderTableRowProject)}
        {needsCategoryRows
          ? renderTableRowCategory(
              isRecruitingModeCompany ? "Other job openings" : "Other Projects",
            )
          : null}
        {otherProjects.map(renderTableRowProject)}
      </>
    );
  };

  const renderTableRowProject = (project: ProjectWithInterviewCount) => (
    <tr
      className="hover:bg-blue-50 cursor-pointer"
      key={project.id}
      onClick={() => {
        navigate(buildProjectUrlPath(project.id));
      }}
    >
      <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 pl-6">
        <div className="flex items-center justify-between space-x-2">
          <div className="text-ellipsis overflow-hidden flex flex-row space-x-2 items-center">
            <div>
              {project.name}
              {project.interview_count ? (
                <span className="text-gray-500">
                  {` (${project.interview_count})`}
                </span>
              ) : null}
            </div>
            {!project.is_live ? (
              <Pill
                label="Paused"
                color={PillColorsEnum.GRAY}
                size={SizesEnum.SMALL}
              />
            ) : null}
          </div>
          <TeammateAvatarPile
            className="-my-4"
            teammates={project.owner_teammate_ids.map((teammateId) =>
              teammates.find((teammate) => teammate.id === teammateId),
            )}
          />
        </div>
      </td>
      {isRecruitingModeCompany ? (
        <td className="px-3 py-4 text-sm text-gray-500">
          {project.job_type ? (
            <Pill
              label={
                <span className="min-w-0 max-w-[150px] truncate">
                  <InfoTooltip
                    content={project.job_type.name}
                    id={`job-type-name-${project.id}`}
                    place="left"
                    delay={300}
                  >
                    {project.job_type.name}
                  </InfoTooltip>
                </span>
              }
              color={PillColorsEnum.GRAY}
              size={SizesEnum.SMALL}
            />
          ) : null}
        </td>
      ) : (
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {project.incentive_quantity
            ? `$${project.incentive_quantity}`
            : "None"}
        </td>
      )}
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <SmallTimeStamp
          timestamp={project.updated_at || project.created_at}
          className="text-sm text-gray-500"
        />
      </td>
    </tr>
  );

  const renderTableRowCategory = (category: ReactNode) => (
    <tr>
      <td
        className="px-6 pt-4 pb-2 text-xs font-semibold text-gray-500"
        colSpan={100}
      >
        {category}
      </td>
    </tr>
  );

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-visible sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50 px-3">
                  <tr>
                    <ProjectTableHeaderCell className="min-w-[300px] pl-6 pr-3">
                      Name
                    </ProjectTableHeaderCell>
                    {isRecruitingModeCompany ? (
                      <ProjectTableHeaderCell className="w-[180px] px-3">
                        Job type
                      </ProjectTableHeaderCell>
                    ) : (
                      <ProjectTableHeaderCell className="w-[100px] px-3">
                        Incentive
                      </ProjectTableHeaderCell>
                    )}
                    <ProjectTableHeaderCell className="w-[100px] pl-3 pr-6">
                      Updated
                    </ProjectTableHeaderCell>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {renderTableRows()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProjectTableHeaderCell: FC<{ className: string; children: ReactNode }> = (
  props,
) => (
  <th
    scope="col"
    className={twJoin(
      "py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider whitespace-nowrap",
      props.className,
    )}
  >
    {props.children}
  </th>
);
