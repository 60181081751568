import { FC } from "react";

export interface InterviewSectionDefinitionCardSkeletonProps {}

export const InterviewSectionCardSkeleton: FC<
  InterviewSectionDefinitionCardSkeletonProps
> = (props) => {
  return (
    <div className="px-3 py-2 space-y-2.5 animate-pulse">
      <div className="h-4 bg-gray-300 rounded-full max-w-56" />
      <div className="h-3 bg-gray-300 rounded-full max-w-96" />
    </div>
  );
};
