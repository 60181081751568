import { PencilIcon } from "@heroicons/react/24/outline";
import { Input, Label, SizesEnum } from "@repo/ui";
import { JobTypeDB, PermissionActions, PermissionSubjects } from "app-types";
import { FC, useRef, useState } from "react";
import { useUpdateJobTypeMutation } from "../../../../api/redux";
import { Can } from "../../../../helpers/teammateAuthorizationContext";
import { useAppDispatch } from "../../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../../notificationsOverlay/notificationsSlice";
import { fetchProjectById } from "../../projectsSlice";

interface JobTypeNameProps {
  jobType: JobTypeDB;
  projectId: string;
}

const MAX_JOB_TYPE_NAME_LENGTH = 150;

export const JobTypeName: FC<JobTypeNameProps> = ({ jobType, projectId }) => {
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState<string | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);
  const [updateJobType, { isLoading: isSaving }] = useUpdateJobTypeMutation();

  const onStopEditing = () => {
    setIsEditing(false);
    setEditedName(undefined);
  };

  const onSave = async () => {
    // Just stop editing if the name is invalid
    if (
      !editedName ||
      editedName.length < 3 ||
      editedName.length > MAX_JOB_TYPE_NAME_LENGTH
    ) {
      onStopEditing();
      return;
    }

    try {
      // Wait for the mutation to complete
      await updateJobType({
        id: jobType.id,
        name: editedName,
      }).unwrap();

      // Now refresh the project after successful update
      await dispatch(fetchProjectById(projectId)).unwrap();

      onStopEditing();
    } catch (err) {
      const apiError = err as {
        data?: {
          error?: string;
        };
      };
      showNotification(dispatch, {
        id: `job-type-name-error-${Date.now().toString()}`,
        primaryMessage:
          apiError.data?.error ??
          "An error occured while updating the job type name",
        type: NotificationTypeEnum.FAILURE,
      });
      onStopEditing();
    }
  };

  if (!isEditing) {
    return (
      <div className="flex group items-center space-x-2">
        <Label size={SizesEnum.LARGE}>{jobType.name}</Label>
        <Can I={PermissionActions.UPDATE} a={PermissionSubjects.JOB_OPENINGS}>
          <div
            className="rounded-md p-1 hover:bg-slate-200 cursor-pointer h-fit mb-[5px]"
            onClick={() => {
              setIsEditing(true);
            }}
          >
            <PencilIcon className="h-4 w-4 text-gray-500 opacity-0 group-hover:opacity-100" />
          </div>
        </Can>
      </div>
    );
  }

  return (
    <div>
      <Input
        autoFocus
        inputClassName="max-w-[400px]"
        ref={inputRef}
        maxLength={MAX_JOB_TYPE_NAME_LENGTH}
        onChange={(e) => {
          setEditedName(e.target.value);
        }}
        value={editedName ?? jobType.name}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            void onSave();
          }
        }}
        onBlur={() => {
          void onSave();
        }}
        placeholder="Store Manager - San Francisco"
        isDisabled={isSaving}
      />
    </div>
  );
};
