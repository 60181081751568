import { z } from "zod";

/*
 * Types
 */

export enum InterviewProcessingQueueMessageTypesEnum {
  VideoProcessing = "VIDEO_PROCESSING",
  InterviewInsights = "INTERVIEW_INSIGHTS",
  WebhookSending = "WEBHOOK_SENDING",
  InterviewRanking = "INTERVIEW_RANKING",
}

export type InterviewProcessingQueueMessage =
  | VideoProcessingQueueMessage
  | InterviewInsightsQueueMessage
  | InterviewRankingQueueMessage
  | WebhookSendingQueueMessage;

export interface VideoProcessingQueueMessage {
  type: InterviewProcessingQueueMessageTypesEnum.VideoProcessing;
  body: VideoProcessingMessageBody;
}

export interface InterviewInsightsQueueMessage {
  type: InterviewProcessingQueueMessageTypesEnum.InterviewInsights;
  body: InterviewInsightsMessageBody;
}

export interface WebhookSendingQueueMessage {
  type: InterviewProcessingQueueMessageTypesEnum.WebhookSending;
  body: WebhookSendingMessageBody;
}

export interface InterviewRankingQueueMessage {
  type: InterviewProcessingQueueMessageTypesEnum.InterviewRanking;
  body: InterviewRankingMessageBody;
}

export interface VideoProcessingMessageBody {
  interviewId: string;
  alpharunCallId: string;
  companyId: string;
  rawVideoKey: string;
  audioUrl: string;
  startTime: string; // E.g. "2024-12-10T00:23:55.849Z"
  endTime: string; // E.g. "2024-12-10T00:24:00.849Z"
}

export interface InterviewInsightsMessageBody {
  companyId: string;
  interviewId: string;
  shouldReprocess?: boolean; // If true, insights will be reprocessed even if they already exist on the interview.
  shouldSkipNotifications?: boolean; // If true, notifications will not be sent for the interview.
  shouldSkipAudioAnalysis?: boolean; // If true, audio analysis like CEFR will not be performed for the interview.
}

export interface InterviewRankingMessageBody {
  companyId: string;
  interviewId: string;
}

export enum WebhookEventTypeEnum {
  INTERVIEW_CALL_COMPLETED = "interview.call.completed",
  INTERVIEW_ADVANCED = "interview.advanced",
  INTERVIEW_REJECTED = "interview.rejected",
}
export interface WebhookSendingMessageBody {
  companyId: string;
  projectId: string;
  interviewId: string;
  eventType: WebhookEventTypeEnum;
}

/*
 * Schemas
 */

export const videoProcessingMessageBodySchema = z.object({
  interviewId: z.string(),
  alpharunCallId: z.string(),
  companyId: z.string(),
  rawVideoKey: z.string(),
  audioUrl: z.string(),
  startTime: z.string(), // ISO date string
  endTime: z.string(), // ISO date string
});

export const interviewInsightsMessageBodySchema = z.object({
  companyId: z.string(),
  interviewId: z.string(),
  shouldReprocess: z.boolean().optional(),
  shouldSkipNotifications: z.boolean().optional(),
  shouldSkipAudioAnalysis: z.boolean().optional(),
});

export const interviewRankingMessageBodySchema = z.object({
  companyId: z.string(),
  interviewId: z.string(),
});

export const webhookSendingMessageBodySchema = z.object({
  companyId: z.string(),
  projectId: z.string(),
  interviewId: z.string(),
  eventType: z.nativeEnum(WebhookEventTypeEnum),
});

export const interviewProcessingQueueMessageSchema: z.ZodType<InterviewProcessingQueueMessage> =
  z.discriminatedUnion("type", [
    z.object({
      type: z.literal(InterviewProcessingQueueMessageTypesEnum.VideoProcessing),
      body: videoProcessingMessageBodySchema,
    }),
    z.object({
      type: z.literal(
        InterviewProcessingQueueMessageTypesEnum.InterviewInsights,
      ),
      body: interviewInsightsMessageBodySchema,
    }),
    z.object({
      type: z.literal(
        InterviewProcessingQueueMessageTypesEnum.InterviewRanking,
      ),
      body: interviewRankingMessageBodySchema,
    }),
    z.object({
      type: z.literal(InterviewProcessingQueueMessageTypesEnum.WebhookSending),
      body: webhookSendingMessageBodySchema,
    }),
  ]);
