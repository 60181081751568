import { SaveAndCancelButtons, Textarea } from "@repo/ui";
import { PermissionActions, PermissionSubjects } from "app-types";
import { FC, useState } from "react";
import { Can } from "../../../../helpers/teammateAuthorizationContext";

const MIN_CONTEXT_LENGTH = 15;
const MAX_CONTEXT_LENGTH = 3000;

interface JobTypeContextProps {
  context: string | null;
  onSave: (newContext: string | null) => void;
  hasJobType: boolean;
}

export const JobTypeContext: FC<JobTypeContextProps> = (props) => {
  const { onSave, hasJobType } = props;

  const context = props.context ?? "";
  const [newContext, setNewContext] = useState(context);
  const [contextError, setContextError] = useState("");

  const onClickSaveContext = (): void => {
    if (newContext && newContext.length < MIN_CONTEXT_LENGTH) {
      setContextError(
        `Must be at least ${MIN_CONTEXT_LENGTH.toString()} characters long`,
      );
      return;
    }

    if (newContext && newContext.length > MAX_CONTEXT_LENGTH) {
      setContextError(
        `Must be less than ${MAX_CONTEXT_LENGTH.toString()} characters long`,
      );
      return;
    }

    setContextError("");
    onSave(newContext);
  };

  return (
    <div className="flex flex-col space-y-3">
      <div>
        <Can
          I={PermissionActions.UPDATE}
          a={PermissionSubjects.JOB_OPENINGS}
          passThrough={true}
        >
          {(allowed) => (
            <Textarea
              label={
                hasJobType
                  ? "AI interviewer instructions"
                  : "Company and role details"
              }
              value={newContext}
              onChange={(newValue) => {
                setContextError("");
                setNewContext(newValue);
              }}
              maxLength={MAX_CONTEXT_LENGTH}
              placeholder={
                "At the end of the interview, inform the candidate that they'll hear from the team within 5 days."
              }
              errorDescription={contextError}
              description={
                <>
                  {hasJobType
                    ? "(Optional) Provide custom instructions for the AI interviewer."
                    : "Provide context (location, pay range, benefits, hours etc.) about your company and role that the AI interviewer can use to answer candidate questions during the interview."}
                </>
              }
              className="w-[700px]"
              isDisabled={!allowed}
            />
          )}
        </Can>
      </div>
      {context !== newContext ? (
        <SaveAndCancelButtons
          onSave={onClickSaveContext}
          onCancel={() => {
            setNewContext(context || "");
            setContextError("");
          }}
        />
      ) : null}
    </div>
  );
};
