import {
  InlineNode,
  MultilineContent,
  ParagraphNode,
  PlaceholderNode,
  SingleLineContent,
  TextNode,
} from "./types";

/**
 * @file
 * Parsing and stringifying content for plain text user input.
 *
 * This module converts between plain text and a structured document format
 * that's compatible with ProseMirror-style editors. The format uses a tree of
 * nodes (paragraphs, text, placeholders) similar to ProseMirror's document
 * schema, allowing easy integration with rich text editing while supporting
 * a simpler plain text input mode.
 *
 * Text like "Hello [name]" is parsed into a document tree with text and
 * placeholder nodes that can be rendered in various ways.
 */

interface ParseOptions {
  parsesVariables: boolean;
}

function parseInlineContent(
  content: string,
  options: ParseOptions,
): InlineNode[] {
  if (!options.parsesVariables) {
    return [{ type: "text", text: content }];
  }

  const parts = content.split(/\[([a-z._-]+)\]/);
  const nodes: InlineNode[] = [];

  for (let i = 0; i < parts.length; i++) {
    if (i % 2 === 1) {
      // Placeholder node
      nodes.push({
        type: "placeholder",
        attrs: {
          name: parts[i],
        },
      });
    } else if (parts[i] !== "") {
      // Text node
      nodes.push({
        type: "text",
        text: parts[i],
      });
    }
  }

  return nodes;
}

function parseParagraphNode(
  paragraph: string,
  options: ParseOptions,
): ParagraphNode {
  return {
    type: "paragraph",
    content: parseInlineContent(paragraph, options),
  };
}

export function parseSingleLineContent(
  subject: string,
  options: ParseOptions,
): SingleLineContent {
  return {
    content: parseInlineContent(subject, options),
  };
}

export function parseMultilineContent(
  body: string,
  options: ParseOptions,
): MultilineContent {
  return {
    content: body
      .split(/[\r\n]{2,}/)
      .map((paragraph) => parseParagraphNode(paragraph, options)),
  };
}

interface StringifyOptions {
  stringifyVariable: (name: string) => string;
}

function stringifyTextNode(node: TextNode): string {
  return node.text;
}

function stringifyPlaceholderNode(
  node: PlaceholderNode,
  options: StringifyOptions,
): string {
  return options.stringifyVariable(node.attrs.name);
}

function stringifyInlineNode(
  node: InlineNode,
  options: StringifyOptions,
): string {
  switch (node.type) {
    case "text":
      return stringifyTextNode(node);
    case "placeholder":
      return stringifyPlaceholderNode(node, options);
    default:
      return "";
  }
}

function stringifyParagraphNode(
  node: ParagraphNode,
  options: StringifyOptions,
): string {
  return node.content
    .map((node) => stringifyInlineNode(node, options))
    .join("");
}

export function stringifySingleLineContent(
  subject: SingleLineContent,
  options: StringifyOptions,
): string {
  return subject.content
    .map((node) => stringifyInlineNode(node, options))
    .join("");
}

export function stringifyMultilineContent(
  body: MultilineContent,
  options: StringifyOptions,
): string {
  return body.content
    .map((node) => stringifyParagraphNode(node, options))
    .join("\n\n");
}

export function stringifyVariableAsPlaceholder(name: string): string {
  return `[${name}]`;
}

export function stringifyVariableAsEmpty(name: string): string {
  console.warn("Failed to stringify a missing variable:", name);
  return "";
}
