import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { type ReactNode } from "react";
import {
  Button,
  ListBox,
  ListBoxItem,
  Popover,
  Select,
  type Key,
} from "react-aria-components";
import { Label } from "../Label/label";
import { SizesEnum } from "../helpers/helpers";
import { select } from "./select";

/** String value used to represent the null/empty option. */
const nullKey = "_null";
const valueToKey = (value: Key | null) => (value === null ? nullKey : value);
const keyToValue = (key: Key) => (key === nullKey ? null : key);

export interface SimpleSelectProps<T extends Key | null> {
  label: string;
  isLabelHidden?: boolean;
  isDisabled?: boolean;
  placeholder?: ReactNode;
  options: readonly SimpleSelectOption<T>[];
  disabledValues?: readonly T[];
  value: T;
  onChange: (value: T) => void;
}

export interface SimpleSelectOption<T extends Key | null> {
  value: T;
  label: string;
}

export const SimpleSelect = <T extends Key | null>(
  props: SimpleSelectProps<T>,
) => {
  const selectedOption = props.options.find(
    (option) => option.value === props.value,
  );

  return (
    <Select
      className="flex-auto space-y-2"
      isDisabled={props.isDisabled}
      disabledKeys={props.disabledValues?.map(valueToKey)}
      selectedKey={valueToKey(props.value)}
      onSelectionChange={(key) => {
        const value = keyToValue(key);
        const option = props.options.find((option) => option.value === value);
        if (!option) {
          console.warn("Selected key not found in options");
          return;
        }

        props.onChange(option.value);
      }}
      aria-label={props.isLabelHidden ? props.label : undefined}
    >
      {!props.isLabelHidden ? (
        <Label size={SizesEnum.SMALL}>{props.label}</Label>
      ) : null}
      <Button
        className={select().button({
          className: "px-3 flex items-center justify-between gap-2",
        })}
      >
        {selectedOption && selectedOption.value !== null ? (
          <span className="truncate">{selectedOption.label}</span>
        ) : (
          <span className={select().placeholder({ className: "truncate" })}>
            {props.placeholder}
          </span>
        )}
        <ChevronUpDownIcon
          className={select().chevron({ className: "-mr-1" })}
        />
      </Button>
      <Popover
        className={select().popover({ className: "min-w-[--trigger-width]" })}
      >
        <ListBox items={props.options}>
          {(option) => (
            <ListBoxItem
              id={valueToKey(option.value)}
              textValue={option.label}
              className="group px-3 py-2 flex items-center justify-between gap-2 select-none text-gray-900 disabled:text-gray-400 disabled:cursor-not-allowed focus:bg-indigo-600 focus:text-white selected:font-semibold"
            >
              <span className="truncate">{option.label}</span>
              <CheckIcon className="size-5 shrink-0 text-indigo-600 group-focus:text-white invisible group-selected:visible" />
            </ListBoxItem>
          )}
        </ListBox>
      </Popover>
    </Select>
  );
};
