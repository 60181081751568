import { buildProjectUrlPath, LoadingStatesEnum, Project } from "app-types";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonVariantsEnum,
  InfoBanner,
  Input,
  Modal,
  ModalHeader,
} from "@repo/ui";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../../notificationsOverlay/notificationsSlice";
import { selectPhoneNumberAndExtensionForProject } from "../../../phoneNumbers/phoneNumbersSlice";
import { createRecruitingProject } from "../../projectsSlice";
import { JobOpeningDetailsInputs } from "./jobOpeningSettings";

interface DuplicateJobOpeningModalProps {
  isOpen: boolean;
  onClose: () => void;
  project: Project;
}

export const DuplicateJobOpeningModal: FC<DuplicateJobOpeningModalProps> = ({
  isOpen,
  onClose,
  project,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const phoneNumberInfo = useAppSelector(
    selectPhoneNumberAndExtensionForProject(project.id),
  );

  const [jobOpeningName, setJobOpeningName] = useState("");
  const [location, setLocation] = useState("");
  const [pay, setPay] = useState(project.pay?.description ?? "");
  const [hours, setHours] = useState(project.hours?.description ?? "");
  const [createProjectStatus, setCreateProjectStatus] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADED);

  const onClickCreateJobOpeningWithinJobType = async () => {
    if (!project.job_type) return;
    setCreateProjectStatus(LoadingStatesEnum.LOADING);

    try {
      const newProject = await dispatch(
        createRecruitingProject({
          name: jobOpeningName,
          phoneNumberId: phoneNumberInfo?.phoneNumber.id,
          jobTypeId: project.job_type.id,
          location: location ? { description: location } : undefined,
          pay: pay ? { description: pay } : undefined,
          hours: hours ? { description: hours } : undefined,
        }),
      ).unwrap();

      const newProjectId = newProject.id;

      showNotification(dispatch, {
        id: `project-created-${new Date().getTime().toString()}`,
        primaryMessage: `Job opening duplicated`,
        type: NotificationTypeEnum.SUCCESS,
      });

      setCreateProjectStatus(LoadingStatesEnum.LOADED);
      navigate(buildProjectUrlPath(newProjectId));
      onClose();
    } catch (err) {
      console.error("error creating project", err);
      setCreateProjectStatus(LoadingStatesEnum.ERROR);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onCancel={onClose}>
      <ModalHeader>Duplicate job opening</ModalHeader>
      <div className="mt-4 flex flex-col space-y-3">
        <InfoBanner
          title="Questions and criteria will be shared"
          description={
            <>
              The new job opening will use the{" "}
              <strong>{project.job_type?.name}</strong> job type and will share
              the same questions and assessment criteria.
            </>
          }
        />
        <JobOpeningDetailsInputs
          location={location}
          pay={pay}
          hours={hours}
          onLocationChange={setLocation}
          onPayChange={setPay}
          onHoursChange={setHours}
          onLocationBlur={() => {
            if (project.job_type && location && !jobOpeningName) {
              setJobOpeningName(`${project.job_type.name} - ${location}`);
            }
          }}
        />
        <div>
          <Input
            autoFocus
            label="Job opening name"
            onChange={(evt) => {
              setJobOpeningName(evt.target.value);
            }}
            placeholder="Store Manager - San Francisco"
            required
            value={jobOpeningName}
          />
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <div className="ml-3">
          <Button
            isDisabled={
              !jobOpeningName ||
              createProjectStatus === LoadingStatesEnum.LOADING
            }
            isLoading={createProjectStatus === LoadingStatesEnum.LOADING}
            onClick={() => void onClickCreateJobOpeningWithinJobType()}
            variant={ButtonVariantsEnum.Primary}
          >
            Create new job opening
          </Button>
        </div>
        <Button onClick={onClose} variant={ButtonVariantsEnum.Secondary}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
