import { KeywordTracker } from "app-types";
import { FC, useState } from "react";
import {
  Button,
  ButtonVariantsEnum,
  Label,
  SearchableSelect,
  SearchableSelectModesEnum,
  SearchableSelectOption,
  SizesEnum,
} from "@repo/ui";
import { useAppDispatch } from "../../hooks/hook";
import { updateKeywordTracker } from "../keywordTrackers/keywordTrackersSlice";
import {
  NotificationTypeEnum,
  showNotification,
} from "../notificationsOverlay/notificationsSlice";

const KEYWORD_LENGTH_LIMIT = 50;

interface KeywordTrackerEditorProps {
  keywordTracker: KeywordTracker;
}

export const KeywordTrackerEditor: FC<KeywordTrackerEditorProps> = ({
  keywordTracker,
}) => {
  const dispatch = useAppDispatch();
  const [hasMadeChanges, setHasMadeChanges] = useState(false);

  const [keywordPhrases, setKeywordPhrases] = useState<string[]>(
    keywordTracker.keywords.map((keyword) => keyword.phrase),
  );

  const onSaveChanges = () => {
    dispatch(
      updateKeywordTracker({
        id: keywordTracker.id,
        changes: {
          keywords: keywordPhrases.map((phrase) => {
            const existingKeyword = keywordTracker.keywords.find(
              (k) => k.phrase === phrase,
            );
            if (existingKeyword) return existingKeyword;

            return {
              phrase,
              transcript_fragment_ids: [],
              analyses: [],
            };
          }),
        },
      }),
    );
    showNotification(dispatch, {
      id: `keyword-tracker-updated-${new Date().getTime()}`,
      primaryMessage: `Keyword tracker updated`,
      type: NotificationTypeEnum.SUCCESS,
    });
    setHasMadeChanges(false);
  };

  return (
    <>
      <Label size={SizesEnum.SMALL}>{keywordTracker.name}</Label>
      <SearchableSelect
        description="Add the names of your competitors so you can track their mentions in interviews. Matching is case-insensitive."
        mode={SearchableSelectModesEnum.MULTI}
        options={[]}
        placeholder="Add the name of a competitor"
        onChange={(options: SearchableSelectOption[]) => {
          // Validate that none of the options are more than 100 characters long
          const hasInvalidOption = options.some(
            (option) => option.name.length > KEYWORD_LENGTH_LIMIT,
          );

          if (hasInvalidOption)
            return showNotification(dispatch, {
              id: `keyword-too-long-${new Date().getTime()}`,
              primaryMessage: `Keywords must be less than 100 characters`,
              type: NotificationTypeEnum.FAILURE,
            });

          setHasMadeChanges(true);
          setKeywordPhrases(options.map((o) => o.name));
        }}
        // TODO: Make this copy generic when we support multiple keyword trackers
        selectedOptions={keywordPhrases.map((phrase) => {
          return { id: phrase, name: phrase };
        })}
      />

      {hasMadeChanges ? (
        <div className="flex mt-3 space-x-2">
          <Button
            onClick={() => {
              setKeywordPhrases(keywordTracker.keywords.map((k) => k.phrase));
              setHasMadeChanges(false);
            }}
            variant={ButtonVariantsEnum.Secondary}
          >
            Cancel
          </Button>
          <Button onClick={onSaveChanges} variant={ButtonVariantsEnum.Primary}>
            Save changes
          </Button>
        </div>
      ) : null}
    </>
  );
};
