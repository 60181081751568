import { z } from "zod";
import { llmFeedbackPrioritySchema } from "./llmFeedback";

export const jobDescriptionFeedbackSchema = z.object({
  overall_feedback: z.string(),
  suggestions: z.array(
    z.object({
      suggestion: z.string(),
      priority: llmFeedbackPrioritySchema,
    }),
  ),
});

export type JobDescriptionFeedback = z.infer<
  typeof jobDescriptionFeedbackSchema
>;
