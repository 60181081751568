import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/outline";
import { FC, ReactNode } from "react";
import { SimpleModalVariantsEnum as ModalVariantsEnum } from "../Modal/modal";

type InfoBannerVariant =
  | ModalVariantsEnum.Standard
  | ModalVariantsEnum.Warning
  | "UPSELL";

interface InfoBannerProps {
  title: string;
  description?: string | ReactNode;
  icon?: React.ReactNode;
  variant?: InfoBannerVariant;
}

interface InfoBannerStyle {
  bgColor: string;
  borderColor: string;
  textColor: string;
  subTextColor: string;
  icon: JSX.Element;
}

export const InfoBanner: FC<InfoBannerProps> = ({
  title,
  description,
  icon,
  variant,
}) => {
  const getVariantStyles = (
    variantType: InfoBannerVariant | undefined,
  ): InfoBannerStyle => {
    switch (variantType) {
      case ModalVariantsEnum.Warning:
        return {
          bgColor: "bg-red-50",
          borderColor: "border-red-400",
          textColor: "text-red-800",
          subTextColor: "text-red-700",
          icon: (
            <ExclamationTriangleIcon
              aria-hidden="true"
              className="h-5 w-5 text-red-400"
            />
          ),
        };
      case "UPSELL":
        return {
          bgColor: "bg-violet-50",
          borderColor: "border-violet-400",
          textColor: "text-violet-800",
          subTextColor: "text-violet-700",
          icon: (
            <RocketLaunchIcon
              aria-hidden="true"
              className="h-5 w-5 text-violet-400"
            />
          ),
        };
      case ModalVariantsEnum.Standard:
      default:
        return {
          bgColor: "bg-blue-50",
          borderColor: "border-blue-400",
          textColor: "text-blue-800",
          subTextColor: "text-blue-700",
          icon: (
            <InformationCircleIcon
              aria-hidden="true"
              className="h-5 w-5 text-blue-400"
            />
          ),
        };
    }
  };

  const {
    bgColor,
    borderColor,
    textColor,
    subTextColor,
    icon: defaultIcon,
  } = getVariantStyles(variant);

  return (
    <div className={`rounded-md ${bgColor} ${borderColor} border p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">{icon || defaultIcon}</div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium ${textColor}`}>{title}</h3>
          {description ? (
            <div className={`mt-2 text-sm ${subTextColor}`}>{description}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
