import { formatDistanceToNow } from "date-fns";
import { FC } from "react";

interface SmallTimeStampProps {
  timestamp: string;
  className?: string;
}

export const SmallTimeStamp: FC<SmallTimeStampProps> = ({
  timestamp,
  className = "text-gray-600 text-xs",
}) => {
  const timeAgo = formatDistanceToNow(new Date(timestamp), {
    addSuffix: true,
  })
    .replace(/about /, "")
    .replace(
      /(\d+) (second|minute|hour|day|month|year)s? ago/,
      (_, num, unit) =>
        `${num}${unit === "month" ? "mo" : unit === "hour" ? "h" : unit[0]} ago`,
    );

  return (
    <span className={className}>
      {timeAgo === "less than a minute ago" ? "1m ago" : timeAgo}
    </span>
  );
};
