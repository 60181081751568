import { Menu } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import {
  Button,
  ButtonVariantsEnum,
  DropdownItem,
  DropdownMenu,
  SizesEnum,
} from "@repo/ui";
import {
  CANDIDATE_ALIGN_FIELD_NAME,
  CustomFieldDefinition,
  CustomFieldDefinitionInsertClient,
  CustomFieldResourceTypesEnum,
  CustomFieldTypesEnum,
  getErrorMessageForAssessmentCriteriaValue,
  getSpecialAssessmentField,
  InterviewLanguagesEnum,
  isCefrLanguageAssessmentLanguage,
  LANGUAGE_DISPLAY_NAMES,
  LanguageToCefrAssessmentFieldName,
  PermissionActions,
  PermissionSubjects,
  SpecialAssessmentFieldNamesEnum,
  specialCustomFieldDetails,
} from "app-types";
import React from "react";
import { Can } from "../../helpers/teammateAuthorizationContext";
import { AssessmentEditorRow } from "./assessmentEditorRow";

const MAX_FIELDS = 10;

interface AssessmentEditorProps {
  interviewLanguage: InterviewLanguagesEnum;
  fields: (CustomFieldDefinition | CustomFieldDefinitionInsertClient)[];
  setFields: (
    fields: (CustomFieldDefinition | CustomFieldDefinitionInsertClient)[],
  ) => void;
  fieldErrors: string[];
  setFieldErrors: React.Dispatch<React.SetStateAction<string[]>>;
}

export const AssessmentEditor: React.FC<AssessmentEditorProps> = ({
  interviewLanguage,
  fields,
  setFields,
  fieldErrors,
  setFieldErrors,
}) => {
  const onAddField = (specialFieldName?: SpecialAssessmentFieldNamesEnum) => {
    const specialFieldDetails = specialFieldName
      ? specialCustomFieldDetails[specialFieldName]
      : null;

    if (fields.length < MAX_FIELDS) {
      const newField: CustomFieldDefinitionInsertClient = {
        display_name: specialFieldDetails?.display_name || "",
        field_name: specialFieldName || "",
        field_type:
          specialFieldDetails?.field_type || CustomFieldTypesEnum.Boolean,
        provider: "alpharun",
        resource_type: CustomFieldResourceTypesEnum.INTERVIEW,
      };

      // Add special fields to the top of the list
      setFields(
        specialFieldDetails ? [newField, ...fields] : [...fields, newField],
      );
    }
  };

  const onRemoveField = (index: number) => {
    setFields(fields.filter((_, i) => i !== index));
    setFieldErrors(fieldErrors.filter((_, i) => i !== index));
  };

  const onRenameField = (index: number, newName: string) => {
    // When we rename a field we're effectively creating a new custom field definition
    setFields(
      fields.map((field, i) =>
        i === index
          ? {
              display_name: newName,
              field_name: newName,
              resource_type: CustomFieldResourceTypesEnum.INTERVIEW,
              provider: "alpharun",
              field_type: field.field_type,
            }
          : field,
      ),
    );
  };

  const validateFieldOnBlur = (index: number, value: string) => {
    const error = getErrorMessageForAssessmentCriteriaValue(value);
    setFieldErrors((prev) => {
      const newErrors = [...prev];
      newErrors[index] = error;
      return newErrors;
    });
  };

  const renderTableRows = () => {
    if (fields.length === 0) {
      return (
        <tr>
          <td
            className="px-3 py-9 text-center text-sm font-semibold text-gray-700"
            colSpan={3}
          >
            No assessment criteria added yet
          </td>
        </tr>
      );
    }

    return fields.map((field, index) => (
      <AssessmentEditorRow
        field={field}
        fieldError={fieldErrors[index]}
        key={`assessment-field-${index}`}
        onBlur={(value: string) => {
          validateFieldOnBlur(index, value);
        }}
        onRemoveField={() => {
          onRemoveField(index);
        }}
        onRenameField={(newName: string) => {
          onRenameField(index, newName);
        }}
      />
    ));
  };

  const languageFieldName = isCefrLanguageAssessmentLanguage(interviewLanguage)
    ? LanguageToCefrAssessmentFieldName[interviewLanguage]
    : undefined;
  const existingLanguageLevelField = languageFieldName
    ? getSpecialAssessmentField(fields, languageFieldName)
    : undefined;
  // Show the language level field if the interview language is a Cefr language (i.e. not for English/Spanish multilingual)
  const newLanguageOption = isCefrLanguageAssessmentLanguage(interviewLanguage)
    ? {
        label: `CEFR ${
          LANGUAGE_DISPLAY_NAMES[interviewLanguage] || "Language"
        } level`,
        onClick: () => {
          onAddField(languageFieldName);
        },
      }
    : null;

  return (
    <div>
      <div>
        <table className="min-w-full">
          <tbody className="bg-white">{renderTableRows()}</tbody>
        </table>
      </div>
      <div className="mt-3 ml-3 mb-3 flex">
        <Can
          I={PermissionActions.UPDATE}
          a={PermissionSubjects.JOB_OPENINGS}
          passThrough={true}
        >
          {(allowed) => (
            <Menu as="div" className="relative inline-block">
              <Menu.Button as={React.Fragment}>
                <Button
                  icon={<PlusIcon className="w-4 h-4" />}
                  isDisabled={fields.length >= MAX_FIELDS || !allowed}
                  size={SizesEnum.MEDIUM}
                  variant={ButtonVariantsEnum.Secondary}
                >
                  Add criteria
                </Button>
              </Menu.Button>

              <DropdownMenu
                align="left"
                position="top"
                items={[
                  {
                    label: "Yes/No criteria",
                    onClick: () => {
                      onAddField();
                    },
                  },
                  existingLanguageLevelField ? null : newLanguageOption,
                  {
                    label: "Candidate/job alignment rating",
                    onClick: () => {
                      const newField: CustomFieldDefinitionInsertClient = {
                        display_name: CANDIDATE_ALIGN_FIELD_NAME,
                        field_name: CANDIDATE_ALIGN_FIELD_NAME,
                        field_type: CustomFieldTypesEnum.Boolean,
                        provider: "alpharun",
                        resource_type: CustomFieldResourceTypesEnum.INTERVIEW,
                      };
                      setFields([newField, ...fields]);
                    },
                  },
                ].filter((item): item is DropdownItem => item !== null)}
              />
            </Menu>
          )}
        </Can>
      </div>
    </div>
  );
};
