import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { Integration, LoadingStatesEnum } from "app-types";
import { Selector } from "react-redux";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { RootState } from "../../app/store";

export const integrationsAdapter = createEntityAdapter<Integration>();

const initialState = integrationsAdapter.getInitialState<{
  status: LoadingStatesEnum;
  error: string | null;
}>({
  status: LoadingStatesEnum.LOADING,
  error: null,
});

export const updateIntegration = createAsyncThunk<
  Integration,
  { id: string; changes: Partial<Integration> },
  { rejectValue: string }
>("integrations/updateIntegration", async ({ id, changes }, thunkAPI) => {
  try {
    const axios = await getAxiosInstanceWithAuth();
    const response = await axios.patch(`/integrations/${id}`, changes);

    return thunkAPI.fulfillWithValue(response.data);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const deleteIntegration = createAsyncThunk(
  "integrations/deleteIntegration",
  async (integrationId: string, thunkAPI) => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      await axios.delete(`/integrations/${integrationId}`);

      return thunkAPI.fulfillWithValue(integrationId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    setAllIntegrations: integrationsAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setAllIntegrations, (state) => {
        state.status = LoadingStatesEnum.LOADED;
      })
      .addCase(updateIntegration.pending, (state, action) => {
        // Optimistic update
        integrationsAdapter.updateOne(state, {
          id: action.meta.arg.id,
          changes: action.meta.arg.changes,
        });

        state.status = LoadingStatesEnum.LOADING;
      })
      .addCase(updateIntegration.fulfilled, (state, action) => {
        state.status = LoadingStatesEnum.LOADED;

        integrationsAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      .addCase(updateIntegration.rejected, (state, action) => {
        state.status = LoadingStatesEnum.ERROR;
        state.error = action.error.message || null;
      })
      .addCase(deleteIntegration.pending, (state) => {
        state.status = LoadingStatesEnum.LOADING;
      })
      .addCase(deleteIntegration.fulfilled, (state, action) => {
        state.status = LoadingStatesEnum.LOADED;
        integrationsAdapter.removeOne(state, action.payload);
      })
      .addCase(deleteIntegration.rejected, (state, action) => {
        state.status = LoadingStatesEnum.ERROR;
        state.error = action.error.message || null;
      });
  },
});

export const { setAllIntegrations } = integrationsSlice.actions;
export default integrationsSlice.reducer;

export const selectAllIntegrations: Selector<RootState, Integration[]> =
  createSelector(
    [(state: RootState) => state.integrations],
    (integrationsState) =>
      integrationsAdapter.getSelectors().selectAll(integrationsState),
  );

export const selectSlackIntegration: Selector<RootState, Integration | null> =
  createSelector(
    [selectAllIntegrations],
    (integrations) => integrations.find((i) => i.provider === "slack") || null,
  );

export const selectFountainIntegration: Selector<
  RootState,
  Integration | null
> = createSelector(
  [selectAllIntegrations],
  (integrations) => integrations.find((i) => i.provider === "fountain") || null,
);
