import { Label, SaveAndCancelButtons, Select, SizesEnum } from "@repo/ui";
import { PermissionActions, PermissionSubjects } from "app-types";
import { useState } from "react";
import { Can } from "../../../helpers/teammateAuthorizationContext";

interface AutoAdvanceSettingProps {
  autoAdvanceAssessmentScoreThreshold: number | null;
  onSave: (autoAdvanceAssessmentScoreThreshold: number | null) => void;
}

const THRESHOLD_OPTIONS = [
  {
    name: "Disabled (recommended)",
    value: undefined,
  },
  {
    name: "Acceptable (3) and above",
    value: 3,
  },
  {
    name: "Good (4) and above",
    value: 4,
  },
  {
    name: "Excellent (5)",
    value: 5,
  },
];

export const AutoAdvanceSetting = ({
  autoAdvanceAssessmentScoreThreshold,
  onSave,
}: AutoAdvanceSettingProps) => {
  const [selectedThreshold, setSelectedThreshold] = useState(
    autoAdvanceAssessmentScoreThreshold,
  );

  const hasChanges =
    (selectedThreshold ?? null) !== autoAdvanceAssessmentScoreThreshold;

  return (
    <div className="space-y-2">
      <Label size={SizesEnum.SMALL}>Auto-advance candidates</Label>
      <div className="w-[220px]">
        <Can
          I={PermissionActions.UPDATE}
          a={PermissionSubjects.JOB_OPENINGS}
          passThrough={true}
        >
          {(allowed) => (
            <Select
              currentSelection={THRESHOLD_OPTIONS.find(
                (option) => option.value === (selectedThreshold ?? undefined),
              )}
              onChange={(option) => {
                setSelectedThreshold(option.value as number);
              }}
              options={THRESHOLD_OPTIONS}
              isDisabled={!allowed}
            />
          )}
        </Can>
      </div>
      <div className="text-sm text-slate-600">
        Candidates that meet or exceed this score will be automatically
        advanced. Other candidates will require recruiter review.
      </div>
      {hasChanges ? (
        <SaveAndCancelButtons
          onSave={() => {
            onSave(selectedThreshold ?? null);
          }}
          onCancel={() => {
            setSelectedThreshold(autoAdvanceAssessmentScoreThreshold);
          }}
        />
      ) : null}
    </div>
  );
};
