import { z } from "zod";
import { collectPlaceholderNames } from "../content/helpers";
import {
  TablesInsertWithFixedTypes,
  TablesUpdateWithFixedTypes,
  TablesWithFixedTypes,
} from "../db/supabase_with_fixed_types";

export const MessageTemplateType = {
  Advanced: "advanced",
  Rejected: "rejected",
} as const;

export const allMessageTemplateTypes = [
  MessageTemplateType.Advanced,
  MessageTemplateType.Rejected,
] as const;

export const messageTemplateTypeSchema = z.enum(allMessageTemplateTypes);

export type MessageTemplateType = z.infer<typeof messageTemplateTypeSchema>;

export function isMessageTemplateType(
  value: string,
): value is MessageTemplateType {
  return messageTemplateTypeSchema.safeParse(value).success;
}

export const MessageTemplateTypeToLabel = {
  [MessageTemplateType.Advanced]: "Advancement message",
  [MessageTemplateType.Rejected]: "Rejection message",
};

export const MessageTemplateVariablesEnum = {
  CONTACT_FIRST_NAME: "contact.first_name",
  CONTACT_LAST_NAME: "contact.last_name",
  PROJECT_NEXT_STEPS: "project.next_steps",
};

export const messageTemplateVariableToLabel = {
  [MessageTemplateVariablesEnum.CONTACT_FIRST_NAME]: "Contact first name",
  [MessageTemplateVariablesEnum.CONTACT_LAST_NAME]: "Contact last name",
  [MessageTemplateVariablesEnum.PROJECT_NEXT_STEPS]: "Job opening next steps",
};

export const messageTemplateTypeToVariables = {
  [MessageTemplateType.Advanced]: [
    MessageTemplateVariablesEnum.CONTACT_FIRST_NAME,
    MessageTemplateVariablesEnum.CONTACT_LAST_NAME,
    MessageTemplateVariablesEnum.PROJECT_NEXT_STEPS,
  ],
  [MessageTemplateType.Rejected]: [
    MessageTemplateVariablesEnum.CONTACT_FIRST_NAME,
    MessageTemplateVariablesEnum.CONTACT_LAST_NAME,
  ],
};

export type MessageTemplateDb = TablesWithFixedTypes<"message_template">;
export type MessageTemplateInsert =
  TablesInsertWithFixedTypes<"message_template">;
export type MessageTemplateUpdate =
  TablesUpdateWithFixedTypes<"message_template">;
export type MessageTemplate = MessageTemplateDb;

export function checkMessageTemplateIncludesNextStepsVariable(
  messageTemplate: MessageTemplate,
) {
  for (const variable of collectMessageTemplateVariables(messageTemplate)) {
    if (variable === MessageTemplateVariablesEnum.PROJECT_NEXT_STEPS) {
      return true;
    }
  }

  return false;
}

function* collectMessageTemplateVariables(
  messageTemplate: MessageTemplate,
): Iterable<string> {
  yield* collectPlaceholderNames(messageTemplate.subject);
  yield* collectPlaceholderNames(messageTemplate.body);
}
