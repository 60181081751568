import { useCallback, useInsertionEffect, useRef } from "react";

/** Polyfill for https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event */
export function useEffectEvent<F extends (...args: any[]) => any>(
  handler: F,
): (...args: Parameters<F>) => ReturnType<F> {
  const renderedHandlerRef = useRef<F | undefined>(undefined);

  useInsertionEffect(() => {
    renderedHandlerRef.current = handler;
  }, [handler]);

  return useCallback((...args) => {
    const renderedHandler = renderedHandlerRef.current;
    if (!renderedHandler) {
      throw new Error(
        "useEffectEvent handler may not be called during the render phase",
      );
    }

    return renderedHandler(...args);
  }, []);
}
