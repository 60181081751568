import { ArrowPathIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import {
  Button,
  ButtonVariantsEnum,
  IconButton,
  Label,
  Loader,
  LoaderStylesEnum,
  SizesEnum,
} from "@repo/ui";
import {
  getProjectAndJobTypeProperty,
  InferenceStatus,
  InterviewLanguagesEnum,
  JobTypePropertyEnum,
  PermissionActions,
  PermissionSubjects,
  ProjectUpdate,
  ProjectWithInterviewCount,
  sortAssessmentFields,
} from "app-types";
import { isAxiosError } from "axios";
import { noop } from "lodash";
import { FC, useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";
import { ReadOnlyBulletedList } from "../../../../components/readOnlyBulletedList";
import { Can } from "../../../../helpers/teammateAuthorizationContext";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import { selectAssessmentById } from "../../../assessments/assessmentsSlice";
import { JobTypeEditorSlideover } from "../../../jobTypes/editorSlideover";
import {
  refreshProjectById,
  retryInferenceForProjectById,
} from "../../projectsSlice";

const NUM_QUESTIONS_TO_DISPLAY = 4;
const NUM_CRITERIA_TO_DISPLAY = 4;

interface RecruitingSettingsQuestionsSectionProps {
  project: ProjectWithInterviewCount;
  language: InterviewLanguagesEnum;
  onSaveProject: (changes: ProjectUpdate) => void;
}

export const RecruitingSettingsQuestionsSection: FC<
  RecruitingSettingsQuestionsSectionProps
> = ({ project, language, onSaveProject }) => {
  const dispatch = useAppDispatch();

  const assessmentStatus = project.job_type?.settings.assessment_status;
  const assessment = useAppSelector(
    selectAssessmentById(
      getProjectAndJobTypeProperty(project, JobTypePropertyEnum.AssessmentId),
    ),
  );

  const questionsStatus = project.job_type?.settings.questions_status;
  const questions = getProjectAndJobTypeProperty(
    project,
    JobTypePropertyEnum.Questions,
  );

  /** Whether we have yet to auto-generate assessment criteria and/or questions. */
  const isInferenceIncomplete = !!assessmentStatus || !!questionsStatus;

  /** Whether auto-generating assessment criteria and/or questions is in progress (as opposed to failed). */
  const isInferenceInProgress =
    assessmentStatus === InferenceStatus.InProgress ||
    questionsStatus === InferenceStatus.InProgress;

  useRefreshProjectWhile(project.id, isInferenceInProgress);

  const [isInferenceRetrying, setIsInferenceRetrying] = useState(false);
  const onInferenceRetry = async () => {
    setIsInferenceRetrying(true);
    try {
      await dispatch(retryInferenceForProjectById(project.id));
    } finally {
      setIsInferenceRetrying(false);
    }
  };

  const [isQuestionsManagerSlideoverOpen, setIsQuestionsManagerSlideoverOpen] =
    useState(false);

  // Criteria items will only be displayed for recruiting projects with an assessment
  const criteriaItems = sortAssessmentFields(
    assessment?.custom_field_definitions || [],
  ).map((criterion) => criterion.display_name);

  return (
    <div className="flex flex-col justify-between mb-2">
      <div className="flex flex-col space-y-3">
        <div>
          <Label size={SizesEnum.SMALL} className="mb-2">
            Job description
          </Label>

          <div
            className={twJoin(
              "rounded-lg border max-w-full flex justify-between items-center px-3 py-2 text-sm relative bg-white",
              !isInferenceIncomplete ? "cursor-pointer hover:bg-blue-50" : null,
            )}
            aria-disabled={isInferenceIncomplete}
            onClick={() => {
              if (isInferenceIncomplete) {
                return;
              }

              setIsQuestionsManagerSlideoverOpen(true);
            }}
          >
            <div className="flex items-center">
              <span className="line-clamp-3">
                {getProjectAndJobTypeProperty(
                  project,
                  JobTypePropertyEnum.JobDescription,
                )}
              </span>
            </div>
          </div>
        </div>
        {assessment || assessmentStatus ? (
          <div className="">
            <Label size={SizesEnum.SMALL} className="mb-2">
              Assessment criteria
            </Label>
            {criteriaItems.length > 0 ? (
              <ReadOnlyBulletedList
                items={criteriaItems}
                maxItemsToDisplay={NUM_CRITERIA_TO_DISPLAY}
                isDisabled={isInferenceIncomplete}
                onListClick={() => {
                  setIsQuestionsManagerSlideoverOpen(true);
                }}
                itemDescriptor="criteria"
                icon={<CheckCircleIcon className="w-4 h-4 text-green-600" />}
              />
            ) : assessmentStatus === InferenceStatus.InProgress ||
              isInferenceRetrying ? (
              <div className="text-sm text-gray-900 flex items-center space-x-2">
                <span className="size-6 shrink-0">
                  <Loader style={LoaderStylesEnum.ORBIT} />
                </span>
                <span>
                  Your assessment is being generated. This may take a minute.
                </span>
              </div>
            ) : assessmentStatus === InferenceStatus.Failed ? (
              <div className="text-sm text-gray-900 flex items-center space-x-2">
                <IconButton
                  variant={ButtonVariantsEnum.Tertiary}
                  icon={<ArrowPathIcon className="size-4 shrink-0" />}
                  onClick={onInferenceRetry}
                />
                <span>An error occurred while generating your assessment.</span>
              </div>
            ) : (
              <div className="text-sm text-gray-500">
                <Can
                  I={PermissionActions.UPDATE}
                  a={PermissionSubjects.JOB_OPENINGS}
                  passThrough={true}
                >
                  {(allowed) => (
                    <Button
                      variant={ButtonVariantsEnum.Primary}
                      onClick={() => {
                        setIsQuestionsManagerSlideoverOpen(true);
                      }}
                      isDisabled={!allowed}
                    >
                      Add assessment criteria
                    </Button>
                  )}
                </Can>
              </div>
            )}
          </div>
        ) : null}
        <div>
          <Label size={SizesEnum.SMALL} className="mb-2">
            AI interview questions
          </Label>
          {questions.length > 0 ? (
            <ReadOnlyBulletedList
              items={questions.map((question) => question.question)}
              maxItemsToDisplay={NUM_QUESTIONS_TO_DISPLAY}
              isDisabled={isInferenceIncomplete}
              onListClick={() => {
                setIsQuestionsManagerSlideoverOpen(true);
              }}
              itemDescriptor="questions"
            />
          ) : questionsStatus === InferenceStatus.InProgress ||
            isInferenceRetrying ? (
            <div className="text-sm text-gray-900 flex items-center space-x-2">
              <span className="size-6 shrink-0">
                <Loader style={LoaderStylesEnum.ORBIT} />
              </span>
              <span>
                Your questions are being generated. This may take a minute.
              </span>
            </div>
          ) : questionsStatus === InferenceStatus.Failed ? (
            <div className="text-sm text-gray-900 flex items-center space-x-2">
              <IconButton
                variant={ButtonVariantsEnum.Tertiary}
                icon={<ArrowPathIcon className="size-4 shrink-0" />}
                onClick={onInferenceRetry}
              />
              <span>An error occurred while generating your questions.</span>
            </div>
          ) : (
            <div className="mt-2">
              <Can
                I={PermissionActions.UPDATE}
                a={PermissionSubjects.JOB_OPENINGS}
                passThrough={true}
              >
                {(allowed) => (
                  <Button
                    variant={ButtonVariantsEnum.Primary}
                    onClick={() => {
                      setIsQuestionsManagerSlideoverOpen(true);
                    }}
                    isDisabled={!allowed}
                  >
                    Add interview questions
                  </Button>
                )}
              </Can>
            </div>
          )}
        </div>
      </div>

      <JobTypeEditorSlideover
        basicQuestions={questions}
        projectId={project.id}
        jobTypeId={project.job_type?.id}
        language={language}
        assessment={assessment ?? null}
        onSaveProject={onSaveProject}
        isOpen={isQuestionsManagerSlideoverOpen}
        onClose={() => {
          setIsQuestionsManagerSlideoverOpen(false);
        }}
        jobDescription={getProjectAndJobTypeProperty(
          project,
          JobTypePropertyEnum.JobDescription,
        )}
      />
    </div>
  );
};

const REFRESH_INTERVAL = 3000;
const MAX_REFRESH_COUNT = 20;

function useRefreshProjectWhile(projectId: string, refreshCondition: boolean) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!refreshCondition) {
      return noop;
    }

    let refreshCount = 0;
    const abortController = new AbortController();

    const scheduleRefreshProject = () => {
      if (
        refreshCount++ < MAX_REFRESH_COUNT &&
        !abortController.signal.aborted
      ) {
        setTimeout(refreshProject, REFRESH_INTERVAL);
      }
    };

    const refreshProject = async () => {
      try {
        await dispatch(
          refreshProjectById(projectId, {
            signal: abortController.signal,
          }),
        );
      } catch (error) {
        if (isAxiosError(error)) {
          abortController.abort();
        }

        throw error;
      } finally {
        scheduleRefreshProject();
      }
    };

    scheduleRefreshProject();

    return () => {
      abortController.abort();
    };
  }, [projectId, refreshCondition]);
}
