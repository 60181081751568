import { useCallback, useState } from "react";
import { AlertDialog } from "../Modal/alertDialog";
import { CenteredModal } from "../Modal/centeredModal";

interface UseConfirmationModalProps {
  needsConfirmation?: boolean;
  onClose: () => void;
}

interface RenderConfirmationModalProps {
  title?: string;
  confirmText?: string;
  message?: string;
}

export const useConfirmationModal = ({
  needsConfirmation,
  onClose: onConfirm,
}: UseConfirmationModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderConfirmationModal = ({
    title,
    confirmText,
    message,
  }: RenderConfirmationModalProps = {}) => (
    <CenteredModal isOpen={isOpen} onOpenChange={setIsOpen} isDismissable>
      <AlertDialog
        title={title || "Unsaved changes"}
        confirmText={confirmText || "Discard changes"}
        onConfirm={() => {
          setIsOpen(false);
          onConfirm();
        }}
      >
        {message || "Your unsaved changes will be discarded."}
      </AlertDialog>
    </CenteredModal>
  );

  const onConfirmThenClose = useCallback(() => {
    if (needsConfirmation) {
      setIsOpen(true);
    } else {
      onConfirm();
    }
  }, [needsConfirmation, onConfirm]);

  return {
    renderConfirmationModal,
    onConfirmThenClose,
  };
};
