import { Menu } from "@headlessui/react";
import {
  Button,
  ButtonVariantsEnum,
  DropdownItem,
  DropdownMenu,
  SizesEnum,
} from "@repo/ui";
import {
  Company,
  ComplexQuestion,
  ComplexQuestionInsert,
  ComplexQuestionTypeToString,
  Project,
} from "app-types";
import { generateSurveyTemplateForProject } from "app-types/constants/projectTypeToDefaultQuestions";
import { FC } from "react";

const MAX_SURVEY_QUESTIONS = 4;

interface AddSurveyQuestionsDropdownProps {
  project: Project;
  company: Company;
  onAddQuestion: (question: ComplexQuestionInsert) => void;
  complexQuestions: (ComplexQuestion | ComplexQuestionInsert)[];
}

export const AddSurveyQuestionsDropdown: FC<
  AddSurveyQuestionsDropdownProps
> = ({ project, company, onAddQuestion, complexQuestions }) => {
  const dropdownItems: DropdownItem[] = [
    {
      label: `${ComplexQuestionTypeToString["rating"]} question`,
      onClick: () => {
        const newQuestion: ComplexQuestionInsert = {
          ...generateSurveyTemplateForProject(project.name, company.name),
          company_id: company.id,
        };

        onAddQuestion(newQuestion);
      },
    },
    {
      label: `${ComplexQuestionTypeToString["multiple_choice"]} question`,
      onClick: () => {
        const newQuestion: ComplexQuestionInsert = {
          type: "multiple_choice",
          company_id: company.id,
          options: [{ name: "" }, { name: "" }, { name: "" }],
          question: "",
        };

        onAddQuestion(newQuestion);
      },
    },
  ];

  return (
    <Menu
      as="div"
      className="relative ml-3"
      onClick={(evt) => evt.stopPropagation()}
    >
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              onClick={(evt) => {
                evt.stopPropagation();
              }}
            >
              <Button
                isHeadlessUIButton
                onClick={() => {}}
                variant={ButtonVariantsEnum.Secondary}
                size={SizesEnum.SMALL}
                isDisabled={complexQuestions.length >= MAX_SURVEY_QUESTIONS}
                tooltip={
                  complexQuestions.length >= MAX_SURVEY_QUESTIONS
                    ? {
                        content: `Up to ${MAX_SURVEY_QUESTIONS} survey questions are supported.`,
                        id: "survey-questions-limit-tooltip",
                      }
                    : undefined
                }
              >
                Add
              </Button>
            </Menu.Button>
          </div>
          <DropdownMenu items={dropdownItems} />
        </>
      )}
    </Menu>
  );
};
