import type { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { Session, createClient } from "@supabase/supabase-js";
import {
  CompanyUpdateDB,
  ContactInsert,
  DatabaseWithFixedTypes,
  InterviewInsert,
  InterviewUpdate,
  mapCompanyDBToCompany,
  mapInterviewDbToInterview,
  mapTeammateDBToTeammate,
  type ContactDB,
  type Project,
  type Teammate,
} from "app-types";
import type { AdminApiBootResponse } from "app-types/api/boot";
import posthog, { type JsonType } from "posthog-js";
import { RootState } from "../app/store";
import { setCompany } from "../features/company/companySlice";
import { setAllIntegrations } from "../features/integrations/integrationsSlice";
import { setAllKeywordTrackers } from "../features/keywordTrackers/keywordTrackersSlice";
import { setTeammateRole } from "../features/miscellaneous/authorizationSlice";
import { setAllPhoneNumbers } from "../features/phoneNumbers/phoneNumbersSlice";
import { setTeammate } from "../features/teammate/teammateSlice";
import { getIsPopupAuthCallback } from "../helpers/urlHelpers";
import { getAxiosInstanceWithAuth } from "./axiosConfig";

/**
 * Normal SSO flows (Google, Microsoft, Okta,...) are "pkce" type (they use a code as result
 * of the authentication that we then exchange for a session).
 * Fountain SSO flow is different: they target our Supabase ACS endpoint with a SAML response
 * and a RelayState (the URL they want the user to be redirected to). In return, Supabase, if
 * authentication is successful, redirects the user to the specified RelayState and attaches
 * the authentication result (access token and refresh token) as a fragment URL. And that is a "implicit" flow type.
 *
 * So if we detect we are in the Fountain case, we set up our Supabase auth client with a "implicit" flow type
 * otherwise we default to "pkce".
 */
function getSupabaseAuthFlowType() {
  // Get the URL hash (everything after the # symbol)
  const hash = window.location.hash.substring(1);
  if (hash !== "") {
    // Parse the hash into key-value pairs
    const params = new URLSearchParams(hash);

    // Extract the tokens
    const accessToken = params.get("access_token");
    const refreshToken = params.get("refresh_token");
    if (accessToken && refreshToken) {
      return "implicit";
    }
  }

  return "pkce";
}

export const supabase = createClient<
  DatabaseWithFixedTypes,
  "public",
  DatabaseWithFixedTypes["public"]
>(
  import.meta.env.VITE_SUPABASE_APP_URL,
  import.meta.env.VITE_SUPABASE_PUBLIC_KEY,
  {
    auth: {
      // "pkce" is generally recommended vs "implicit" flow because the access/refresh tokens are not exposed to the client.
      // Instead, the client gets a token it uses to exchange for a session. See https://supabase.com/docs/guides/auth/sessions/pkce-flow
      flowType: getSupabaseAuthFlowType(),
      // Disable auto-detection if we are doing popup auth flow. Otherwise the popup will be authenticated automatically, not the parent window.
      detectSessionInUrl: !getIsPopupAuthCallback(),
    },
  },
);

export const fetchInitialState = async (
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  session: Session,
) => {
  if (!session.user.email) {
    // TODO: Handle no email case? Shouldn't ever happen?
    throw new Error("No email found in session");
  }

  try {
    const axios = await getAxiosInstanceWithAuth();
    const { data } = await axios.get<AdminApiBootResponse>("/boot");

    const {
      teammate,
      company,
      keyword_trackers,
      integrations,
      posthog: postHogBootstrap,
      teammate_role,
    } = data;

    dispatch(setTeammate(teammate));
    if (company) {
      dispatch(setCompany(company));
    }
    dispatch(setAllKeywordTrackers(keyword_trackers));
    dispatch(setAllIntegrations(integrations));
    dispatch(setAllPhoneNumbers(data.phone_numbers));
    dispatch(setTeammateRole(teammate_role));

    Sentry.setUser({
      id: teammate.id,
      company_id: company?.id,
    });

    const hasSensitiveData = Boolean(company?.has_sensitive_data);

    /** Whether to do any capturing at all. */
    const canCapture =
      import.meta.env.PROD || import.meta.env.VITE_POSTHOG_ENABLED;
    /** Whether to do automatic capturing and session recording (beyond just page views and manually captured events). */
    const canAutoCapture = canCapture && !hasSensitiveData;

    // We need PostHog in development for feature flags even if we don't do any capturing.
    posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
      api_host: "https://us.i.posthog.com",
      opt_out_capturing_by_default: !canCapture,
      autocapture: canAutoCapture,
      disable_session_recording: !canAutoCapture,
      bootstrap: postHogBootstrap
        ? {
            distinctID: teammate.id,
            isIdentifiedID: true,
            featureFlags: postHogBootstrap.featureFlags as Record<
              string,
              string | boolean
            >,
            featureFlagPayloads: postHogBootstrap.featureFlagPayloads as Record<
              string,
              JsonType
            >,
          }
        : undefined,
    });
    posthog.identify(teammate.id, {
      ...(company ? { company_id: company.id } : {}),
    });
  } catch (error: any) {
    if (error) throw error;
  }
};

export const putTeammate = async (teammate: Teammate) => {
  const { data, error } = await supabase
    .from("teammate")
    .update({
      first_name: teammate.first_name,
      last_name: teammate.last_name,
    })
    .eq("id", teammate.id)
    .select()
    .single();

  if (error) throw error;

  return mapTeammateDBToTeammate(data);
};

export const updateCompany = async (
  companyId: string,
  updates: CompanyUpdateDB,
) => {
  const { data, error } = await supabase
    .from("company")
    .update(updates)
    .eq("id", companyId)
    .select()
    .single();

  if (error) throw error;

  return mapCompanyDBToCompany(data);
};

export const signoutUser = async () => {
  await supabase.auth.signOut();
  Sentry.setUser(null);
  posthog.reset();
  window.location.href = "/";
};

export const upsertContactsDb = async (contacts: ContactInsert[]) => {
  const { data, error } = await supabase
    .from("contact")
    .upsert(contacts, {
      onConflict: "company_id, email", // If there's a conflict, update the existing row instead of creating a new one
    })
    .select();

  if (error) throw error;

  if (data.length === 0) {
    throw new Error("Failed to upsert contacts.");
  }

  // `data` includes both newly created and updated contacts
  return data as ContactDB[];
};

export const maybeCreateInterviewsForContactsDb = async (
  contacts: ContactDB[],
  project: Project,
) => {
  // The company ID is the same for all the contacts
  const commonCompanyId = contacts[0].company_id;

  // Fetch any existing interviews for contacts in this project
  // that are currently pending or in progress.
  const { data: conflictingInterviews, error: fetchError } = await supabase
    .from("interview")
    .select("company_id, contact_id, project_id")
    .is("deleted_at", null)
    .eq("company_id", commonCompanyId)
    .eq("project_id", project.id)
    .in(
      "contact_id",
      contacts.map((c) => c.id),
    )
    .in("status", ["pending", "in_progress"]);

  if (fetchError) {
    throw fetchError;
  }

  // Filter out contacts that already have a pending or in progress interview.
  // We don't want to create another interview for these contacts.
  const filteredContacts = contacts.filter(
    (contact) =>
      !conflictingInterviews.some(
        (interview) => interview.contact_id === contact.id,
      ),
  );

  // Prepare objects for new interviews to be created
  const newInterviews: InterviewInsert[] = filteredContacts.map((contact) => ({
    company_id: commonCompanyId,
    contact_id: contact.id,
    project_id: project.id,
    status: "pending",
  }));

  const { data, error } = await supabase
    .from("interview")
    .insert(newInterviews)
    .select();

  if (error) {
    throw error;
  }

  // It's possible that data is empty if all contacts already had a pending or in progress interview.
  return data.map((interview) => mapInterviewDbToInterview(interview));
};

export const bulkUpdateInterviewsDb = async (
  interviewIds: string[],
  updates: InterviewUpdate,
) => {
  const { data, error } = await supabase
    .from("interview")
    .update(updates)
    .in("id", interviewIds)
    .select();

  if (error) {
    throw error;
  }

  if (!data) {
    throw new Error("Failed to bulk update interviews.");
  }

  return data.map((interview) => mapInterviewDbToInterview(interview));
};
