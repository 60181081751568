import { RatingSurveyResponse } from "app-types";
import React, { FC } from "react";
import { RadioButtonsRow } from "../Buttons/radioButtonsRow";

const focusStyle = "focus:outline focus:outline-1 focus:outline-indigo-600";

interface SurveyQuestionTableProps {
  columnNames: string[];
  rowNames: string[];
  rowRefs?: React.RefObject<HTMLTableCellElement>[];
  onChange?: (rowName: string, index: number) => void;
  isReadOnly?: boolean;
  currentRatings: RatingSurveyResponse[];
}

export const SurveyQuestionTable: FC<SurveyQuestionTableProps> = ({
  columnNames,
  rowNames,
  rowRefs,
  onChange,
  isReadOnly,
  currentRatings,
}) => {
  return (
    <table className="divide-y divide-gray-300 w-full">
      <thead>
        <tr>
          <th />
          {columnNames.map((name) => (
            <th
              className={`text-xs text-gray-900 pb-2 w-[12%] ${
                isReadOnly ? "font-medium" : "font-semibold"
              }`}
              key={`col-${name}`}
            >
              {name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {rowNames.map((rowName, rowIndex) => (
          <tr key={`row-${rowName}`}>
            <td
              className={`text-sm text-gray-900 ${
                isReadOnly ? "py-1" : "py-2 font-medium"
              }`}
            >
              {rowName}
            </td>
            <td
              className={isReadOnly ? "" : focusStyle}
              colSpan={columnNames.length}
              ref={rowRefs ? rowRefs[rowIndex] : undefined}
              tabIndex={isReadOnly ? undefined : rowIndex}
            >
              <RadioButtonsRow
                isReadOnly={isReadOnly}
                onChange={(index) => {
                  if (onChange) onChange(rowName, index);
                }}
                optionsCount={columnNames.length}
                rowIndex={rowIndex}
                selectionIndex={
                  currentRatings.find((rating) => rating.name === rowName)
                    ?.rating
                }
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
