import { Checkbox, Label, SizesEnum } from "@repo/ui";
import {
  getProjectAndJobTypeProperty,
  JobTypePropertyEnum,
  PermissionActions,
  PermissionSubjects,
  Project,
  ProjectUpdate,
  ResumeCollectionTypesEnum,
} from "app-types";
import { FC, useState } from "react";
import { useUpdateJobTypeMutation } from "../../../../api/redux";
import { Can } from "../../../../helpers/teammateAuthorizationContext";

interface RecruitingOtherSettingsProps {
  onSaveProject: (update: ProjectUpdate) => void;
  project: Project;
}

export const RecruitingOtherSettings: FC<RecruitingOtherSettingsProps> = ({
  onSaveProject,
  project,
}) => {
  const [updateJobType] = useUpdateJobTypeMutation();

  const [shouldRequestResume, setShouldRequestResume] = useState(
    getProjectAndJobTypeProperty(
      project,
      JobTypePropertyEnum.ResumeCollectionType,
    ),
  );
  const [shouldForceFullscreen, setShouldForceFullscreen] = useState(
    getProjectAndJobTypeProperty(
      project,
      JobTypePropertyEnum.ShouldForceFullscreen,
    ),
  );
  const [shouldRecordVideo, setShouldRecordVideo] = useState(
    getProjectAndJobTypeProperty(
      project,
      JobTypePropertyEnum.ShouldRecordVideo,
    ),
  );

  const onChangeShouldRequestResume = (isChecked: boolean) => {
    setShouldRequestResume(
      isChecked ? ResumeCollectionTypesEnum.AfterInterview : null,
    );
    onSaveProject({
      id: project.id,
      settings: {
        ...project.settings,
        resume_collection_type: isChecked
          ? ResumeCollectionTypesEnum.AfterInterview
          : null,
      },
    });

    if (project.job_type) {
      void updateJobType({
        id: project.job_type.id,
        settings: {
          ...project.job_type.settings,
          resume_collection_type: isChecked
            ? ResumeCollectionTypesEnum.AfterInterview
            : null,
        },
      });
    }
  };

  const onChangeShouldForceFullscreen = (isChecked: boolean) => {
    setShouldForceFullscreen(isChecked);
    onSaveProject({
      id: project.id,
      settings: {
        ...project.settings,
        should_force_fullscreen: isChecked,
      },
    });

    if (project.job_type) {
      void updateJobType({
        id: project.job_type.id,
        settings: {
          ...project.job_type.settings,
          should_force_fullscreen: isChecked,
        },
      });
    }
  };

  const onChangeShouldRecordVideo = (isChecked: boolean) => {
    setShouldRecordVideo(isChecked);
    onSaveProject({
      id: project.id,
      settings: {
        ...project.settings,
        should_record_video: isChecked,
      },
    });

    if (project.job_type) {
      void updateJobType({
        id: project.job_type.id,
        settings: {
          ...project.job_type.settings,
          should_record_video: isChecked,
        },
      });
    }
  };

  return (
    <Can
      I={PermissionActions.UPDATE}
      a={PermissionSubjects.JOB_OPENINGS}
      passThrough={true}
    >
      {(allowed) => (
        <>
          <div className="space-y-3">
            <div>
              <Label size={SizesEnum.SMALL} className="mb-2">
                Request resume
              </Label>
              <Checkbox
                isChecked={
                  shouldRequestResume ===
                  ResumeCollectionTypesEnum.AfterInterview
                }
                onChange={onChangeShouldRequestResume}
                label="Ask the candidate to upload their resume after they complete their interview."
                isDisabled={!allowed}
              />
            </div>

            <div>
              <Label size={SizesEnum.SMALL} className="mb-2">
                Fullscreen mode
              </Label>
              <Checkbox
                isChecked={shouldForceFullscreen}
                onChange={onChangeShouldForceFullscreen}
                label="Force the interview to be conducted in a full-screen browser window to help prevent possible cheating."
                isDisabled={!allowed}
              />
              <div className="mt-2 text-sm text-gray-600">
                Candidates that exit fullscreen mode will be flagged.
              </div>
            </div>

            <div>
              <Label size={SizesEnum.SMALL} className="mb-2">
                Record video
              </Label>
              <Checkbox
                isChecked={shouldRecordVideo}
                onChange={onChangeShouldRecordVideo}
                label="Record video during the interview."
                isDisabled={!allowed}
              />
              <div className="mt-2 text-sm text-gray-600">
                Candidates will be required to provide webcam access and conduct
                their interview on a desktop computer.
              </div>
            </div>
          </div>
        </>
      )}
    </Can>
  );
};
