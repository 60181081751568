import { FC } from "react";
import { Pill, PillColorsEnum, SizesEnum } from "@repo/ui";

enum SentimentEnum {
  VERY_SATISFIED = "Very Satisfied",
  SATISFIED = "Satisfied",
  NEUTRAL = "Neutral",
  DISSATISFIED = "Dissatisfied",
  VERY_DISSATISFIED = "Very Dissatisfied",
}

// 1-5 satisfaction scale
const scoreToDetails: Record<number, { color: PillColorsEnum; text: string }> =
  {
    1: {
      color: PillColorsEnum.RED,
      text: SentimentEnum.VERY_DISSATISFIED,
    },
    2: {
      color: PillColorsEnum.YELLOW,
      text: SentimentEnum.DISSATISFIED,
    },
    3: {
      color: PillColorsEnum.GRAY,
      text: SentimentEnum.NEUTRAL,
    },
    4: {
      color: PillColorsEnum.LIME,
      text: SentimentEnum.SATISFIED,
    },
    5: {
      color: PillColorsEnum.GREEN,
      text: SentimentEnum.VERY_SATISFIED,
    },
  };

export const SentimentScore: FC<{ score: number }> = ({ score }) => {
  const scoreDetails = scoreToDetails[score];

  return (
    <Pill
      size={SizesEnum.SMALL}
      color={scoreDetails.color}
      label={`${score}/5: ${scoreDetails.text}`}
    />
  );
};

export function formatSentimentTickForChart(value: number) {
  switch (value) {
    case 1:
      return SentimentEnum.VERY_DISSATISFIED;
    case 2:
      return SentimentEnum.DISSATISFIED;
    case 3:
      return SentimentEnum.NEUTRAL;
    case 4:
      return SentimentEnum.SATISFIED;
    case 5:
      return SentimentEnum.VERY_SATISFIED;
    default:
      throw new Error("Invalid sentiment tick value");
  }
}
