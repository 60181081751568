import { chain, times } from "lodash";

/** Given contact data parsed from a CSV file, returns a header row and data rows. */
export function extractHeaderRow(
  rows: readonly string[][],
): [string[], string[][]] {
  // If the first row is likely a header row, return it.
  if (checkIncludesHeaderRow(rows)) {
    return [rows.at(0) ?? [], rows.slice(1)];
  }

  // Otherwise, generate a header row based on the longest data row.
  const numColumns =
    chain(rows)
      .map((row) => row.length)
      .max()
      .value() ?? 0;

  const header = times(numColumns).map((_, index) => `Column ${index + 1}`);
  return [header, rows.slice(0)];
}

/** Given contact data parsed from a CSV file, determines if the first row is probably a header row. */
export function checkIncludesHeaderRow(rows: readonly string[][]): boolean {
  const scores = rows.slice(0, 3).map((row) => {
    // Presence of name, email, phone labels indicates a header row.
    const headerScore = row.some((cell) => /name|email|phone/i.test(cell))
      ? 1
      : 0;

    // Presence of @ or sequences of digits (from phone numbers) indicates a data row.
    const dataScore = row.some((cell) => /@|\d{4}/.test(cell)) ? 1 : 0;

    // Give a positive score if a row is likely to be a header row.
    return headerScore - dataScore;
  });

  if (scores.length === 0) {
    return false;
  } else if (scores.length === 1) {
    return scores[0] > 0;
  } else {
    return (
      // The score of the first row must be greater than the subsequent rows.
      scores.slice(1).every((score) => scores[0] > score) &&
      // The first row must have at least as many columns as all the subsequent rows.
      rows.slice(1).every((row) => rows[0].length >= row.length)
    );
  }
}

export function collectEmptyColumnIndexes(
  header: string[],
  data: string[][],
): Iterable<number> | null | undefined {
  return header
    .map((_, index) => index)
    .filter((index) => data.every((row) => !row[index]));
}

/** Returns the first N unique values from a column to help the user recognize what's in there. */
export function peekColumnValues(
  data: readonly string[][],
  columnIndex: number,
  maxCount: number,
): string[] {
  const values: string[] = [];

  for (let i = 0; i < data.length && values.length < maxCount; i++) {
    const value = data[i][columnIndex];
    if (value && !values.includes(value)) {
      values.push(value);
    }
  }

  return values;
}
