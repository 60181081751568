import type { FC, ReactNode } from "react";

/** Foreground layout container for modal dialogs. */
export const ModalStage: FC<{ children: ReactNode }> = (props) => (
  <div className="absolute inset-0 z-10 overflow-y-auto">
    <div className="min-h-full flex flex-col items-center justify-center p-4 sm:p-0">
      {props.children}
    </div>
  </div>
);
