import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Cog6ToothIcon, HomeIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { PosthogEventTypesEnum } from "app-types";
import { differenceInCalendarDays } from "date-fns";
import posthog from "posthog-js";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { signoutUser } from "../../api/supabaseService";
import { useAppSelector } from "../../hooks/hook";
import { selectCompany } from "../company/companySlice";
import { TeammateAvatar } from "../teammate/teammateAvatar";
import { selectTeammate } from "../teammate/teammateSlice";

const NavigationHeader: React.FC = () => {
  const company = useAppSelector(selectCompany);
  const teammate = useAppSelector(selectTeammate);

  const { pathname } = useLocation();

  const navigationItems = [
    { name: "Home", href: "/", icon: HomeIcon, current: pathname === "/" },
  ];

  const onClickContactUs = () => {
    if (trialExpirationDate)
      posthog.capture(PosthogEventTypesEnum.TrialContactUs);

    window.open("mailto:support@alpharun.com", "_blank");
  };

  const trialExpirationDate =
    company?.trial_expires_at && new Date(company.trial_expires_at);

  const isTrialExpired = Boolean(
    trialExpirationDate && trialExpirationDate < new Date(),
  );

  const maybeRenderTrialBanner = () => {
    // If we don't have a trial expiration date or the company has an active plan, don't render the banner
    if (!trialExpirationDate || company.billing_current_plan) {
      return null;
    }

    const contactUsLink = (
      <span className="font-semibold cursor-pointer" onClick={onClickContactUs}>
        Contact us
      </span>
    );

    if (isTrialExpired) {
      return (
        <div className="bg-gray-700 w-full h-9 leading-9 text-white text-sm text-center justify-center items-center flex flex-row space-x-2">
          <ExclamationTriangleIcon className="h-6 w-6 text-red-600" />
          <span className="truncate">
            {"Your trial has expired, and no new interviews can be conducted. "}
            {contactUsLink}
            {" to upgrade."}
          </span>
        </div>
      );
    }

    // Calculate the number of days remaining
    const daysRemaining = differenceInCalendarDays(
      trialExpirationDate,
      new Date(),
    );

    const ctaText =
      daysRemaining < 6 ? (
        <>
          {contactUsLink}
          {" to upgrade and prevent blocked interviews as your trial expires."}
        </>
      ) : (
        <>
          {"Questions about Alpharun or ready to upgrade? "}
          {contactUsLink}
          {"."}
        </>
      );

    return (
      <div className="bg-gray-700 w-full h-9 leading-9 text-white text-sm text-center justify-center items-center">
        Trial ends {computeDaysText(daysRemaining)}. {ctaText}
      </div>
    );
  };

  return (
    <div>
      {maybeRenderTrialBanner()}
      <Disclosure as="nav" className="bg-gray-800 z-40 h-[50px]">
        {({ open }) => (
          <div className="mx-auto px-4">
            <div className="flex h-[50px] items-center justify-between">
              <div className="flex items-center">
                <div className="block">
                  <div className="flex items-baseline space-x-4">
                    {navigationItems.map((item) => (
                      <Link
                        aria-current={item.current ? "page" : undefined}
                        className={twJoin(
                          item.current
                            ? "bg-gray-700 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "rounded-md px-3 py-2 text-sm font-medium",
                        )}
                        key={item.name}
                        to={item.href}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="block">
                <div className="ml-4 flex items-center md:ml-6">
                  <Link
                    aria-current={
                      pathname === "/settings/teammates" ? "page" : undefined
                    }
                    className={twJoin(
                      pathname === "/settings/teammates"
                        ? "bg-gray-700 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "rounded-md px-3 py-1.5 mr-2 text-sm font-medium",
                    )}
                    to={"/settings/teammates"}
                  >
                    Invite teammates
                  </Link>
                  <Link
                    className={twJoin(
                      pathname.startsWith("/settings")
                        ? "bg-gray-700 text-white"
                        : "bg-gray-800 text-gray-300 hover:bg-gray-700 hover:text-white",
                      "relative rounded-md p-1 focus:outline-none",
                    )}
                    to="settings/profile"
                    type="button"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Settings</span>
                    <Cog6ToothIcon aria-hidden="true" className="h-6 w-6" />
                  </Link>

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <TeammateAvatar teammate={teammate} />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={React.Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className={twJoin(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700 cursor-pointer",
                              )}
                              onClick={() => {
                                window.open(
                                  "https://help.alpharun.com/",
                                  "_blank",
                                );
                              }}
                            >
                              Knowledge Center
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className={twJoin(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700 cursor-pointer",
                              )}
                              onClick={onClickContactUs}
                            >
                              Contact Support
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              className={twJoin(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700 cursor-pointer",
                              )}
                              onClick={signoutUser}
                            >
                              Sign out
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        )}
      </Disclosure>
    </div>
  );
};

export default NavigationHeader;

function computeDaysText(daysRemaining: number) {
  switch (daysRemaining) {
    case 0:
      return <span className="font-semibold">today</span>;
    case 1:
      return <span className="font-semibold">tomorrow</span>;
    default:
      return (
        <>
          {"in "}
          <span className="font-semibold">{daysRemaining} days</span>
        </>
      );
  }
}
