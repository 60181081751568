import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import {
  CustomField,
  JobAssessmentFeedback,
  JobDescriptionFeedback,
  JobQuestionsFeedback,
  type JobTypeDB as JobType,
  type MessageTemplate,
} from "app-types";
import type { InterviewSectionDefinition } from "app-types/entities/interviewSection/definition";
import type { InterviewSectionSettings } from "app-types/entities/interviewSection/settings";
import type { InterviewSectionTemplateId } from "app-types/templates/interviewSection";
import { apiBaseUrl, getApiAuthorizationHeaderValue } from "./shared";

const baseQuery = fetchBaseQuery({
  baseUrl: apiBaseUrl,
  prepareHeaders: async (headers) => {
    const authorization = await getApiAuthorizationHeaderValue();
    headers.set("Authorization", authorization);
  },
});

export function isFetchBaseQueryError(
  error: unknown,
): error is FetchBaseQueryError {
  return typeof error === "object" && error !== null && "status" in error;
}

const TagType = {
  CustomField: "CustomField",
  MessageTemplate: "MessageTemplate",
  JobType: "JobType",
  InterviewSectionDefinition: "InterviewSectionDefinition",
} as const;

type TagTypes = (typeof TagType)[keyof typeof TagType];

const SpecialTagId = {
  All: "$all",
} as const;

// Helper functions for tag generation
const generateTags = <T extends { id: string }>(
  type: TagTypes,
  result?: T[],
) => [
  { type, id: SpecialTagId.All },
  ...(result ?? []).map(({ id }) => ({ type, id })),
];

const generateInvalidationTags = (type: TagTypes, id?: string) =>
  id
    ? [
        { type, id },
        { type, id: SpecialTagId.All },
      ]
    : [{ type, id: SpecialTagId.All }];

export const api = createApi({
  baseQuery,
  tagTypes: [
    TagType.CustomField,
    TagType.MessageTemplate,
    TagType.JobType,
    TagType.InterviewSectionDefinition,
  ],
  endpoints: (builder) => ({
    // Project custom field endpoints.
    // TODO: Dedupe.
    projectCustomField: builder.query<
      CustomField | null,
      { projectId: string; customFieldName: string }
    >({
      query: ({ projectId, customFieldName }) =>
        `/projects/${encodeURIComponent(projectId)}/custom_fields/${encodeURIComponent(customFieldName)}`,
      transformResponse: (response: {
        data: { custom_field: CustomField | null };
      }) => response.data.custom_field,
      providesTags: (result, error, { projectId, customFieldName }) => [
        {
          type: TagType.CustomField,
          id: `${projectId}/${customFieldName}`,
        },
      ],
    }),
    updateProjectCustomField: builder.mutation<
      CustomField | null,
      { projectId: string; customFieldName: string; data: CustomField["data"] }
    >({
      query: (values) => ({
        method: "PUT",
        url: `/projects/${encodeURIComponent(values.projectId)}/custom_fields/${encodeURIComponent(values.customFieldName)}`,
        body: values.data,
      }),
      transformResponse: (response: {
        data: { custom_field: CustomField | null };
      }) => response.data.custom_field,
      invalidatesTags: (result, error, { projectId, customFieldName }) => [
        {
          type: TagType.CustomField,
          id: `${projectId}/${customFieldName}`,
        },
      ],
    }),

    // Project feedback endpoints
    projectJobDescriptionFeedbackById: builder.query<
      JobDescriptionFeedback,
      string
    >({
      query: (id) =>
        `/projects/${encodeURIComponent(id)}/job-description-feedback`,
    }),
    projectJobAssessmentFeedbackById: builder.query<
      JobAssessmentFeedback,
      string
    >({
      query: (id) =>
        `/projects/${encodeURIComponent(id)}/job-assessment-feedback`,
    }),
    projectJobQuestionsFeedbackById: builder.query<
      JobQuestionsFeedback,
      string
    >({
      query: (id) =>
        `/projects/${encodeURIComponent(id)}/job-questions-feedback`,
    }),

    // Message Template endpoints
    allMessageTemplates: builder.query<MessageTemplate[], void>({
      query: () => "/message-templates",
      providesTags: (result) => generateTags(TagType.MessageTemplate, result),
    }),
    createMessageTemplate: builder.mutation<
      MessageTemplate,
      Pick<MessageTemplate, "type" | "subject" | "body">
    >({
      query: (values) => ({
        method: "POST",
        url: "/message-templates",
        body: values,
      }),
      invalidatesTags: () => generateInvalidationTags(TagType.MessageTemplate),
    }),
    updateMessageTemplate: builder.mutation<
      MessageTemplate,
      Pick<MessageTemplate, "id"> &
        Partial<Pick<MessageTemplate, "type" | "subject" | "body">>
    >({
      query: (values) => ({
        method: "PATCH",
        url: `/message-templates/${encodeURIComponent(values.id)}`,
        body: values,
      }),
      invalidatesTags: (result, error, { id }) =>
        generateInvalidationTags(TagType.MessageTemplate, id),
    }),
    deleteMessageTemplate: builder.mutation<void, string>({
      query: (id) => ({
        method: "DELETE",
        url: `/message-templates/${encodeURIComponent(id)}`,
      }),
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          api.util.updateQueryData(
            "allMessageTemplates",
            undefined,
            (draft) => {
              const index = draft.findIndex((template) => template.id === id);
              if (index !== -1) {
                draft.splice(index, 1);
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patch.undo();
        }
      },
      invalidatesTags: (result, error, id) =>
        generateInvalidationTags(TagType.MessageTemplate, id),
    }),

    // Job Type endpoints
    allJobTypes: builder.query<JobType[], void>({
      query: () => "/job-types",
      providesTags: (result) => generateTags(TagType.JobType, result),
    }),
    updateJobType: builder.mutation<
      JobType,
      Pick<JobType, "id"> &
        Partial<
          Pick<
            JobType,
            | "name"
            | "questions"
            | "job_description"
            | "prompt_context"
            | "settings"
          >
        >
    >({
      query: (values) => ({
        method: "PATCH",
        url: `/job-types/${encodeURIComponent(values.id)}`,
        body: values,
      }),
      invalidatesTags: (result, error, { id }) =>
        generateInvalidationTags(TagType.JobType, id),
    }),

    // Interview section definition endpoints.
    // TODO: Dedupe.
    interviewSectionDefinitionsByJobTypeId: builder.query<
      InterviewSectionDefinition[],
      { jobTypeId: string }
    >({
      query: ({ jobTypeId }) =>
        `/job-types/${encodeURIComponent(jobTypeId)}/sections`,
      transformResponse: (response: {
        data: { interview_section_definitions: InterviewSectionDefinition[] };
      }) => response.data.interview_section_definitions,
      providesTags: (result, error, { jobTypeId }) => [
        {
          type: TagType.InterviewSectionDefinition,
          id: `${jobTypeId}/${SpecialTagId.All}`,
        },
      ],
    }),
    createInterviewSectionDefinition: builder.mutation<
      InterviewSectionDefinition,
      { jobTypeId: string; settings: InterviewSectionSettings }
    >({
      query: ({ jobTypeId, ...values }) => ({
        method: "POST",
        url: `/job-types/${encodeURIComponent(jobTypeId)}/sections`,
        body: values,
      }),
      transformResponse: (response: {
        data: { interview_section_definition: InterviewSectionDefinition };
      }) => response.data.interview_section_definition,
      onQueryStarted: async ({ jobTypeId }, { dispatch, queryFulfilled }) => {
        try {
          const { data: interviewSectionDefinition } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              "interviewSectionDefinitionsByJobTypeId",
              { jobTypeId },
              (draft) => {
                draft.push(interviewSectionDefinition);
              },
            ),
          );
        } catch {
          // Ignore.
        }
      },
      invalidatesTags: (result, error, { jobTypeId }) => [
        {
          type: TagType.InterviewSectionDefinition,
          id: `${jobTypeId}/${SpecialTagId.All}`,
        },
      ],
    }),
    updateInterviewSectionDefinition: builder.mutation<
      InterviewSectionDefinition,
      { jobTypeId: string; id: string; settings: InterviewSectionSettings }
    >({
      query: ({ jobTypeId, id, ...values }) => ({
        method: "PATCH",
        url: `/job-types/${encodeURIComponent(jobTypeId)}/sections/${encodeURIComponent(
          id,
        )}`,
        body: values,
      }),
      transformResponse: (response: {
        data: { interview_section_definition: InterviewSectionDefinition };
      }) => response.data.interview_section_definition,
      onQueryStarted: async ({ jobTypeId }, { dispatch, queryFulfilled }) => {
        try {
          const { data: interviewSectionDefinition } = await queryFulfilled;
          dispatch(
            api.util.updateQueryData(
              "interviewSectionDefinitionsByJobTypeId",
              { jobTypeId },
              (draft) => {
                const index = draft.findIndex(
                  (section) => section.id === interviewSectionDefinition.id,
                );
                if (index !== -1) {
                  draft[index] = interviewSectionDefinition;
                }
              },
            ),
          );
        } catch {
          // Ignore.
        }
      },
      invalidatesTags: (result, error, { jobTypeId }) => [
        {
          type: TagType.InterviewSectionDefinition,
          id: `${jobTypeId}/${SpecialTagId.All}`,
        },
      ],
    }),
    deleteInterviewSectionDefinition: builder.mutation<
      void,
      { jobTypeId: string; id: string }
    >({
      query: ({ jobTypeId, id }) => ({
        method: "DELETE",
        url: `/job-types/${encodeURIComponent(jobTypeId)}/sections/${encodeURIComponent(
          id,
        )}`,
      }),
      onQueryStarted: async (
        { jobTypeId, id },
        { dispatch, queryFulfilled },
      ) => {
        const patch = dispatch(
          api.util.updateQueryData(
            "interviewSectionDefinitionsByJobTypeId",
            { jobTypeId },
            (draft) => {
              const index = draft.findIndex((section) => section.id === id);
              if (index !== -1) {
                draft.splice(index, 1);
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patch.undo();
        }
      },
      invalidatesTags: (result, error, { jobTypeId }) => [
        {
          type: TagType.InterviewSectionDefinition,
          id: `${jobTypeId}/${SpecialTagId.All}`,
        },
      ],
    }),
    generateTemplateInterviewSectionSettings: builder.mutation<
      InterviewSectionSettings,
      { jobTypeId: string; templateId: InterviewSectionTemplateId }
    >({
      query: ({ jobTypeId, templateId }) => ({
        method: "POST",
        url: `/job-types/${encodeURIComponent(jobTypeId)}/sections/template-settings`,
        body: {
          template_id: templateId,
        },
      }),
      transformResponse: (response: {
        data: { interview_section_settings: InterviewSectionSettings };
      }) => response.data.interview_section_settings,
    }),
    generateRolePlayInterviewSectionSettings: builder.mutation<
      InterviewSectionSettings,
      {
        jobTypeId: string;
        participantRoleDescription: string;
        agentRoleDescription: string;
        exampleScenarios: string;
      }
    >({
      query: ({ jobTypeId, ...body }) => ({
        method: "POST",
        url: `/job-types/${encodeURIComponent(jobTypeId)}/sections/role-play-settings`,
        body: {
          participant_role_description: body.participantRoleDescription,
          agent_role_description: body.agentRoleDescription,
          example_scenarios: body.exampleScenarios,
        },
      }),
      transformResponse: (response: {
        data: { interview_section_settings: InterviewSectionSettings };
      }) => response.data.interview_section_settings,
    }),
  }),
});

export const {
  // Project custom field hooks
  useLazyProjectCustomFieldQuery,
  useUpdateProjectCustomFieldMutation,
  // Project feedback hooks
  useProjectJobDescriptionFeedbackByIdQuery,
  useProjectJobAssessmentFeedbackByIdQuery,
  useProjectJobQuestionsFeedbackByIdQuery,
  // Message Template hooks
  useAllMessageTemplatesQuery,
  useCreateMessageTemplateMutation,
  useUpdateMessageTemplateMutation,
  useDeleteMessageTemplateMutation,
  // Job Type hooks
  useAllJobTypesQuery,
  useUpdateJobTypeMutation,
  // Interview section definition hooks
  useInterviewSectionDefinitionsByJobTypeIdQuery,
  useGenerateTemplateInterviewSectionSettingsMutation,
  useGenerateRolePlayInterviewSectionSettingsMutation,
} = api;
