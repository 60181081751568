import {
  Button,
  ButtonVariantsEnum,
  Modal,
  ModalHeader,
  Textarea,
} from "@repo/ui";
import { LoadingText } from "@repo/ui/Buttons/loadingText";
import { isValidUrl } from "app-types/helpers/utilities";
import React, { useState } from "react";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppDispatch } from "../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { PhoneNumberSelect } from "../../phoneNumbers/phoneNumberSelect";
import { createRecruitingProject } from "../projectsSlice";

interface ImportJobPostingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ImportJobsResponse {
  successfulUrls: {
    name: string;
    job_description: string;
  }[];
  failedUrls: {
    url: string;
    error: string;
  }[];
}

export const ImportJobPostingsModal: React.FC<ImportJobPostingsModalProps> = (
  props,
) => {
  const { isOpen } = props;

  const [urls, setUrls] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedPhoneNumberId, setSelectedPhoneNumberId] = useState<
    string | undefined
  >(undefined);
  const dispatch = useAppDispatch();

  const onClose = () => {
    // Reset state before calling the onClose function
    setUrls("");
    setError(null);
    setIsLoading(false);
    props.onClose();
  };

  const handleSubmit = async () => {
    setError(null);

    const urlList = urls
      .split(/[\n,]+/) // Split by newlines and commas
      .filter(Boolean)
      .map((url) => url.trim());
    if (urlList.length > 10) {
      setError("Please provide no more than 10 job posting links.");
      return;
    }

    const invalidUrls = urlList.filter((url) => !isValidUrl(url));
    if (invalidUrls.length > 0) {
      setError(
        `Invalid URL${invalidUrls.length > 1 ? "s" : ""}: ${invalidUrls.join(
          ", ",
        )}`,
      );
      return;
    }

    setIsLoading(true);
    const axiosInstance = await getAxiosInstanceWithAuth();
    const response = await axiosInstance.post<ImportJobsResponse>(
      "/projects/import-jobs",
      {
        urls: urlList,
      },
    );

    // If we have no successful projects, set an error and allow the user to correct their URLs
    if (response.data.successfulUrls.length === 0) {
      showNotification(dispatch, {
        id: `projects-error-${new Date().getTime().toString()}`,
        primaryMessage: `Sorry, an issue occurred while importing job postings. Please check the job posting URLs and try again.`,
        type: NotificationTypeEnum.FAILURE,
      });
      setIsLoading(false);
      return;
    }

    try {
      // Create a project for each successful scraping and track results
      const projectResults = await Promise.all(
        response.data.successfulUrls.map(async (projectData) => {
          try {
            await dispatch(
              createRecruitingProject({
                name: projectData.name,
                jobDescription: projectData.job_description,
                phoneNumberId: selectedPhoneNumberId,
              }),
            );
            return { success: true };
          } catch (error) {
            return { success: false };
          }
        }),
      );

      const numSuccessfulProjects = projectResults.filter(
        (result) => result.success,
      ).length;
      const totalAttempted = response.data.successfulUrls.length;

      if (numSuccessfulProjects === 0) {
        showNotification(dispatch, {
          id: `projects-error-${new Date().getTime().toString()}`,
          primaryMessage: "Failed to import job postings. Please try again.",
          type: NotificationTypeEnum.FAILURE,
        });
      } else if (numSuccessfulProjects === totalAttempted) {
        showNotification(dispatch, {
          id: `projects-imported-${new Date().getTime().toString()}`,
          primaryMessage: `Successfully imported ${numSuccessfulProjects.toString()} job ${
            numSuccessfulProjects === 1 ? "posting" : "postings"
          }.`,
          type: NotificationTypeEnum.SUCCESS,
        });
      } else {
        showNotification(dispatch, {
          id: `projects-partially-imported-${new Date().getTime().toString()}`,
          primaryMessage: `Imported ${numSuccessfulProjects.toString()} of ${totalAttempted.toString()} job ${
            totalAttempted === 1 ? "posting" : "postings"
          } - some job postings could not be imported.`,
          type: NotificationTypeEnum.SUCCESS,
        });
      }

      // Only close if at least one project was created successfully
      if (numSuccessfulProjects > 0) {
        onClose();
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error creating projects:", error);
      showNotification(dispatch, {
        id: `projects-error-${new Date().getTime().toString()}`,
        primaryMessage: "An error occurred while creating the projects.",
        type: NotificationTypeEnum.FAILURE,
      });
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onCancel={onClose}>
      <ModalHeader>Import job postings</ModalHeader>
      <div className="space-y-4 mt-4">
        <div className="text-sm ">
          Provide a list of up to 10 job posting links separated by a comma or
          newline. We'll scrape each URL for a job description, automatically
          generate interview questions, and create a project for it.
        </div>

        <Textarea
          id="job-description-urls"
          placeholder="https://example.com/jobs/customer-service-rep, https://example.com/jobs/receptionist"
          value={urls}
          onChange={(newValue) => {
            setUrls(newValue);
            setError(null);
          }}
          height="200px"
        />
        <PhoneNumberSelect
          phoneNumberId={selectedPhoneNumberId}
          onChange={setSelectedPhoneNumberId}
          className="mt-4"
        />
        {error ? <p className="text-red-500 text-sm">{error}</p> : null}
        <div className="flex justify-end space-x-2">
          <Button variant={ButtonVariantsEnum.Secondary} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant={ButtonVariantsEnum.Primary}
            onClick={handleSubmit}
            isDisabled={isLoading || !urls.trim() || Boolean(error)}
            isLoading={isLoading}
          >
            <LoadingText
              isLoading={isLoading}
              stages={[
                "Scraping job posting...",
                "Generating interview questions...",
                "Creating projects...",
              ]}
            >
              Import
            </LoadingText>
          </Button>
        </div>
      </div>
    </Modal>
  );
};
