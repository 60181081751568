import { InformationCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Tooltip } from "react-tooltip";

interface InfoTooltipProps {
  id: string;
  place: "top" | "bottom" | "left" | "right";
  children?: React.ReactNode; // The target element
  content?: React.ReactNode; // The content to be shown by the tooltip. If empty, don't render a tooltip.
  delay?: number; // Delay in ms before showing the tooltip
}

export const InfoTooltip: React.FC<InfoTooltipProps> = ({
  id,
  place,
  children,
  content,
  delay,
}) => {
  return (
    <>
      <span data-tooltip-id={id}>
        {children || (
          <InformationCircleIcon aria-hidden="true" className="h-5 w-5" />
        )}
      </span>
      <Tooltip
        delayShow={delay}
        id={id}
        place={place}
        style={{
          borderRadius: "6px",
          backgroundColor: "#0f172a",
          padding: "8px 10px",
          zIndex: 50,
          maxWidth: "300px",
          overflowWrap: "break-word",
          whiteSpace: "normal",
        }}
      >
        {content}
      </Tooltip>
    </>
  );
};
