import { Loader, LoaderStylesEnum } from "@repo/ui";
import "@repo/ui/styles.css";
import * as Sentry from "@sentry/react";
import type { Session } from "@supabase/supabase-js";
import { LoadingStatesEnum, ProjectDetailsTabPathsEnum } from "app-types";
import posthog from "posthog-js";
import { useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useParams,
} from "react-router-dom";
import { fetchInitialState, supabase } from "../api/supabaseService";
import NavigationHeader from "../features/NavigationHeader";
import { OnboardingFormContainer } from "../features/OnboardingForm/onboardingFormContainer";
import SettingsPage from "../features/SettingsPage/settingsPage";
import { selectCompany } from "../features/company/companySlice";
import NotificationsOverlay from "../features/notificationsOverlay/notificationsOverlay";
import { AuthPopupCallbackPage } from "../features/signin/authPopupCallbackPage";
import { SignInPage } from "../features/signin/signInPage";
import {
  selectTeammate,
  selectTeammateLoadingStatus,
} from "../features/teammate/teammateSlice";
import { TeammateAuthorizationProvider } from "../helpers/teammateAuthorizationContext";
import { getIsPopupAuthCallback } from "../helpers/urlHelpers";
import { useAppDispatch, useAppSelector } from "../hooks/hook";
import { AllProjectsPage } from "../pages/allProjectsPage";
import { ProjectDetailsTabsEnum } from "../pages/projectDetailsPage";
import { ProjectDetailsPageContainer } from "../pages/projectDetailsPageContainer";
import "./styles.css";

const RedirectToDefaultProjectPageTab = () => {
  const { project_id } = useParams();
  return <Navigate to={`/project/${project_id}/setup`} replace />;
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <AllProjectsPage />,
      },
      {
        path: `/project/:project_id/${ProjectDetailsTabPathsEnum.ProjectConfiguration}`,
        element: (
          <ProjectDetailsPageContainer
            tab={ProjectDetailsTabsEnum.ProjectConfiguration}
          />
        ),
      },
      {
        path: `/project/:project_id/${ProjectDetailsTabPathsEnum.Interviews}/:interview_id?/*`,
        element: (
          <ProjectDetailsPageContainer
            tab={ProjectDetailsTabsEnum.Interviews}
          />
        ),
      },
      {
        path: `/project/:project_id/${ProjectDetailsTabPathsEnum.Insights}`,
        element: (
          <ProjectDetailsPageContainer tab={ProjectDetailsTabsEnum.Insights} />
        ),
      },
      // Catch-all route for invalid project page tabs
      {
        path: "/project/:project_id/*",
        element: <RedirectToDefaultProjectPageTab />,
      },
      {
        path: "/settings/*",
        element: <SettingsPage />,
      },
      {
        // Redirect home on other routes
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
]);

function Layout() {
  return (
    <div className="grid grid-rows-[auto,1fr] w-full h-full">
      <NavigationHeader />
      <main className="overflow-auto" style={{ width: "100%", height: "100%" }}>
        <Outlet />
      </main>
      <NotificationsOverlay />
    </div>
  );
}

function App(): JSX.Element {
  const [session, setSession] = useState<Session | null>(null);
  const [sessionLoadingState, setSessionLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADING);
  const dispatch = useAppDispatch();

  const company = useAppSelector(selectCompany);
  const teammate = useAppSelector(selectTeammate);
  const teammateLoadingStatus = useAppSelector(selectTeammateLoadingStatus);

  const [companyInvitationToken, setCompanyInvitationToken] = useState<
    string | null
  >(null);

  useEffect(() => {
    // If the user has opened a link to accept a company invitation, get the invite token.
    const pathname = window.location.pathname;
    const isOnAcceptInvitationPage = pathname === "/accept-invitation";
    const searchParams = new URLSearchParams(window.location.search);
    const inviteToken = searchParams.get("t");
    if (isOnAcceptInvitationPage) setCompanyInvitationToken(inviteToken);
  }, []);

  useEffect(() => {
    // Fetch the current session and update state
    supabase.auth.getSession().then(async ({ data: { session } }) => {
      setSession(session);
      setSessionLoadingState(LoadingStatesEnum.LOADED);

      if (!session) {
        Sentry.setUser(null); // Clear the user in Sentry if we don't have a session.
        posthog.reset();
      }
    });

    // Subscribe to auth state changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);

      // Load initial state
      if (session) fetchInitialState(dispatch, session);
    });

    // Return the unsubscribe function to cleanup on unmount
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  if (getIsPopupAuthCallback()) {
    return <AuthPopupCallbackPage />;
  }

  if (sessionLoadingState === LoadingStatesEnum.LOADING)
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader style={LoaderStylesEnum.ZOOMIES} />
      </div>
    );

  if (sessionLoadingState === LoadingStatesEnum.ERROR)
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <div className="text-2xl text-gray-800">
          There was an error loading your session. Please refresh and try again.
        </div>
      </div>
    );

  // If we've loaded our session call and there's no session, show a sign in/sign up prompt
  if (!session) {
    return (
      <SignInPage isAcceptingInvitation={Boolean(companyInvitationToken)} />
    );
  }

  // By this point we should have a valid session, so we can render based on the teammate loading status
  if (teammateLoadingStatus === LoadingStatesEnum.LOADING)
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader style={LoaderStylesEnum.ZOOMIES} />
      </div>
    );

  if (teammateLoadingStatus === LoadingStatesEnum.ERROR || !teammate) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <div className="text-2xl text-gray-800">
          There was an error loading your profile. Please refresh and try again.
        </div>
      </div>
    );
  }

  // Show onboarding if we're missing any required properties:
  // - Company
  // - First or last name
  // or if we're accepting an invitation
  if (
    !company ||
    !teammate.first_name ||
    !teammate.last_name ||
    companyInvitationToken
  )
    return (
      <OnboardingFormContainer
        company={company}
        teammate={teammate}
        companyInvitationToken={companyInvitationToken}
      />
    );

  return (
    <TeammateAuthorizationProvider>
      <RouterProvider router={router} />
    </TeammateAuthorizationProvider>
  );
}

export default App;
