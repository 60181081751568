import { z, ZodSchema } from "zod";
import {
  LANGUAGE_DISPLAY_NAMES,
  type CustomFieldDefinition,
  type CustomFieldDefinitionInsertClient,
} from "..";
import {
  CANDIDATE_ALIGN_FIELD_NAME,
  CefrAssessmentFieldNameToLanguage,
  SpecialAssessmentFieldNamesEnum,
} from "./assessment";
import { SpecialProjectCustomFieldNamesEnum } from "./project";

export enum CustomFieldTypesEnum {
  Text = "text",
  DateAndTime = "date_and_time",
  Number = "number",
  Boolean = "boolean",
  UserReference = "user_reference", // Reference to a user/teammate. The value is an ID and the display_value is email.
}

export const customFieldValueSchemaByType = {
  [CustomFieldTypesEnum.Text]: z.string(),
  [CustomFieldTypesEnum.DateAndTime]: z.date(),
  [CustomFieldTypesEnum.Number]: z.number(),
  [CustomFieldTypesEnum.Boolean]: z.boolean(),
  [CustomFieldTypesEnum.UserReference]: z.string(),
} as const satisfies Record<CustomFieldTypesEnum, ZodSchema>;

function createCustomFieldDataSchema<T extends ZodSchema>(valueSchema: T) {
  return z.object({
    value: valueSchema.nullable(),
    display_value: z.string().optional(),
  });
}

// It's easier to write this out if we want to keep the distinct type of each key.
export const customFieldDataSchemaByType = {
  [CustomFieldTypesEnum.Text]: createCustomFieldDataSchema(
    customFieldValueSchemaByType[CustomFieldTypesEnum.Text],
  ),
  [CustomFieldTypesEnum.DateAndTime]: createCustomFieldDataSchema(
    customFieldValueSchemaByType[CustomFieldTypesEnum.DateAndTime],
  ),
  [CustomFieldTypesEnum.Number]: createCustomFieldDataSchema(
    customFieldValueSchemaByType[CustomFieldTypesEnum.Number],
  ),
  [CustomFieldTypesEnum.Boolean]: createCustomFieldDataSchema(
    customFieldValueSchemaByType[CustomFieldTypesEnum.Boolean],
  ),
  [CustomFieldTypesEnum.UserReference]: createCustomFieldDataSchema(
    customFieldValueSchemaByType[CustomFieldTypesEnum.UserReference],
  ),
} as const satisfies Record<CustomFieldTypesEnum, ZodSchema>;

export const customFieldValueDataSchema = z.union([
  customFieldDataSchemaByType[CustomFieldTypesEnum.Text],
  customFieldDataSchemaByType[CustomFieldTypesEnum.DateAndTime],
  customFieldDataSchemaByType[CustomFieldTypesEnum.Number],
  customFieldDataSchemaByType[CustomFieldTypesEnum.Boolean],
  customFieldDataSchemaByType[CustomFieldTypesEnum.UserReference],
]);

export type CustomFieldValueData = z.infer<typeof customFieldValueDataSchema>;

export type SpecialInterviewCustomFieldName =
  | SpecialInterviewCustomFieldNamesEnum
  | InterviewSectionAssessmentCustomFieldName;

export enum SpecialInterviewCustomFieldNamesEnum {
  FountainApplicantId = "fountain_applicant_id",
  DataLabelingSectionAssessment = "data_labeling_section_assessment",
}

export const specialCustomFieldDetails = {
  [SpecialProjectCustomFieldNamesEnum.AdvancedNextSteps]: {
    display_name: "Advancement message next steps",
    field_type: CustomFieldTypesEnum.Text,
  },
  [SpecialInterviewCustomFieldNamesEnum.FountainApplicantId]: {
    display_name: "Fountain applicant ID",
    field_type: CustomFieldTypesEnum.Text,
  },
  [SpecialInterviewCustomFieldNamesEnum.DataLabelingSectionAssessment]: {
    display_name: "Data labeling exercise assessment",
    field_type: CustomFieldTypesEnum.Text,
  },
  [SpecialAssessmentFieldNamesEnum.CefrLanguageLevelEnglish]: {
    display_name: "CEFR English level estimate",
    field_type: CustomFieldTypesEnum.Text,
  },
  [SpecialAssessmentFieldNamesEnum.CefrLanguageLevelGerman]: {
    display_name: "CEFR German level estimate",
    field_type: CustomFieldTypesEnum.Text,
  },
};

/** Special custom field name for storing section assessments on interviews. */
export type InterviewSectionAssessmentCustomFieldName =
  `${typeof interviewSectionAssessmentCustomFieldNamePrefix}:${string}`;

export const interviewSectionAssessmentCustomFieldNamePrefix =
  "interview_section_assessment";

const interviewSectionAssessmentCustomFieldDetails = {
  // TODO: Make this dynamic by requiring it to be passed into findOrCreateSpecialCustomFieldDefinition?
  display_name: "Interview section assessment",
  field_type: CustomFieldTypesEnum.Text,
};

export function checkIsInterviewSectionAssessmentCustomFieldName(
  fieldName: string | undefined,
): fieldName is InterviewSectionAssessmentCustomFieldName {
  return (
    fieldName !== undefined &&
    fieldName.startsWith(`${interviewSectionAssessmentCustomFieldNamePrefix}:`)
  );
}

export function buildInterviewSectionAssessmentCustomFieldName(
  interviewSectionDefinitionId: string,
): InterviewSectionAssessmentCustomFieldName {
  return `${interviewSectionAssessmentCustomFieldNamePrefix}:${interviewSectionDefinitionId}`;
}

export function findSpecialCustomFieldDetails(
  fieldName:
    | SpecialProjectCustomFieldNamesEnum
    | SpecialAssessmentFieldNamesEnum
    | SpecialInterviewCustomFieldName,
) {
  if (checkIsInterviewSectionAssessmentCustomFieldName(fieldName)) {
    return interviewSectionAssessmentCustomFieldDetails;
  }

  return specialCustomFieldDetails[fieldName];
}

export function getCustomFieldDisplayName(
  customField: CustomFieldDefinition | CustomFieldDefinitionInsertClient,
) {
  if (customField.field_name in CefrAssessmentFieldNameToLanguage) {
    const languageName =
      LANGUAGE_DISPLAY_NAMES[
        CefrAssessmentFieldNameToLanguage[
          customField.field_name as keyof typeof CefrAssessmentFieldNameToLanguage
        ]
      ];
    return `CEFR ${languageName} Level Assessment`;
  }

  if (customField.display_name === CANDIDATE_ALIGN_FIELD_NAME) {
    return "Candidate/Job Alignment";
  }

  return customField.display_name;
}
