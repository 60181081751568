import { TranscriptFragmentRolesEnum } from "app-types";
import { FC } from "react";
import { twJoin } from "tailwind-merge";
import { ShareFragmentIconButton } from "../interviews/interviewSlideover/shareFragmentIconButton";
import { TextWithHighlight } from "./textWithHighlight";

interface TranscriptFragmentTextProps {
  text: string | null;
  role: TranscriptFragmentRolesEnum | null;
  highlightedText?: string;
  isHighlighted?: boolean;
  className?: string;
  onClickShare?: () => void;
}

export const TranscriptFragmentText: FC<TranscriptFragmentTextProps> = ({
  text,
  role,
  isHighlighted,
  highlightedText,
  className,
  onClickShare,
}) => {
  const additionalClassName = twJoin(className || "");

  if (!text) return null;

  // Display agent transcript with less emphasis
  if (role === TranscriptFragmentRolesEnum.AGENT)
    return <div className={"text-sm text-gray-700 mt-2 mb-2"}>{text}</div>;

  return (
    <div className="block">
      <div
        className={twJoin(
          `group ${
            isHighlighted ? "bg-amber-300" : "bg-amber-50"
          } py-1 px-2 rounded transition-colors duration-200 ease-in-out inline-block relative`,
          additionalClassName,
        )}
      >
        <TextWithHighlight text={text} highlight={highlightedText} />
        {onClickShare && (
          <div
            className="absolute top-1/2 right-1 transform -translate-y-1/2 mr-1 opacity-0 group-hover:opacity-100"
            onClick={(e) => e.stopPropagation()}
          >
            <ShareFragmentIconButton onClick={onClickShare} />
          </div>
        )}
      </div>
    </div>
  );
};
