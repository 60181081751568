import { Menu, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { ButtonVariantsEnum } from "@repo/ui";
import { Button } from "@repo/ui/Buttons/button";
import { PermissionActions, PermissionSubjects } from "app-types";
import React, { useState, type FC } from "react";
import { twJoin } from "tailwind-merge";
import { Can } from "../../../helpers/teammateAuthorizationContext";
import { useAppSelector } from "../../../hooks/hook";
import { selectFountainIntegration } from "../../integrations/integrationsSlice";
import { CreateProjectViaFountainModal } from "./createProjectViaFountainModal";

interface CreateJobOpeningButtonProps {
  onClickCreate: () => void;
}

export const CreateJobOpeningButton: FC<CreateJobOpeningButtonProps> = ({
  onClickCreate,
}) => {
  const fountainIntegration = useAppSelector(selectFountainIntegration);
  const [isFountainModalOpen, setIsFountainModalOpen] = useState(false);

  if (fountainIntegration) {
    return (
      <Can
        I={PermissionActions.CREATE}
        a={PermissionSubjects.JOB_OPENINGS}
        passThrough={true}
      >
        {(allowed) => (
          <>
            <Menu as="div" className="relative">
              <Menu.Button
                className="inline-flex items-center rounded-md bg-indigo-600 hover:bg-indigo-500 text-white px-3 py-2 text-sm font-semibold shadow-sm disabled:bg-slate-300 disabled:text-slate-500"
                disabled={!allowed}
              >
                <PlusIcon className="h-4 w-4 mr-1" />
                Create job opening
              </Menu.Button>
              <Transition
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute overflow-visible right-0 z-10 mt-2 w-48 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        className={twJoin(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700",
                        )}
                        onClick={() => {
                          onClickCreate();
                        }}
                      >
                        From a job description
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        className={twJoin(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700",
                        )}
                        onClick={() => {
                          setIsFountainModalOpen(true);
                        }}
                      >
                        From a Fountain Position
                      </a>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
            <CreateProjectViaFountainModal
              isOpen={isFountainModalOpen}
              onClose={() => {
                setIsFountainModalOpen(false);
              }}
              fountainIntegration={fountainIntegration}
            />
          </>
        )}
      </Can>
    );
  }

  return (
    <Can I={PermissionActions.CREATE} a={PermissionSubjects.JOB_OPENINGS}>
      {(allowed) => (
        <Button
          variant={ButtonVariantsEnum.Primary}
          onClick={onClickCreate}
          icon={<PlusIcon className="h-4 w-4" />}
          isDisabled={!allowed}
        >
          Create job opening
        </Button>
      )}
    </Can>
  );
};
