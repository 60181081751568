import { KeywordAnalysis, KeywordAnalysisTypesEnum } from "app-types";
import { FC, Fragment } from "react";
import { Label, PillColorsEnum, SizesEnum } from "@repo/ui";
import { InsightBar } from "../insightBar";

interface KeywordAnalysisCard {
  competitorName: string;
  keywordAnalysis: KeywordAnalysis;
}

const keywordAnalysisTypeToTitlePrefix = {
  [KeywordAnalysisTypesEnum.COMPETITIVE_STRENGTH]: "Strengths against ",
  [KeywordAnalysisTypesEnum.COMPETITIVE_WEAKNESS]: "Weaknesses against ",
};

export const KeywordAnalysisCard: FC<KeywordAnalysisCard> = ({
  keywordAnalysis,
  competitorName,
}) => {
  const title = `${
    keywordAnalysisTypeToTitlePrefix[keywordAnalysis.type]
  } ${competitorName}`;

  const renderAnalysisValues = () => {
    // Edge case where LLM doesn't produce any analyses
    if (keywordAnalysis.values.length === 0) {
      return (
        <div className="col-span-4 flex items-center">
          Insights will appear here as more interviews are completed.
        </div>
      );
    }

    return keywordAnalysis.values.map((value, index) => {
      return (
        <Fragment key={`keyword-analysis-${competitorName}-${index}`}>
          <div className="col-span-4 flex items-center">
            <span>{value.label}</span>
          </div>
          <div className="col-span-1 flex rounded-md pt-2">
            <InsightBar
              color={
                keywordAnalysis.type ===
                KeywordAnalysisTypesEnum.COMPETITIVE_STRENGTH
                  ? PillColorsEnum.GREEN
                  : PillColorsEnum.RED
              }
              percentageWidth={(value.score / 5) * 100}
            />
          </div>
        </Fragment>
      );
    });
  };

  return (
    <div>
      <div className="grid grid-cols-5 gap-3">
        <div className="col-span-4 flex">
          <Label size={SizesEnum.SMALL}>{title}</Label>
        </div>
        <div className="col-span-1">
          <Label size={SizesEnum.SMALL}>Influence</Label>
        </div>
        {renderAnalysisValues()}
      </div>
    </div>
  );
};
