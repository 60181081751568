import { nanoid } from "nanoid";
import { FC, useState } from "react";
import { ModalVariantsEnum, SimpleModal } from "@repo/ui";
import {
  NotificationTypeEnum,
  showNotification,
} from "../features/notificationsOverlay/notificationsSlice";
import { updateProject } from "../features/projects/projectsSlice";
import { useAppDispatch } from "../hooks/hook";

interface RegenerateInterviewLinkModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  inviteLinkUrl: string;
}

export const RegenerateInterviewLinkModal: FC<
  RegenerateInterviewLinkModalProps
> = ({ isOpen, onClose, projectId, inviteLinkUrl }) => {
  const dispatch = useAppDispatch();
  const [isInterviewLinkSaveInProgress, setIsInterviewLinkSaveInProgress] =
    useState(false);

  const handleRegenerateInterviewLink = async () => {
    setIsInterviewLinkSaveInProgress(true);
    try {
      await dispatch(
        updateProject({
          projectId,
          changes: {
            short_id: nanoid(),
          },
        }),
      );

      showNotification(dispatch, {
        id: `interviewlink-${projectId}-regenerated-${new Date().getTime()}`,
        primaryMessage: `Interview link refreshed`,
        type: NotificationTypeEnum.SUCCESS,
      });

      onClose();
    } catch (err) {
      console.error("error regenerating interview link", err);
      showNotification(dispatch, {
        id: `interviewlink-${projectId}-regenerate-failed-${new Date().getTime()}`,
        primaryMessage: `Failed to refresh interview link`,
        type: NotificationTypeEnum.FAILURE,
      });
    } finally {
      setIsInterviewLinkSaveInProgress(false);
    }
  };

  return (
    <SimpleModal
      isOpen={isOpen}
      variant={ModalVariantsEnum.Warning}
      title="Refresh interview link"
      subtitle={`If you continue, your current link (${inviteLinkUrl}) will be disabled, and a new link will be generated. Interviews already conducted using the old link will be preserved.`}
      confirmButtonText="Refresh link"
      onCancel={() => {
        onClose();
      }}
      onConfirm={() => {
        void handleRegenerateInterviewLink();
      }}
      isPrimaryButtonLoading={isInterviewLinkSaveInProgress}
    />
  );
};
