import { Select } from "@repo/ui";
import type { SelectOption } from "@repo/ui/Select/select";
import { JobTypeDB } from "app-types";
import { FC } from "react";
import { useAllJobTypesQuery } from "../../api/redux";

export interface JobTypePickerProps {
  jobTypeId: string | null;
  onChange: (jobType: JobTypeDB | null) => void;
  tabIndex?: number;
}

export const JobTypePicker: FC<JobTypePickerProps> = ({
  jobTypeId,
  onChange,
  tabIndex,
}) => {
  const { data: allJobTypes, isLoading } = useAllJobTypesQuery();

  const options: SelectOption[] = [
    { name: "New job type", value: "new" },
    ...(allJobTypes?.map((jobType) => ({
      name: jobType.name,
      value: jobType.id,
    })) ?? []),
  ];

  const currentSelection = options.find(
    (option) => option.value === (jobTypeId ?? "new"),
  );

  return (
    <div className="relative">
      <Select
        options={options}
        currentSelection={currentSelection}
        onChange={(selected) => {
          if (selected.value === "new") {
            onChange(null);
          } else {
            const jobType = allJobTypes?.find((jt) => jt.id === selected.value);
            onChange(jobType ?? null);
          }
        }}
        placeholder="New job type"
        label="Job type"
        loadingMessage={isLoading ? "Loading job types..." : undefined}
        tabIndex={tabIndex}
      />
    </div>
  );
};
