import {
  Button,
  ButtonVariantsEnum,
  Modal,
  ModalHeader,
  Select,
  SizesEnum,
  Textarea,
} from "@repo/ui";
import {
  BasicQuestion,
  LoadingStatesEnum,
  QuestionsImportSourcesEnum,
} from "app-types";
import { useState } from "react";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppDispatch } from "../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";

/*
 * Constants.
 */

const importSourceDropdownOptions = [
  {
    name: "List of questions",
    value: QuestionsImportSourcesEnum.LIST_OF_QUESTIONS,
  },
  {
    name: "Research discussion guide",
    value: QuestionsImportSourcesEnum.RESEARCH_DISCUSSION_GUIDE,
  },
  {
    name: "Job description",
    value: QuestionsImportSourcesEnum.JOB_DESCRIPTION,
  },
];

export const questionsImportTypeToPlaceholder = {
  [QuestionsImportSourcesEnum.LIST_OF_QUESTIONS]: `What motivated you to consider our product initially?
What were the key factors in your decision-making process?
How did our product compare to competing solutions?
What ultimately led to your decision to choose or not choose our product?
Is there anything we could have done differently to win your business?
Would you consider our product in the future or recommend it to others?
`,
  [QuestionsImportSourcesEnum.RESEARCH_DISCUSSION_GUIDE]: `Acme Brand Perception Research

Introduction
Understanding our enterprise customers’ brand perception is crucial for maintaining competitive advantage and fostering long-term relationships. This document outlines our research approach, findings, and implications regarding the brand perception of Acme among our enterprise customers.

Research Objectives
  1.	Assess Brand Awareness and Recognition: Determine the level of awareness and recognition of Acme’s brand among enterprise customers.
  2.	Evaluate Brand Attributes: Identify key attributes associated with Acme’s brand and how they align with customer expectations and needs.
  3.	Understand Customer Satisfaction and Loyalty: Measure the satisfaction levels and loyalty of enterprise customers using Acme’s CRM solutions.
  4.	Identify Areas for Improvement: Highlight areas where Acme can improve its brand perception to better meet enterprise customer needs.

Methodology
...`,
  [QuestionsImportSourcesEnum.JOB_DESCRIPTION]: `Store Manager at Sunset Surf Shop
Location: Sunset Surf Shop, Outer Sunset, San Francisco
About Us:
Sunset Surf Shop is a premier destination for surf enthusiasts, offering a wide range of surfboards, apparel, accessories, and gear.
Job Overview:
We are seeking an experienced and dynamic Store Manager to lead our team and oversee the daily operations of Sunset Surf Shop. The ideal candidate will have a strong background in retail management, a passion for surfing, and the ability to inspire and motivate a team to achieve sales targets and deliver outstanding customer service.

Responsibilities:

  •	Store Operations: Oversee the daily operations of the store, ensuring it runs smoothly and efficiently. This includes opening and closing procedures, inventory management, and maintaining store cleanliness and organization.
  •	Customer Service: Ensure that every customer has a positive shopping experience. Address customer inquiries, resolve complaints, and handle returns and exchanges in a professional and courteous manner.
  •	Team Leadership: Recruit, train, and manage a team of sales associates. Foster a positive work environment, set clear performance expectations, and provide regular feedback and development opportunities.
  •	Inventory Control: Manage inventory levels to prevent stockouts and overstock situations. Conduct regular inventory counts and coordinate with suppliers for timely restocking.
  •	Financial Management: Monitor and manage the store’s budget, expenses, and financial performance. Prepare and analyze sales reports and provide insights and recommendations for improvement.

Qualifications:

  •	Experience: Minimum of 3-5 years of retail management experience, preferably in the surf or outdoor sports industry.
  •	Education: High school diploma or equivalent required; a bachelor’s degree in business, marketing, or a related field is preferred.
  •	Skills: Strong leadership and team management skills. Excellent communication and interpersonal skills. Proven ability to achieve sales targets and provide exceptional customer service.
  •	Knowledge: In-depth knowledge of surf products and the surf industry. Passion for surfing and familiarity with surf culture.
  •	Technology: Proficiency in using point-of-sale (POS) systems, inventory management software, and Microsoft Office Suite.

Benefits:

  •	Competitive salary and performance-based bonuses.
  •	Employee discounts on store merchandise.
  •	Health, dental, and vision insurance.
  •	Paid time off and holidays.
  •	Opportunities for career growth and development.
  •	A fun and vibrant work environment with a passionate team of surf enthusiasts.

How to Apply:
Interested candidates are invited to submit their resume and a cover letter detailing their relevant experience and passion for surfing to [email address]. Please include “Store Manager Application - Sunset Surf Shop” in the subject line.

Sunset Surf Shop is an equal opportunity employer. We celebrate diversity and are committed to creating an inclusive environment for all employees.

Join us at Sunset Surf Shop and be a part of a team that loves the ocean and shares the stoke!
`,
};

/*
 * Props.
 */

interface ImportQuestionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onImport: (basicQuestions: BasicQuestion[]) => void;
  projectId: string;
}

/*
 * Component.
 */

export const ImportQuestionsModal = ({
  isOpen,
  onClose,
  onImport,
  projectId,
}: ImportQuestionsModalProps) => {
  const dispatch = useAppDispatch();

  const [importSource, setImportSource] =
    useState<QuestionsImportSourcesEnum>();
  const [content, setContent] = useState<string>("");
  const [importLoadingState, setImportLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADED);

  const onClickImport = async () => {
    setImportLoadingState(LoadingStatesEnum.LOADING);
    try {
      const axios = await getAxiosInstanceWithAuth();

      const { data } = await axios.post(
        `/projects/${projectId}/generate-questions`,
        {
          import_type: importSource,
          content,
        },
      );

      onImport(data);

      showNotification(dispatch, {
        id: `project-${projectId}-questions-import-succeeded-${new Date().getTime()}`,
        primaryMessage: `Questions successfully imported`,
        type: NotificationTypeEnum.SUCCESS,
      });

      setImportLoadingState(LoadingStatesEnum.LOADED);
    } catch (err) {
      showNotification(dispatch, {
        id: `project-${projectId}-questions-import-failed-${new Date().getTime()}`,
        primaryMessage: `Failed to import questions. Please try again.`,
        type: NotificationTypeEnum.FAILURE,
      });
      setImportLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  const renderContent = () => {
    return (
      <>
        <div className="mt-6 mb-2 w-60">
          <Select
            options={importSourceDropdownOptions}
            currentSelection={importSourceDropdownOptions.find(
              (o) => o.value === importSource,
            )}
            label="Source"
            placeholder="Select an import source"
            onChange={(option) => {
              const newValue = importSourceDropdownOptions.find(
                (o) => o.name === option.name,
              )?.value;
              setImportSource(newValue);
              setContent("");
            }}
          />
        </div>

        {importSource ? (
          <div className="mt-4 text-sm">
            <Textarea
              label="Content"
              placeholder={
                importSource
                  ? questionsImportTypeToPlaceholder[importSource]
                  : ""
              }
              value={content}
              onChange={(newValue) => {
                setContent(newValue);
              }}
              height="235px"
            />
            <div className="mt-2 text-gray-500">
              {generateContentHelperText(importSource)}
            </div>
          </div>
        ) : null}

        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <div className="ml-3">
            <Button
              variant={ButtonVariantsEnum.Primary}
              isDisabled={!importSource || !content}
              onClick={async () => {
                await onClickImport();
              }}
              isLoading={importLoadingState === LoadingStatesEnum.LOADING}
            >
              Generate
            </Button>
          </div>
          <Button variant={ButtonVariantsEnum.Secondary} onClick={onClose}>
            Cancel
          </Button>
        </div>
      </>
    );
  };

  return (
    <Modal isOpen={isOpen} onCancel={onClose} size={SizesEnum.LARGE}>
      <ModalHeader>Import Questions</ModalHeader>
      {renderContent()}
    </Modal>
  );
};

/*
 * Helpers.
 */

function generateContentHelperText(
  importTemplateType: QuestionsImportSourcesEnum,
) {
  switch (importTemplateType) {
    case QuestionsImportSourcesEnum.LIST_OF_QUESTIONS:
      return "Copy and paste a list of questions (no need to worry about text formatting or styling).";

    case QuestionsImportSourcesEnum.RESEARCH_DISCUSSION_GUIDE:
      return "Copy and paste your research discussion guide. Interview questions will automatically be generated based on the goals of your research.";

    case QuestionsImportSourcesEnum.JOB_DESCRIPTION:
      return "Copy and paste your job description (no need to worry about text formatting or styling). Phone screen questions tailored to the company and role will automatically be generated.";
  }
}
