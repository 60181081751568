import { FC } from "react";
import { InfoBanner } from "@repo/ui";

interface UnansweredQuestionsBannerProps {
  unansweredQuestions: string[];
  jumpToFragmentByText?: (text: string) => void;
  isTooltipMode?: boolean;
}

export const UnansweredQuestionsBanner: FC<UnansweredQuestionsBannerProps> = ({
  unansweredQuestions,
  jumpToFragmentByText,
  isTooltipMode = false,
}) => {
  if (unansweredQuestions.length === 0) {
    return null;
  }

  const list = (
    <span>
      <ul className="list-disc pl-4 space-y-1">
        {unansweredQuestions.map((question, index) => (
          <li
            key={`unanswered-question-${index}`}
            className={
              isTooltipMode ? "" : "cursor-pointer hover:text-blue-400"
            }
            onClick={() => jumpToFragmentByText?.(question)}
          >
            {`"${question}"`}
          </li>
        ))}
      </ul>
    </span>
  );

  if (isTooltipMode) {
    return (
      <span>
        Consider following up on these questions:
        {list}
      </span>
    );
  }

  return (
    <div className="mb-4">
      <InfoBanner
        title="Consider following up on these questions"
        description={list}
      />
    </div>
  );
};
