import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { Interview } from "app-types";
import { FC, useState } from "react";
import { DropdownMenu, ModalVariantsEnum, SimpleModal } from "@repo/ui";
import { useAppDispatch } from "../../../hooks/hook";
import { deleteInterview } from "../interviewsSlice";
import { InterviewFeedbackModal } from "./interviewFeedbackModal";

interface InterviewMoreMenuProps {
  interview: Interview;
  onClickDeleteInterview: () => void;
}

interface MenuItem {
  label: string;
  onClick: () => void;
}

export const InterviewMoreMenu: FC<InterviewMoreMenuProps> = ({
  interview,
  onClickDeleteInterview,
}) => {
  const dispatch = useAppDispatch();

  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isDeleteWarningModalOpen, setIsDeleteWarningModalOpen] =
    useState(false);

  const menuItems: MenuItem[] = [
    {
      label: interview?.metadata?.feedback
        ? "View feedback"
        : "Rate this interview",
      onClick: () => setIsFeedbackModalOpen(true),
    },
    {
      label: "Delete this interview",
      onClick: () => setIsDeleteWarningModalOpen(true),
    },
  ];

  return (
    <>
      <Menu as="div" className="relative ml-3">
        <Menu.Button className="relative flex items-center ring-1 ring-inset ring-gray-300 bg-white p-1.5 hover:bg-gray-50 rounded-md shadow-sm text-gray-900 disabled:bg-slate-300 disabled:text-slate-500">
          <span className="absolute -inset-1.5" />
          <span className="sr-only">Open interview menu</span>
          <EllipsisVerticalIcon className="w-6 h-6" />
        </Menu.Button>
        <DropdownMenu items={menuItems} />
      </Menu>
      <InterviewFeedbackModal
        interview={interview}
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
      />
      <SimpleModal
        isOpen={isDeleteWarningModalOpen}
        variant={ModalVariantsEnum.Warning}
        title="Delete interview"
        subtitle="Are you sure you want to delete this interview? This action cannot be undone."
        confirmButtonText="Delete interview"
        onCancel={() => {
          setIsDeleteWarningModalOpen(false);
        }}
        onConfirm={() => {
          dispatch(
            deleteInterview({
              projectId: interview.project_id,
              interviewId: interview.id,
            }),
          );
          setIsDeleteWarningModalOpen(false);
          onClickDeleteInterview();
        }}
      />
    </>
  );
};
