import {
  ArrowUpOnSquareStackIcon,
  BuildingOfficeIcon,
  UserCircleIcon,
  ViewfinderCircleIcon,
} from "@heroicons/react/24/outline";
import { Button, ButtonVariantsEnum } from "@repo/ui";
import {
  buildProjectUrlPath,
  InterviewAgentTypesEnum,
  LoadingStatesEnum,
  PermissionActions,
  PermissionSubjects,
  ProjectEmailDigestFrequencyEnum,
  ProjectModesEnum,
  projectSettingsDefaults,
} from "app-types";
import {
  getQuestionsForProjectType,
  projectTemplates,
  ProjectTypesEnum,
  TemplateCategoriesEnum,
} from "app-types/constants/projectTypeToDefaultQuestions";
import { nanoid } from "nanoid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import {
  selectCompany,
  selectIsRecruitingModeCompany,
} from "../features/company/companySlice";
import {
  NotificationTypeEnum,
  showNotification,
} from "../features/notificationsOverlay/notificationsSlice";
import { CreateJobOpeningButton } from "../features/projects/projectTemplates/createJobOpeningButton";
import { CreateProjectModal } from "../features/projects/projectTemplates/createProjectModal";
import { CreateRecruitingProjectModal } from "../features/projects/projectTemplates/createRecruitingProjectModal";
import { ImportJobPostingsModal } from "../features/projects/projectTemplates/importJobPostingsModal";
import { createProject } from "../features/projects/projectsSlice";
import { ProjectsTable } from "../features/projects/projectsTable";
import { selectTeammate } from "../features/teammate/teammateSlice";
import { Can } from "../helpers/teammateAuthorizationContext";
import { useAppDispatch, useAppSelector } from "../hooks/hook";

interface NewProjectOptions {
  name: string;
  type: ProjectTypesEnum;
  mode: ProjectModesEnum;
}

const templateCategories = [
  {
    name: TemplateCategoriesEnum.Research,
    templates: projectTemplates.filter(
      (p) => p.category === TemplateCategoriesEnum.Research,
    ),
    icon: ViewfinderCircleIcon,
    iconForeground: "text-orange-700",
    iconBackground: "bg-orange-50",
  },
  {
    name: TemplateCategoriesEnum.SalesAndSuccess,
    templates: projectTemplates.filter(
      (p) => p.category === TemplateCategoriesEnum.SalesAndSuccess,
    ),
    icon: UserCircleIcon,
    iconForeground: "text-green-700",
    iconBackground: "bg-green-50",
  },
  {
    name: TemplateCategoriesEnum.HrAndRecruiting,
    templates: projectTemplates.filter(
      (p) => p.category === TemplateCategoriesEnum.HrAndRecruiting,
    ),
    icon: BuildingOfficeIcon,
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
  },
];

export function AllProjectsPage(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const company = useAppSelector(selectCompany);
  const teammate = useAppSelector(selectTeammate);
  if (!company || !teammate) return <></>; // Should not happen

  const isRecruitingModeCompany = useAppSelector(selectIsRecruitingModeCompany);

  const [newProjectOptions, setNewProjectOptions] = useState<
    NewProjectOptions | undefined
  >();
  const [createProjectStatus, setCreateProjectStatus] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADED);

  const newProjectTemplate = projectTemplates.find(
    (p) => p.type === newProjectOptions?.type,
  );

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const onClickCreate = async ({
    jobDescription,
    interviewAgentType,
    phoneNumberId,
  }: {
    jobDescription?: string;
    interviewAgentType?: InterviewAgentTypesEnum;
    phoneNumberId?: string;
  }) => {
    if (!newProjectOptions || !newProjectTemplate) return;

    setCreateProjectStatus(LoadingStatesEnum.LOADING);

    try {
      const newProject = await dispatch(
        createProject({
          name: newProjectOptions.name,
          incentive_quantity:
            newProjectOptions.mode === ProjectModesEnum.VOICE_AGENT ? 0 : 25,
          questions: getQuestionsForProjectType(
            newProjectOptions.type,
            company.name,
            newProjectOptions.mode === ProjectModesEnum.VOICE_AGENT,
          ),
          short_id: nanoid(),
          mode: newProjectOptions.mode,
          settings: {
            ...projectSettingsDefaults,
            ...(interviewAgentType && {
              interview_agent_type: interviewAgentType,
            }),
            send_email_digest_to_all_teammates: false,
            send_email_digest_to_project_owners: true,
            email_digest_frequency: isRecruitingModeCompany
              ? ProjectEmailDigestFrequencyEnum.INSTANT
              : ProjectEmailDigestFrequencyEnum.DAILY,
          },
          is_live: true,
          job_description: jobDescription,
          phone_number_id: phoneNumberId,
          owner_teammate_ids: [teammate.id],
        }),
      ).unwrap();

      const newProjectId = newProject.id;

      showNotification(dispatch, {
        id: `project-created-${new Date().getTime()}`,
        primaryMessage: `Project successfully created`,
        type: NotificationTypeEnum.SUCCESS,
      });

      setCreateProjectStatus(LoadingStatesEnum.LOADED);
      navigate(buildProjectUrlPath(newProjectId));
    } catch (err) {
      console.error("error creating project", err);
      setCreateProjectStatus(LoadingStatesEnum.ERROR);
    }
  };

  return (
    <div className="mx-auto max-w-4xl px-4 py-6 sm:px-6 sm:py-8 lg:px-8">
      {!isRecruitingModeCompany && (
        <>
          <h2 className="text-xl mb-1 font-semibold leading-6 text-gray-900">
            New Project
          </h2>
          <p className="text-base mb-4 text-gray-600">
            {"Get started with a template, or "}
            <span
              className="text-blue-500 cursor-pointer hover:text-blue-600"
              onClick={() => {
                setNewProjectOptions({
                  name: "",
                  type: ProjectTypesEnum.Custom,
                  mode: ProjectModesEnum.SURVEY,
                });
              }}
            >
              create a project from scratch
            </span>
            .
          </p>
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-3">
            {templateCategories.map((c) => (
              <div
                key={c.name}
                className="relative items-start rounded-lg border border-gray-300 bg-white p-4 shadow-sm"
              >
                <div className="flex flex-row space-x-3 mb-4 items-center">
                  <div>
                    <span
                      className={twJoin(
                        c.iconBackground,
                        c.iconForeground,
                        "inline-flex rounded-lg p-2 ring-4 ring-white",
                      )}
                    >
                      <c.icon className="h-4 w-4" aria-hidden="true" />
                    </span>
                  </div>
                  <div>
                    <p className="font-medium text-sm text-gray-900">
                      {c.name}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col space-y-2">
                  {c.templates.map((t) => (
                    <div className="flex flex-row space-x-1 items-center group cursor-pointer">
                      <span
                        className="text-gray-600 text-sm group-hover:text-blue-500"
                        key={t.name}
                        onClick={() => {
                          setNewProjectOptions({
                            name: t.projectDefaultName,
                            type: t.type,
                            mode: ProjectModesEnum.SURVEY,
                          });
                        }}
                      >
                        {t.name}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <div
        className={`${
          !isRecruitingModeCompany ? "mt-10" : ""
        } mb-4 flex flex-row justify-between items-center`}
      >
        <h2 className="text-xl font-semibold leading-6 text-gray-900">
          {isRecruitingModeCompany ? "Job openings" : "Projects"}
        </h2>
        {isRecruitingModeCompany ? (
          <div className="flex space-x-2">
            <Can
              I={PermissionActions.CREATE}
              a={PermissionSubjects.JOB_OPENINGS}
              passThrough={true}
            >
              {(allowed) => (
                <Button
                  variant={ButtonVariantsEnum.Secondary}
                  onClick={() => {
                    setIsImportModalOpen(true);
                  }}
                  icon={<ArrowUpOnSquareStackIcon className="h-4 w-4" />}
                  isDisabled={!allowed}
                >
                  Import job postings
                </Button>
              )}
            </Can>
            <CreateJobOpeningButton
              onClickCreate={() => {
                setNewProjectOptions({
                  name: "",
                  type: ProjectTypesEnum.Custom,
                  mode: ProjectModesEnum.VOICE_AGENT,
                });
              }}
            />
          </div>
        ) : null}
      </div>
      <ProjectsTable />
      {isRecruitingModeCompany ||
      newProjectOptions?.type === ProjectTypesEnum.CandidateScreen ? (
        <CreateRecruitingProjectModal
          isOpen={Boolean(newProjectOptions)}
          onClose={() => {
            setNewProjectOptions(undefined);
          }}
        />
      ) : (
        <CreateProjectModal
          isOpen={Boolean(newProjectOptions)}
          onClose={() => {
            setNewProjectOptions(undefined);
          }}
          name={newProjectOptions?.name || ""}
          onNameChange={(name) => {
            if (!newProjectOptions) return;
            setNewProjectOptions({ ...newProjectOptions, name });
          }}
          mode={newProjectOptions?.mode || ProjectModesEnum.SURVEY}
          onModeChange={(mode) => {
            if (!newProjectOptions) return;
            setNewProjectOptions({ ...newProjectOptions, mode });
          }}
          onClickCreate={onClickCreate}
          template={newProjectTemplate}
          company={company}
          loadingState={createProjectStatus}
        />
      )}
      <ImportJobPostingsModal
        isOpen={isImportModalOpen}
        onClose={() => {
          setIsImportModalOpen(false);
        }}
      />
    </div>
  );
}
