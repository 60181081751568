import { LockClosedIcon } from "@heroicons/react/24/outline";
import { Input, Label, SaveAndCancelButtons, SizesEnum } from "@repo/ui";
import {
  Company,
  JobTypePropertyEnum,
  PermissionActions,
  PermissionSubjects,
  Project,
  ProjectUpdate,
  convertToValidHttpsUrl,
  getCompanyHasFeatureAccess,
  getProjectAndJobTypeProperty,
  isRecruitingModeProject,
} from "app-types";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateJobTypeMutation } from "../../../api/redux";
import { Can } from "../../../helpers/teammateAuthorizationContext";
import { UpgradeText } from "../../billing/upgradeText";
import { WelcomeAndOutroMessagesEditor } from "./welcomeAndOutroMessagesEditor";

interface AppearanceSettingsSectionProps {
  onSaveProject: (update: ProjectUpdate) => void;
  company: Company;
  project: Project;
}

export const AppearanceSettingsSection: FC<AppearanceSettingsSectionProps> = ({
  onSaveProject,
  company,
  project,
}) => {
  const navigate = useNavigate();
  const [updateJobType] = useUpdateJobTypeMutation();

  const currentRedirectUrl =
    getProjectAndJobTypeProperty(project, JobTypePropertyEnum.RedirectUrl) ??
    "";
  const [redirectUrl, setRedirectUrl] = useState(currentRedirectUrl);
  const [redirectUrlError, setRedirectUrlError] = useState("");

  const welcomeMessage = getProjectAndJobTypeProperty(
    project,
    JobTypePropertyEnum.WelcomeMessage,
  );
  const outroMessage = getProjectAndJobTypeProperty(
    project,
    JobTypePropertyEnum.OutroMessage,
  );
  const [shouldShowOverrideSettings, setShouldShowOverrideSettings] = useState(
    Boolean(welcomeMessage || outroMessage),
  );

  const isMissingFeatureAccess = !getCompanyHasFeatureAccess(
    company,
    "whitelabeling",
  );

  const onClickSaveRedirectUrl = () => {
    setRedirectUrlError("");

    if (!redirectUrl) {
      onSaveProject({
        id: project.id,
        settings: {
          ...project.settings,
          redirect_url: null,
        },
      });

      if (project.job_type) {
        void updateJobType({
          id: project.job_type.id,
          settings: {
            ...project.job_type.settings,
            redirect_url: null,
          },
        });
      }
      return;
    }

    const url = convertToValidHttpsUrl(redirectUrl);

    if (!url) {
      setRedirectUrlError(
        "Invalid URL - please provide a valid URL starting with https://",
      );
      return;
    }

    setRedirectUrl(url);
    onSaveProject({
      id: project.id,
      settings: {
        ...project.settings,
        redirect_url: url,
      },
    });

    if (project.job_type) {
      void updateJobType({
        id: project.job_type.id,
        settings: {
          ...project.job_type.settings,
          redirect_url: url,
        },
      });
    }
  };

  return (
    <Can
      I={PermissionActions.UPDATE}
      a={PermissionSubjects.JOB_OPENINGS}
      passThrough={true}
    >
      {(allowed) => (
        <div>
          {!isRecruitingModeProject(project) && (
            <Label size={SizesEnum.LARGE}>{"Interview link settings"}</Label>
          )}

          <div>
            <Label size={SizesEnum.SMALL}>Logo and background color</Label>
            <div className="mt-2 text-sm text-gray-600">
              Add a logo and background color in{" "}
              <span
                className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
                onClick={() => {
                  navigate("/settings/organization");
                }}
              >
                organization settings
              </span>
              .
            </div>
          </div>

          <div className="mt-4">
            <Label size={SizesEnum.SMALL}>Redirect URL</Label>
            <div className="mt-2">
              <Input
                value={redirectUrl}
                onChange={(evt) => {
                  setRedirectUrlError("");
                  setRedirectUrl(evt.target.value);
                }}
                placeholder="https://example.com"
                errorDescription={redirectUrlError}
                inputClassName="max-w-[300px]"
                leftIcon={
                  isMissingFeatureAccess ? (
                    <LockClosedIcon className="h-4 w-4 text-gray-700" />
                  ) : undefined
                }
                isDisabled={isMissingFeatureAccess || !allowed}
                maxLength={200}
              />
            </div>
            {redirectUrl !== currentRedirectUrl ? (
              <SaveAndCancelButtons
                onSave={onClickSaveRedirectUrl}
                onCancel={() => {
                  setRedirectUrl(currentRedirectUrl);
                  setRedirectUrlError("");
                }}
              />
            ) : null}
            <div className="mt-2 text-sm text-gray-600">
              Participants will be redirected to this URL after they complete
              their interview.
              {isMissingFeatureAccess ? (
                <UpgradeText planFeatureName="whitelabeling" />
              ) : null}
            </div>
          </div>

          {shouldShowOverrideSettings ? (
            <WelcomeAndOutroMessagesEditor
              welcomeMessage={welcomeMessage}
              outroMessage={outroMessage}
              onWelcomeMessageSave={(message) => {
                onSaveProject({
                  id: project.id,
                  settings: {
                    ...project.settings,
                    welcome_message: message,
                  },
                });

                if (project.job_type) {
                  void updateJobType({
                    id: project.job_type.id,
                    settings: {
                      ...project.job_type.settings,
                      welcome_message: message,
                    },
                  });
                }
              }}
              onOutroMessageSave={(message) => {
                onSaveProject({
                  id: project.id,
                  settings: {
                    ...project.settings,
                    outro_message: message,
                  },
                });

                if (project.job_type) {
                  void updateJobType({
                    id: project.job_type.id,
                    settings: {
                      ...project.job_type.settings,
                      outro_message: message,
                    },
                  });
                }
              }}
              isProjectLevel={true}
              isDisabled={!allowed}
            />
          ) : (
            <div className="mt-4">
              <Label size={SizesEnum.SMALL}>Welcome and outro message</Label>
              <div className="mt-2 text-sm text-gray-600">
                Add a welcome and outro message in{" "}
                <span
                  className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
                  onClick={() => {
                    navigate("/settings/organization");
                  }}
                >
                  organization settings
                </span>
                {allowed && (
                  <>
                    , or{" "}
                    <span
                      className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
                      onClick={() => setShouldShowOverrideSettings(true)}
                    >
                      customize the message for this{" "}
                      {isRecruitingModeProject(project)
                        ? "job opening"
                        : "project"}
                    </span>
                  </>
                )}
                .
              </div>
            </div>
          )}
        </div>
      )}
    </Can>
  );
};
