import { z } from "zod";
import { llmFeedbackPrioritySchema } from "./llmFeedback";

export const jobQuestionsFeedbackSchema = z.object({
  suggestions: z.array(
    z.object({
      suggestion: z.string(),
      priority: llmFeedbackPrioritySchema,
    }),
  ),
});

export type JobQuestionsFeedback = z.infer<typeof jobQuestionsFeedbackSchema>;
