import type { ReactNode } from "react";

/*
 * Functions
 */

export const isValidName = (name: string): boolean => {
  return name.length > 0 && name.length < 36;
};

// Converts snake cased or camel cased strings to human readable strings
export const toHumanReadableString = (str: string): string => {
  // Convert snake_case to space-separated words
  let result = str.replace(/_/g, " ");

  // Insert space before each uppercase letter for camelCase
  result = result.replace(/(?:[a-z])(?:[A-Z])/g, "$1 $2");

  // Capitalize the first letter and return
  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
};

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getInitials = (
  email: string | null,
  firstName: string | null,
  lastName: string | null,
): string => {
  const firstInitial = firstName?.charAt(0) || email?.charAt(0);
  if (!firstInitial) return ""; // If we only have a phone number for this contact

  const secondInitial = lastName?.charAt(0);
  return `${firstInitial}${secondInitial || ""}`;
};

export function isInIframe(): boolean {
  if (typeof window === "undefined") return false;
  try {
    // If window.self and window.top can be compared without a security error,
    // and are different, we're in an iframe.
    return window.self !== window.top;
  } catch (err) {
    // A cross-origin iframe comparison can throw, which also means we're in an iframe.
    return true;
  }
}

/*
 * Types
 */

export enum SizesEnum {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

// FOr displaying "1st, 2nd etc."
export const getOrdinalForNumber = (n: number): string => {
  const suffixes = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};

export function renderPlural(
  count: number,
  variants: {
    zero?: ReactNode;
    one: ReactNode;
    other: (count: number) => ReactNode;
  },
): ReactNode {
  if (count === 0) {
    return variants.zero ?? variants.other(count);
  }

  if (count === 1) {
    return variants.one;
  }

  return variants.other(count);
}
