import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { FC, type ReactNode } from "react";
import { Dialog, Header } from "react-aria-components";
import { tv } from "tailwind-variants";
import { Button, ButtonVariantsEnum } from "../Buttons/button";

export interface AlertDialogProps {
  variant?: AlertDialogVariant;
  title: ReactNode;
  children?: ReactNode;
  confirmText: ReactNode;
  isConfirming?: boolean;
  onConfirm?: (context: { close: () => void }) => void;
}

export const AlertDialogVariant = {
  /** Ask the user to confirm their action. */
  Confirm: "CONFIRM",
  /** Warn the user about the impact of their action. */
  Warning: "WARNING",
  /** Special variant to confirm hanging up / ending a call. */
  CallEnd: "CALL_END",
};

export type AlertDialogVariant =
  (typeof AlertDialogVariant)[keyof typeof AlertDialogVariant];

const icon = tv({
  slots: {
    circle:
      "flex size-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:size-10",
    icon: "size-6",
  },
  variants: {
    variant: {
      [AlertDialogVariant.Confirm]: {
        circle: "bg-indigo-100",
        icon: "text-indigo-600",
      },
      [AlertDialogVariant.Warning]: {
        circle: "bg-red-100",
        icon: "text-red-600",
      },
      [AlertDialogVariant.CallEnd]: {
        circle: "bg-red-100",
        icon: "text-red-600",
      },
    },
  },
  defaultVariants: {
    variant: AlertDialogVariant.Confirm,
  },
});

const confirmButtonVariants: Record<AlertDialogVariant, ButtonVariantsEnum> = {
  [AlertDialogVariant.Confirm]: ButtonVariantsEnum.Primary,
  [AlertDialogVariant.Warning]: ButtonVariantsEnum.Warning,
  [AlertDialogVariant.CallEnd]: ButtonVariantsEnum.CallEnd,
};

const iconRenderers: Record<
  AlertDialogVariant,
  (props: { className: string }) => ReactNode
> = {
  [AlertDialogVariant.Confirm]: (props) => <InformationCircleIcon {...props} />,
  [AlertDialogVariant.Warning]: (props) => (
    <ExclamationTriangleIcon {...props} />
  ),
  [AlertDialogVariant.CallEnd]: (props) => <PhoneIcon {...props} />,
};

// TODO: Make more versatile so it can be used for non-destructive alerts.
export const AlertDialog: FC<AlertDialogProps> = (props) => {
  const { variant = AlertDialogVariant.Confirm, onConfirm } = props;

  return (
    <Dialog role="alertdialog" className="p-4 sm:p-6 space-y-4">
      {({ close }) => (
        <>
          <div className="flex flex-col items-center sm:flex-row sm:items-start gap-4">
            <div className={icon({ variant }).circle()}>
              {iconRenderers[variant]({ className: icon({ variant }).icon() })}
            </div>

            <div className="space-y-4">
              <div className="space-y-2">
                <Header
                  slot="title"
                  className="text-base font-semibold text-gray-900"
                >
                  {props.title}
                </Header>

                {props.children ? (
                  <div className="text-sm text-gray-500">{props.children}</div>
                ) : null}
              </div>

              <div className="flex items-center justify-end space-x-2">
                <Button slot="close" variant={ButtonVariantsEnum.Secondary}>
                  Cancel
                </Button>

                <Button
                  variant={confirmButtonVariants[variant]}
                  isLoading={props.isConfirming}
                  onClick={onConfirm ? () => onConfirm({ close }) : undefined}
                >
                  {props.confirmText}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Dialog>
  );
};
