import { Menu, Transition } from "@headlessui/react";
import { FC, Fragment } from "react";
import { twJoin } from "tailwind-merge";

export interface DropdownItem {
  label: string;
  onClick: () => void;
}

interface DropdownMenuProps {
  items: DropdownItem[];
  align?: "left" | "right";
  position?: "top" | "bottom";
}

export const DropdownMenu: FC<DropdownMenuProps> = ({
  items,
  align = "right",
  position = "bottom",
}) => {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className={twJoin(
          "absolute z-20 w-56 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
          align === "right" ? "right-0" : "left-0",
          position === "top" ? "bottom-full mb-2" : "top-full mt-2",
        )}
      >
        {items.map((i) => {
          return (
            <Menu.Item key={`dropdown-item-${i.label}`}>
              {({ active }: { active: boolean }) => (
                <button
                  className={twJoin(
                    active ? "bg-gray-100" : "",
                    "block w-full text-left px-4 py-2 text-sm text-gray-700 cursor-pointer",
                  )}
                  onClick={i.onClick}
                  type="button"
                >
                  {i.label}
                </button>
              )}
            </Menu.Item>
          );
        })}
      </Menu.Items>
    </Transition>
  );
};
