import { CheckCircleIcon } from "@heroicons/react/24/outline";
import {
  Button,
  ButtonVariantsEnum,
  Label,
  ModalVariantsEnum,
  SimpleModal,
  SizesEnum,
  capitalize,
} from "@repo/ui";
import {
  Integration,
  IntegrationProvider,
  PermissionActions,
  PermissionSubjects,
} from "app-types";
import { FC, useState } from "react";
import { Can } from "../../../helpers/teammateAuthorizationContext";
import { openOAuthSigninPopupForIntegration } from "../../../helpers/urlHelpers";
import { useAppDispatch } from "../../../hooks/hook";
import { deleteIntegration } from "../../integrations/integrationsSlice";

export const OAuthIntegrationButtonsRow: FC<{ integration: Integration }> = ({
  integration,
}) => {
  const dispatch = useAppDispatch();
  const [isRemoveAccountModalOpen, setIsRemoveAccountModalOpen] =
    useState(false);

  return (
    <div>
      <Label size={SizesEnum.SMALL}>Account</Label>
      <div className="flex flex-row space-x-3 items-center text-sm">
        <div className="flex flex-row space-x-1 items-center">
          <CheckCircleIcon className="h-5 w-5 text-green-500" />
          <div>
            {capitalize(integration.provider)} account successfully connected
          </div>
        </div>
        <Can
          I={PermissionActions.UPDATE}
          a={PermissionSubjects.INTEGRATIONS}
          passThrough={true}
        >
          {(allowed) => (
            <Button
              onClick={() =>
                openOAuthSigninPopupForIntegration(integration.provider)
              }
              size={SizesEnum.SMALL}
              variant={ButtonVariantsEnum.Secondary}
              isDisabled={!allowed}
            >
              Re-authenticate
            </Button>
          )}
        </Can>
        <Can
          I={PermissionActions.DELETE}
          a={PermissionSubjects.INTEGRATIONS}
          passThrough={true}
        >
          {(allowed) => (
            <Button
              onClick={() => {
                setIsRemoveAccountModalOpen(true);
              }}
              size={SizesEnum.SMALL}
              variant={ButtonVariantsEnum.Warning}
              isDisabled={!allowed}
            >
              Remove
            </Button>
          )}
        </Can>
      </div>
      <SimpleModal
        isOpen={isRemoveAccountModalOpen}
        onCancel={() => {
          setIsRemoveAccountModalOpen(false);
        }}
        variant={ModalVariantsEnum.Warning}
        title="Are you sure?"
        subtitle={getRemoveAccountWarningMessage(integration.provider)}
        confirmButtonText="Remove account"
        onConfirm={() => {
          dispatch(deleteIntegration(integration.id));

          setIsRemoveAccountModalOpen(false);
        }}
      />
    </div>
  );
};

function getRemoveAccountWarningMessage(provider: IntegrationProvider) {
  switch (provider) {
    case "salesforce":
      return "Removing your Salesforce account will stop all data from syncing.";
    case "slack":
      return "Removing your account will stop all interview notifications from being sent to Slack.";
    default:
      return "";
  }
}
