import {
  type BasicQuestion,
  type Company,
  type InterviewLanguagesEnum,
  type LanguageVoice,
  type Project,
  type ResumeCollectionTypesEnum,
  splitProjectQuestions,
} from "..";
import {
  stringifyMultilineContent,
  stringifyVariableAsPlaceholder,
} from "./../content/plaintext";

export interface ProjectPublicAPI {
  id: string;
  name: string;
}

export const ProjectLinkAccess = {
  Default: null,
  ExistingOnly: "existing-only",
} as const;

export type ProjectLinkAccess =
  (typeof ProjectLinkAccess)[keyof typeof ProjectLinkAccess];

export enum JobTypePropertyEnum {
  AssessmentId = "assessment_id",
  Questions = "questions",
  PromptContext = "prompt_context",
  JobDescription = "job_description",
  Language = "language",
  LanguageVoice = "language_voice",
  AutoAdvanceAssessmentScoreThreshold = "auto_advance_assessment_score_threshold",
  ShouldForceFullscreen = "should_force_fullscreen",
  ShouldRecordVideo = "should_record_video",
  ResumeCollectionType = "resume_collection_type",
  WelcomeMessage = "welcome_message",
  OutroMessage = "outro_message",
  RedirectUrl = "redirect_url",
}

export enum SpecialProjectCustomFieldNamesEnum {
  AdvancedNextSteps = "advanced_next_steps",
}

export function getProjectAndJobTypeProperty(
  project: Project,
  propertyName: JobTypePropertyEnum.Questions,
): BasicQuestion[];
export function getProjectAndJobTypeProperty(
  project: Project,
  propertyName:
    | JobTypePropertyEnum.PromptContext
    | JobTypePropertyEnum.JobDescription
    | JobTypePropertyEnum.AssessmentId
    | JobTypePropertyEnum.WelcomeMessage
    | JobTypePropertyEnum.OutroMessage
    | JobTypePropertyEnum.RedirectUrl,
): string | null;
export function getProjectAndJobTypeProperty(
  project: Project,
  propertyName: JobTypePropertyEnum.Language,
): InterviewLanguagesEnum;
export function getProjectAndJobTypeProperty(
  project: Project,
  propertyName: JobTypePropertyEnum.LanguageVoice,
): LanguageVoice | null;
export function getProjectAndJobTypeProperty(
  project: Project,
  propertyName: JobTypePropertyEnum.AutoAdvanceAssessmentScoreThreshold,
): number | null;
export function getProjectAndJobTypeProperty(
  project: Project,
  propertyName:
    | JobTypePropertyEnum.ShouldForceFullscreen
    | JobTypePropertyEnum.ShouldRecordVideo,
): boolean;
export function getProjectAndJobTypeProperty(
  project: Project,
  propertyName: JobTypePropertyEnum.ResumeCollectionType,
): ResumeCollectionTypesEnum | null;
export function getProjectAndJobTypeProperty(
  project: Project,
  propertyName: JobTypePropertyEnum,
) {
  if (project.job_type) {
    const jobType = project.job_type;
    switch (propertyName) {
      case JobTypePropertyEnum.Questions:
        return jobType.questions;
      case JobTypePropertyEnum.PromptContext:
        return jobType.prompt_context
          ? stringifyMultilineContent(jobType.prompt_context, {
              stringifyVariable: stringifyVariableAsPlaceholder,
            })
          : null;
      case JobTypePropertyEnum.JobDescription:
        return stringifyMultilineContent(jobType.job_description, {
          stringifyVariable: stringifyVariableAsPlaceholder,
        });
      case JobTypePropertyEnum.Language:
        return jobType.settings.interview_languages[0];
      case JobTypePropertyEnum.LanguageVoice:
        return jobType.settings.language_voice;
      case JobTypePropertyEnum.AutoAdvanceAssessmentScoreThreshold:
        return jobType.settings.auto_advance_assessment_score_threshold;
      case JobTypePropertyEnum.ShouldForceFullscreen:
        return jobType.settings.should_force_fullscreen;
      case JobTypePropertyEnum.ShouldRecordVideo:
        return jobType.settings.should_record_video;
      case JobTypePropertyEnum.ResumeCollectionType:
        return jobType.settings.resume_collection_type;
      case JobTypePropertyEnum.AssessmentId:
        return jobType.assessment_id;
      case JobTypePropertyEnum.WelcomeMessage:
        return jobType.settings.welcome_message;
      case JobTypePropertyEnum.OutroMessage:
        return jobType.settings.outro_message;
      case JobTypePropertyEnum.RedirectUrl:
        return jobType.settings.redirect_url;
      default:
        throw new Error(`Invalid property name`);
    }
  }

  // TODO: When we've fully moved over to job type, remove fallback to project settings
  switch (propertyName) {
    case JobTypePropertyEnum.Questions:
      return splitProjectQuestions(project.questions).basicQuestions;
    case JobTypePropertyEnum.PromptContext:
      return project.settings.prompt_context;
    case JobTypePropertyEnum.JobDescription:
      return project.job_description;
    case JobTypePropertyEnum.Language:
      return project.settings.interview_language;
    case JobTypePropertyEnum.LanguageVoice:
      return project.settings.language_voice;
    case JobTypePropertyEnum.AutoAdvanceAssessmentScoreThreshold:
      return project.settings.auto_advance_assessment_score_threshold;
    case JobTypePropertyEnum.ShouldForceFullscreen:
      return project.settings.should_force_fullscreen;
    case JobTypePropertyEnum.ShouldRecordVideo:
      return project.settings.should_record_video;
    case JobTypePropertyEnum.ResumeCollectionType:
      return project.settings.resume_collection_type;
    case JobTypePropertyEnum.AssessmentId:
      return project.assessment_id;
    case JobTypePropertyEnum.WelcomeMessage:
      return project.settings.welcome_message;
    case JobTypePropertyEnum.OutroMessage:
      return project.settings.outro_message;
    case JobTypePropertyEnum.RedirectUrl:
      return project.settings.redirect_url;
    default:
      throw new Error(`Invalid property name`);
  }
}

export function isValidJobOpeningLocation(location: unknown): boolean {
  const loc = location as { description?: unknown };
  return Boolean(
    location &&
      typeof location === "object" &&
      loc.description &&
      typeof loc.description === "string",
  );
}

export function isValidJobOpeningPay(pay: unknown): boolean {
  const p = pay as { description?: unknown };
  return Boolean(
    pay &&
      typeof pay === "object" &&
      p.description &&
      typeof p.description === "string",
  );
}

export function isValidJobOpeningHours(hours: unknown): boolean {
  const h = hours as { description?: unknown };
  return Boolean(
    hours &&
      typeof hours === "object" &&
      h.description &&
      typeof h.description === "string",
  );
}

export function getInterviewLinkForProject(
  interviewAppBaseUrl: string,
  company: Company,
  project: Project,
) {
  // Fully whitelabeled company.
  if (company.settings.whitelabel_domain)
    return `${company.settings.whitelabel_domain}/i/${project.short_id}`;

  // Standard Alpharun interview link.
  return `${interviewAppBaseUrl}/${project.short_id}`;
}
