import { FC, ReactNode } from "react";
import { twJoin } from "tailwind-merge";

export interface SettingsTableHeaderCellProps {
  className?: string;
  colSpan?: number;
  children: ReactNode;
}

export const SettingsTableHeaderCell: FC<SettingsTableHeaderCellProps> = (
  props,
) => (
  <th
    scope="col"
    colSpan={props.colSpan}
    className={twJoin(
      "px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",
      props.className,
    )}
  >
    {props.children}
  </th>
);
