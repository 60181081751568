import { EnvelopeIcon } from "@heroicons/react/24/outline";
import {
  renderMultilineContent,
  renderSingleLineContent,
} from "@repo/ui/content/renderers";
import { MessageTemplate } from "app-types";
import { FC } from "react";
import { twJoin } from "tailwind-merge";

export interface MessageTemplateCardProps {
  template: MessageTemplate | undefined;
}

export const MessageTemplateCard: FC<MessageTemplateCardProps> = ({
  template,
}) => {
  return (
    <div
      className={twJoin(
        "max-w-72 overflow-hidden flex flex-row items-center space-x-3",
        template ? " text-gray-900" : " text-gray-500",
      )}
    >
      <EnvelopeIcon className="size-4 shrink-0" />
      {template ? (
        <div className="flex flex-col overflow-hidden">
          <div className="text-sm leading-5 truncate">
            {renderSingleLineContent(template.subject)}
          </div>
          <div className="text-xs leading-5 text-gray-500 line-clamp-2">
            {renderMultilineContent(template.body)}
          </div>
        </div>
      ) : (
        <div className="text-sm leading-10">Select a message template</div>
      )}
    </div>
  );
};
