import { XMarkIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { Button as AriaButton } from "react-aria-components";

export interface ModalCloseButtonProps {
  onClick?: () => void;
}

// The outer <div> avoids the negative margin from being overridden.
export const ModalCloseButton: FC<ModalCloseButtonProps> = (props) => (
  <div className="size-5 flex-none">
    <AriaButton
      slot="close"
      className="-m-2 p-2 text-gray-400"
      aria-label="Close"
      onPress={props.onClick}
    >
      <XMarkIcon className="size-5 flex-none" />
    </AriaButton>
  </div>
);
