import { LightBulbIcon } from "@heroicons/react/24/outline";
import { Button, ButtonVariantsEnum, SizesEnum } from "@repo/ui";
import { FC, ReactNode } from "react";
import { Dialog, DialogTrigger, Popover } from "react-aria-components";
import { SuggestionsPill } from "./suggestionsPill";

export interface SuggestionsWidgetProps {
  isLoading?: boolean;
  isFetching?: boolean;
  summary?: ReactNode;
  suggestions: readonly ReactNode[] | undefined;
  onRefreshClick?: () => void;
}

export const SuggestionsWidget: FC<SuggestionsWidgetProps> = (props) => {
  return (
    <DialogTrigger>
      <SuggestionsPill
        isLoading={props.isLoading}
        count={props.suggestions?.length}
      />
      {props.summary || props.suggestions?.length ? (
        <Popover className="rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 data-[entering]:animate-popover-enter data-[exiting]:animate-popover-exit">
          <Dialog className="w-96 px-4 py-4 space-y-4 text-sm">
            {props.summary ? <div>{props.summary}</div> : null}
            {props.suggestions?.length ? (
              <ul className="space-y-2">
                {props.suggestions.map((suggestion, index) => (
                  <li key={index} className="flex items-top space-x-2">
                    <LightBulbIcon className="size-5 text-purple-700 shrink-0" />
                    <div>{suggestion}</div>
                  </li>
                ))}
              </ul>
            ) : null}
            {props.onRefreshClick ? (
              <Button
                variant={ButtonVariantsEnum.Secondary}
                size={SizesEnum.SMALL}
                isLoading={props.isFetching}
                isDisabled={props.isFetching}
                onClick={props.onRefreshClick}
              >
                Refresh
              </Button>
            ) : null}
          </Dialog>
        </Popover>
      ) : null}
    </DialogTrigger>
  );
};
