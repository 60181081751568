import { FC } from "react";
import { Label as AriaLabel } from "react-aria-components";
import { twJoin } from "tailwind-merge";
import { SizesEnum } from "../helpers/helpers";

interface LabelProps {
  size: SizesEnum;
  children: React.ReactNode;
  variant?: "default" | "secondary";
  className?: string;
}

export const Label: FC<LabelProps> = ({
  children,
  size,
  className,
  variant,
}) => {
  const baseClasses =
    "leading-6 block flex flex-row items-center font-semibold";

  const variantClasses =
    variant === "secondary"
      ? "text-gray-500 uppercase tracking-wider"
      : "text-gray-900";

  return (
    <AriaLabel
      className={twJoin(
        baseClasses,
        getSizeClasses(size),
        variantClasses,
        className,
      )}
    >
      {children}
    </AriaLabel>
  );
};

function getSizeClasses(size: SizesEnum): string {
  switch (size) {
    case SizesEnum.SMALL:
      return "text-sm";
    case SizesEnum.MEDIUM:
      return "text-md";
    case SizesEnum.LARGE:
      return "text-lg mb-2";
  }
}
