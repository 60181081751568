import { Menu } from "@headlessui/react";
import { VariableIcon } from "@heroicons/react/24/outline";
import { Button, ButtonVariantsEnum, DropdownMenu, SizesEnum } from "@repo/ui";
import {
  MessageTemplateType,
  messageTemplateTypeToVariables,
  messageTemplateVariableToLabel,
} from "app-types";
import { Fragment, useRef } from "react";

interface UseMessageVariablesProps<
  T extends HTMLInputElement | HTMLTextAreaElement,
> {
  type: MessageTemplateType;
  value: string;
  onChange: (value: string) => void;
}

export const useMessageVariables = <
  T extends HTMLInputElement | HTMLTextAreaElement,
>({
  type,
  value,
  onChange,
}: UseMessageVariablesProps<T>) => {
  const elementRef = useRef<T>(null);

  const insertPlaceholder = (placeholder: string) => {
    const element = elementRef.current;

    if (!element) {
      // If no element exists, append to end
      const newValue = value + `[${placeholder}]`;
      onChange(newValue);
      return;
    }

    const cursorPosition = element.selectionStart || 0;
    const newValue =
      value.slice(0, cursorPosition) +
      `[${placeholder}]` +
      value.slice(cursorPosition);

    onChange(newValue);

    // Maintain focus and set cursor position after the inserted placeholder
    const newPosition = cursorPosition + placeholder.length + 2; // +2 for [ and ]
    setTimeout(() => {
      element.focus();
      element.setSelectionRange(newPosition, newPosition);
    }, 0);
  };

  const dropdownItems = messageTemplateTypeToVariables[type].map(
    (variable) => ({
      label: messageTemplateVariableToLabel[variable],
      onClick: () => insertPlaceholder(variable),
    }),
  );

  const widget = (
    <Menu as="div" className="relative inline-block">
      <Menu.Button as={Fragment}>
        <Button
          icon={<VariableIcon className="w-4 h-4" />}
          size={SizesEnum.SMALL}
          variant={ButtonVariantsEnum.Secondary}
        >
          Add variable
        </Button>
      </Menu.Button>

      <DropdownMenu align="left" items={dropdownItems} />
    </Menu>
  );

  return {
    ref: elementRef,
    widget: widget,
  };
};
