import { Combobox } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { LoadingStatesEnum } from "app-types";
import { FC, KeyboardEvent, ReactNode, useMemo, useState } from "react";
import { Label } from "../Label/label";
import { SizesEnum } from "../helpers/helpers";

export enum SearchableSelectModesEnum {
  SINGLE = "single",
  MULTI = "multi",
}

export interface SearchableSelectOption {
  id: string;
  name: string;
}

interface BaseProps {
  options: SearchableSelectOption[];
  placeholder: string;
  label?: string;
  description?: string;
  errorDescription?: string;
  id?: string;
  isLoading?: LoadingStatesEnum;
  shouldDisableCustomOptions?: boolean;
  isDisabled?: boolean;
}

interface SingleSelectProps {
  mode: SearchableSelectModesEnum.SINGLE;
  selection: SearchableSelectOption | null;
  onChange: (value: SearchableSelectOption | null) => void;
}

interface MultiSelectProps {
  mode: SearchableSelectModesEnum.MULTI;
  selectedOptions: SearchableSelectOption[];
  onChange: (value: SearchableSelectOption[]) => void;
}

// Combine the base properties with the mode-specific properties using union types
type SearchableSelectProps = BaseProps & (SingleSelectProps | MultiSelectProps);

export const SearchableSelect: FC<SearchableSelectProps> = (props) => {
  const {
    mode,
    placeholder,
    label,
    onChange,
    options,
    description,
    errorDescription,
    id,
    shouldDisableCustomOptions,
    isDisabled,
  } = props;

  const selection = useMemo(
    () =>
      mode === SearchableSelectModesEnum.SINGLE
        ? (props as SingleSelectProps & BaseProps).selection
        : null,
    [mode, props],
  );

  const selectedOptions = useMemo(
    () =>
      mode === SearchableSelectModesEnum.MULTI
        ? (props as MultiSelectProps & BaseProps).selectedOptions
        : [],
    [mode, props],
  );

  const [query, setQuery] = useState("");

  const filteredOptions = useMemo(() => {
    const lowerQuery = query.toLowerCase().replace(/\s+/g, "");

    if (!query) return options;

    return options.filter((option) => {
      const optionAlreadySelected =
        (mode === SearchableSelectModesEnum.MULTI &&
          selectedOptions.some((o) => option.id === o.id)) ||
        (mode === SearchableSelectModesEnum.SINGLE && selection === option);

      return (
        !optionAlreadySelected &&
        option.name.toLowerCase().replace(/\s+/g, "").includes(lowerQuery)
      );
    });
  }, [options, selection, selectedOptions, query, mode]);

  const hasError = Boolean(errorDescription);

  const removeOption = (option: SearchableSelectOption): void => {
    // No X button for single mode.
    if (mode === SearchableSelectModesEnum.MULTI)
      onChange(selectedOptions.filter((o) => o.id !== option.id));
  };

  const handleBackspace = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (
      event.key === "Backspace" &&
      query === "" &&
      mode === SearchableSelectModesEnum.MULTI &&
      selectedOptions.length > 0
    ) {
      onChange(selectedOptions.slice(0, -1));
    }
  };

  const handleOnChange = (
    value: SearchableSelectOption | SearchableSelectOption[],
  ): void => {
    if (mode === SearchableSelectModesEnum.SINGLE) {
      onChange(value as SearchableSelectOption);
    } else {
      onChange(value as SearchableSelectOption[]);
    }
    setQuery("");
  };

  const descriptionClassNames = `
  mt-2 text-sm ${hasError ? "text-red-600" : "text-gray-600"}
`;
  const descriptionText = errorDescription || description;

  const renderComboboxContent = (): ReactNode => {
    return (
      <div className="relative mt-1">
        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
          <div className="flex flex-wrap items-center gap-2 p-1.5 pr-5">
            {mode === SearchableSelectModesEnum.MULTI &&
              selectedOptions.map((option) => (
                <span
                  className="flex items-center gap-1 rounded bg-gray-200 px-2 py-1 text-gray-800"
                  key={option.id}
                >
                  {option.name}
                  <button
                    onClick={() => {
                      removeOption(option);
                    }}
                    type="button"
                  >
                    <XMarkIcon aria-hidden="true" className="h-4 w-4" />
                  </button>
                </span>
              ))}
            {mode === SearchableSelectModesEnum.MULTI ? (
              <Combobox.Input
                autoComplete="off"
                className="flex-1 border-none py-1 pl-1 text-sm leading-5 text-gray-900 focus:ring-0"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setQuery(event.target.value);
                }}
                onKeyDown={handleBackspace}
                placeholder={placeholder}
                value={query}
              />
            ) : (
              <Combobox.Input
                autoComplete="off"
                className="flex-1 border-none py-1 pl-1 text-sm leading-5 text-gray-900 focus:ring-0"
                displayValue={(value: SearchableSelectOption | null) => {
                  if (value) return value.name;

                  return "";
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setQuery(event.target.value);
                }}
                placeholder={placeholder}
              />
            )}
          </div>
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              aria-hidden="true"
              className="h-5 w-5 text-gray-400"
            />
          </Combobox.Button>
        </div>
        <Combobox.Options
          className={`${
            query.length === 0 && filteredOptions.length === 0 ? "hidden" : ""
          } absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm`}
        >
          {!shouldDisableCustomOptions && query.length > 0 && (
            <Combobox.Option
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                  active ? "bg-gray-100 text-gray-900" : "text-gray-900"
                }`
              }
              value={{ id: query, name: query }}
            >
              {({ selected }: { selected: boolean }) => (
                <span
                  className={`block truncate ${
                    selected ? "font-medium" : "font-normal"
                  }`}
                >
                  Add &quot;{query}&quot;
                </span>
              )}
            </Combobox.Option>
          )}

          {shouldDisableCustomOptions &&
          filteredOptions.length === 0 &&
          query.length > 0 ? (
            <div className="py-2 pl-4 pr-4">No matches found</div>
          ) : null}

          {filteredOptions.map((option) => {
            return (
              <Combobox.Option
                className={({ active }: { active: boolean }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? "bg-gray-100 text-gray-900" : "text-gray-900"
                  }`
                }
                key={option.id}
                value={option}
              >
                {({
                  active,
                  selected,
                }: {
                  active: boolean;
                  selected: boolean;
                }) => {
                  return (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {option.name}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? "text-gray-900" : "text-blue-600"
                          }`}
                        >
                          <CheckIcon aria-hidden="true" className="h-5 w-5" />
                        </span>
                      ) : null}
                    </>
                  );
                }}
              </Combobox.Option>
            );
          })}
        </Combobox.Options>
      </div>
    );
  };

  return (
    <>
      {label ? <Label size={SizesEnum.SMALL}>{label}</Label> : null}
      {/* TODO: use "immediate" prop once its in a stable headlessui release (v 2.0) https://github.com/tailwindlabs/headlessui/pull/2686 */}
      {mode === SearchableSelectModesEnum.SINGLE ? (
        <Combobox
          by="id"
          onChange={handleOnChange}
          value={selection}
          disabled={isDisabled}
        >
          {renderComboboxContent()}
        </Combobox>
      ) : (
        <Combobox
          by="id"
          multiple
          onChange={handleOnChange}
          value={selectedOptions}
          disabled={isDisabled}
        >
          {renderComboboxContent()}
        </Combobox>
      )}

      {descriptionText ? (
        <p className={descriptionClassNames} id={`${id}-description`}>
          {descriptionText}
        </p>
      ) : null}
    </>
  );
};
