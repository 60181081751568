import type { InterviewApiInterviewSectionResult } from "app-types/entities/interviewSection/result";
import type {
  AttachmentPublic,
  ComplexQuestion,
  ComplexQuestionInsert,
  ComplexQuestionType,
  IdentifiedBasicContact,
  InterviewAgentTypesEnum,
  InterviewLanguagesEnum,
  InterviewQuestion,
  InterviewStatus,
  LineChartDataSegment,
  MatrixQuestionPublic,
  MultipleChoiceQuestionPublic,
  ProjectLinkAccess,
  ProjectModesEnum,
  ResumeCollectionTypesEnum,
  SurveyScale,
  TranscriptFragmentRolesEnum,
} from "..";

/*
 * Enums.
 */

export enum IncentiveProviderType {
  Amazon = "AMAZON",
}

export function getRatingsFromSurveyScale(surveyScale: SurveyScale) {
  switch (surveyScale) {
    case "important_unimportant":
      return [
        {
          name: "Not at all important",
          rating: 1,
        },
        {
          name: "Not so important",
          rating: 2,
        },
        {
          name: "Somewhat important",
          rating: 3,
        },
        {
          name: "Very important",
          rating: 4,
        },
        {
          name: "Extremely important",
          rating: 5,
        },
      ];
    case "agree_disagree":
      return [
        {
          name: "Strongly disagree",
          rating: 1,
        },
        {
          name: "Disagree",
          rating: 2,
        },
        {
          name: "Neutral",
          rating: 3,
        },
        {
          name: "Agree",
          rating: 4,
        },
        {
          name: "Strongly agree",
          rating: 5,
        },
      ];
    case "satisfied_dissatisfied":
      return [
        {
          name: "Very dissatisfied",
          rating: 1,
        },
        {
          name: "Dissatisfied",
          rating: 2,
        },
        {
          name: "Neutral",
          rating: 3,
        },
        {
          name: "Satisfied",
          rating: 4,
        },
        {
          name: "Very satisfied",
          rating: 5,
        },
      ];
    default:
      return [];
  }
}

export const ComplexQuestionTypeToString: Record<ComplexQuestionType, string> =
  {
    rating: "Rating scale",
    multiple_choice: "Multiple choice",
  };

export type SurveyInsights = MatrixQuestionInsights | MultipleChoiceInsights;

export interface MatrixQuestionInsights {
  question: string;
  scale: SurveyScale;
  response_count: number;
  type: "rating";
  data: RatingSurveyDataPoint[];
}

export interface RatingSurveyDataPoint {
  name: string;
  // The key is the rating, the value is the count of that rating
  // e.g. 1: 12 means 12 people rated this as 1
  ratings: Record<number, number>;
}

export interface MultipleChoiceInsights {
  question: string;
  type: "multiple_choice";
  response_count: number;
  data: MultipleChoiceDataPoint[];
}

export interface MultipleChoiceDataPoint {
  name: string;
  response_count: number;
}

export enum TrendDataTypesEnum {
  SENTIMENT = "sentiment",
}

export interface TrendData {
  type: TrendDataTypesEnum;
  data: LineChartDataSegment[];
}

// Use `category:object_event` naming convention
export enum PosthogEventTypesEnum {
  ProjectCreate = "projects_list:project_create",
  ProjectLaunch = "project_configuration:project_launch",
  ParticipantsInvite = "project_configuration:participants_invite",
  TeammatesInvite = "company_settings:teammates_invite",
  TeammateDomainLogin = "signup_flow:teammate_domainlogin",
  InterviewStart = "interview_app:interview_start",
  InterviewEnd = "interview_app:interview_end",
  TrialContactUs = "trial:contactus_click",
  /** @deprecated Superseded by InterviewError with interview_error_type = unsupported_browser. */
  InterviewUnsupportedBrowser = "interview_app:interview_unsupported_browser",
  /** The interview error page was shown. */
  InterviewError = "interview_app:interview_error",
  /** The interview onboarding form was shown. */
  InterviewOnboardingStarted = "interview_app:interview_onboarding_started",
  /** The user submitted the interview onboarding form. */
  InterviewOnboardingSubmitted = "interview_app:interview_onboarding_submitted",
  /** The reCAPTCHA was verified after the user submitted the onboarding form. */
  InterviewOnboardingVerified = "interview_app:interview_onboarding_verified",
  /** An error occurred trying to set up microphone and/or camera. */
  InterviewDeviceError = "interview_app:device_error",
  /** The voice agent interviewer was shown. */
  InterviewStarted = "interview_app:interview_started",
  /** Microphone and/or camera have been set up. */
  InterviewDevicesSetup = "interview_app:devices_setup",
  /** An interview call is starting. */
  InterviewCallInitiating = "interview_app:call_initiating",
  /** An interview call has been initiated. */
  InterviewCallInitiated = "interview_app:call_initiated",
  /** An interview call has started. */
  InterviewCallStarted = "interview_app:call_started",
  /** An interview call has stopped. */
  InterviewCallEnded = "interview_app:call_ended",
  /** The interview completion page was shown. */
  InterviewCompleted = "interview_app:interview_completed",
  /** We started processing the Retell call. Used by the API on behalf of the interview app. */
  RetellCallProcessing = "interview_app:retell_call_processing",
  /** We started processing the interview insights. Used by the Lambda on behalf of the interview app. */
  InterviewInsightsProcessing = "interview_app:insights_processing",
  InterviewFeedbackSubmit = "interview_slideover:feedback_submit",
  InterviewEmptyAudioResponse = "interview_app:empty_audio_response",
  CallFailedToInitialize = "interview_app:call_failed_to_initialize",
  CompletedInterviewProcessed = "completed_interview_processed",
}

/*
 * Types.
 */

export interface BasicQuestion {
  question: string;
}

export type ProjectQuestion =
  | BasicQuestion
  | ComplexQuestion
  | ComplexQuestionInsert;

export type ComplexQuestionPublic =
  | MatrixQuestionPublic
  | MultipleChoiceQuestionPublic;

export type ProjectQuestionPublic = BasicQuestion | ComplexQuestionPublic;

export interface PhoneNumberWithExtensionPublic {
  phone_number: string;
  extension: string;
}

export interface ProjectPublic {
  company_name: string;
  logo_url: string | null;
  favicon_url: string | null;
  redirect_url: string | null;
  incentive_quantity: number | null;
  short_id: string;
  questions: ProjectQuestionPublic[];
  has_sections: boolean;
  has_sensitive_data: boolean;
  interview_color: string | null;
  welcome_message: string | null;
  outro_message: string | null;
  mode: ProjectModesEnum | null;
  interview_agent_type: InterviewAgentTypesEnum;
  resume_collection_type: ResumeCollectionTypesEnum | null;
  interview_language: InterviewLanguagesEnum;
  should_force_fullscreen: boolean;
  should_record_video: boolean;
  phone_number_with_extension: PhoneNumberWithExtensionPublic | null;
  link_access: ProjectLinkAccess;
}

// The interview object that the interview client uses
export interface InterviewPublic {
  id: string;
  questions: InterviewQuestion[];
  incomplete_sections: InterviewApiInterviewSectionResult[];
  status: InterviewStatus;
  contact: IdentifiedBasicContact;
  attachments: AttachmentPublic[];
}

export enum TranscriptFragmentSaveStatusEnum {
  IDLE = "idle",
  SAVING = "saving",
  SAVED = "saved",
  ERROR = "error",
}

// Used in the interview client
export interface TranscriptFragmentPublicJSON {
  id: string;
  start_time: string;
  end_time: string;
  question: string | null;
  is_dynamic_question: boolean;
  save_status: TranscriptFragmentSaveStatusEnum;
}

// Used in the admin app client
export interface TranscriptFragment {
  id: string;
  interview_id: string;
  interview_section_result_id?: string;
  project_id: string;
  start_time: string;
  end_time: string;
  question: string | null;
  is_dynamic_question: boolean;
  dynamic_transcript_fragment_ids: string[];
  save_status: TranscriptFragmentSaveStatusEnum;
  text_transcript: string | null;
  text_transcript_translations?: Partial<
    Record<InterviewLanguagesEnum, string>
  >;
  role: TranscriptFragmentRolesEnum | null;
}

export enum LoadingStatesEnum {
  LOADING = "loading",
  LOADED = "loaded",
  ERROR = "error",
}

export enum InterviewDeviceTypesEnum {
  MICROPHONE = "microphone",
  CAMERA = "camera",
}
