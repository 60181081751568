import { FC, useEffect, useState } from "react";

export const TextCarouselLoader: FC<{ messages: string[] }> = ({
  messages,
}) => {
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);

  useEffect(() => {
    const updateMessage = (): void => {
      if (loadingMessageIndex < messages.length - 1) {
        setLoadingMessageIndex(loadingMessageIndex + 1);
      }
    };

    // Only set a new timeout if we haven't displayed all messages
    if (loadingMessageIndex < messages.length - 1) {
      // Generate a random interval time between 2000 and 5000 milliseconds
      const randomInterval = Math.floor(Math.random() * 3000) + 2000;
      const timeoutId = setTimeout(updateMessage, randomInterval);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [loadingMessageIndex, messages.length]);

  return (
    <div
      className="font-semibold mb-4 text-sm animate-fadeIn"
      key={`loading-message-${loadingMessageIndex}`}
    >
      {messages[loadingMessageIndex]}
    </div>
  );
};
