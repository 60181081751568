import posthog from "posthog-js";
import { useSyncExternalStore } from "react";

// This should be stable or else `useSyncExternalStore` re-subscribes.
const subscribe = (callback: () => void): (() => void) =>
  posthog.onFeatureFlags(() => {
    callback();
  });

export function useIsFeatureFlagEnabled(key: string): boolean | undefined {
  return useSyncExternalStore(subscribe, () => posthog.isFeatureEnabled(key));
}
