import { FC } from "react";
import { twJoin } from "tailwind-merge";

interface ReadOnlyBulletedListProps {
  items: string[];
  maxItemsToDisplay?: number;
  isDisabled?: boolean;
  onListClick?: () => void;
  itemDescriptor?: string;
  icon?: string | JSX.Element;
}

export const ReadOnlyBulletedList: FC<ReadOnlyBulletedListProps> = ({
  items,
  maxItemsToDisplay,
  isDisabled,
  onListClick,
  itemDescriptor = "item",
  icon = "•",
}) => {
  const shouldTruncate =
    maxItemsToDisplay && items.length > maxItemsToDisplay + 1;
  const displayItems = shouldTruncate
    ? items.slice(0, maxItemsToDisplay)
    : items;
  const excessItems = items.length - (maxItemsToDisplay || 0);

  const onContainerClick = !isDisabled ? onListClick : undefined;
  const containerClasses = onContainerClick
    ? "cursor-pointer inline-block group"
    : "group";

  return (
    <div
      className={containerClasses}
      aria-disabled={isDisabled}
      onClick={onContainerClick}
    >
      {displayItems.map((item, index) => (
        <div
          key={index}
          className={twJoin(
            "border-b border-l border-r max-w-full flex justify-between items-center px-3 py-2 text-sm relative bg-white",
            !isDisabled ? "group-hover:bg-blue-50" : null,
            index === 0 ? "border-t rounded-t-lg" : null,
            !shouldTruncate && index === items.length - 1
              ? "rounded-b-lg"
              : null,
          )}
        >
          <div className="flex items-center">
            <span className="text-gray-500 mr-2">
              {typeof icon === "string" ? icon : icon}
            </span>
            <span>{item}</span>
          </div>
        </div>
      ))}
      {shouldTruncate && (
        <div
          className={twJoin(
            "border-b border-l border-r max-w-full flex justify-center items-center px-3 py-2 text-sm relative bg-white rounded-b-lg cursor-pointer text-blue-500",
            !isDisabled ? "group-hover:bg-blue-50" : null,
          )}
        >
          +{excessItems} more {itemDescriptor}
        </div>
      )}
    </div>
  );
};
