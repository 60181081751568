import {
  Contact,
  Interview,
  TranscriptFragment,
  getContactDisplayNames,
  getShareLinkForInterview,
  splitSummaryTextIntoLines,
} from "app-types";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonVariantsEnum,
  Checkbox,
  Link,
  Modal,
  ModalHeader,
} from "@repo/ui";
import { copyRichText } from "../../../helpers/utilities";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  fetchCompanyDomains,
  selectCompanyDomainsState,
} from "../../company/companyDomainsSlice";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { selectProjectsDictionary } from "../../projects/projectsSlice";

interface ShareInterviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  contact: Contact;
  interview: Interview;
  transcriptFragment: TranscriptFragment | null;
}

export const ShareInterviewModal: FC<ShareInterviewModalProps> = ({
  isOpen,
  onClose,
  interview,
  transcriptFragment,
  contact,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // Whether we should include the interview summary or transcript fragment quote
  const [shouldShareDetails, setShouldShareDetails] = useState(true);

  const project = useAppSelector(selectProjectsDictionary)[
    interview.project_id
  ];

  const { domains: companyDomains } = useAppSelector(selectCompanyDomainsState);

  // Fetch the company domains so we can show guidance on how to share the interview.
  useEffect(() => {
    dispatch(fetchCompanyDomains());
  }, []);

  // Should not happen
  if (!project) return null;

  // Create the share link
  const { primaryDisplayName } = getContactDisplayNames(contact);
  const link = getShareLinkForInterview({
    baseUrl: window.location.origin,
    projectId: project.id,
    interviewId: interview.id,
    primaryDisplayName: primaryDisplayName,
    transcriptFragmentId: transcriptFragment
      ? transcriptFragment.id
      : undefined,
  });

  // Create the share text snippet
  const shareSnippet = generateShareSnippet(
    primaryDisplayName,
    interview,
    transcriptFragment,
    link,
    shouldShareDetails,
  );

  const renderDomainLoginGuidance = () => {
    if (companyDomains.length === 0) {
      return (
        <span>
          {" "}
          Add{" "}
          <span
            className="cursor-pointer font-semibold"
            onClick={() => {
              navigate("/settings/teammates");
            }}
          >
            approved signup domains
          </span>{" "}
          to allow teammates to sign in with their work email to view the full
          interview transcript.
        </span>
      );
    }

    const domainList =
      companyDomains.length > 1
        ? companyDomains.slice(0, -1).join(", ") +
          ", or " +
          companyDomains[companyDomains.length - 1]
        : companyDomains[0];

    return (
      <span>
        {" "}
        Teammates with a matching {domainList} email can sign in to view the
        full transcript in Alpharun.
      </span>
    );
  };

  return (
    <Modal isOpen={isOpen} onCancel={onClose}>
      <ModalHeader onClose={onClose}>Share interview</ModalHeader>

      <div className="rounded-md text-xs bg-slate-50 py-2 px-2 border border-solid border-slate-300 w-full flex space-x-2 hover:bg-slate-100 cursor-text mt-4">
        {shareSnippet.jsx}
      </div>

      <div className="text-sm mt-2 text-slate-600">
        <span>Paste this snippet into Slack, Teams, or emails to share.</span>
        {renderDomainLoginGuidance()}
      </div>

      <div className="flex space-x-4 mt-4">
        <Button
          variant={ButtonVariantsEnum.Primary}
          onClick={async () => {
            await copyRichText(shareSnippet.richText, shareSnippet.plainText);

            showNotification(dispatch, {
              id: `interview-${interview?.id}-copied-${new Date().getTime()}`,
              primaryMessage: `Share text copied`,
              type: NotificationTypeEnum.SUCCESS,
            });
          }}
        >
          Copy
        </Button>
        <Checkbox
          isChecked={shouldShareDetails}
          onChange={setShouldShareDetails}
          label={transcriptFragment ? "Include quote" : "Include summary"}
        />
      </div>
    </Modal>
  );
};
// Generates share snippet in JSX, plaintext, and richtext formats.
function generateShareSnippet(
  primaryDisplayName: string,
  interview: Interview,
  transcriptFragment: TranscriptFragment | null,
  link: string,
  shouldShareDetails: boolean,
) {
  // If we're not sharing details, or we're trying to share the summary but don't have one,
  // and also no strengths and weaknesses, just return the link.
  if (
    !shouldShareDetails ||
    (shouldShareDetails &&
      !transcriptFragment &&
      !interview.insights?.summary &&
      (!interview.insights?.strengths || !interview.insights?.weaknesses))
  ) {
    return {
      plainText: link,
      richText: link,
      jsx: <Link href={link}>{link}</Link>,
    };
  }

  const linkElement = <Link href={link}>See the full interview</Link>;

  if (transcriptFragment) {
    return {
      plainText: `Quote from ${primaryDisplayName}'s interview:\nQ: ${transcriptFragment.question}\nA: ${transcriptFragment.text_transcript}\n\nSee the full interview transcript and analysis:\n${link}`,
      richText:
        `<div>Quote from <strong>${primaryDisplayName}'s</strong> interview:<br />` +
        `Q: ${transcriptFragment.question}<br />` +
        `A: ${transcriptFragment.text_transcript}<br /><br />` +
        `<a href=${link} >See the full interview</a></div>`,
      jsx: (
        <div>
          Quote from <strong>{primaryDisplayName}'s</strong> interview:
          <br />
          Q: {transcriptFragment.question}
          <br />
          A: {transcriptFragment.text_transcript}
          <br />
          <br />
          {linkElement}
        </div>
      ),
    };
  } else if (interview.insights?.summary) {
    // Summary should always exist here because of the earlier check.
    const summary = interview.insights.summary || "";
    const summaryLines = splitSummaryTextIntoLines(summary);

    return {
      plainText: `Summary of interview with ${primaryDisplayName}:\n${summaryLines.join(
        "\n",
      )}\n\nSee the full interview transcript and analysis:\n${link}`,
      richText:
        `<div>Summary of interview with <strong>${primaryDisplayName}</strong>:<br />` +
        `${summaryLines.join("<br />")}<br /><br />` +
        `<a href=${link} >See the full interview</a></div>`,
      jsx: (
        <div>
          Summary of interview with <strong>{primaryDisplayName}</strong>:
          <br />
          {summary.split(". ").map((sentence, index) => (
            <div key={index}>
              - {sentence.trim()}
              {index !== summary.split(". ").length - 1 && (
                <>
                  {"."}
                  <br />
                </>
              )}
            </div>
          ))}
          <br />
          {linkElement}
        </div>
      ),
    };
  } else if (interview.insights?.strengths && interview.insights?.weaknesses) {
    // Add handling for strengths and weaknesses with summary line
    const strengths = interview.insights.strengths.join("\n");
    const weaknesses = interview.insights.weaknesses.join("\n");

    return {
      plainText: `Summary of interview with ${primaryDisplayName}:\nStrengths:\n${strengths}\n\nWeaknesses:\n${weaknesses}\n\nSee the full interview transcript and analysis:\n${link}`,
      richText:
        `<div>Summary of interview with <strong>${primaryDisplayName}</strong>:<br />` +
        `Strengths:<br />${strengths.replace(/\n/g, "<br />")}<br /><br />` +
        `Weaknesses:<br />${weaknesses.replace(/\n/g, "<br />")}<br /><br />` +
        `<a href=${link} >See the full interview</a></div>`,
      jsx: (
        <div>
          Summary of interview with <strong>{primaryDisplayName}</strong>:
          <br />
          Strengths:
          <br />
          {interview.insights.strengths.map((strength, index) => (
            <div key={index}>- {strength}</div>
          ))}
          <br />
          Weaknesses:
          <br />
          {interview.insights.weaknesses.map((weakness, index) => (
            <div key={index}>- {weakness}</div>
          ))}
          <br />
          {linkElement}
        </div>
      ),
    };
  } else {
    // Fallback to just the link if no details are available
    return {
      plainText: link,
      richText: link,
      jsx: <Link href={link}>{link}</Link>,
    };
  }
}
