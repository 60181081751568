import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { MAX_UPLOAD_FILE_SIZE_BYTES } from "app-types";
import { ChangeEvent, FC, useRef, useState } from "react";
import { Button, ButtonVariantsEnum } from "../Buttons/button";

interface FileUploadProps {
  onUpload: (file: File) => Promise<string>;
  isLoading?: boolean;
  label?: string;
  isDisabled?: boolean;
}

const IMAGE_FILE_TYPES = "image/jpeg,image/png";

export const FileUpload: FC<FileUploadProps> = ({
  onUpload,
  isLoading,
  label,
  isDisabled,
}) => {
  const [uploading, setUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      if (file.size > MAX_UPLOAD_FILE_SIZE_BYTES) {
        setErrorMessage("File size exceeds the 10MB limit.");
        return;
      }

      setUploading(true);
      setErrorMessage("");

      try {
        await onUpload(file);
      } catch (error) {
        setErrorMessage("Failed to upload file.");
      } finally {
        setUploading(false);
      }
    }
  };

  const handleButtonClick = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div>
      <Button
        icon={<ArrowUpTrayIcon className="w-4 h-4" />}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={handleButtonClick}
        variant={ButtonVariantsEnum.Secondary}
      >
        {label || "Upload"}
      </Button>
      <input
        accept={IMAGE_FILE_TYPES}
        className="sr-only"
        disabled={uploading}
        id="file-upload"
        name="file-upload"
        onChange={(e) => void handleFileChange(e)}
        ref={fileInputRef}
        type="file"
      />
      {errorMessage ? (
        <p className="text-sm text-red-500">{errorMessage}</p>
      ) : null}
    </div>
  );
};
