import { FC, ReactNode } from "react";
import { twJoin } from "tailwind-merge";
import { SizesEnum } from "../helpers/helpers";

export enum PillColorsEnum {
  BLUE = "blue",
  GREEN = "green",
  RED = "red",
  YELLOW = "yellow",
  GRAY = "gray",
  LIME = "lime",
}

interface PillProps {
  label: ReactNode;
  size: SizesEnum;
  color: PillColorsEnum;
  onClick?: () => void;
}

export const Pill: FC<PillProps> = ({ label, size, color, onClick }) => {
  const sharedClasses =
    "inline-flex items-center rounded-md font-medium ring-1 ring-inset h-fit";
  const generateSizeStyle = (pillSize: SizesEnum): string => {
    switch (pillSize) {
      case SizesEnum.SMALL:
        return "px-2 py-1 text-xs";

      case SizesEnum.MEDIUM:
        return "px-3 py-2 text-sm";

      case SizesEnum.LARGE:
        return "px-5 py-2 text-lg";
    }
  };

  const generateColorStyle = (pillColor: PillColorsEnum): string => {
    switch (pillColor) {
      case PillColorsEnum.BLUE:
        return `bg-blue-50 text-blue-700 ring-blue-600/20 border-blue-600 ${
          onClick ? "hover:bg-blue-100 cursor-pointer" : ""
        }`;
      case PillColorsEnum.GREEN:
        return `bg-green-50 text-green-700 ring-green-600/20 ${
          onClick ? "hover:bg-green-100 cursor-pointer" : ""
        }`;
      case PillColorsEnum.RED:
        return `bg-red-50 text-red-700 ring-red-600/20 ${
          onClick ? "hover:bg-red-100 cursor-pointer" : ""
        }`;
      case PillColorsEnum.YELLOW:
        return `bg-yellow-50 text-yellow-700 ring-yellow-600/20 ${
          onClick ? "hover:bg-yellow-100 cursor-pointer" : ""
        }`;
      case PillColorsEnum.GRAY:
        return `bg-gray-50 text-gray-700 ring-gray-600/20 ${
          onClick ? "hover:bg-grey-100 cursor-pointer" : ""
        }`;
      case PillColorsEnum.LIME:
        return `bg-lime-50 text-lime-700 ring-lime-600/20 ${
          onClick ? "hover:bg-lime-100 cursor-pointer" : ""
        }`;
    }
  };

  if (onClick) {
    return (
      <button
        className={twJoin(
          sharedClasses,
          generateSizeStyle(size),
          generateColorStyle(color),
        )}
        onClick={onClick}
        type="button"
      >
        {label}
      </button>
    );
  }

  return (
    <div
      className={twJoin(
        sharedClasses,
        generateSizeStyle(size),
        generateColorStyle(color),
      )}
    >
      {label}
    </div>
  );
};
