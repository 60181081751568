import { Label, Link, SaveAndCancelButtons, Select, SizesEnum } from "@repo/ui";
import {
  InterviewLanguagesEnum,
  LANGUAGE_DISPLAY_NAMES,
  LANGUAGE_TO_FLAG,
  type LanguageVoice,
  PermissionActions,
  PermissionSubjects,
  getDefaultVoiceForLanguage,
  getVoicesForLanguage,
  languageVoiceDisplayNames,
} from "app-types";
import { useState } from "react";
import { Can } from "../../../helpers/teammateAuthorizationContext";

const languageOptions = Object.entries(InterviewLanguagesEnum).map(
  ([_, value]) => ({
    name: `${LANGUAGE_TO_FLAG[value]}\u00A0\u00A0${LANGUAGE_DISPLAY_NAMES[value]}`,
    value,
  }),
);

interface InterviewLanguageSettingProps {
  language: InterviewLanguagesEnum;
  voice: LanguageVoice | null;
  onSave: (
    language: InterviewLanguagesEnum,
    voice: LanguageVoice | null,
  ) => void;
}

export const InterviewLanguageSetting = ({
  language,
  voice,
  onSave,
}: InterviewLanguageSettingProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState(language);
  const [selectedVoice, setSelectedVoice] = useState<LanguageVoice | null>(
    voice,
  );

  const availableVoices = getVoicesForLanguage(selectedLanguage);
  const voiceOptions = availableVoices.map((voice) => ({
    name: languageVoiceDisplayNames[voice],
    value: voice,
  }));

  const hasLanguageChanged = selectedLanguage !== language;
  // When the current voice is null, we display the default voice for the language.
  // We don't want to consider displaying the default voice in that case as a voice change.
  const hasVoiceChanged =
    selectedVoice !== voice &&
    (voice || selectedVoice !== getDefaultVoiceForLanguage(selectedLanguage));

  const hasChanges = hasLanguageChanged || hasVoiceChanged;

  const voiceToDisplay = selectedVoice || getDefaultVoiceForLanguage(language);

  return (
    <Can
      I={PermissionActions.UPDATE}
      a={PermissionSubjects.JOB_OPENINGS}
      passThrough={true}
    >
      {(allowed) => (
        <>
          <div className="space-y-2">
            <div className="flex flex-row gap-4">
              <div className="min-w-[280px]">
                <Label size={SizesEnum.SMALL} className="mb-2 block">
                  Interview language
                </Label>
                <Select
                  currentSelection={languageOptions.find(
                    (option) => option.value === selectedLanguage,
                  )}
                  onChange={(option) => {
                    const changedLanguage =
                      option.value as InterviewLanguagesEnum;
                    setSelectedLanguage(changedLanguage);
                    // If we select back the original language, also set back the original voice
                    if (changedLanguage === language) {
                      setSelectedVoice(voice);
                    } else {
                      setSelectedVoice(
                        getDefaultVoiceForLanguage(changedLanguage),
                      );
                    }
                  }}
                  options={languageOptions}
                  isDisabled={!allowed}
                />
              </div>

              {availableVoices.length > 0 ? (
                <div className="min-w-[280px]">
                  <Label size={SizesEnum.SMALL} className="mb-2 block">
                    Voice
                  </Label>
                  <Select
                    currentSelection={voiceOptions.find(
                      (option) => option.value === voiceToDisplay,
                    )}
                    onChange={(option) => {
                      setSelectedVoice(option.value as LanguageVoice);
                    }}
                    options={voiceOptions}
                    isDisabled={!allowed}
                  />
                </div>
              ) : (
                <div></div> // Empty placeholder to maintain grid structure
              )}
            </div>

            <div className="text-sm text-slate-600">
              Select the language that the AI interviewer will use during
              interviews. Your interview questions do not need to match this
              language.{" "}
              <Link href="mailto:support@alpharun.com">
                Reach out to support
              </Link>{" "}
              to access additional languages.
            </div>
            {hasChanges ? (
              <SaveAndCancelButtons
                onSave={() => {
                  onSave(selectedLanguage, selectedVoice);
                }}
                onCancel={() => {
                  setSelectedLanguage(language);
                  setSelectedVoice(voice);
                }}
              />
            ) : null}
          </div>
        </>
      )}
    </Can>
  );
};
