import { FC } from "react";
import { twJoin } from "tailwind-merge";

interface SmallTabsProps {
  tabs: string[];
  selectedTabIndex: number;
  onSelectTabIndex: (newTabIndex: number) => void;
}

export const SmallTabs: FC<SmallTabsProps> = ({
  tabs,
  selectedTabIndex,
  onSelectTabIndex,
}) => {
  return (
    <nav
      aria-label="Tabs"
      className="isolate inline-flex divide-x divide-gray-200 rounded-lg shadow whitespace-nowrap"
    >
      {tabs.map((tab, tabIdx) => {
        return (
          <button
            className={twJoin(
              selectedTabIndex === tabIdx
                ? "text-gray-900"
                : "text-gray-500 hover:text-gray-700",
              tabIdx === 0 ? "rounded-l-lg" : "",
              tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
              "group relative min-w-0 overflow-hidden bg-white py-2 px-3 text-center text-sm font-medium hover:bg-gray-50 focus:z-10 cursor-pointer",
            )}
            key={tab}
            onClick={() => {
              onSelectTabIndex(tabIdx);
            }}
            type="button"
          >
            <span>{tab}</span>
            <span
              aria-hidden="true"
              className={twJoin(
                selectedTabIndex === tabIdx
                  ? "bg-indigo-500"
                  : "bg-transparent",
                "absolute inset-x-0 bottom-0 h-0.5",
              )}
            />
          </button>
        );
      })}
    </nav>
  );
};
