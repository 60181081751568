import {
  Button,
  ButtonVariantsEnum,
  Input,
  Modal,
  ModalHeader,
} from "@repo/ui";
import { ApiKeyWithFullKey, LoadingStatesEnum } from "app-types";
import { FC, useState } from "react";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppDispatch } from "../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";

interface CreateApiKeyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateApiKeyModal: FC<CreateApiKeyModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const [apiKeyName, setApiKeyName] = useState<string>("");
  const [createApiKeyLoadingState, setCreateApiKeyLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADED);
  const [newApiKey, setNewApiKey] = useState<ApiKeyWithFullKey | undefined>(
    undefined,
  );

  const onClickCreateApiKey = async () => {
    setCreateApiKeyLoadingState(LoadingStatesEnum.LOADING);
    try {
      const axios = await getAxiosInstanceWithAuth();
      const { data } = await axios.post(`/api-keys`, {
        name: apiKeyName,
      });

      setNewApiKey(data);
      setCreateApiKeyLoadingState(LoadingStatesEnum.LOADED);
    } catch (err) {
      setCreateApiKeyLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  return (
    <Modal isOpen={isOpen} onCancel={onClose}>
      <ModalHeader onClose={onClose}>
        {newApiKey ? "API Key Created" : "Create an API Key"}
      </ModalHeader>

      {newApiKey ? (
        <>
          <div className="mt-6 mb-2">
            <p className="text-sm text-gray-700">
              Copy your API key and store it in a safe place. You won't be able
              to see it again.
            </p>
            <div className="mt-4 mb-4 bg-gray-100 p-4 rounded-lg text-sm break-words">
              {newApiKey.key}
            </div>
            <Button
              variant={ButtonVariantsEnum.Primary}
              onClick={() => {
                navigator.clipboard.writeText(newApiKey.key);
                showNotification(dispatch, {
                  id: `api-key-copied-${new Date().getTime()}`,
                  primaryMessage: `API key copied to clipboard`,
                  type: NotificationTypeEnum.SUCCESS,
                });
              }}
            >
              Copy
            </Button>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <Button
              variant={ButtonVariantsEnum.Secondary}
              onClick={() => onClose()}
            >
              Done
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="mt-6 mb-2">
            <Input
              label="API Key Name"
              autoFocus={true}
              onChange={(evt) => {
                setApiKeyName(evt.target.value);
              }}
              value={apiKeyName}
              description="Provide a name that describes how this API key will be used."
            />
          </div>

          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <div className="ml-3">
              <Button
                variant={ButtonVariantsEnum.Primary}
                isDisabled={!apiKeyName}
                onClick={() => onClickCreateApiKey()}
                isLoading={
                  createApiKeyLoadingState === LoadingStatesEnum.LOADING
                }
              >
                Create API Key
              </Button>
            </div>
            <Button
              variant={ButtonVariantsEnum.Secondary}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};
