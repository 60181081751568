import {
  BasicQuestion,
  Company,
  ComplexQuestion,
  ComplexQuestionInsert,
  ProjectUpdate,
  ProjectWithInterviewCount,
} from "app-types";
import { FC, useState } from "react";
import { Button, ButtonVariantsEnum } from "@repo/ui";
import { ReadOnlyBulletedList } from "../../../components/readOnlyBulletedList";
import { QuestionsManagerSlideover } from "./questionsManagerSlideover";

const NUM_QUESTIONS_TO_DISPLAY = 4;

interface ProjectSettingsQuestionsSectionProps {
  complexQuestions: (ComplexQuestion | ComplexQuestionInsert)[];
  basicQuestions: BasicQuestion[];
  project: ProjectWithInterviewCount;
  company: Company;
  onSaveProject: (changes: ProjectUpdate) => void;
}

export const ProjectSettingsQuestionsSection: FC<
  ProjectSettingsQuestionsSectionProps
> = ({ complexQuestions, basicQuestions, project, company, onSaveProject }) => {
  const [isQuestionsManagerSlideoverOpen, setIsQuestionsManagerSlideoverOpen] =
    useState(false);
  const questions = [...complexQuestions, ...basicQuestions];

  return (
    <div className="flex flex-col justify-between mb-2">
      <div className="flex flex-col space-y-3">
        <div>
          {questions.length > 0 ? (
            <ReadOnlyBulletedList
              items={questions.map((question) => question.question)}
              maxItemsToDisplay={NUM_QUESTIONS_TO_DISPLAY}
              onListClick={() => setIsQuestionsManagerSlideoverOpen(true)}
              itemDescriptor="questions"
            />
          ) : (
            <div className="mt-2">
              <Button
                variant={ButtonVariantsEnum.Primary}
                onClick={() => setIsQuestionsManagerSlideoverOpen(true)}
              >
                Add interview questions
              </Button>
            </div>
          )}
        </div>
      </div>

      <QuestionsManagerSlideover
        complexQuestions={complexQuestions}
        basicQuestions={basicQuestions}
        project={project}
        company={company}
        onSaveProject={onSaveProject}
        isOpen={isQuestionsManagerSlideoverOpen}
        onClose={() => setIsQuestionsManagerSlideoverOpen(false)}
      />
    </div>
  );
};
