import { FC, ReactNode, useId, useMemo } from "react";
import { SimpleRadioGroupContext } from "./simpleRadioGroupContext";

interface SimpleRadioGroupProps {
  value: string;
  onChange: (value: string) => void;
  children: ReactNode;
}

export const SimpleRadioGroup: FC<SimpleRadioGroupProps> = (props) => {
  const name = useId();
  const { value, onChange } = props;
  const contextValue = useMemo(
    () => ({ name, value, onChange }),
    [name, value, onChange],
  );

  return (
    <SimpleRadioGroupContext.Provider value={contextValue}>
      <div className="flex flex-col space-y-2">{props.children}</div>
    </SimpleRadioGroupContext.Provider>
  );
};
