import { z } from "zod";
import {
  type InterviewLanguagesEnum,
  type LanguageVoice,
} from "../constants/languages";
import {
  multilineContentSchema,
  singleLineContentSchema,
} from "../content/schema";
import {
  type MultilineContent,
  type SingleLineContent,
} from "../content/types";
import type { ProjectLinkAccess } from "../entities/project";
import type { TeammatePermissions } from "../entities/teammatePermissions";
import { type TablesWithFixedTypes } from "./supabase_with_fixed_types";

/**
 * ActivityType
 */
const activityTypes = [
  "email_sent", // Email sent to research participants
  "email_bounced",
  "email_marked_spam",
  "in_progress_interview_reminder_email",
  "interview_window_blur",
  "interview_window_focus",
  "interview_fullscreen_exit",
  "outreach_message", // Messages inviting pending candidates to take their interview
] as const;
export type ActivityType = (typeof activityTypes)[number];

export function isActivityType(value: string): value is ActivityType {
  return activityTypes.includes(value as ActivityType);
}

/**
 * Answer
 */

export interface RatingSurveyResponse {
  name: string;
  rating: number;
}

export function isRatingSurveyResponse(
  response: unknown,
): response is RatingSurveyResponse {
  return (
    typeof response === "object" &&
    response !== null &&
    "name" in response &&
    typeof (response as RatingSurveyResponse).name === "string" &&
    "rating" in response &&
    typeof (response as RatingSurveyResponse).rating === "number" &&
    (response as RatingSurveyResponse).rating > 0 &&
    (response as RatingSurveyResponse).rating <= 5
  );
}

export function isRatingSurveyResponseList(
  response: unknown,
): response is RatingSurveyResponse[] {
  return Array.isArray(response) && response.every(isRatingSurveyResponse);
}

export interface SimpleSurveyResponse {
  value: string;
}

export function isSimpleSurveyResponse(
  response: unknown,
): response is SimpleSurveyResponse {
  return (
    typeof response === "object" &&
    response !== null &&
    "value" in response &&
    typeof (response as SimpleSurveyResponse).value === "string"
  );
}

export type ComplexAnswerResponse =
  | RatingSurveyResponse[]
  | SimpleSurveyResponse;

export function isComplexAnswerResponse(
  response: unknown,
): response is ComplexAnswerResponse {
  // Check if response is RatingSurveyResponse[] (matrix-style question)
  if (isRatingSurveyResponseList(response)) {
    return true;
  }

  // Check if response is SimpleSurveyResponse
  if (isSimpleSurveyResponse(response)) {
    return true;
  }

  return false;
}

/**
 * Attachment
 */

const attachmentTypes = ["resume"] as const;
export const attachmentTypeSchema = z.enum(attachmentTypes);
export type AttachmentType = z.infer<typeof attachmentTypeSchema>;

export function isAttachmentType(value: string): value is AttachmentType {
  return attachmentTypes.includes(value as AttachmentType);
}

const attachmentResourceTypes = ["contact"] as const;
export type AttachmentResourceType = (typeof attachmentResourceTypes)[number];

export interface AttachmentMetadata {
  text: string | null;
  body: string | null | undefined;
  type: string | null | undefined; // type of the body, e.g. "markdown", "text"
}

/**
 * Balance transaction
 */

// Compatible with 'Stripe.InvoiceItemCreateParams.PriceData' type.
const currencyCodes = ["usd"] as const;
export type CurrencyCode = (typeof currencyCodes)[number];

const balanceTransactionTypes = [
  "stripe_credit",
  "tremendous_debit",
  "no_incentive_interview",
  "alpharun_credit",
] as const;
export type BalanceTransactionType = (typeof balanceTransactionTypes)[number];

const balanceTransactionStatuses = ["pending", "confirmed", "failed"] as const;
export type BalanceTransactionStatus =
  (typeof balanceTransactionStatuses)[number];

/**
 * Company
 */

const billingPlans = ["starter-1", "growth-1", "scale-1"] as const;
export type BillingPlan = (typeof billingPlans)[number];

export enum CompanyModesEnum {
  RECRUITING = "recruiting",
}

export const ExtAccountIdSource = {
  Fountain: "fountain",
} as const;
const allExtAccountIdSources = [ExtAccountIdSource.Fountain] as const;
export type ExtAccountIdSource = (typeof allExtAccountIdSources)[number];

export interface CompanySettings {
  balance_refill_unit_amount: number | null;
  balance_refill_threshold_unit_amount: number | null;
  is_sending_invites_blocked: boolean;
  logo_file_name: string | null;
  favicon_file_name: string | null;
  interview_color: string | null;
  mode: CompanyModesEnum | null;
  whitelabel_domain: string | null;
  whitelabel_contact_email: string | null;
  welcome_message: string | null;
  outro_message: string | null;
  identity_provider_id: string | null;
  is_cheating_detection_enabled: boolean;
  ext_account_id: {
    id: string;
    source: ExtAccountIdSource;
  } | null;
  domain_signup_teammate_permissions: TeammatePermissions;
}

/**
 * Company invitation
 */

const companyInvitationStatuses = ["pending", "accepted", "rejected"] as const;
export type CompanyInvitationStatus =
  (typeof companyInvitationStatuses)[number];

/**
 * Custom field
 */

export type CustomFieldProvider = IntegrationProvider | "alpharun";

/**
 * Integration
 */

export const IntegrationProviders = {
  FOUNTAIN: "fountain",
  INTERNAL_TWILIO_SUB_ACCOUNT: "internal_twilio_sub_account",
  SALESFORCE: "salesforce",
  SLACK: "slack",
} as const;
const allIntegrationProviders = [
  IntegrationProviders.FOUNTAIN,
  IntegrationProviders.INTERNAL_TWILIO_SUB_ACCOUNT,
  IntegrationProviders.SALESFORCE,
  IntegrationProviders.SLACK,
] as const;
const integrationProviderSchema = z.enum(allIntegrationProviders);
export type IntegrationProvider = z.infer<typeof integrationProviderSchema>;

export function isIntegrationProvider(
  value: string,
): value is IntegrationProvider {
  return allIntegrationProviders.includes(value as IntegrationProvider);
}

export function isInternalTwilioSubAccountIntegrationSettings(
  provider: IntegrationProvider,
  settings: IntegrationSettings,
): settings is InternalTwilioSubAccountIntegrationSettings {
  return (
    provider === IntegrationProviders.INTERNAL_TWILIO_SUB_ACCOUNT &&
    "account_sid" in settings
  );
}

// Fountain

export interface FountainIntegrationSettings {
  api_base_url?: string | null; // Most Fountain customers use https://api.fountain.com but some use a different tenant URL
}

export interface FountainIntegrationUpsert {
  provider: "fountain";
  access_token: null;
  refresh_token: string | null;
  settings: FountainIntegrationSettings;
}

// Salesforce
export interface SalesforceIntegrationSettings {
  instance_url: string | null;
  is_enabled: boolean;
}

export interface SalesforceIntegrationUpsert {
  provider: "salesforce";
  access_token: string;
  refresh_token: string;
  settings: SalesforceIntegrationSettings;
}

export function isSalesforceIntegrationSettings(
  settings: IntegrationSettings,
): settings is SalesforceIntegrationSettings {
  return (
    "instance_url" in settings &&
    (settings.instance_url === null ||
      typeof settings.instance_url === "string") &&
    "is_enabled" in settings &&
    typeof settings.is_enabled === "boolean"
  );
}

// Slack
export interface SlackIntegrationSettings {
  channel_id: string | null;
}

export interface SlackIntegrationUpsert {
  provider: "slack";
  access_token: string;
  settings: SlackIntegrationSettings;
}

export function isSlackIntegrationSettings(
  settings: IntegrationSettings,
): settings is SlackIntegrationSettings {
  return (
    "channel_id" in settings &&
    (settings.channel_id === null || typeof settings.channel_id === "string")
  );
}

// Internal Twilio sub account
export interface InternalTwilioSubAccountIntegrationSettings {
  account_sid: string;
}

export interface InternalTwilioSubAccountIntegrationUpsert {
  provider: typeof IntegrationProviders.INTERNAL_TWILIO_SUB_ACCOUNT;
  access_token: string;
  settings: InternalTwilioSubAccountIntegrationSettings;
}

// General settings
export type IntegrationSettings =
  | FountainIntegrationSettings
  | SalesforceIntegrationSettings
  | SlackIntegrationSettings
  | InternalTwilioSubAccountIntegrationSettings;

export type IntegrationUpsert =
  | FountainIntegrationUpsert
  | SalesforceIntegrationUpsert
  | SlackIntegrationUpsert
  | InternalTwilioSubAccountIntegrationUpsert;

/**
 * Keyword
 */

// preserve "lexical" to avoid db backfill
// "competitive": project-level competitive insights (inherits phrases from COMPANY_COMPETITORS/"lexical")
const keywordTrackerTypes = [
  "lexical", // key for this one used to be 'COMPANY_COMPETITORS'
  "competitive",
  "question_summary",
] as const;
export type KeywordTrackerType = (typeof keywordTrackerTypes)[number];

export interface KeywordDb {
  phrase?: string;
  transcript_fragment_ids?: string[];
  analyses?: KeywordAnalysis[];
}

export interface KeywordAnalysis {
  type: KeywordAnalysisTypesEnum;
  values: KeywordAnalysisValue[];
}

export enum KeywordAnalysisTypesEnum {
  COMPETITIVE_STRENGTH = "competitive_strength",
  COMPETITIVE_WEAKNESS = "competitive_weakness",
}

interface KeywordAnalysisValue {
  score: number;
  label: string;
}

/**
 * Message template
 */

export type MessageTemplateSubject = SingleLineContent;
export type MessageTemplateBody = MultilineContent;

export function isMessageTemplateSubject(
  subject: unknown,
): subject is MessageTemplateSubject {
  return singleLineContentSchema.safeParse(subject).success;
}

export function isMessageTemplateBody(
  body: unknown,
): body is MessageTemplateBody {
  return multilineContentSchema.safeParse(body).success;
}

/**
 * Project question
 */
export type ProjectDbQuestion = { question: string } | { question_id: string };

export enum ProjectEmailDigestFrequencyEnum {
  INSTANT = "instant",
  DAILY = "daily",
}

export enum InterviewAgentTypesEnum {
  GenericInterviewer = "generic",
  RecruitingInterviewer = "recruiter",
}

export enum ResumeCollectionTypesEnum {
  BeforeInterview = "before_interview",
  AfterInterview = "after_interview",
}

export interface ProjectSettings {
  send_email_digest_to_all_teammates: boolean;
  send_email_digest_to_project_owners: boolean;
  email_digest_recipients: string[];
  email_digest_frequency: ProjectEmailDigestFrequencyEnum;
  max_interview_count: number | null;
  is_incentive_charity_only: boolean;
  is_slack_enabled: boolean;
  slack_channel_id: string | null;
  prompt_context: string | null;
  max_clarifying_questions_count: number | null;
  redirect_url: string | null;
  welcome_message: string | null;
  outro_message: string | null;
  interview_agent_type: InterviewAgentTypesEnum;
  resume_collection_type: ResumeCollectionTypesEnum | null;
  interview_language: InterviewLanguagesEnum;
  language_voice: LanguageVoice | null;
  should_force_fullscreen: boolean;
  should_record_video: boolean;
  acceptance_template_id: string | null;
  rejection_template_id: string | null;
  auto_advance_assessment_score_threshold: number | null;
  link_access: ProjectLinkAccess;
}

/**
 * Interview
 */
const interviewStatuses = [
  "pending",
  "in_progress",
  "completed",
  "cancelled",
] as const;
export const interviewStatusSchema = z.enum(interviewStatuses);
export type InterviewStatus = (typeof interviewStatuses)[number];

const interviewDecisions = ["advanced", "rejected"] as const;
export type InterviewDecision = (typeof interviewDecisions)[number];

export function isInterviewDecision(value: string): value is InterviewDecision {
  return interviewDecisions.includes(value as InterviewDecision);
}

export interface InterviewMetadata {
  feedback: InterviewFeedback | null;
  calls: InterviewCallMetadata[];
  self_identification?: SelfIdentificationData | null;
}

/**
 * Self-identification data for voluntary EEOC compliance
 * All fields are optional and should be enums or null
 */
export interface SelfIdentificationData {
  gender?: GenderOption | null;
  ethnicity?: EthnicityOption | null;
  race?: RaceOption | null;
}
/**
 * Constants for self-identification data
 */
export const genderOptions = [
  "Male",
  "Female",
  "Decline to Self Identify",
] as const;
export type GenderOption = (typeof genderOptions)[number];

export const ethnicityOptions = [
  "Hispanic or Latino",
  "Not Hispanic or Latino",
] as const;
export type EthnicityOption = (typeof ethnicityOptions)[number];

export const raceOptions = [
  "American Indian or Alaska Native",
  "Asian",
  "Black or African American",
  "White",
  "Native Hawaiian or Other Pacific Islander",
  "Two or more races",
] as const;
export type RaceOption = (typeof raceOptions)[number];

interface InterviewFeedback {
  rating: InterviewFeedbackRatingsEnum;
  text: string | null;
}

export enum InterviewFeedbackRatingsEnum {
  Great = "Great",
  Repetitive = "Repetitive",
  MissingFocusAreas = "Missing focus areas",
  InaccurateAssessment = "Inaccurate assessment",
  Other = "Other feedback",
}

export interface InterviewCallMetadata {
  id: string;
  call_id: string;
  agent_id: string;
  interview_section_result_id: string | undefined;
  start_time: string | undefined;
  end_time: string | undefined;
  twilio_recording_id: string | undefined;
  recording_url: string | undefined;
  raw_video_key: string | undefined; // S3 key for the raw video
  processed_video_key: string | undefined; // S3 key for the processed video
}

export interface InterviewInsights {
  summary?: string | null;
  sentiment_score?: number | null;
  key_positive_quotes?: string[] | null;
  key_negative_quotes?: string[] | null;
  assessment_score?: number | null;
  strengths?: string[] | null;
  weaknesses?: string[] | null;
  unanswered_candidate_questions?: string[] | null;
}

export interface InterviewReward {
  ext_id: string;
  type: string;
  value_denomination: number;
  value_currency_code: string;
  reward_link: string;
  sent_at: string;
}

/**
 * Phone number
 */
const phoneNumberProviders = ["twilio"] as const;
export type PhoneNumberProvider = (typeof phoneNumberProviders)[number];

/**
 * Questions
 */
export interface ComplexQuestionOption {
  name: string;
}

interface ComplexQuestionPublicBase {
  id: string;
  question: string;
  options: ComplexQuestionOption[];
}

interface BasicInterviewQuestion {
  question: string;
  type: "preset" | "dynamic";
  has_been_answered: boolean;
}

export type MultipleChoiceQuestionPublic = ComplexQuestionPublicBase & {
  type: "multiple_choice";
};

export type MultipleChoiceInterviewQuestionPublic =
  MultipleChoiceQuestionPublic & {
    has_been_answered: boolean;
  };

export type MatrixQuestionPublic = ComplexQuestionPublicBase & {
  type: "rating";
  scale: SurveyScale;
};

export type MatrixInterviewQuestionPublic = MatrixQuestionPublic & {
  has_been_answered: boolean;
};

export type ComplexInterviewQuestion =
  | MultipleChoiceInterviewQuestionPublic
  | MatrixInterviewQuestionPublic;

export type InterviewQuestion =
  | BasicInterviewQuestion
  | ComplexInterviewQuestion;

/**
 * Survey scale
 */
export const surveyScales = [
  "important_unimportant",
  "agree_disagree",
  "satisfied_dissatisfied",
  "yes_no",
] as const;
export type SurveyScale = (typeof surveyScales)[number];

export function isSurveyScale(value: string): value is SurveyScale {
  return surveyScales.includes(value as SurveyScale);
}

/*
 * Invite payloads
 */

export type InvitePayloadsView = TablesWithFixedTypes<"invite_payloads4">;
export type InviteReminderPayloadsView =
  TablesWithFixedTypes<"invite_reminder_payloads7">;
export type InProgressInterviewReminderPayloadsView =
  TablesWithFixedTypes<"in_progress_interview_reminders5">;
