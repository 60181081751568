import { Label, SizesEnum, Textarea } from "@repo/ui";
import { ComplexQuestion, ComplexQuestionInsert } from "app-types";
import { FC } from "react";
import { MAX_CHARACTERS_PER_QUESTION } from "./basicQuestionEditor";
import { SurveyOptionsEditor } from "./surveyOptionsEditor";

interface MultipleChoiceQuestionEditorProps {
  question: ComplexQuestion | ComplexQuestionInsert;
  onChange: (question: ComplexQuestion | ComplexQuestionInsert) => void;
}

export const MultipleChoiceQuestionEditor: FC<
  MultipleChoiceQuestionEditorProps
> = ({ question, onChange }) => {
  return (
    <div>
      <div className="w-full">
        <div>
          <Textarea
            label="Question"
            value={question.question}
            placeholder="Add your survey question..."
            onChange={(newValue) => {
              onChange({
                ...question,
                question: newValue,
              });
            }}
            shouldDisableNewline
            maxLength={MAX_CHARACTERS_PER_QUESTION}
          />
        </div>
      </div>
      <div className="mt-2">
        <Label size={SizesEnum.SMALL}>Options</Label>
      </div>
      <SurveyOptionsEditor
        onOptionsChange={(options) => {
          onChange({
            ...question,
            options,
          });
        }}
        options={question.options}
      />
    </div>
  );
};
