import {
  Button,
  ButtonVariantsEnum,
  Modal,
  ModalHeader,
  Select,
} from "@repo/ui";
import { SelectOption } from "@repo/ui/Select/select";
import { buildProjectUrlPath, Integration, LoadingStatesEnum } from "app-types";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { selectTeammate } from "../../teammate/teammateSlice";
import { createRecruitingProject } from "../projectsSlice";

export interface CreateProjectViaFountainModalProps {
  isOpen: boolean;
  onClose: () => void;
  fountainIntegration: Integration;
}

export const CreateProjectViaFountainModal: FC<
  CreateProjectViaFountainModalProps
> = ({ isOpen, onClose, fountainIntegration }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [fountainPositions, setFountainPositions] = useState<SelectOption[]>(
    [],
  );
  const [selectedFountainPositionId, setSelectedFountainPositionId] = useState<
    string | null
  >(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fountainPositionLoadingState, setFountainPositionLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADED);
  const [createProjectStatus, setCreateProjectStatus] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADED);

  const teammate = useAppSelector(selectTeammate);
  if (!teammate) {
    throw new Error("Teammate not found");
  }

  const currentSelection = fountainPositions.find(
    (option) => option.value === selectedFountainPositionId,
  );

  const fetchFountainPositions = async () => {
    setFountainPositionLoadingState(LoadingStatesEnum.LOADING);
    try {
      const axios = await getAxiosInstanceWithAuth();
      const { data } = await axios.post(
        `/integrations/${fountainIntegration.id}/job-types`,
      );

      const selectOptions = (data as { name: string; id: string }[])
        .map((position) => ({
          name: position.name,
          value: position.id,
        }))
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        );

      setFountainPositions(selectOptions);
      setFountainPositionLoadingState(LoadingStatesEnum.LOADED);
    } catch (err) {
      console.error("Error fetching fountain positions", err);
      setFountainPositionLoadingState(LoadingStatesEnum.ERROR);
    }
  };
  useEffect(() => {
    void fetchFountainPositions();

    return () => {
      setFountainPositions([]);
      setSelectedFountainPositionId(null);
      setErrorMessage(null);
      setFountainPositionLoadingState(LoadingStatesEnum.LOADED);
      setCreateProjectStatus(LoadingStatesEnum.LOADED);
    };
  }, []);

  const onClickCreate = async () => {
    setCreateProjectStatus(LoadingStatesEnum.LOADING);
    setErrorMessage(null);

    try {
      const axios = await getAxiosInstanceWithAuth();
      const { data: position } = await axios.get(
        `/integrations/${fountainIntegration.id}/job-types/${selectedFountainPositionId}`,
      );

      if (!position) {
        setErrorMessage("Fountain position not found");
        setCreateProjectStatus(LoadingStatesEnum.LOADED);
        return;
      }

      if (!position.description) {
        setErrorMessage(
          "This position is missing a job description - a job description is required to create an AI interviewer.",
        );
        setCreateProjectStatus(LoadingStatesEnum.LOADED);
        return;
      }

      if (position.openings.length === 0) {
        setErrorMessage(
          "This position has no openings. At least 1 opening is required.",
        );
        setCreateProjectStatus(LoadingStatesEnum.LOADED);
        return;
      }

      const firstOpening = position.openings[0];

      const newProject = await dispatch(
        createRecruitingProject({
          name: firstOpening.title || "Untitled Opening",
          jobDescription: position.description,
          jobTypeId: undefined,
          jobTypeName: position.name || "Untitled Position",
          extId: position.id,
          extProvider: "fountain",
        }),
      ).unwrap();

      const newProjectId = newProject.id;

      showNotification(dispatch, {
        id: `project-created-${new Date().getTime().toString()}`,
        primaryMessage: `Job opening successfully created`,
        type: NotificationTypeEnum.SUCCESS,
      });

      setCreateProjectStatus(LoadingStatesEnum.LOADED);
      navigate(buildProjectUrlPath(newProjectId));
    } catch (err) {
      console.error("error creating project", err);
      setCreateProjectStatus(LoadingStatesEnum.ERROR);
      setErrorMessage("Failed to create AI interviewer. Please try again.");
    }
  };

  return (
    <Modal isOpen={isOpen} onCancel={onClose}>
      <ModalHeader>Create AI interviewer</ModalHeader>
      <div className="mt-4 flex flex-col space-y-4">
        <div className="relative">
          <Select
            options={fountainPositions}
            currentSelection={currentSelection}
            onChange={(selected) => {
              setSelectedFountainPositionId(selected.value as string);
            }}
            label="Fountain Position"
            loadingMessage={
              fountainPositionLoadingState === LoadingStatesEnum.LOADING
                ? "Loading Fountain Positions..."
                : undefined
            }
            placeholder="Select a Fountain Position"
          />
        </div>
        <div className="mt-2 text-sm text-gray-500">
          An AI interviewer will be created for this Position. Add it to an AI
          Interview workflow stage to start interviewing candidates.
        </div>
        {errorMessage ? (
          <div className="mt-2 text-sm text-red-500">{errorMessage}</div>
        ) : null}
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <div className="ml-3">
          <Button
            isDisabled={
              !selectedFountainPositionId ||
              createProjectStatus === LoadingStatesEnum.LOADING
            }
            isLoading={createProjectStatus === LoadingStatesEnum.LOADING}
            onClick={() => void onClickCreate()}
            variant={ButtonVariantsEnum.Primary}
          >
            {createProjectStatus === LoadingStatesEnum.LOADING
              ? "Creating AI interviewer..."
              : "Create AI interviewer"}
          </Button>
        </div>
        <Button onClick={onClose} variant={ButtonVariantsEnum.Secondary}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
