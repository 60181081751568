import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import {
  BasicQuestion,
  ComplexQuestionInsert,
  getProjectAndJobTypeProperty,
  JobTypePropertyEnum,
  Project,
} from "app-types";
import copy from "copy-to-clipboard";
import { nanoid } from "nanoid";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DropdownMenu, ModalVariantsEnum, SimpleModal } from "@repo/ui";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../notificationsOverlay/notificationsSlice";
import { selectPhoneNumberAndExtensionForProject } from "../phoneNumbers/phoneNumbersSlice";
import { createProject } from "./projectsSlice";

interface ProjectMoreMenuProps {
  onClickPause?: () => void;
  onClickDeleteProject: () => void;
  onClickCopyInterviewLink?: () => void;
  onClickViewDialInstructions?: () => void;
  onClickCsvExport: () => void;
  onClickRegenerateInterviewLink: () => void;
  project: Project;
}

interface MenuItem {
  label: string;
  onClick: () => void;
}

export const ProjectMoreMenu: FC<ProjectMoreMenuProps> = ({
  onClickPause,
  onClickDeleteProject,
  onClickCopyInterviewLink,
  onClickViewDialInstructions,
  onClickCsvExport,
  project,
  onClickRegenerateInterviewLink,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const phoneNumberInfo = useAppSelector(
    selectPhoneNumberAndExtensionForProject(project.id),
  );

  const [isDuplicateProjectModalOpen, setIsDuplicateProjectModalOpen] =
    useState<boolean>(false);

  const onConfirmDuplicateProject = async () => {
    try {
      await dispatch(
        createProject({
          name: `Copy of ${project.name}`,
          incentive_quantity: project.incentive_quantity || 0,
          questions: getProjectAndJobTypeProperty(
            project,
            JobTypePropertyEnum.Questions,
          ).map((q) => {
            // Remove id and project_id so that server creates new questions
            const newQuestion: BasicQuestion | ComplexQuestionInsert = {
              ...q,
            };
            if ("id" in newQuestion) {
              delete newQuestion.id;
            }
            if ("project_id" in newQuestion) {
              delete newQuestion.project_id;
            }
            return newQuestion;
          }),
          short_id: nanoid(),
          settings: {
            ...project.settings,
            prompt_context: getProjectAndJobTypeProperty(
              project,
              JobTypePropertyEnum.PromptContext,
            ),
          },
          mode: project.mode,
          phone_number_id: phoneNumberInfo?.phoneNumber.id,
          owner_teammate_ids: project.owner_teammate_ids,
        }),
      ).unwrap();

      showNotification(dispatch, {
        id: `project-duplicated-${new Date().getTime()}`,
        primaryMessage: `Project duplicated`,
        type: NotificationTypeEnum.SUCCESS,
      });

      navigate("/");
    } catch (err) {
      console.error("error duplicating project", err);
    }
  };

  const menuItems: MenuItem[] = [
    { label: "Pause", onClick: onClickPause },
    { label: "Copy Interview Link", onClick: onClickCopyInterviewLink },
    {
      label: "View Dialing Instructions",
      onClick: onClickViewDialInstructions,
    },
    {
      label: "Copy Project ID",
      onClick: () => {
        copy(project.id);
        showNotification(dispatch, {
          id: `project-${project.id}-id-copied-${new Date().getTime()}`,
          primaryMessage: `Project ID copied - use it to reference this project in the API`,
          type: NotificationTypeEnum.SUCCESS,
        });
      },
    },
    {
      label: "Duplicate Project",
      onClick: () => {
        setIsDuplicateProjectModalOpen(true);
      },
    },
    { label: "CSV Export", onClick: onClickCsvExport },
    {
      label: "Refresh Interview Link",
      onClick: onClickRegenerateInterviewLink,
    },
    { label: "Delete Project", onClick: onClickDeleteProject },
  ].filter((item): item is MenuItem => item.onClick !== undefined);

  return (
    <>
      <Menu as="div" className="relative ml-3">
        <Menu.Button className="relative flex items-center ring-1 ring-inset ring-gray-300 bg-white p-1.5 hover:bg-gray-50 rounded-md shadow-sm text-gray-900 disabled:bg-slate-300 disabled:text-slate-500">
          <span className="absolute -inset-1.5" />
          <span className="sr-only">Open user menu</span>
          <EllipsisVerticalIcon className="w-6 h-6" />
        </Menu.Button>
        <DropdownMenu items={menuItems} />
      </Menu>
      <SimpleModal
        isOpen={isDuplicateProjectModalOpen}
        variant={ModalVariantsEnum.Standard}
        title="Duplicate project"
        subtitle="A new project will be created with the same questions and settings as this project."
        confirmButtonText="Continue"
        onCancel={() => {
          setIsDuplicateProjectModalOpen(false);
        }}
        onConfirm={onConfirmDuplicateProject}
      />
    </>
  );
};
