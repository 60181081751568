import {
  ArrowRightIcon,
  EnvelopeIcon,
  PhoneIcon,
} from "@heroicons/react/16/solid";
import {
  CheckCircleIcon,
  DocumentArrowUpIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import {
  FileCard,
  Label,
  renderPlural,
  SimpleSelect,
  SizesEnum,
} from "@repo/ui";
import { Button, ButtonVariantsEnum } from "@repo/ui/Buttons/button";
import { useEffectEvent } from "@repo/ui/helpers/useEffectEvent";
import type { SimpleSelectOption } from "@repo/ui/Select/simpleSelect";
import { Slideover } from "@repo/ui/Slideover/slideover";
import { useVirtualizer } from "@tanstack/react-virtual";
import { CONTACTS_IMPORT_LIMIT } from "app-types/constants/api";
import { chain, times } from "lodash";
import Papa from "papaparse";
import { FC, Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  Button as AriaButton,
  Dialog,
  DialogTrigger,
  Link,
  OverlayArrow,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
} from "react-aria-components";
import { useDropzone } from "react-dropzone";
import { twJoin, twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";
import { z } from "zod";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { fetchAllProjects, selectAllProjects } from "../projects/projectsSlice";
import {
  createProjectSearcher,
  extractEmailAddress,
  extractNameParts,
  extractPhoneNumber,
  extractProject,
} from "./import/extraction";
import {
  collectEmptyColumnIndexes,
  peekColumnValues as collectExampleValues,
  extractHeaderRow,
} from "./import/helpers";
import {
  allSourceFields,
  generateSourceMapping,
  getMappedSourceFields,
  getSourceFieldForColumnIndex,
  projectSourceFields,
  setSourceFieldForColumnIndex,
  SourceField,
  type SourceMapping,
} from "./import/sourceMapping";

export interface ImportContactsSlideoverProp {
  isOpen: boolean;
  defaultProjectId: string;
  onClose: () => void;
}

const MAX_SIZE_BYTES = 10 * 1024 * 1024; // 10 MB
const PREVIEW_ROW_HEIGHT = 60;

// prettier-ignore
const sampleCsv = Papa.unparse([
  ["First name", "Last name", "Email address", "Phone number", "Job opening name"],
  ["Aisha", "Khan", "aisha.khan@example.org", "+1 (123) 456-7890", "Customer Support Specialist - Nashville"],
  ["Carlos", "Rodriguez", "carlos.rodriguez@example.org", "+34 123 456 789", "Customer Support Specialist - Madrid"],
]);
const sampleCsvUri = `data:text/csv;charset=utf-8,${encodeURIComponent(sampleCsv)}`;

const sourceFieldLabels: Record<SourceField, string> = {
  [SourceField.FirstName]: "First name",
  [SourceField.LastName]: "Last name",
  [SourceField.FullName]: "Full name",
  [SourceField.Email]: "Email address",
  [SourceField.Phone]: "Phone number",
  [SourceField.JobOpeningId]: "Job opening ID",
  [SourceField.JobOpeningName]: "Job opening name",
  [SourceField.JobTitle]: "Job title",
  [SourceField.JobLocation]: "Job location",
};

const sourceFieldOptions: readonly SimpleSelectOption<SourceField | null>[] = [
  {
    label: "Don't import",
    value: null,
  },
  ...allSourceFields.map((value) => ({
    label: sourceFieldLabels[value],
    value,
  })),
] as const;

// TODO: Enrich this object with the source and parsing errors so we can surface these to the user.
interface TransformedRow {
  originalIndex: number;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  project:
    | {
        id: string;
        name: string;
      }
    | undefined;
}

function checkIsRowValid(row: TransformedRow): boolean {
  return !!(row.email || row.phone) && !!row.project;
}

const PreviewFilterKey = {
  All: "all",
  Invalid: "invalid",
  Pending: "pending",
  Failed: "failed",
  Imported: "imported",
} as const;

type PreviewFilterKey =
  (typeof PreviewFilterKey)[keyof typeof PreviewFilterKey];

const PreviewFilterColor = {
  Gray: "gray",
  Red: "red",
  Blue: "blue",
  Green: "green",
} as const;

type PreviewFilterColor =
  (typeof PreviewFilterColor)[keyof typeof PreviewFilterColor];

interface PreviewFilterOption {
  key: PreviewFilterKey;
  label: string;
  color: PreviewFilterColor;
  count: number;
  isVisible: boolean;
  rowPredicate: (rowIndex: number) => boolean;
}

const previewFilterButtonGroup = tv({
  base: "inline-flex rounded-md shadow-sm -space-x-px",
});

const previewFilterButton = tv({
  slots: {
    base: "group flex items-center space-x-2 bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300",
    label: "truncate",
    pill: "flex items-center rounded-full px-2 py-1 text-xs font-medium",
  },
  variants: {
    placement: {
      start: "rounded-l-md",
      center: "",
      end: "rounded-r-md",
    },
    color: {
      [PreviewFilterColor.Gray]: {
        base: "hover:bg-gray-50 selected:bg-gray-600 selected:text-white selected:ring-gray-600",
        pill: "bg-gray-100 text-gray-800 group-selected:bg-gray-500 group-selected:text-white",
      },
      [PreviewFilterColor.Red]: {
        base: "hover:bg-red-50 selected:bg-red-600 selected:text-white selected:ring-red-600",
        pill: "bg-red-100 text-red-800 group-selected:bg-red-500 group-selected:text-white",
      },
      [PreviewFilterColor.Blue]: {
        base: "hover:bg-blue-50 selected:bg-blue-600 selected:text-white selected:ring-blue-600",
        pill: "bg-blue-100 text-blue-800 group-selected:bg-blue-500 group-selected:text-white",
      },
      [PreviewFilterColor.Green]: {
        base: "hover:bg-green-50 selected:bg-green-600 selected:text-white selected:ring-green-600",
        pill: "bg-green-100 text-green-800 group-selected:bg-green-500 group-selected:text-white",
      },
    },
  },
});

function computePreviewFilterButtonPlacement(index: number, length: number) {
  if (index === 0) {
    return "start";
  } else if (index === length - 1) {
    return "end";
  } else {
    return "center";
  }
}

export const ImportContactsSlideover: FC<ImportContactsSlideoverProp> = (
  props,
) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [isParsing, setIsParsing] = useState<boolean>(false);
  const [header, setHeader] = useState<string[]>([]);
  const [data, setData] = useState<string[][]>([]);
  const [emptyColumnIndexes, setEmptyColumnIndexes] = useState<Set<number>>(
    new Set(),
  );
  const [mapping, setMapping] = useState<SourceMapping>({});
  const mappedFields = getMappedSourceFields(mapping);
  const isMappingValid = [SourceField.Email, SourceField.Phone].some((field) =>
    mappedFields.includes(field),
  );
  const isMappingProjects = projectSourceFields.some((field) =>
    mappedFields.includes(field),
  );

  // TODO: Fetch only the project information we need using rtk-query.
  const dispatch = useAppDispatch();
  const projects = useAppSelector((state) => selectAllProjects(state.projects));
  const defaultProject = useMemo(
    () => projects.find((project) => project.id === props.defaultProjectId),
    [projects, props.defaultProjectId],
  );

  const onOpen = useEffectEvent(() => {
    // If there's only one project, we probably loaded it for this page and we should assume we haven't loaded the project list.
    if (projects.length < 2) {
      dispatch(fetchAllProjects());
    }
  });

  useEffect(() => {
    if (props.isOpen) {
      onOpen();
    } else {
      // Reset state when the popover is closed.
      // TODO: Fix the component lifecycle of slideovers so that they're unmounted when closed.
      setFile(undefined);
      setIsParsing(false);
    }
  }, [props.isOpen]);

  useEffect(() => {
    // Reset state when the file is removed.
    // TODO: Do this better.
    if (!file) {
      setHeader([]);
      setData([]);
      setEmptyColumnIndexes(new Set());
      setMapping({});
      setImportedRowIndexes(new Set());
      setFailedRows(new Map());
    }
  }, [file]);

  const {
    getRootProps: getDropzoneRootProps,
    getInputProps: getDropzoneInputProps,
    isDragAccept: isDropzoneDragAccept,
    isDragReject: isDropzoneDragReject,
  } = useDropzone({
    onDropAccepted: async (acceptedFiles) => {
      setIsParsing(true);
      try {
        const acceptedFile = acceptedFiles[0];
        const [rows] = await Promise.all([
          new Promise<string[][]>((resolve, reject) => {
            const rows: string[][] = [];

            Papa.parse<string[]>(acceptedFile, {
              skipEmptyLines: true,
              step: (results) => {
                rows.push(results.data);
              },
              complete: (results) => {
                // TODO: Handle results.errors.
                resolve(rows);
              },
              error: (error) => {
                reject(error);
              },
            });
          }),
          // Avoid flashing the loader for small imports.
          new Promise((resolve) => setTimeout(resolve, 500)),
        ]);

        setFile(acceptedFile);

        const [header, data] = extractHeaderRow(rows);
        setHeader(header);
        setData(data);

        const emptyColumnIndexes = new Set(
          collectEmptyColumnIndexes(header, data),
        );
        setEmptyColumnIndexes(emptyColumnIndexes);

        // TODO: Also look at the data to detect email and phone columns.
        setMapping(generateSourceMapping(header, emptyColumnIndexes));
      } finally {
        setIsParsing(false);
      }
    },
    maxFiles: 1,
    maxSize: MAX_SIZE_BYTES,
    accept: { "text/csv": [".csv"] },
  });

  // Used to fuzzy search job openings.
  const searchProjects = useMemo(
    () => createProjectSearcher(projects),
    [projects],
  );

  // Apply the mappings and transform the data so we can preview the import.
  const transformedData: readonly TransformedRow[] = useMemo(() => {
    const getValue = (row: string[], field: SourceField) => {
      const index = mapping[field];
      return index === undefined ? undefined : row[index];
    };

    return data.map((row, index) => {
      // Determine the first and last name from whatever combination of name fields we have.
      const { firstName, lastName } = extractNameParts({
        firstName: getValue(row, SourceField.FirstName),
        lastName: getValue(row, SourceField.LastName),
        fullName: getValue(row, SourceField.FullName),
      });

      // Find the project ID from whatever combination of job-related fields we have.
      const project = isMappingProjects
        ? extractProject(projects, searchProjects, {
            id: getValue(row, SourceField.JobOpeningId),
            name: getValue(row, SourceField.JobOpeningName),
            role: getValue(row, SourceField.JobTitle),
            location: getValue(row, SourceField.JobLocation),
          })
        : defaultProject;

      return {
        originalIndex: index,
        firstName,
        lastName,
        email: extractEmailAddress(getValue(row, SourceField.Email)),
        phone: extractPhoneNumber(getValue(row, SourceField.Phone)),
        project,
      };
    });
  }, [data, mapping, defaultProject, projects, searchProjects]);

  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [importedRowIndexes, setImportedRowIndexes] = useState<Set<number>>(
    new Set(),
  );
  const [failedRows, setFailedRows] = useState<Map<number, { reason: string }>>(
    new Map(),
  );

  const onImportClick = async () => {
    setIsImporting(true);
    try {
      const axios = await getAxiosInstanceWithAuth();

      const batches = chain(transformedData)
        .map((row, originalIndex) => {
          if (!checkIsRowValid(row)) {
            return undefined;
          }

          return {
            _original_index: originalIndex,
            first_name: row.firstName,
            last_name: row.lastName,
            email: row.email,
            phone_number: row.phone,
            project_id: row.project?.id,
          };
        })
        .compact()
        .chunk(CONTACTS_IMPORT_LIMIT)
        .value();

      for (const batch of batches) {
        const { data } = await axios.post("/contacts/import", {
          contacts: batch,
        });
        const {
          results: { imported_count, failed_contacts },
        } = z
          .object({
            results: z.object({
              imported_count: z.number(),
              failed_contacts: z.array(
                z.object({
                  contact: z.object({
                    _original_index: z.number(),
                  }),
                  reason: z.string(),
                }),
              ),
            }),
          })
          .parse(data);

        const failedRows = new Map(
          failed_contacts.map(
            (failedContact) =>
              [
                failedContact.contact._original_index,
                { reason: failedContact.reason },
              ] as const,
          ),
        );

        setImportedRowIndexes((prevImportedIndexes) => {
          const nextImportedIndexes = new Set(prevImportedIndexes);

          for (const { _original_index: rowIndex } of batch) {
            nextImportedIndexes.add(rowIndex);
          }

          for (const failedRow of failedRows.keys()) {
            nextImportedIndexes.delete(failedRow);
          }

          return nextImportedIndexes;
        });
        setFailedRows((prevFailedRows) => {
          const nextFailedRows = new Map(prevFailedRows);

          for (const { _original_index: rowIndex } of batch) {
            const failedRow = failedRows.get(rowIndex);

            if (failedRow) {
              nextFailedRows.set(rowIndex, failedRow);
            } else {
              nextFailedRows.delete(rowIndex);
            }
          }

          return nextFailedRows;
        });
      }
    } finally {
      setIsImporting(false);
    }
  };

  const totalRowCount = transformedData.length;
  const [invalidRowCount, failedRowCount, importedRowCount, pendingRowCount] =
    useMemo(
      () =>
        transformedData.reduce(
          ([invalid, failed, imported, pending], row, index) => {
            if (!checkIsRowValid(row)) {
              return [invalid + 1, failed, imported, pending];
            }

            if (failedRows.has(index)) {
              return [invalid, failed + 1, imported, pending];
            }

            if (importedRowIndexes.has(index)) {
              return [invalid, failed, imported + 1, pending];
            }

            return [invalid, failed, imported, pending + 1];
          },
          [0, 0, 0, 0],
        ),
      [transformedData, failedRows, importedRowIndexes],
    );

  const previewFilterOptions: readonly PreviewFilterOption[] = [
    {
      key: PreviewFilterKey.All,
      label: "All",
      color: PreviewFilterColor.Gray,
      count: totalRowCount,
      isVisible: true,
      rowPredicate: () => true,
    },
    {
      key: PreviewFilterKey.Invalid,
      label: "Invalid",
      color: PreviewFilterColor.Red,
      count: invalidRowCount,
      isVisible: invalidRowCount > 0 && invalidRowCount < totalRowCount,
      rowPredicate: (rowIndex: number) =>
        !checkIsRowValid(transformedData[rowIndex]) &&
        !failedRows.has(rowIndex) &&
        !importedRowIndexes.has(rowIndex),
    },
    {
      key: PreviewFilterKey.Pending,
      label: "Ready to import",
      color: PreviewFilterColor.Blue,
      count: pendingRowCount,
      isVisible: pendingRowCount > 0 && pendingRowCount < totalRowCount,
      rowPredicate: (rowIndex: number) =>
        checkIsRowValid(transformedData[rowIndex]) &&
        !failedRows.has(rowIndex) &&
        !importedRowIndexes.has(rowIndex),
    },
    {
      key: PreviewFilterKey.Failed,
      label: "Failed",
      color: PreviewFilterColor.Red,
      count: failedRowCount,
      isVisible: failedRowCount > 0 && failedRowCount < totalRowCount,
      rowPredicate: (rowIndex: number) => failedRows.has(rowIndex),
    },
    {
      key: PreviewFilterKey.Imported,
      label: "Imported",
      color: PreviewFilterColor.Green,
      count: importedRowCount,
      isVisible: importedRowCount > 0 && importedRowCount < totalRowCount,
      rowPredicate: (rowIndex: number) => importedRowIndexes.has(rowIndex),
    },
  ] as const;
  const [selectedPreviewFilterKey, setSelectedPreviewFilterKey] =
    useState<PreviewFilterKey>(PreviewFilterKey.All);
  const visiblePreviewFilterOptions = previewFilterOptions.filter(
    (option) => option.key === selectedPreviewFilterKey || option.isVisible,
  );

  const previewData = useMemo(() => {
    const predicate =
      previewFilterOptions.find(
        (option) => option.key === selectedPreviewFilterKey,
      )?.rowPredicate ?? (() => true);

    return transformedData.filter((_, index) => predicate(index));
  }, [transformedData, selectedPreviewFilterKey]);

  const scrollElementRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: previewData.length,
    getScrollElement: () => scrollElementRef.current,
    estimateSize: () => PREVIEW_ROW_HEIGHT,
    overscan: 10,
  });

  return (
    <Slideover
      // The z-index breaks our popover underlay, and we don't need it here anyway.
      skipsZIndex
      shouldShow={props.isOpen}
      onClickClose={props.onClose}
      title="Import candidates"
      buttons={
        failedRowCount + importedRowCount > 0 ? (
          <div className="flex items-center space-x-4">
            <div>
              {failedRowCount > 0 ? (
                <div className="flex items-center space-x-2">
                  <XCircleIcon className="shrink-0 size-5 text-red-600" />
                  <span className="text-sm">
                    {renderPlural(failedRowCount, {
                      one: "Failed to import 1 candidate",
                      other: (count) => `Failed to import ${count} candidates`,
                    })}
                  </span>
                </div>
              ) : null}
              {importedRowCount > 0 ? (
                <div className="flex items-center space-x-2">
                  <CheckCircleIcon className="shrink-0 size-5 text-green-600" />
                  <span className="text-sm">
                    {renderPlural(importedRowCount, {
                      one: "Imported 1 candidate successfully",
                      other: (count) =>
                        `Imported ${count} candidates successfully`,
                    })}
                  </span>
                </div>
              ) : null}
            </div>
            <Button
              variant={ButtonVariantsEnum.Primary}
              onClick={props.onClose}
            >
              Close
            </Button>
          </div>
        ) : (
          <>
            <Button
              variant={ButtonVariantsEnum.Secondary}
              onClick={props.onClose}
            >
              Cancel
            </Button>
            <Button
              variant={ButtonVariantsEnum.Primary}
              isDisabled={pendingRowCount === 0}
              isLoading={isImporting}
              onClick={onImportClick}
            >
              {renderPlural(pendingRowCount, {
                zero: "Import candidates",
                one: "Import 1 candidate",
                other: (count) => `Import ${count} candidates`,
              })}
            </Button>
          </>
        )
      }
    >
      <div className="overflow-hidden flex-auto flex flex-col">
        {file && transformedData ? (
          <div className="overflow-hidden flex-auto flex flex-col bg-gray-50">
            <div className="overflow-hidden grid grid-cols-[min-content_minmax(0,1fr)]">
              <div className="overflow-hidden flex flex-col">
                <div className="p-6">
                  <FileCard
                    name={file.name}
                    onDelete={() => void setFile(undefined)}
                  />
                </div>
                <div className="px-6 pb-4 space-y-2">
                  <Label size={SizesEnum.MEDIUM}>Map CSV columns</Label>
                  <p className="text-sm text-gray-500">
                    Specify how each column should be imported. Either email
                    address or phone number is required.
                  </p>
                </div>
                <div className="overflow-y-auto">
                  <div className="px-6 pb-6 grid grid-cols-[12rem_12rem] gap-2 items-start text-sm">
                    {header.map((headerLabel, index) => (
                      <Fragment key={index}>
                        <div className="pt-2 font-mono">
                          <div className="truncate font-semibold">
                            {headerLabel}
                          </div>
                          <div className="min-h-8">
                            {emptyColumnIndexes.has(index) ? (
                              <div className="text-xs text-gray-500">
                                (empty column)
                              </div>
                            ) : (
                              collectExampleValues(data, index, 2).map(
                                (value, index) => (
                                  <div
                                    key={index}
                                    className="truncate text-xs text-gray-500"
                                  >
                                    {value}
                                  </div>
                                ),
                              )
                            )}
                          </div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <ArrowRightIcon className="shrink-0 size-4" />
                          <SimpleSelect
                            label={`Mapping for ${headerLabel}`}
                            isLabelHidden
                            isDisabled={emptyColumnIndexes.has(index)}
                            placeholder="Don't import"
                            options={sourceFieldOptions}
                            disabledValues={mappedFields.filter(
                              (field) =>
                                field !==
                                getSourceFieldForColumnIndex(mapping, index),
                            )}
                            value={getSourceFieldForColumnIndex(mapping, index)}
                            onChange={(newValue) => {
                              setMapping((prevMapping) =>
                                setSourceFieldForColumnIndex(
                                  prevMapping,
                                  index,
                                  newValue || null,
                                ),
                              );
                            }}
                          />
                        </div>
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>

              <div className="overflow-hidden flex flex-col">
                {!isMappingValid ? (
                  <div className="flex-auto flex items-center justify-center">
                    <div className="text-gray-500 text-center max-w-96">
                      Use the dropdowns on the left to specify from which
                      columns to import the candidate&apos;s{" "}
                      <span className="font-semibold">email address</span> or{" "}
                      <span className="font-semibold">phone number</span>.
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="p-6">
                      <ToggleButtonGroup
                        className={previewFilterButtonGroup({
                          className:
                            visiblePreviewFilterOptions.length === 1
                              ? "invisible"
                              : "",
                        })}
                        selectedKeys={[selectedPreviewFilterKey]}
                        onSelectionChange={(keys) =>
                          void setSelectedPreviewFilterKey(
                            keys.values().next().value as any,
                          )
                        }
                      >
                        {visiblePreviewFilterOptions.map(
                          (option, index, array) => {
                            const style = previewFilterButton({
                              placement: computePreviewFilterButtonPlacement(
                                index,
                                array.length,
                              ),
                              color: option.color,
                            });

                            return (
                              <ToggleButton
                                key={option.key}
                                id={option.key}
                                className={style.base()}
                              >
                                <span className={style.label()}>
                                  {option.label}
                                </span>
                                <span className={style.pill()}>
                                  {option.count}
                                </span>
                              </ToggleButton>
                            );
                          },
                        )}
                      </ToggleButtonGroup>
                    </div>
                    <div className="px-6 pb-4 space-y-2">
                      <div className="flex items-center space-x-4">
                        <Label size={SizesEnum.MEDIUM}>Preview</Label>
                      </div>
                      <p className="text-sm text-gray-500">
                        Review rows for accuracy to ensure candidates will be
                        imported correctly. Click on a row for details.
                      </p>
                    </div>
                    <div
                      ref={scrollElementRef}
                      className="overflow-auto h-full"
                    >
                      <div className="px-6 pb-4 text-sm">
                        <div
                          style={{
                            height: `${virtualizer.getTotalSize()}px`,
                          }}
                        >
                          {virtualizer
                            .getVirtualItems()
                            .map((virtualRow, index) => {
                              const row = previewData[virtualRow.index];

                              return (
                                <div
                                  key={index}
                                  className="overflow-hidden pb-1 flex"
                                  style={{
                                    height: `${virtualRow.size}px`,
                                    transform: `translateY(${
                                      virtualRow.start - index * virtualRow.size
                                    }px)`,
                                  }}
                                >
                                  <DialogTrigger>
                                    <AriaButton
                                      className={twJoin(
                                        "flex-auto p-2 grid grid-cols-[minmax(0,2fr)_minmax(0,4fr)_minmax(0,3fr)] gap-2 items-center rounded-md text-gray-900 shadow-sm text-left",
                                        twMerge(
                                          "border border-gray-300 bg-white",
                                          importedRowIndexes.has(
                                            row.originalIndex,
                                          )
                                            ? "border-green-500 bg-green-50"
                                            : "",
                                          failedRows.has(row.originalIndex)
                                            ? "border-red-500 bg-red-50"
                                            : "",
                                        ),
                                      )}
                                    >
                                      <div>
                                        <div className="truncate">
                                          {row.firstName ? (
                                            <span className="font-semibold">
                                              {row.firstName}
                                            </span>
                                          ) : (
                                            <span className="italic text-gray-400">
                                              no first name
                                            </span>
                                          )}
                                        </div>
                                        <div className="truncate">
                                          {row.lastName ? (
                                            <span>{row.lastName}</span>
                                          ) : (
                                            <span className="italic text-gray-400">
                                              no last name
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        {row.email || row.phone ? (
                                          <>
                                            <div className="flex items-center space-x-2">
                                              {row.email ? (
                                                <>
                                                  <EnvelopeIcon className="shrink-0 size-3 text-gray-500" />
                                                  <span className="truncate">
                                                    {row.email}
                                                  </span>
                                                </>
                                              ) : (
                                                <>
                                                  <EnvelopeIcon className="shrink-0 size-3 text-gray-300" />
                                                  <span className="truncate italic text-gray-400">
                                                    no email address
                                                  </span>
                                                </>
                                              )}
                                            </div>
                                            <div className="flex items-center space-x-2">
                                              {row.phone ? (
                                                <>
                                                  <PhoneIcon className="shrink-0 size-3 text-gray-500" />
                                                  <span className="truncate">
                                                    {row.phone}
                                                  </span>
                                                </>
                                              ) : (
                                                <>
                                                  <PhoneIcon className="shrink-0 size-3 text-gray-300" />
                                                  <span className="truncate italic text-gray-400">
                                                    no phone number
                                                  </span>
                                                </>
                                              )}
                                            </div>
                                          </>
                                        ) : (
                                          <span className="italic text-red-500">
                                            email address or phone number is
                                            required
                                          </span>
                                        )}
                                      </div>
                                      <div className="line-clamp-2">
                                        {row.project ? (
                                          <span>{row.project.name}</span>
                                        ) : (
                                          <span className="italic text-red-500">
                                            job opening not found
                                          </span>
                                        )}
                                      </div>
                                    </AriaButton>

                                    <Popover
                                      placement="start"
                                      offset={16}
                                      className="group rounded-md bg-white shadow-lg ring-1 ring-black/5 data-[entering]:animate-popover-enter data-[exiting]:animate-popover-exit"
                                    >
                                      <OverlayArrow>
                                        <svg
                                          viewBox="0 0 12 12"
                                          className="block size-4 group-placement-left:-rotate-90 group-placement-bottom:rotate-180 group-placement-right:rotate-270"
                                        >
                                          <path
                                            className="fill-white stroke-black/5"
                                            d="M0 0L6 6L12 0"
                                          />
                                        </svg>
                                      </OverlayArrow>
                                      <Dialog className="p-4 space-y-4 text-sm">
                                        {importedRowIndexes.has(
                                          row.originalIndex,
                                        ) ? (
                                          <div className="font-semibold text-green-600">
                                            Imported successfully
                                          </div>
                                        ) : failedRows.has(
                                            row.originalIndex,
                                          ) ? (
                                          <div className="space-y-2 text-red-600">
                                            <div className="font-semibold">
                                              Failed to import:{" "}
                                            </div>
                                            <div>
                                              {failedRows.get(row.originalIndex)
                                                ?.reason || "(unknown reason)"}
                                            </div>
                                          </div>
                                        ) : null}
                                        <div className="space-y-2">
                                          <div className="font-semibold">
                                            Original CSV row values:
                                          </div>
                                          <div className="max-w-80 max-h-80 grid grid-cols-[minmax(min-content,1fr)_minmax(min-content,2fr)] gap-x-1 overflow-auto">
                                            {data[row.originalIndex].map(
                                              (value, index) => (
                                                <Fragment key={index}>
                                                  <div className="truncate font-semibold text-xs font-mono">
                                                    {header[index]}
                                                  </div>
                                                  <div className="truncate text-xs font-mono">
                                                    {value}
                                                  </div>
                                                </Fragment>
                                              ),
                                            )}
                                          </div>
                                        </div>
                                      </Dialog>
                                    </Popover>
                                  </DialogTrigger>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : isParsing ? (
          <div className="p-6 grid grid-cols-5 gap-4">
            {times(50).map((index) => (
              <div
                key={index}
                className="h-5 bg-gray-200 rounded-full animate-pulse"
              />
            ))}
          </div>
        ) : (
          <div className="p-6 space-y-6">
            <div className="text-sm text-gray-900 space-y-2">
              <p>
                Add many candidates to this and other job openings by uploading
                a CSV file.
              </p>
              <p>
                The file must include an email address or phone number for each
                candidate and may also include a first name, last name, and/or
                full name. To add candidates to many job openings at once, the
                file must include a job opening ID or job opening name.{" "}
                <Link
                  className="font-medium text-blue-600 hover:text-blue-500"
                  href={sampleCsvUri}
                >
                  Download this sample CSV file
                </Link>{" "}
                for reference.
              </p>
              <p>
                You'll be able to customize and preview the import on the next
                step.
              </p>
            </div>
            <div
              {...getDropzoneRootProps()}
              className={twJoin(
                "group cursor-pointer flex justify-center rounded-lg border border-dashed border-gray-300 px-6 py-10 hover:border-gray-400 hover:bg-gray-50",
                isDropzoneDragAccept ? "border-green-500 bg-green-100" : "",
                isDropzoneDragReject ? "border-red-500 bg-red-100" : "",
              )}
            >
              <div className="text-center">
                <DocumentArrowUpIcon
                  aria-hidden="true"
                  className="mx-auto h-12 w-12 text-gray-300"
                />
                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                  <label
                    className="font-semibold text-indigo-600 group-hover:text-indigo-500 cursor-pointer"
                    htmlFor="file-upload"
                  >
                    <span>Upload a CSV file</span>
                    <input
                      {...getDropzoneInputProps()}
                      className="sr-only"
                      id="file-upload"
                      name="file-upload"
                      type="file"
                    />
                  </label>
                  <p className="pl-1">or drag and drop.</p>
                </div>
                <p className="text-xs leading-5 text-gray-600">
                  Up to {(MAX_SIZE_BYTES / (1024 * 1024)).toFixed()} MB
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Slideover>
  );
};
