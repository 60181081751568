import { ExclamationCircleIcon } from "@heroicons/react/20/solid"; // Make sure to install @heroicons/react
import { XCircleIcon } from "@heroicons/react/24/outline";
import { ReactNode, forwardRef } from "react";
import { ButtonVariantsEnum } from "../Buttons/button";
import { IconButton } from "../Buttons/iconButton";
import { SizesEnum } from "../helpers/helpers";
import { Label } from "../Label/label";

export interface InputProps {
  label?: string;
  id?: string;
  placeholder?: string;
  description?: string | React.ReactNode;
  errorDescription?: string;
  value: string;
  autoComplete?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
  isDisabled?: boolean;
  minLength?: number;
  maxLength?: number;
  leftIcon?: ReactNode;
  required?: boolean;
  onClear?: () => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  inputClassName?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    id,
    label,
    placeholder,
    description,
    errorDescription,
    value,
    onChange,
    onKeyPress,
    onKeyDown,
    onClear,
    leftIcon,
    inputClassName,
    onBlur,
    required,
  } = props;
  const hasError = Boolean(errorDescription);

  const textColor = hasError ? "text-red-900" : "text-gray-900";
  const ringColor = hasError ? "ring-red-300" : "ring-gray-300";
  const placeholderColor = hasError
    ? "placeholder-red-400"
    : "placeholder-gray-400";
  const focusColor = hasError ? "focus:ring-red-500" : "focus:ring-indigo-500";
  const disabledClassNames = props.isDisabled
    ? `disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none`
    : "";

  const descriptionClassNames = `
    mt-2 text-sm ${hasError ? "text-red-600" : "text-gray-600"}
  `;
  const descriptionText = errorDescription ?? description;

  const renderRightIcon = (): JSX.Element | null => {
    if (hasError) {
      return (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ExclamationCircleIcon
            aria-hidden="true"
            className="h-5 w-5 text-red-500"
          />
        </div>
      );
    }

    if (onClear && value) {
      return (
        <div className="absolute inset-y-0 right-0 flex items-center pr-2">
          <IconButton
            icon={<XCircleIcon className="h-4 w-4 text-gray-700" />}
            onClick={onClear}
            variant={ButtonVariantsEnum.Tertiary}
          />
        </div>
      );
    }

    return null;
  };
  const rightIcon = renderRightIcon();

  const inputClassNames = `
    block w-full rounded-md border-0 py-1.5 ${textColor} shadow-sm ring-1 ring-inset ${ringColor} ${placeholderColor} focus:ring-2 focus:ring-inset ${focusColor} text-sm sm:leading-6 ${disabledClassNames} ${
      leftIcon ? "pl-8" : ""
    } ${rightIcon ? "pr-8" : ""} ${inputClassName ?? ""}
  `;

  return (
    <>
      {label ? (
        <Label size={SizesEnum.SMALL}>
          {label}
          {required ? <span className="text-red-500 ml-1">*</span> : null}
        </Label>
      ) : null}
      <div
        className={`relative ${label ? "mt-2" : ""} ${
          hasError ? "rounded-md shadow-sm" : ""
        }`}
      >
        {leftIcon ? (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2.5">
            {leftIcon}
          </div>
        ) : null}
        <input
          aria-describedby={`${id}-description`}
          aria-invalid={hasError}
          autoComplete={props.autoComplete}
          autoFocus={props.autoFocus}
          className={inputClassNames}
          disabled={props.isDisabled}
          id={id}
          maxLength={props.maxLength ?? 100}
          minLength={props.minLength ?? 0}
          onBlur={onBlur}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          onPaste={props.onPaste}
          placeholder={placeholder}
          ref={ref}
          type="text" // TODO: add support for other input types
          value={value}
        />
        {rightIcon}
      </div>
      {descriptionText ? (
        <p className={descriptionClassNames} id={`${id}-description`}>
          {descriptionText}
        </p>
      ) : null}
    </>
  );
});

Input.displayName = "Input";
