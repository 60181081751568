import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { PressEvents } from "react-aria";
import { Button as AriaButton, SlotProps } from "react-aria-components";
import { twJoin } from "tailwind-merge";

export interface SuggestionsPillProps extends PressEvents, SlotProps {
  isLoading?: boolean;
  count: number | undefined;
}

export const SuggestionsPill: FC<SuggestionsPillProps> = (props) => {
  const { isLoading, count, ...buttonProps } = props;

  if (!isLoading && count === 0) {
    return null;
  }

  return (
    <AriaButton
      {...buttonProps}
      className={twJoin(
        "flex flex-row items-center px-1 py-0.5 rounded-full text-xs font-medium cursor-pointer",
        isLoading
          ? "text-purple-500 animate-pulse"
          : "bg-purple-100 text-purple-700 hover:bg-purple-200 hover:text-purple-800",
      )}
      isPending={isLoading}
    >
      <SparklesIcon
        className={twJoin(
          "size-4",
          isLoading ? "text-purple-400" : "text-purple-600",
        )}
      />
      {count !== undefined ? (
        <div className="px-1">
          {count} {count === 1 ? "suggestion" : "suggestions"}
        </div>
      ) : null}
      {!isLoading ? (
        <ChevronDownIcon className="size-4 text-purple-600" />
      ) : null}
    </AriaButton>
  );
};
