import { CreditCardIcon } from "@heroicons/react/24/outline";
import { LoadingStatesEnum } from "app-types";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonVariantsEnum,
  Checkbox,
  Label,
  Select,
  SizesEnum,
} from "@repo/ui";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  fetchBillingInfo,
  selectBillingLoadingState,
  selectDefaultPaymentMethod,
} from "../../billing/billingSlice";

const incentiveDropdownOptions = [
  { name: "None", value: 0 },
  { name: "$5 gift card", value: 5 },
  { name: "$10 gift card", value: 10 },
  { name: "$15 gift card", value: 15 },
  { name: "$20 gift card", value: 20 },
  { name: "$25 gift card", value: 25 },
  { name: "$35 gift card", value: 35 },
  { name: "$50 gift card", value: 50 },
  { name: "$60 gift card", value: 60 },
  { name: "$70 gift card", value: 70 },
  { name: "$100 gift card", value: 100 },
  { name: "$150 gift card", value: 100 },
  { name: "$200 gift card", value: 100 },
];

interface IncentiveSettingsSectionProps {
  incentiveQuantity: number | null;
  isIncentiveCharityOnly: boolean;
  onIncentiveQuantityChange: (incentiveQuantity: number | null) => void;
  onIncentiveCharityOnlyChange: (isIncentiveCharityOnly: boolean) => void;
}

export const IncentiveSettingsSection: FC<IncentiveSettingsSectionProps> = ({
  incentiveQuantity,
  isIncentiveCharityOnly,
  onIncentiveQuantityChange,
  onIncentiveCharityOnlyChange,
}) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const defaultPaymentMethod = useAppSelector(selectDefaultPaymentMethod);
  const billingLoadingState = useAppSelector(selectBillingLoadingState);

  // Load billing info if necessary
  useEffect(() => {
    if (billingLoadingState !== LoadingStatesEnum.LOADED)
      dispatch(fetchBillingInfo());
  }, []);

  return (
    <div>
      <Label size={SizesEnum.LARGE}>Incentive</Label>
      <div className="flex flex-row space-x-4 items-end">
        <div className="mt-2 w-52">
          <Select
            options={incentiveDropdownOptions}
            currentSelection={incentiveDropdownOptions.find(
              (o) => o.value === incentiveQuantity,
            )}
            placeholder="Select an incentive"
            onChange={(option) => {
              const newQuantity = incentiveDropdownOptions.find(
                (o) => o.name === option.name,
              )?.value;
              // Account for case where newQuantity is 0 (falsy)
              if (newQuantity !== null && newQuantity !== undefined)
                onIncentiveQuantityChange(newQuantity);
            }}
          />
        </div>
        {!defaultPaymentMethod &&
          billingLoadingState === LoadingStatesEnum.LOADED && (
            <div>
              <Button
                variant={ButtonVariantsEnum.Secondary}
                icon={<CreditCardIcon className="h-5 w-5" />}
                onClick={() => {
                  navigate(`/settings/billing`);
                }}
              >
                Add payment method
              </Button>
            </div>
          )}
      </div>
      <div className="mt-2 ml-1">
        <Checkbox
          isChecked={isIncentiveCharityOnly}
          onChange={(isChecked) => onIncentiveCharityOnlyChange(isChecked)}
          label="Only provide charity donation options to the participant"
        />
      </div>
      <div className="mt-2 text-sm text-gray-600">
        Incentives drive higher quality feedback and reduce selection bias.
        Participants receive a gift card (choice of Amazon, Visa, charity
        donation, and more) after they complete an interview. 100% of your
        incentive goes to the participant.
      </div>
    </div>
  );
};
