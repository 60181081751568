import {
  Company,
  CompanyInvitation,
  CompanyInvitationErrorsEnum,
  LoadingStatesEnum,
  PosthogEventTypesEnum,
  Teammate,
} from "app-types";
import axios from "axios";
import posthog from "posthog-js";
import { FC, useEffect, useState } from "react";
import { Loader, LoaderStylesEnum, TextCarouselLoader } from "@repo/ui";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { signoutUser } from "../../api/supabaseService";
import { useAppSelector } from "../../hooks/hook";
import { selectCompanyLoadingState } from "../company/companySlice";
import OnboardingForm from "./onboardingForm";

interface OnboardingFormContainerProps {
  teammate: Teammate;
  company: Company | null;
  companyInvitationToken: string | null;
}

export const OnboardingFormContainer: FC<OnboardingFormContainerProps> = (
  props,
) => {
  const { teammate, company, companyInvitationToken } = props;

  const errorEnumToErrorMessage = {
    [CompanyInvitationErrorsEnum.NOT_FOUND]:
      "Sorry, we couldn't find that invitation. Please ask your admin to invite you again.",
    [CompanyInvitationErrorsEnum.EXPIRED]:
      "This invitation has expired. Please ask your admin to invite you again.",
    [CompanyInvitationErrorsEnum.NO_EMAIL_MATCH]: `The email on this invitation doesn't match the email you've logged in with (${teammate.email}). Please change accounts or ask your admin to invite you again. `,
    [CompanyInvitationErrorsEnum.ALREADY_JOINED]:
      "You've already joined this company.",
  };

  const [companyInvitation, setCompanyInvitation] =
    useState<CompanyInvitation | null>(null);
  const [companyInvitationLoadingState, setCompanyInvitationLoadingState] =
    useState<LoadingStatesEnum>(
      companyInvitationToken
        ? LoadingStatesEnum.LOADING
        : LoadingStatesEnum.LOADED,
    );
  const [companyInvitationError, setCompanyInvitationError] =
    useState<CompanyInvitationErrorsEnum | null>(null);

  const [existingPendingInvitationToken, setExistingPendingInvitationToken] =
    useState<string | null>();
  const companyLoadingState = useAppSelector(selectCompanyLoadingState);

  const [domainLoginLoadingState, setDomainLoginLoadingState] =
    useState<LoadingStatesEnum>(
      companyInvitationToken
        ? LoadingStatesEnum.LOADED
        : LoadingStatesEnum.LOADING,
    );
  // The name of the company the user is eligible to domain-login to (if any);
  const [domainLoginCompanyName, setDomainLoginCompanyName] =
    useState<string>();
  // Whether the user should domain login to an eligible company. Only relevant if
  // `domainLoginCompanyName` is set.
  const [isJoiningMatchingDomainCompany, setIsJoiningMatchingDomainCompany] =
    useState(false);

  // Whether the user's domain is eligible to be claimed by a new company.
  // If true, this means no other company has claimed the domain and
  // the domain is not a common/free domain.
  const [isDomainAvailable, setIsDomainAvailable] = useState<boolean>(false);
  const [
    shouldEnableDomainLoginForNewCompany,
    setShouldEnableDomainLoginForNewCompany,
  ] = useState(true);

  // If we have an invitation token, fetch it on initial load
  // If we don't have an invitation token, check if there is a company to domain-login to
  useEffect(() => {
    const fetchInvitation = async () => {
      try {
        const axios = await getAxiosInstanceWithAuth();
        const { data } = await axios.get(
          `/company-invitations/invitation-for-token?t=${companyInvitationToken}`,
        );

        setCompanyInvitation(data);
        setCompanyInvitationLoadingState(LoadingStatesEnum.LOADED);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          const errorCode = err.response.data.errorCode;

          // If the user is already a member of the company we're viewing an invite for, redirect home
          if (errorCode === CompanyInvitationErrorsEnum.ALREADY_JOINED)
            window.location.href = "/";

          setCompanyInvitationError(errorCode);
        }
        setCompanyInvitationLoadingState(LoadingStatesEnum.ERROR);
      }
    };

    // Checks if there is a pending invitation or matching domain-login company for the teammate.
    const fetchPendingInvitationOrCompanyForTeammate = async () => {
      try {
        const axios = await getAxiosInstanceWithAuth();
        const { data } = await axios.get(`/domains/company-for-teammate`);

        // If there's a pending invite for this user, use it. This case
        // occurs when a user is invited, but signs in without using their invite link.
        if (data && data.pending_invitation) {
          setCompanyInvitation(data.pending_invitation);
          setExistingPendingInvitationToken(data.pending_invitation_token);
          setDomainLoginLoadingState(LoadingStatesEnum.LOADED);
          return;
        }

        if (data) {
          if (data.matching_company_name) {
            setDomainLoginCompanyName(data.matching_company_name);
            setIsJoiningMatchingDomainCompany(true); // Default to domain login
          }

          setIsDomainAvailable(data.is_company_domain_available);
        }

        setDomainLoginLoadingState(LoadingStatesEnum.LOADED);
      } catch (err) {
        setDomainLoginLoadingState(LoadingStatesEnum.ERROR);
      }
    };

    // If we have an invitation token directly from the URL, fetch the corresponding invite.
    if (companyInvitationToken) {
      fetchInvitation();
    } else {
      fetchPendingInvitationOrCompanyForTeammate();
    }
  }, []);

  const onAcceptCompanyInvitation = async () => {
    try {
      setCompanyInvitationLoadingState(LoadingStatesEnum.LOADING);
      const axios = await getAxiosInstanceWithAuth();
      await axios.post(
        `/company-invitations/invitation-for-token?t=${
          companyInvitationToken || existingPendingInvitationToken
        }`,
      );

      // Reload the app now that we've joined a new company
      window.location.reload();
      return;
    } catch (err) {
      setCompanyInvitationLoadingState(LoadingStatesEnum.ERROR);
      return;
    }
  };

  const onJoinCompanyViaDomainLogin = async () => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      await axios.post(`/domains/company-for-teammate-domain`);

      posthog.capture(PosthogEventTypesEnum.TeammateDomainLogin, {
        teammate_email: teammate.email,
      });
      // Reload the app now that we've joined a new company
      window.location.href = "/";
      return;
    } catch (err) {
      setDomainLoginLoadingState(LoadingStatesEnum.ERROR);
      return;
    }
  };

  const renderContent = () => {
    if (
      companyInvitationLoadingState === LoadingStatesEnum.LOADING ||
      domainLoginLoadingState === LoadingStatesEnum.LOADING
    )
      return (
        <div className="flex flex-col justify-center items-center h-40">
          <Loader style={LoaderStylesEnum.ZOOMIES} />
        </div>
      );

    if (
      companyInvitationLoadingState === LoadingStatesEnum.ERROR ||
      (companyInvitationToken && !companyInvitation)
    ) {
      const errorMessage = companyInvitationError
        ? errorEnumToErrorMessage[companyInvitationError]
        : "We couldn't find that invitation. Please refresh or request a new invitation from your admin.";

      return <div className="text-xl text-gray-800">{errorMessage}</div>;
    }

    if (companyLoadingState === LoadingStatesEnum.LOADING) {
      return (
        <div className="flex flex-col justify-center items-center h-40">
          <TextCarouselLoader
            messages={[
              "Creating your account...",
              "Setting up your organization...",
              "Preparing demo project...",
            ]}
          />
          <Loader style={LoaderStylesEnum.ZOOMIES} />
        </div>
      );
    }

    return (
      <OnboardingForm
        companyInvitation={companyInvitation}
        company={company}
        teammate={teammate}
        onAcceptCompanyInvitation={onAcceptCompanyInvitation}
        onJoinCompanyViaDomainLogin={onJoinCompanyViaDomainLogin}
        domainLoginCompanyName={domainLoginCompanyName}
        isDomainAvailable={isDomainAvailable}
        isJoiningMatchingDomainCompany={isJoiningMatchingDomainCompany}
        setIsJoiningMatchingDomainCompany={setIsJoiningMatchingDomainCompany}
      />
    );
  };

  return (
    <>
      <nav className="bg-gray-800">
        <div className="mx-auto px-3">
          <div className="flex h-12 items-center justify-end">
            <button
              type="button"
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={signoutUser}
            >
              Sign out
            </button>
          </div>
        </div>
      </nav>
      <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
        <div className="bg-white py-6 px-8 shadow">{renderContent()}</div>
      </div>
    </>
  );
};
