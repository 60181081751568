import { Menu } from "@headlessui/react";
import { PhoneIcon } from "@heroicons/react/24/solid";
import {
  Button,
  ButtonVariantsEnum,
  DropdownMenu,
  Label,
  Select,
  SizesEnum,
  TextSkeleton,
} from "@repo/ui";
import {
  allTranslationLanguages,
  CompletedInterviewCallMetadata,
  InterviewCallMetadata,
  InterviewInsights,
  InterviewLanguagesEnum,
  LANGUAGE_DISPLAY_NAMES,
  LoadingStatesEnum,
  TranscriptFragment,
} from "app-types";
import type { AdminApiInterviewSectionResult } from "app-types/entities/interviewSection/result";
import type { InterviewSectionSettings } from "app-types/entities/interviewSection/settings";
import { InterviewSectionType } from "app-types/entities/interviewSection/types";
import {
  FC,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
  type ReactNode,
} from "react";
import { useLocation } from "react-router-dom";
import { TranscriptFragmentText } from "../../insights/transcriptFragmentText";
import { UnansweredQuestionsBanner } from "./unansweredQuestionsBanner";

const STICKY_HEADER_HEIGHT = 80;

interface InterviewTranscriptProps {
  loadingState: LoadingStatesEnum;
  interviewSectionResults: readonly AdminApiInterviewSectionResult[];
  transcriptFragments: TranscriptFragment[];
  highlightedTranscriptFragment: string | null;
  onClickShare: (fragment: TranscriptFragment) => void;
  onHighlightFragment: (fragmentId: string | null) => void;
  calls: CompletedInterviewCallMetadata[];
  recordingIdToUrl: Record<string, string>;
  callIdToVideoUrl: Record<string, string>;
  interviewInsights: InterviewInsights | null;
  onClickTranslate?: (targetLanguage: InterviewLanguagesEnum) => void;
  jumpToFragmentByText: (text: string) => void;
}

const speedOptions = [
  { name: "1x speed", value: 1 },
  { name: "1.25x speed", value: 1.25 },
  { name: "1.5x speed", value: 1.5 },
  { name: "2x speed", value: 2 },
];

interface BaseTimelineEvent {
  type: string;
  timestamp: number;
  data: unknown;
}

interface TranscriptTimelineEvent extends BaseTimelineEvent {
  type: "transcript";
  data: TranscriptFragment;
}

interface CallTimelineEvent extends BaseTimelineEvent {
  type: "call_start" | "call_end";
  data: CompletedInterviewCallMetadata;
}

interface InterviewSectionTimelineEvent extends BaseTimelineEvent {
  type: "section_start";
  data: AdminApiInterviewSectionResult;
}

type TimelineEvent =
  | TranscriptTimelineEvent
  | CallTimelineEvent
  | InterviewSectionTimelineEvent;

// Create translation dropdown items
const translateDropdownItems = allTranslationLanguages.map((language) => ({
  label: LANGUAGE_DISPLAY_NAMES[language],
  value: language,
}));

export const InterviewTranscript: FC<InterviewTranscriptProps> = ({
  loadingState,
  interviewSectionResults,
  transcriptFragments,
  highlightedTranscriptFragment,
  onClickShare,
  onHighlightFragment,
  calls,
  recordingIdToUrl,
  callIdToVideoUrl,
  onClickTranslate,
  interviewInsights,
  jumpToFragmentByText,
}) => {
  const [failedUrls, setFailedUrls] = useState<Set<string>>(new Set());
  const [playbackSpeed, setPlaybackSpeed] = useState(
    speedOptions.find((option) => option.value === 1),
  );
  const callIdToAudioRef = useRef<{ [key: string]: HTMLAudioElement | null }>(
    {},
  );
  const transcriptFragmentRefs = useRef<{ [key: string]: HTMLDivElement }>({});
  const location = useLocation();

  const [selectedLanguage, setSelectedLanguage] =
    useState<InterviewLanguagesEnum | null>(null);

  const jumpToFragmentById = (fragmentId: string) => {
    if (transcriptFragmentRefs.current[fragmentId]) {
      onHighlightFragment(fragmentId);
      setTimeout(() => onHighlightFragment(null), 5000);
      transcriptFragmentRefs.current[fragmentId].scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  // Jump to fragment if fragment ID is included as a query param
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const fragmentId = queryParams.get("f");

    if (fragmentId && transcriptFragments.length > 0) {
      jumpToFragmentById(fragmentId);
    }
  }, [location.search, transcriptFragments]);

  // Jump to fragment when the highlighted fragment ID changes
  useEffect(() => {
    if (highlightedTranscriptFragment) {
      jumpToFragmentById(highlightedTranscriptFragment);
    }
  }, [highlightedTranscriptFragment]);

  // Update playback speed on audio elements when it changes
  useEffect(() => {
    Object.values(callIdToAudioRef.current).forEach((audio) => {
      if (audio && playbackSpeed) {
        audio.playbackRate = playbackSpeed.value;
      }
    });
  }, [playbackSpeed]);

  const handleAudioError = (url: string) => {
    setFailedUrls((prev) => new Set(prev).add(url));
  };

  const getVideoUrl = (call: InterviewCallMetadata) => {
    return call.processed_video_key && callIdToVideoUrl[call.id];
  };

  const getAudioRecordingUrl = (call: InterviewCallMetadata) => {
    return (
      // Web call case where we store the recording URL directly from retell
      call.recording_url ||
      // Phone call case where we store the twilio recording ID and then use a presigned URL to get the item in AWS
      (call.twilio_recording_id && recordingIdToUrl[call.twilio_recording_id])
    );
  };

  // Add a ref callback to set the initial playback speed when audio elements are created
  const setAudioRef = (id: string, element: HTMLAudioElement | null) => {
    callIdToAudioRef.current[id] = element;
    if (element && playbackSpeed) {
      element.playbackRate = playbackSpeed.value;
    }
  };

  // Handles user clicking on a transcript fragment to play the corresponding audio
  const playAudioAtFragment = (fragment: TranscriptFragment) => {
    const fragmentTime = new Date(fragment.start_time).getTime();

    // Find the corresponding call by checking if fragment time falls between call start/end
    const matchingCall = calls.find((call) => {
      const startTime = new Date(call.start_time).getTime();
      const endTime = new Date(call.end_time).getTime();
      return fragmentTime >= startTime && fragmentTime <= endTime;
    });

    if (matchingCall) {
      const audio = callIdToAudioRef.current[matchingCall.id];
      if (audio) {
        // Calculate relative position in seconds
        const startTime = new Date(matchingCall.start_time).getTime();
        const relativePosition = (fragmentTime - startTime) / 1000; // Convert to seconds

        audio.currentTime = relativePosition;
        audio.play();
      }
    }
  };

  const timelineEvents = useMemo(() => {
    // Create chronological timeline of transcript fragments and call events
    const events: TimelineEvent[] = transcriptFragments.map((fragment) => ({
      type: "transcript",
      timestamp: new Date(fragment.start_time).getTime(),
      data: fragment,
    }));

    // Add call events
    for (const call of calls) {
      if (call.start_time) {
        events.push({
          type: "call_start",
          timestamp: new Date(call.start_time).getTime(),
          data: call,
        });
      }
      if (call.end_time) {
        events.push({
          type: "call_end",
          timestamp: new Date(call.end_time).getTime(),
          data: call,
        });
      }
    }

    // Sort by timestamp
    events.sort((a, b) => a.timestamp - b.timestamp);

    // Insert interview section markers
    let markedSectionResultId: string | undefined;
    for (let i = 0; i < events.length; i++) {
      // Determine the section for the current event
      const event = events[i];
      const sectionResultId =
        event.type === "call_start" || event.type === "transcript"
          ? event.data.interview_section_result_id
          : undefined;

      // Check if the section has changed since the last marker
      if (!sectionResultId || sectionResultId === markedSectionResultId) {
        continue;
      }
      markedSectionResultId = sectionResultId;

      // Insert a marker for the new section
      const interviewSectionResult = interviewSectionResults.find(
        (section) => section.id === sectionResultId,
      );
      if (interviewSectionResult) {
        events.splice(i, 0, {
          type: "section_start",
          timestamp: event.timestamp,
          data: interviewSectionResult,
        });
        i++; // Increment after splice to account for the newly inserted element
      }
    }

    return events;
  }, [interviewSectionResults, transcriptFragments, calls]);

  const hasRecordings = calls.some(
    (call) => getVideoUrl(call) || getAudioRecordingUrl(call),
  );

  if (loadingState === LoadingStatesEnum.LOADING) {
    return (
      <div className="w-full mt-5 flex flex-col space-y-5">
        <TextSkeleton />
        <TextSkeleton />
      </div>
    );
  }

  if (loadingState === LoadingStatesEnum.ERROR) {
    return (
      <div className="mt-2 text-sm text-orange-600">
        An error occurred while loading the transcript. Please refresh and try
        again.
      </div>
    );
  }

  const renderCallMarker = (
    type: "call_start" | "call_end",
    call: CompletedInterviewCallMetadata,
  ) => {
    const isStart = type === "call_start";
    const timestamp = new Date(isStart ? call.start_time : call.end_time);
    const formattedTime = timestamp.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZoneName: "short",
    });

    return (
      <div>
        <div className="flex items-center justify-between py-2 text-sm">
          <div className="flex items-center space-x-2">
            <PhoneIcon
              className={`h-4 w-4 ${
                isStart ? "text-green-500" : "text-red-500"
              }`}
            />
            <span>{isStart ? "Call started" : "Call ended"}</span>
            <span className="text-gray-500">{formattedTime}</span>
          </div>
        </div>
        {isStart && (
          <div className="my-2">
            {(() => {
              const videoUrl = getVideoUrl(call);

              if (videoUrl) {
                return (
                  <video
                    ref={(el) => setAudioRef(call.id, el)}
                    controls
                    src={videoUrl}
                    className="max-w-[300px] w-full"
                    onError={() => handleAudioError(videoUrl)}
                  >
                    Your browser does not support the video element.
                  </video>
                );
              }

              const audioUrl = getAudioRecordingUrl(call);
              if (!audioUrl || failedUrls.has(audioUrl)) return null;

              return (
                <audio
                  ref={(el) => setAudioRef(call.id, el)}
                  controls
                  src={audioUrl}
                  onError={() => handleAudioError(audioUrl)}
                >
                  Your browser does not support the audio element.
                </audio>
              );
            })()}
          </div>
        )}
        {!isStart && <div className="border-b border-gray-200 my-2" />}
      </div>
    );
  };

  const renderSectionMarker = (section: AdminApiInterviewSectionResult) => (
    <Label size={SizesEnum.SMALL} className="mt-4 mb-2">
      {renderSectionMarkerTitle(section.settings)}
    </Label>
  );

  return (
    <>
      {interviewInsights?.unanswered_candidate_questions && (
        <UnansweredQuestionsBanner
          unansweredQuestions={interviewInsights.unanswered_candidate_questions}
          jumpToFragmentByText={jumpToFragmentByText}
        />
      )}
      <div className="flex items-center gap-4 mb-4">
        {hasRecordings && (
          <div className="w-[150px]">
            <Select
              options={speedOptions}
              currentSelection={playbackSpeed}
              onChange={({ name }) =>
                setPlaybackSpeed(
                  speedOptions.find((option) => option.name === name),
                )
              }
              placeholder="Select speed"
            />
          </div>
        )}
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button as={Fragment}>
            <Button variant={ButtonVariantsEnum.Secondary}>
              {selectedLanguage
                ? `Translated to ${LANGUAGE_DISPLAY_NAMES[selectedLanguage]}`
                : "Translate to..."}
            </Button>
          </Menu.Button>

          <DropdownMenu
            items={translateDropdownItems.map((item) => ({
              label: item.label,
              onClick: () => {
                setSelectedLanguage(item.value);
                onClickTranslate?.(item.value);
              },
            }))}
          />
        </Menu>
      </div>
      {timelineEvents.map((event, i) => {
        if (event.type === "transcript") {
          const fragment = event.data;

          // Display the original transcript or a translated version if selected
          const displayText =
            selectedLanguage &&
            fragment.text_transcript_translations?.[selectedLanguage]
              ? fragment.text_transcript_translations[selectedLanguage]
              : fragment.text_transcript;

          return (
            <div
              key={fragment.id}
              ref={(el) => {
                if (el) {
                  transcriptFragmentRefs.current[fragment.id] = el;
                }
              }}
              className={`text-sm text-gray-900 cursor-pointer ${
                fragment.is_dynamic_question ? "pl-8" : ""
              }`}
              style={{ scrollMarginTop: STICKY_HEADER_HEIGHT }}
              onClick={() => playAudioAtFragment(fragment)}
            >
              {fragment.question ? (
                <div className={`text-gray-700 ${i !== 0 ? "mt-4" : ""} mb-2`}>
                  {fragment.question}
                </div>
              ) : null}
              <TranscriptFragmentText
                text={displayText || null}
                isHighlighted={fragment.id === highlightedTranscriptFragment}
                onClickShare={() => onClickShare(fragment)}
                role={fragment.role}
              />
            </div>
          );
        } else if (event.type === "call_start" || event.type === "call_end") {
          return (
            <div key={`${event.type}-${event.timestamp}`}>
              {renderCallMarker(event.type, event.data)}
            </div>
          );
        } else if (event.type === "section_start") {
          return (
            <div key={`${event.type}-${event.timestamp}`}>
              {renderSectionMarker(event.data)}
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

function renderSectionMarkerTitle<T extends InterviewSectionSettings>(
  settings: T,
): ReactNode {
  switch (settings.type) {
    case InterviewSectionType.Questions:
      return "Screening interview";
    case InterviewSectionType.Case:
      return "Data labeling exercise";
    case InterviewSectionType.RolePlay:
      return settings.title || "Role play interview";
    default:
      return "Unknown section";
  }
}
