import { PencilIcon } from "@heroicons/react/24/outline";
import { ButtonVariantsEnum, IconButton } from "@repo/ui";
import {
  buildInterviewSectionAssessmentCustomFieldName,
  buildProjectUrlPath,
  getProjectAndJobTypeProperty,
  InterviewLoaded,
  JobTypePropertyEnum,
  ProjectSettingsSections,
  SpecialInterviewCustomFieldNamesEnum,
} from "app-types";
import type { AdminApiInterviewSectionResult } from "app-types/entities/interviewSection/result";
import { InterviewSectionType } from "app-types/entities/interviewSection/types";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/hook";
import { selectAssessmentById } from "../../assessments/assessmentsSlice";
import { selectProjectById } from "../../projects/projectsSlice";
import { InterviewAssessmentRating } from "./interviewAssessmentRating";

interface InterviewAssessmentSectionProps {
  interview: InterviewLoaded;
  interviewSectionResults?: readonly AdminApiInterviewSectionResult[];
}

export const InterviewAssessmentSection: FC<
  InterviewAssessmentSectionProps
> = ({ interview, interviewSectionResults }) => {
  const navigate = useNavigate();

  const project = useAppSelector(selectProjectById(interview.project_id));

  const assessment = useAppSelector(
    selectAssessmentById(
      project
        ? getProjectAndJobTypeProperty(
            project,
            JobTypePropertyEnum.AssessmentId,
          )
        : undefined,
    ),
  );

  if (!assessment || !project) {
    return null;
  }

  // Prepare all assessment fields to render
  const assessmentFields: {
    id: string;
    displayName: string;
    value: any;
    displayValue?: string;
    fieldName?: string;
  }[] = [];

  // Add special fields for sections first
  for (const section of interviewSectionResults ?? []) {
    switch (section.settings.type) {
      case InterviewSectionType.Case: {
        const dataLabelingField = interview.custom_fields.find(
          (field) =>
            field.field_name ===
            SpecialInterviewCustomFieldNamesEnum.DataLabelingSectionAssessment,
        );

        if (dataLabelingField) {
          assessmentFields.push({
            id: "data_labeling_assessment",
            displayName: dataLabelingField.display_name,
            value: dataLabelingField.data.value,
            displayValue: dataLabelingField.data.display_value,
            fieldName:
              SpecialInterviewCustomFieldNamesEnum.DataLabelingSectionAssessment,
          });
        }
        break;
      }
      case InterviewSectionType.RolePlay: {
        const sectionDefinitionId = section.interview_section_definition_id;
        const fieldName = sectionDefinitionId
          ? buildInterviewSectionAssessmentCustomFieldName(sectionDefinitionId)
          : undefined;
        const field = interview.custom_fields.find(
          (field) => field.field_name === fieldName,
        );

        if (field) {
          assessmentFields.push({
            id: `section:${section.id}`,
            displayName: `${section.settings.title || "Role play"} assessment`,
            value: field.data.value,
            displayValue: field.data.display_value,
            fieldName: field.field_name,
          });
        }
      }
    }
  }

  // Add standard assessment fields
  assessment.custom_field_definitions.forEach((fieldDefinition) => {
    const value = interview.custom_fields.find(
      (cf) => cf.custom_field_definition_id === fieldDefinition.id,
    );

    assessmentFields.push({
      id: fieldDefinition.id,
      displayName: fieldDefinition.display_name,
      value: value?.data.value,
      displayValue: value?.data.display_value,
      fieldName: value?.field_name,
    });
  });

  // Render the assessment table
  return (
    <div className="overflow-hidden border border-gray-200 sm:rounded-lg mt-4">
      <table className="min-w-full divide-y divide-gray-200 table-fixed w-full">
        <thead className="bg-gray-50 group">
          <tr>
            <th
              scope="col"
              className="w-[25%] px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider flex items-center gap-2"
            >
              Criteria
              <IconButton
                icon={
                  <PencilIcon className="w-3 h-3 opacity-0 group-hover:opacity-100" />
                }
                variant={ButtonVariantsEnum.Tertiary}
                onClick={() => {
                  navigate(
                    buildProjectUrlPath(
                      project.id,
                      undefined,
                      undefined,
                      undefined,
                      ProjectSettingsSections.InterviewAssessment,
                    ),
                  );
                }}
              />
            </th>
            <th
              scope="col"
              className="w-[15%] px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Rating
            </th>
            <th
              scope="col"
              className="w-[60%] px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Comments
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {assessmentFields.length === 0 ? (
            <tr>
              <td
                colSpan={3}
                className="px-4 py-4 text-sm text-gray-700 text-center"
              >
                No assessment criteria added yet.{" "}
                <span
                  onClick={() => {
                    navigate(
                      buildProjectUrlPath(
                        project.id,
                        undefined,
                        undefined,
                        undefined,
                        ProjectSettingsSections.InterviewAssessment,
                      ),
                    );
                  }}
                  className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
                >
                  Add criteria.
                </span>
              </td>
            </tr>
          ) : (
            assessmentFields.map((field) => (
              <tr key={field.id} className={`group relative`}>
                <td className={`px-4 py-2 text-sm text-gray-800`}>
                  {field.displayName}
                </td>
                <td className="px-4 py-2 text-sm text-gray-800">
                  <InterviewAssessmentRating
                    rating={field.value}
                    fieldName={field.fieldName}
                  />
                </td>
                <td className="px-4 py-2 text-sm text-gray-800">
                  {field.displayValue}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};
