import { z } from "zod";

export const InterviewSectionType = {
  Questions: "questions",
  Case: "case",
  RolePlay: "role-play",
} as const;

export const allInterviewSectionTypes = [
  InterviewSectionType.Questions,
  InterviewSectionType.Case,
  InterviewSectionType.RolePlay,
] as const;

export const interviewSectionTypeSchema = z.enum(allInterviewSectionTypes);

export type InterviewSectionType = z.infer<typeof interviewSectionTypeSchema>;
