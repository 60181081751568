import {
  Checkbox,
  Input,
  Label,
  SaveAndCancelButtons,
  SizesEnum,
} from "@repo/ui";
import {
  PermissionActions,
  PermissionSubjects,
  ProjectLinkAccess,
  ProjectWithInterviewCount,
  type ProjectSettings,
} from "app-types";
import { FC, useState } from "react";
import { Can } from "../../../../helpers/teammateAuthorizationContext";

interface JobOpeningDetailsInputsProps {
  location: string;
  pay: string;
  hours: string;
  onLocationChange: (value: string) => void;
  onPayChange: (value: string) => void;
  onHoursChange: (value: string) => void;
  shouldShowSaveButtons?: boolean;
  onSaveLocation?: () => void;
  onSavePay?: () => void;
  onSaveHours?: () => void;
  onCancelLocation?: () => void;
  onCancelPay?: () => void;
  onCancelHours?: () => void;
  onLocationBlur?: () => void;
}

export const JobOpeningDetailsInputs: FC<JobOpeningDetailsInputsProps> = ({
  location,
  pay,
  hours,
  onLocationChange,
  onPayChange,
  onHoursChange,
  shouldShowSaveButtons,
  onSaveLocation,
  onSavePay,
  onSaveHours,
  onCancelLocation,
  onCancelPay,
  onCancelHours,
  onLocationBlur,
}) => {
  return (
    <Can
      I={PermissionActions.UPDATE}
      a={PermissionSubjects.JOB_OPENINGS}
      passThrough={true}
    >
      {(allowed) => (
        <>
          <div className="space-y-3">
            <div>
              <Label size={SizesEnum.SMALL}>Location</Label>
              <div className="mt-2">
                <Input
                  value={location}
                  onChange={(evt) => {
                    onLocationChange(evt.target.value);
                  }}
                  onBlur={onLocationBlur}
                  placeholder="e.g. In-office 3 days per week in San Francisco, CA or Remote"
                  maxLength={200}
                  inputClassName="max-w-[400px]"
                  isDisabled={!allowed}
                />
              </div>
              {shouldShowSaveButtons && onSaveLocation && onCancelLocation ? (
                <SaveAndCancelButtons
                  onSave={onSaveLocation}
                  onCancel={onCancelLocation}
                />
              ) : null}
            </div>

            <div>
              <Label size={SizesEnum.SMALL}>Pay</Label>
              <div className="mt-2">
                <Input
                  value={pay}
                  onChange={(evt) => {
                    onPayChange(evt.target.value);
                  }}
                  placeholder="e.g. $18 per hour or $80,000 - $100,000 per year"
                  maxLength={200}
                  inputClassName="max-w-[400px]"
                  isDisabled={!allowed}
                />
              </div>
              {shouldShowSaveButtons && onSavePay && onCancelPay ? (
                <SaveAndCancelButtons
                  onSave={onSavePay}
                  onCancel={onCancelPay}
                />
              ) : null}
            </div>

            <div>
              <Label size={SizesEnum.SMALL}>Hours</Label>
              <div className="mt-2">
                <Input
                  value={hours}
                  onChange={(evt) => {
                    onHoursChange(evt.target.value);
                  }}
                  placeholder="e.g. Full-time, 40 hours per week"
                  maxLength={200}
                  inputClassName="max-w-[400px]"
                  isDisabled={!allowed}
                />
              </div>
              {shouldShowSaveButtons && onSaveHours && onCancelHours ? (
                <SaveAndCancelButtons
                  onSave={onSaveHours}
                  onCancel={onCancelHours}
                />
              ) : null}
            </div>
          </div>
        </>
      )}
    </Can>
  );
};

interface JobOpeningSettingsProps {
  project: ProjectWithInterviewCount;
  onSaveProject: (project: {
    id: string;
    location?: { description: string } | null;
    pay?: { description: string } | null;
    hours?: { description: string } | null;
    settings?: ProjectSettings;
  }) => Promise<void>;
}

export const JobOpeningSettings: FC<JobOpeningSettingsProps> = ({
  project,
  onSaveProject,
}) => {
  const originalLocation = project.location?.description ?? "";
  const originalPay = project.pay?.description ?? "";
  const originalHours = project.hours?.description ?? "";

  const [location, setLocation] = useState(originalLocation);
  const [pay, setPay] = useState(originalPay);
  const [hours, setHours] = useState(originalHours);

  const hasLocationChanges = location !== originalLocation;
  const hasPayChanges = pay !== originalPay;
  const hasHoursChanges = hours !== originalHours;

  const handleSaveLocation = () => {
    void onSaveProject({
      id: project.id,
      location: location ? { description: location } : null,
    });
  };

  const handleSavePay = () => {
    void onSaveProject({
      id: project.id,
      pay: pay ? { description: pay } : null,
    });
  };

  const handleSaveHours = () => {
    void onSaveProject({
      id: project.id,
      hours: hours ? { description: hours } : null,
    });
  };

  const [allowsExistingInterviewsOnly, setAllowsExistingInterviewsOnly] =
    useState(project.settings.link_access === ProjectLinkAccess.ExistingOnly);

  const onAllowsExistingInterviewsOnlyChange = (isChecked: boolean) => {
    setAllowsExistingInterviewsOnly(isChecked);
    onSaveProject({
      id: project.id,
      settings: {
        ...project.settings,
        link_access: isChecked
          ? ProjectLinkAccess.ExistingOnly
          : ProjectLinkAccess.Default,
      },
    });
  };

  return (
    <>
      <JobOpeningDetailsInputs
        location={location}
        pay={pay}
        hours={hours}
        onLocationChange={setLocation}
        onPayChange={setPay}
        onHoursChange={setHours}
        shouldShowSaveButtons
        onSaveLocation={hasLocationChanges ? handleSaveLocation : undefined}
        onSavePay={hasPayChanges ? handleSavePay : undefined}
        onSaveHours={hasHoursChanges ? handleSaveHours : undefined}
        onCancelLocation={
          hasLocationChanges
            ? () => {
                setLocation(originalLocation);
              }
            : undefined
        }
        onCancelPay={
          hasPayChanges
            ? () => {
                setPay(originalPay);
              }
            : undefined
        }
        onCancelHours={
          hasHoursChanges
            ? () => {
                setHours(originalHours);
              }
            : undefined
        }
      />
      <div>
        <Label size={SizesEnum.SMALL} className="mb-2">
          Restrict interview link access
        </Label>
        <Can
          I={PermissionActions.UPDATE}
          a={PermissionSubjects.JOB_OPENINGS}
          passThrough={true}
        >
          {(allowed) => (
            <Checkbox
              isChecked={allowsExistingInterviewsOnly}
              onChange={onAllowsExistingInterviewsOnlyChange}
              label="Only allow pre-registered candidates to interview (not recommended)"
              isDisabled={!allowed}
            />
          )}
        </Can>
        <div className="mt-2 text-sm text-gray-600">
          When enabled, you'll need to upload candidates in advance and
          unrecognized candidates will be prevented from interviewing.
        </div>
      </div>
    </>
  );
};
