import { z } from "zod";
import { dataRespectsSchema } from "../helpers/zodHelpers";

/**
 * The editor and reviewer roles are pre-defined and their access is hard-coded.
 * The custom role has its access stored in the db.
 */
export const TeammateRoleTypes = {
  CUSTOM: "custom",
  EDITOR: "editor",
  REVIEWER: "reviewer",
} as const;
export const allTeammateRoleTypes = [
  TeammateRoleTypes.CUSTOM,
  TeammateRoleTypes.EDITOR,
  TeammateRoleTypes.REVIEWER,
] as const;
export const teammateRoleTypeSchema = z.enum(allTeammateRoleTypes);
export type TeammateRoleType = z.infer<typeof teammateRoleTypeSchema>;

export const predefinedTeammateRoleTypeSchema = teammateRoleTypeSchema.refine(
  (roleType) => roleType !== TeammateRoleTypes.CUSTOM,
);

export type PredefinedTeammateRoleType = z.infer<
  typeof predefinedTeammateRoleTypeSchema
>;

export function isPredefinedTeammateRoleType(
  roleType: TeammateRoleType,
): roleType is PredefinedTeammateRoleType {
  return dataRespectsSchema(roleType, predefinedTeammateRoleTypeSchema);
}

/**
 * All possible actions.
 * "all" is a shortcut for ["read", "create", "update", "delete"].
 */
export const PermissionActions = {
  CREATE: "create",
  DELETE: "delete",
  READ: "read",
  UPDATE: "update",
} as const;
export const allPermissionActions = [
  PermissionActions.CREATE,
  PermissionActions.DELETE,
  PermissionActions.READ,
  PermissionActions.UPDATE,
] as const;
export type PermissionAction = (typeof allPermissionActions)[number];

/**
 * All possible subjects.
 * "all" is a shortcut for all the subjects.
 */
export const PermissionSubjects = {
  BILLING: "billing",
  COMPANY_SETTINGS: "company_settings",
  DEVELOPERS: "developers",
  INTEGRATIONS: "integrations",
  JOB_OPENINGS: "job_openings",
  MESSAGE_TEMPLATES: "message_templates",
  TEAMMATES: "teammates",
  WEBHOOKS: "webhooks",
} as const;
export const allPermissionSubjects = [
  PermissionSubjects.BILLING,
  PermissionSubjects.COMPANY_SETTINGS,
  PermissionSubjects.DEVELOPERS,
  PermissionSubjects.INTEGRATIONS,
  PermissionSubjects.JOB_OPENINGS,
  PermissionSubjects.MESSAGE_TEMPLATES,
  PermissionSubjects.TEAMMATES,
  PermissionSubjects.WEBHOOKS,
] as const;
export type PermissionSubject = (typeof allPermissionSubjects)[number];

export function isPermissionSubject(value: string): value is PermissionSubject {
  return allPermissionSubjects.includes(value as PermissionSubject);
}

/**
 * Structure representing the possible actions on subjects for a given role.
 * Pre-defined role types have their role access hard-coded (see getRoleAccessForPredefinedRole).
 * Custom role types will have an object of this type saved in db.
 * It's possible to define an inverted rule using "inverted: true". This translate into a "cannot" rule.
 */
export type RoleAccess = {
  inverted?: true;
  action: PermissionAction[];
  subject: PermissionSubject;
  conditions?: Record<string, unknown>;
}[];

/**
 * Type of the "permissions" field in the "teammate" table.
 */
export const teammatePermissionSchema = z.object({
  role: z.union([
    z.object({
      type: predefinedTeammateRoleTypeSchema,
    }),
    z.object({
      type: z.literal(TeammateRoleTypes.CUSTOM),
      // id is required for custom roles (it points to the role in the "role" table -that does not exist yet-)
      id: z.string(),
    }),
  ]),
});

export type TeammatePermissions = z.infer<typeof teammatePermissionSchema>;

/**
 * Role access for the pre-defined teammate role types
 */
export function getRoleAccessForPredefinedRole(
  roleType: PredefinedTeammateRoleType,
): RoleAccess {
  switch (roleType) {
    case TeammateRoleTypes.EDITOR: {
      return allPermissionSubjects.map((subject) => ({
        action: [...allPermissionActions],
        subject,
      }));
    }
    // The reviewer has access to company settings, integrations, job openings,
    // and message templates but they cannot edit (create, delete, update) them.
    case TeammateRoleTypes.REVIEWER:
      return [
        {
          action: [PermissionActions.READ],
          subject: PermissionSubjects.COMPANY_SETTINGS,
        },
        {
          action: [PermissionActions.READ],
          subject: PermissionSubjects.INTEGRATIONS,
        },
        {
          action: [PermissionActions.READ],
          subject: PermissionSubjects.JOB_OPENINGS,
        },
        {
          action: [PermissionActions.READ],
          subject: PermissionSubjects.MESSAGE_TEMPLATES,
        },
        {
          action: [PermissionActions.CREATE],
          subject: PermissionSubjects.TEAMMATES,
        },
      ];
    default:
      throw new Error(`Role type ${roleType} not supported`);
  }
}

export const predefinedRoleNameByType: Record<
  PredefinedTeammateRoleType,
  string
> = {
  [TeammateRoleTypes.EDITOR]: "Editor",
  [TeammateRoleTypes.REVIEWER]: "Reviewer",
};
