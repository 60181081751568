import { FC } from "react";

interface RadioButtonsRowProps {
  optionsCount: number;
  rowIndex: number;
  selectionIndex?: number;
  onChange: (index: number) => void;
  isReadOnly?: boolean;
}

export const RadioButtonsRow: FC<RadioButtonsRowProps> = ({
  optionsCount,
  rowIndex,
  selectionIndex,
  onChange,
  isReadOnly,
}) => {
  const options = Array.from({ length: optionsCount }, (_, index) => index + 1);

  return (
    <fieldset>
      <div className="flex items-center justify-between">
        {options.map((option) => (
          <div
            className="flex-grow flex items-center justify-center"
            key={option}
          >
            <label
              aria-label={`Option ${option}`}
              className={`flex-grow flex items-center justify-center ${
                isReadOnly ? "p-1" : "p-4"
              }`}
              htmlFor={`option-${rowIndex}-${option}`}
              key={`${rowIndex}-${option}`}
            >
              <input
                checked={selectionIndex === option}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                disabled={Boolean(isReadOnly)}
                id={`option-${rowIndex}-${option}`}
                onChange={() => {
                  onChange(option);
                }}
                type="radio"
              />
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  );
};
