import { FC } from "react";
import { QuestionsManager } from "../../../projects/recruitingQuestions/questionsManager";
import {
  type QuestionsDispatch,
  type QuestionWithTemporaryId,
} from "../../../projects/recruitingQuestions/questionsStore";
import { QuestionsSuggestionsWidget } from "../../../projects/recruitingQuestions/questionsSuggestionsWidget";
import { BaseInterviewSectionDefinitionCard } from "../card";

export interface QuestionsInterviewSectionDefinitionCardProps {
  interviewSectionDefinitionId: string;
  projectId: string;
  questions: readonly QuestionWithTemporaryId[];
  onQuestionsChange: QuestionsDispatch;
}

/**
 * Card in right-side panel for the interview section that contains interview questions.
 */
export const QuestionsInterviewSectionDefinitionCard: FC<
  QuestionsInterviewSectionDefinitionCardProps
> = (props) => (
  <BaseInterviewSectionDefinitionCard
    interviewSectionDefinitionId={props.interviewSectionDefinitionId}
    title="Screening interview"
    titleAccessory={<QuestionsSuggestionsWidget projectId={props.projectId} />}
    subtitle="The AI interviewer will automatically ask clarifying questions and adapt
        to the candidate's responses."
  >
    <QuestionsManager
      projectId={props.projectId}
      questions={props.questions}
      onQuestionsChange={props.onQuestionsChange}
    />
  </BaseInterviewSectionDefinitionCard>
);
