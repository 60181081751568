import { RadioGroup } from "@headlessui/react";
import type { FC } from "react";
import { twJoin } from "tailwind-merge";
import { SizesEnum } from "../helpers/helpers";

interface RadioButtonOption {
  name: string;
  description?: string;
}

interface RadioButtonsGroupProps {
  label?: string;
  options: RadioButtonOption[];
  value: string | null;
  onChange?: (newValue: string) => void;
  size?: SizesEnum.MEDIUM | SizesEnum.LARGE;
  isDisabled?: boolean;
}

export const RadioButtonsGroup: FC<RadioButtonsGroupProps> = ({
  label,
  options,
  value,
  onChange,
  size,
  isDisabled,
}) => {
  return (
    <RadioGroup
      disabled={Boolean(isDisabled)}
      onChange={(selectedValue) => {
        if (selectedValue && onChange) {
          onChange(selectedValue);
        }
      }}
      value={value}
    >
      {label ? (
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      ) : null}
      <div className={`${label ? "mt-2" : ""} -space-y-px rounded-md bg-white`}>
        {options.map((option, index) => (
          <RadioGroup.Option
            className={({ checked }: { checked: boolean }) =>
              twJoin(
                index === 0 ? "rounded-tl-md rounded-tr-md" : "",
                index === options.length - 1
                  ? "rounded-bl-md rounded-br-md"
                  : "",
                checked
                  ? "z-10 border-indigo-200 bg-indigo-50"
                  : "border-gray-200",
                size === SizesEnum.MEDIUM ? "p-2" : "p-2 sm:p-4",
                isDisabled ? "" : "cursor-pointer",
                "relative flex border focus:outline-none items-center",
              )
            }
            key={option.name}
            value={option.name}
          >
            {({ active, checked }: { active: boolean; checked: boolean }) => (
              <>
                <span
                  aria-hidden="true"
                  className={twJoin(
                    checked
                      ? "bg-indigo-600 border-transparent"
                      : "bg-white border-gray-300",
                    active ? "ring-2 ring-offset-2 ring-indigo-600" : "",
                    isDisabled ? "" : "cursor-pointer",
                    "mt-0.5 h-4 w-4 shrink-0 rounded-full border flex items-center justify-center",
                  )}
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <span className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    className={twJoin(
                      checked ? "text-indigo-900" : "text-gray-900",
                      "block text-sm font-medium",
                    )}
                  >
                    {option.name}
                  </RadioGroup.Label>
                  {option.description ? (
                    <RadioGroup.Description
                      className={twJoin(
                        checked ? "text-indigo-700" : "text-gray-500",
                        "block text-sm",
                      )}
                    >
                      {option.description}
                    </RadioGroup.Description>
                  ) : null}
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
