import { FC } from "react";
import { PillColorsEnum } from "@repo/ui";

export interface InsightsBarProps {
  color: PillColorsEnum;
  percentageWidth: number;
  label?: string;
}

const colorToBarColor = {
  [PillColorsEnum.BLUE]: "bg-blue-400",
  [PillColorsEnum.GREEN]: "bg-green-400",
  [PillColorsEnum.RED]: "bg-red-400",
  [PillColorsEnum.YELLOW]: "bg-yellow-400",
  [PillColorsEnum.GRAY]: "bg-gray-400",
  [PillColorsEnum.LIME]: "bg-lime-400",
};

export const InsightBar: FC<InsightsBarProps> = ({
  color,
  percentageWidth,
  label,
}) => {
  const barColor = colorToBarColor[color];
  const bar = (
    <div className="bg-gray-200 h-1.5 w-full rounded-full text-center relative">
      <div
        className={`${barColor} h-full rounded-full text-center absolute top-0 left-0`}
        style={{
          width: `${percentageWidth}%`,
        }}
      />
    </div>
  );

  if (label) {
    return (
      <div className="w-full">
        <div className="text-xs pb-1 leading-tight">{label}</div>
        {bar}
      </div>
    );
  }

  return bar;
};
