import { z } from "zod";

/** Durable identifiers to help determine which template an interview section definition was created from. */
export const allInterviewSectionTemplateIds = [
  "role-play-billing-error",
  "role-play-hotel-overbooking",
  "role-play-takeout-complaint",
  "role-play-employee-negotiation",
  "role-play-travel-upsell",
  "role-play-offer-presentation",
] as const;

export type InterviewSectionTemplateId =
  (typeof allInterviewSectionTemplateIds)[number];

export const interviewSectionTemplateIdSchema = z.enum(
  allInterviewSectionTemplateIds,
);

export interface InterviewSectionTemplateListing {
  title: string;
  description: string;
  skills: readonly string[];
}

/** All the information needed to show interview section templates in the UI. */
export const interviewSectionTemplateListings: {
  [id in InterviewSectionTemplateId]: InterviewSectionTemplateListing;
} = {
  "role-play-billing-error": {
    title: "Billing error role play",
    description:
      "The candidate assists a frustrated customer disputing an unexpected charge on their bill.",
    skills: ["Problem-solving", "Communication", "Empathy"],
  },
  "role-play-hotel-overbooking": {
    title: "Hotel overbooking role play",
    description:
      "The candidate addresses the concerns of a hotel guest whose reservation has been unexpectedly canceled due to overbooking.",
    skills: ["Customer service", "Adaptability", "Crisis management"],
  },
  "role-play-takeout-complaint": {
    title: "Takeout order role play",
    description:
      "The candidate addresses a dissatisfied customer unhappy with their takeout order from the restaurant.",
    skills: ["Empathy", "Communication", "Conflict resolution"],
  },
  "role-play-employee-negotiation": {
    title: "Employee negotiation role play",
    description:
      "The candidate, acting as a manager, navigates a discussion with an employee who asks for increased compensation or flexible work arrangements.",
    skills: ["Negotiation", "Communication", "Decision-making"],
  },
  "role-play-travel-upsell": {
    title: "Vacation upsell role play",
    description:
      "The candidate presents higher-value vacation packages to a customer who initially chose a budget option, focusing on value rather than cost alone.",
    skills: ["Sales", "Active listening", "Influencing"],
  },
  "role-play-offer-presentation": {
    title: "Job offer role play",
    description:
      "The candidate presents a formal job offer to a promising candidate, emphasizing company benefits and handling the candidate's queries or concerns.",
    skills: ["Recruitment", "Listening", "Persuasion"],
  },
};
