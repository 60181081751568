import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { ButtonVariantsEnum, IconButton } from "@repo/ui";

interface ShareFragmentIconButtonProps {
  onClick: () => void;
}

export const ShareFragmentIconButton: FC<ShareFragmentIconButtonProps> = ({
  onClick,
}) => {
  return (
    <div className="opacity-0 group-hover:opacity-100">
      <IconButton
        variant={ButtonVariantsEnum.Secondary}
        icon={<ArrowUpOnSquareIcon className="h-3 w-3" />}
        onClick={onClick}
      />
    </div>
  );
};
