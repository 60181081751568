import { Label, SizesEnum } from "@repo/ui";
import { FC } from "react";
import { QuestionsManager } from "./questionsManager";
import {
  type QuestionsDispatch,
  type QuestionWithTemporaryId,
} from "./questionsStore";
import { QuestionsSuggestionsWidget } from "./questionsSuggestionsWidget";

export interface QuestionsPanelProps {
  projectId: string;
  questions: readonly QuestionWithTemporaryId[];
  onQuestionsChange: QuestionsDispatch;
}

/**
 * Right-side panel before the introduction of interview sections.
 */
export const QuestionsPanel: FC<QuestionsPanelProps> = (props) => (
  <div className="flex-1 flex flex-col">
    <div className="px-3 py-2 flex flex-col space-between border-b border-gray-200 bg-gray-50">
      <div className="flex flex-row items-center space-x-2">
        <Label size={SizesEnum.MEDIUM}>Interview questions</Label>
        <QuestionsSuggestionsWidget projectId={props.projectId} />
      </div>
      <div className="text-gray-600 text-xs">
        The AI interviewer will automatically ask clarifying questions and adapt
        to the candidate's responses.
      </div>
    </div>
    <div className="overflow-y-auto flex-1">
      <QuestionsManager
        projectId={props.projectId}
        questions={props.questions}
        onQuestionsChange={props.onQuestionsChange}
      />
    </div>
  </div>
);
