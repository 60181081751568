import { FC } from "react";
import { BaseInterviewSectionDefinitionCard } from "../card";

export interface CaseInterviewSectionDefinitionCardProps {
  interviewSectionDefinitionId: string;
  onDelete?: (interviewSectionDefinitionId: string) => void;
}

export const CaseInterviewSectionDefinitionCard: FC<
  CaseInterviewSectionDefinitionCardProps
> = (props) => {
  return (
    <BaseInterviewSectionDefinitionCard
      interviewSectionDefinitionId={props.interviewSectionDefinitionId}
      title="Data labeling exercise"
      subtitle="The AI interviewer will walk through a data annotation task with candidates."
      onDelete={props.onDelete}
    >
      <div className="p-3 flex items-center space-x-3">
        <div className="flex-1 overflow-hidden rounded-md">
          <img
            className="w-full"
            src="https://assets.alpharun.com/data-labeling-2025030415.jpg"
            alt="Street scene with data annotations"
            width={1973}
            height={1389}
          />
        </div>
        <div className="flex-1 text-sm text-gray-900">
          The candidate will have 20 seconds to study this image before
          discussing it with the AI interviewer. No configuration is required
          for this section.
        </div>
      </div>
    </BaseInterviewSectionDefinitionCard>
  );
};
