import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  Button,
  ButtonVariantsEnum,
  IconButton,
  ModalVariantsEnum,
  SimpleModal,
  SmallTimeStamp,
  TableSkeleton,
} from "@repo/ui";
import {
  renderMultilineContent,
  renderSingleLineContent,
} from "@repo/ui/content/renderers";
import {
  allMessageTemplateTypes,
  MessageTemplate,
  MessageTemplateTypeToLabel,
  PermissionActions,
  PermissionSubjects,
} from "app-types";
import { FC, Fragment, useState } from "react";
import {
  useAllMessageTemplatesQuery,
  useDeleteMessageTemplateMutation,
} from "../../../api/redux";
import { Can } from "../../../helpers/teammateAuthorizationContext";
import { SettingsSection } from "../shared/settingsSection";
import { SettingsTable } from "../shared/settingsTable";
import { SettingsTableBodySection } from "../shared/settingsTableBodySection";
import { SettingsTableDataCell } from "../shared/settingsTableDataCell";
import { SettingsTableEmptyBodyContent } from "../shared/settingsTableEmptyBodyContent";
import { SettingsTableHeaderCell } from "../shared/settingsTableHeaderCell";
import { SettingsTableHeaderSection } from "../shared/settingsTableHeaderSection";
import { SettingsTableSectionHeader } from "../shared/settingsTableSectionHeader";
import { MessageTemplateCreateDialog } from "./messageTemplateCreateDialog";
import { MessageTemplateEditDialog } from "./messageTemplateEditDialog";

export const MessageTemplateSettingsSection: FC = () => {
  const { data: allMessageTemplates, isLoading } =
    useAllMessageTemplatesQuery();

  const [deleteMessageTemplate, { isLoading: isDeleting }] =
    useDeleteMessageTemplateMutation();

  const [templateIdToDelete, setTemplateIdToDelete] = useState<
    string | undefined
  >(undefined);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [messageTemplateToEdit, setMessageTemplateToEdit] = useState<
    MessageTemplate | undefined
  >(undefined);

  return (
    <>
      <SimpleModal
        isOpen={!!templateIdToDelete}
        onCancel={() => {
          setTemplateIdToDelete(undefined);
        }}
        variant={ModalVariantsEnum.Warning}
        title="Delete message template"
        subtitle={
          "Are you sure you want to delete this template? This action can't be undone."
        }
        confirmButtonText="Delete message template"
        onConfirm={() => {
          if (templateIdToDelete) {
            deleteMessageTemplate(templateIdToDelete);
          }

          setTemplateIdToDelete(undefined);
        }}
      />
      <SettingsSection>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold leading-6 text-gray-900">
              Message templates
            </h2>

            <Can
              I={PermissionActions.CREATE}
              a={PermissionSubjects.MESSAGE_TEMPLATES}
              passThrough={true}
            >
              {(allowed) => (
                <Button
                  variant={ButtonVariantsEnum.Primary}
                  icon={<PlusIcon className="size-4" />}
                  onClick={() => setIsCreateDialogOpen(true)}
                  isDisabled={!allowed}
                >
                  Create message template
                </Button>
              )}
            </Can>
          </div>
          <div className="text-sm text-gray-600">
            Set up message templates and use them in projects to notify
            candidates when they are advanced or rejected.
          </div>
          <SettingsTable>
            <SettingsTableHeaderSection>
              <tr>
                <SettingsTableHeaderCell className="w-[80%]">
                  Template
                </SettingsTableHeaderCell>
                <SettingsTableHeaderCell className="w-[20%]">
                  Created
                </SettingsTableHeaderCell>
              </tr>
            </SettingsTableHeaderSection>
            <SettingsTableBodySection>
              {isLoading ? <TableSkeleton /> : null}

              {allMessageTemplates?.length === 0 ? (
                <SettingsTableEmptyBodyContent colSpan={2}>
                  No message templates have been created yet.
                </SettingsTableEmptyBodyContent>
              ) : null}

              {allMessageTemplateTypes.map((type) => {
                const templates = allMessageTemplates?.filter(
                  (template) => template.type === type,
                );

                if (!templates || templates.length === 0) {
                  return null;
                }

                return (
                  <Fragment key={type}>
                    <SettingsTableSectionHeader>
                      {`${MessageTemplateTypeToLabel[type]}s`}
                    </SettingsTableSectionHeader>
                    {templates.map((template, index) => (
                      <tr
                        key={`${template.type}-${index}`}
                        className="group relative"
                      >
                        <SettingsTableDataCell>
                          {/* TODO: Show this prettier. */}
                          <p className="font-semibold">
                            {renderSingleLineContent(template.subject)}
                          </p>
                          {renderMultilineContent(template.body)}
                        </SettingsTableDataCell>
                        <SettingsTableDataCell>
                          <SmallTimeStamp
                            timestamp={template.created_at}
                            className="text-sm text-gray-500"
                          />
                        </SettingsTableDataCell>
                        <SettingsTableDataCell>
                          <div className="text-gray-500 absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer hidden group-hover:flex items-center space-x-2">
                            <Can
                              I={PermissionActions.UPDATE}
                              a={PermissionSubjects.MESSAGE_TEMPLATES}
                            >
                              <IconButton
                                variant={ButtonVariantsEnum.Secondary}
                                icon={<PencilIcon className="h-4 w-4" />}
                                onClick={() => {
                                  setMessageTemplateToEdit(template);
                                }}
                              />
                            </Can>
                            <Can
                              I={PermissionActions.DELETE}
                              a={PermissionSubjects.MESSAGE_TEMPLATES}
                            >
                              <IconButton
                                variant={ButtonVariantsEnum.Secondary}
                                icon={<TrashIcon className="h-4 w-4" />}
                                onClick={() => {
                                  setTemplateIdToDelete(template.id);
                                }}
                              />
                            </Can>
                          </div>
                        </SettingsTableDataCell>
                      </tr>
                    ))}
                  </Fragment>
                );
              })}
            </SettingsTableBodySection>
          </SettingsTable>
        </div>
      </SettingsSection>

      <MessageTemplateCreateDialog
        isOpen={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
      />

      <MessageTemplateEditDialog
        messageTemplate={messageTemplateToEdit}
        onClose={() => setMessageTemplateToEdit(undefined)}
      />
    </>
  );
};
