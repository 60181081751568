import {
  CheckCircleIcon,
  LanguageIcon,
  TrashIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import {
  ButtonVariantsEnum,
  IconButton,
  Textarea,
  TextareaVariantsEnum,
} from "@repo/ui";
import {
  ASSESSMENT_FIELD_NAME_MAX_LENGTH,
  CANDIDATE_ALIGN_FIELD_NAME,
  CefrAssessmentFieldNameToLanguage,
  CustomFieldDefinition,
  CustomFieldDefinitionInsertClient,
  getCustomFieldDisplayName,
  PermissionActions,
  PermissionSubjects,
} from "app-types";
import React from "react";
import { Can } from "../../helpers/teammateAuthorizationContext";

interface AssessmentEditorRowProps {
  field: CustomFieldDefinition | CustomFieldDefinitionInsertClient;
  onRenameField: (newName: string) => void;
  onBlur: (value: string) => void;
  onRemoveField: () => void;
  fieldError: string;
}

export const AssessmentEditorRow: React.FC<AssessmentEditorRowProps> = ({
  field,
  onRenameField,
  onBlur,
  onRemoveField,
  fieldError,
}) => {
  const renderFieldContent = () => {
    if (field.field_name in CefrAssessmentFieldNameToLanguage && !fieldError) {
      return (
        <div className="flex items-center space-x-2">
          <LanguageIcon className="w-4 h-4" />
          <span>{getCustomFieldDisplayName(field)}</span>
        </div>
      );
    }

    if (field.display_name === CANDIDATE_ALIGN_FIELD_NAME && !fieldError) {
      return (
        <div className="flex items-center space-x-2">
          <UserIcon className="w-4 h-4" />
          <span>{getCustomFieldDisplayName(field)}</span>
        </div>
      );
    }

    return (
      <div>
        <Can
          I={PermissionActions.UPDATE}
          a={PermissionSubjects.JOB_OPENINGS}
          passThrough={true}
        >
          {(allowed) => (
            <Textarea
              value={getCustomFieldDisplayName(field)}
              onChange={onRenameField}
              onBlur={(e) => onBlur(e.target.value)}
              placeholder="Has 2+ years of customer service experience"
              errorDescription={fieldError}
              maxLength={ASSESSMENT_FIELD_NAME_MAX_LENGTH}
              variant={TextareaVariantsEnum.MINIMAL}
              shouldDisableNewline
              isDisabled={!allowed}
            />
          )}
        </Can>
      </div>
    );
  };

  return (
    <tr className="group relative border-b border-gray-900/5">
      <td className="px-3 py-2 text-sm text-gray-900">
        <div className="flex flex-row items-center space-x-2 w-full">
          <CheckCircleIcon className="w-4 h-4 text-green-600 flex-shrink-0" />
          <div className="w-full">{renderFieldContent()}</div>
        </div>
      </td>
      <td className="absolute right-2 top-1/2 -translate-y-1/2">
        <Can I={PermissionActions.UPDATE} a={PermissionSubjects.JOB_OPENINGS}>
          <div className="invisible group-hover:visible">
            <IconButton
              variant={ButtonVariantsEnum.Secondary}
              icon={<TrashIcon className="w-4 h-4 text-red-500" />}
              onClick={onRemoveField}
            />
          </div>
        </Can>
      </td>
    </tr>
  );
};
