import { noop } from "lodash";
import { FC, ReactNode, useEffect, useState } from "react";

export interface LoadingTextProps {
  isLoading: boolean;
  stages: readonly [ReactNode, ...ReactNode[]];
  interval?: number;
  children: ReactNode;
}

export const LoadingText: FC<LoadingTextProps> = (props) => {
  const { isLoading, stages, interval = 5000, children } = props;
  const [stageIndex, setStageIndex] = useState(0);

  useEffect(() => {
    if (!isLoading) {
      return noop;
    }

    const handle = setInterval(() => {
      setStageIndex((prevIndex) => Math.min(prevIndex + 1, stages.length - 1));
    }, interval);

    setStageIndex(0);

    return () => {
      clearInterval(handle);
    };
  }, [isLoading, stages.length]);

  return isLoading ? stages[stageIndex] : children;
};
