import { FC, ReactNode, useInsertionEffect, useRef } from "react";

export interface BufferWhileExitingProps {
  isOpen: boolean;
  isExiting: boolean;
  children: ReactNode;
}

/** Prevents children from re-rendering during exit animations. Inspired by react-spectrum's DialogContainer. */
export const BufferWhileExiting: FC<BufferWhileExitingProps> = (props) => {
  const { isOpen, isExiting, children } = props;
  const childrenBeforeExitingRef = useRef<ReactNode>(null);

  useInsertionEffect(() => {
    if (!isExiting) {
      childrenBeforeExitingRef.current = isOpen ? children : null;
    }
  });

  return isExiting ? childrenBeforeExitingRef.current : children;
};
