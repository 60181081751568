import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { InfoBanner } from "@repo/ui";
import { ModalCloseButton } from "@repo/ui/Modal/modalCloseButton";
import { modalHeader } from "@repo/ui/Modal/modalHeader";
import { FC } from "react";
import {
  Dialog,
  GridList,
  GridListItem,
  Header,
  Heading,
} from "react-aria-components";
import type { VisibleInterviewSectionDefinition } from "../state";
import {
  presets,
  useInterviewSectionPresetAvailability,
  type InterviewSectionPresetDefinition,
} from "./presets";

export interface InterviewSectionPickPresetDialogProps {
  existingSections: readonly VisibleInterviewSectionDefinition[] | undefined;
  onSelect: (preset: InterviewSectionPresetDefinition) => void;
}

export const InterviewSectionPickPresetDialog: FC<
  InterviewSectionPickPresetDialogProps
> = (props) => {
  const checkIsAvailable = useInterviewSectionPresetAvailability();
  const checkIsUsed = (preset: InterviewSectionPresetDefinition) =>
    // Assume everything is used (and disable all presets) until existing sections have been loaded.
    props.existingSections ? preset.checkIsUsed(props.existingSections) : true;

  const checkIsDisabled = (preset: InterviewSectionPresetDefinition) =>
    !checkIsAvailable(preset) || checkIsUsed(preset);

  const isAllDisabled = presets.every(checkIsDisabled);

  return (
    <Dialog>
      {({ close }) => (
        <>
          <Header
            className={modalHeader().header({
              className: "px-4 pt-4 sm:px-6 sm:pt-6",
            })}
          >
            <Heading slot="title" className={modalHeader().title()}>
              Add section
            </Heading>
            <ModalCloseButton />
          </Header>

          <div className="p-4 sm:p-6 flex flex-col space-y-4 sm:space-y-6">
            <div className="text-sm text-gray-900">
              Choose the type of interview section to add to your interview
              plan.
            </div>

            {isAllDisabled ? (
              <InfoBanner title="You've already added all types of interview sections." />
            ) : null}

            <GridList
              className="max-w-4xl grid grid-cols-2 gap-4 md:grid-cols-3"
              aria-label="Section type"
              layout="grid"
            >
              {presets.map((preset) => (
                <GridListItem
                  key={preset.key}
                  textValue={preset.title}
                  className="group relative flex justify-between cursor-pointer disabled:cursor-not-allowed rounded-lg border border-gray-300 bg-white hover:bg-gray-50 text-gray-900 p-4 shadow-sm disabled:shadow-none disabled:opacity-30 focus:outline-none focus-visible:border-indigo-600 focus-visible:ring-2 focus-visible:ring-indigo-600"
                  isDisabled={checkIsDisabled(preset)}
                  onAction={() => {
                    props.onSelect(preset);
                    close();
                  }}
                >
                  <div className="flex flex-col justify-between">
                    <div className="flex flex-col items-start space-y-1">
                      {!checkIsAvailable(preset) ? (
                        <span className="inline-flex items-center rounded-md bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700">
                          Coming soon
                        </span>
                      ) : checkIsUsed(preset) ? (
                        <span className="inline-flex items-center rounded-md bg-purple-100 px-1.5 py-0.5 text-xs font-medium text-purple-600">
                          Already added
                        </span>
                      ) : null}

                      <span className="text-sm font-semibold">
                        {preset.title}
                      </span>
                      <span className="text-xs text-gray-500">
                        {preset.description}
                      </span>
                    </div>

                    <div className="mt-4 sm:mt-6 flex flex-wrap gap-1 justify-self-end group-disabled:opacity-70">
                      {preset.skills.map((skill, index) => (
                        <span
                          key={index}
                          className="inline-flex items-center rounded-full bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600"
                        >
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                  <CheckCircleIcon className="size-5 flex-none text-indigo-600 invisible group-data-[selected]:visible" />
                  <span
                    aria-hidden="true"
                    className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-focus-visible:border group-data-[selected]:border-indigo-600"
                  />
                </GridListItem>
              ))}
            </GridList>
          </div>
        </>
      )}
    </Dialog>
  );
};
