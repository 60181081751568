import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { TeammateRole } from "app-types/api/boot";
import { RootState } from "../../app/store";

// Define the slice state
interface AuthorizationState {
  teammateRole: TeammateRole | null;
}

// Initialize the state
const initialState: AuthorizationState = {
  teammateRole: null,
};

// Create the slice
const authorizationSlice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    setTeammateRole: (state, action: PayloadAction<TeammateRole | null>) => {
      state.teammateRole = action.payload;
    },
  },
});

// Export actions
export const { setTeammateRole } = authorizationSlice.actions;

// Export selectors
export const selectTeammateRole = (state: RootState) =>
  state.authorization.teammateRole;

export default authorizationSlice.reducer;
