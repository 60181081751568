import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ButtonVariantsEnum, IconButton } from "@repo/ui";
import { SizesEnum } from "@repo/ui/helpers/helpers";
import { Label } from "@repo/ui/Label/label";
import { PermissionActions, PermissionSubjects } from "app-types";
import { FC, ReactNode } from "react";
import {
  Button as AriaButton,
  Disclosure,
  DisclosurePanel,
  Heading,
} from "react-aria-components";
import { Can } from "../../../helpers/teammateAuthorizationContext";

export interface BaseInterviewSectionDefinitionCardProps {
  interviewSectionDefinitionId: string;
  title: ReactNode;
  titleAccessory?: ReactNode;
  subtitle?: ReactNode;
  children: ReactNode;
  onDelete?: (interviewSectionDefinitionId: string) => void;
}

export const BaseInterviewSectionDefinitionCard: FC<
  BaseInterviewSectionDefinitionCardProps
> = (props) => {
  const { onDelete } = props;

  return (
    <Disclosure
      id={props.interviewSectionDefinitionId}
      className="overflow-hidden rounded-lg bg-white shadow"
    >
      <Heading className="group px-3 py-2 border-b border-gray-200 flex items-center justify-between">
        <div>
          <div className="flex items-center space-x-2">
            <AriaButton
              slot="trigger"
              className="flex items-center group -ml-1"
            >
              <ChevronRightIcon className="size-5 text-gray-900 group-aria-expanded:rotate-90" />
              <Label className="cursor-pointer" size={SizesEnum.SMALL}>
                {props.title}
              </Label>
            </AriaButton>
            {props.titleAccessory}
          </div>
          {props.subtitle ? (
            <div className="text-gray-600 text-xs">{props.subtitle}</div>
          ) : null}
        </div>

        {onDelete ? (
          <Can I={PermissionActions.UPDATE} a={PermissionSubjects.JOB_OPENINGS}>
            <div className="opacity-0 group-hover:opacity-100 focus-within:opacity-100">
              <IconButton
                variant={ButtonVariantsEnum.Secondary}
                icon={<TrashIcon className="size-4 text-red-500" />}
                onClick={() => onDelete(props.interviewSectionDefinitionId)}
              />
            </div>
          </Can>
        ) : null}
      </Heading>

      <DisclosurePanel>{props.children}</DisclosurePanel>
    </Disclosure>
  );
};
