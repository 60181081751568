import {
  type TablesInsertWithFixedTypes,
  type TablesUpdateWithFixedTypes,
  type TablesWithFixedTypes,
} from "..";
import {
  InterviewLanguagesEnum,
  type LanguageVoice,
} from "../constants/languages";
import type { ResumeCollectionTypesEnum } from "../db/types";

export type JobTypeDB = TablesWithFixedTypes<"job_type">;
export type JobTypeInsert = TablesInsertWithFixedTypes<"job_type">;
export type JobTypeUpdate = TablesUpdateWithFixedTypes<"job_type">;
export type JobType = JobTypeDB;

export const InferenceStatus = {
  InProgress: "in_progress",
  Failed: "failed",
} as const;

export type InferenceStatus =
  (typeof InferenceStatus)[keyof typeof InferenceStatus];

export interface JobTypeSettings {
  interview_languages: InterviewLanguagesEnum[];
  language_voice: LanguageVoice | null;
  resume_collection_type: ResumeCollectionTypesEnum | null;
  should_force_fullscreen: boolean;
  should_record_video: boolean;
  auto_advance_assessment_score_threshold: number | null;
  welcome_message: string | null;
  outro_message: string | null;
  redirect_url: string | null;
  /** Unformatted, raw job description initially provided by the user, in case we need to reprocess it. */
  original_job_description: string | null;
  questions_status: InferenceStatus | null;
  assessment_status: InferenceStatus | null;
}

export const JobTypeSettingsDefault: JobTypeSettings = {
  interview_languages: [InterviewLanguagesEnum.ENGLISH],
  language_voice: null,
  resume_collection_type: null,
  should_force_fullscreen: false,
  should_record_video: false,
  auto_advance_assessment_score_threshold: null,
  welcome_message: null,
  outro_message: null,
  redirect_url: null,
  original_job_description: null,
  questions_status: null,
  assessment_status: null,
};

export const ACME_CRM_COMPANY_ID = "19aac94b-24dc-42ac-bfcf-44c275493736";
export const FOUNTAIN_INTERNAL_COMPANY_ID =
  "230d44ce-7c62-4631-9366-6e1486d7ab30";
