import { z } from "zod";

/**
 * Used to prompt the LLM to reflect on the importance of its suggestions.
 */
const llmFeedbackPriorities = [
  "must-have",
  "should-have",
  "could-have",
  // LLMs avoid using "wont-have" so we deviate from MoSCoW and use "nice-to-have" instead.
  "nice-to-have",
] as const;

export const llmFeedbackPrioritySchema = z.enum(llmFeedbackPriorities);

export type LlmFeedbackPriority = z.infer<typeof llmFeedbackPrioritySchema>;

/**
 * Used to prompt the LLM which priorities to assign.
 */
export const llmFeedbackPrioritiesPrompt = llmFeedbackPriorities.join(", ");

const priorityToRankMap: Record<LlmFeedbackPriority, number> = {
  "must-have": 1,
  "should-have": 2,
  "could-have": 3,
  "nice-to-have": 4,
};

/**
 * Compares two priorities, from higher to lower priority.
 */
export function compareLlmFeedbackPriority(
  a: LlmFeedbackPriority,
  b: LlmFeedbackPriority,
) {
  return priorityToRankMap[a] - priorityToRankMap[b];
}

/**
 * Compares two suggestions by priority, from higher to lower priority.
 */
export function compareLlmFeedback<T extends { priority: LlmFeedbackPriority }>(
  a: T,
  b: T,
) {
  return compareLlmFeedbackPriority(a.priority, b.priority);
}

/**
 * Creates a predicate to check if a priority is higher than or equal to the minimum priority.
 */
export function createLlmFeedbackPriorityGtePredicate(
  minPriority: LlmFeedbackPriority,
) {
  return (priority: LlmFeedbackPriority) =>
    compareLlmFeedbackPriority(priority, minPriority) <= 0;
}

/**
 * Creates a predicate to check if a suggestion is higher than or equal to the minimum priority.
 */
export function createLlmFeedbackGtePredicate<
  T extends { priority: LlmFeedbackPriority },
>(minPriority: LlmFeedbackPriority) {
  return (feedback: T) =>
    compareLlmFeedbackPriority(feedback.priority, minPriority) <= 0;
}
