import { Input } from "@repo/ui";
import { FC, useState } from "react";
import { SaveAndCancelButtons } from "../Buttons/saveAndCancelButtons";

export interface ColorPickerProps {
  color: string;
  onChange: (color: string) => void;
  isDisabled?: boolean;
}

const defaultColorOptions = [
  "172554", // blue-950
  "1e40af", // blue-800
  "166534", // green-800
  "854d0e", // yellow-800
  "991b1b", // red-800
  "6b21a8", // purple-800
  "9d174d", // pink-800
];

const isValidHex = (hex: string) => {
  return /^[0-9A-Fa-f]{6}$/.test(hex);
};

export const ColorPicker: FC<ColorPickerProps> = ({
  color,
  onChange,
  isDisabled,
}) => {
  const colorWithoutHash = color.slice(1);

  const [inputValue, setInputValue] = useState(colorWithoutHash);
  const [isValid, setIsValid] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (value.startsWith("#")) {
      value = value.slice(1);
    }
    setInputValue(value);
  };

  const handleBlur = () => {
    setIsValid(isValidHex(inputValue));
  };

  const handleColorTileClick = (color: string) => {
    setInputValue(color);
    setIsValid(true);
  };

  const hexCode = `#${inputValue}`;

  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-2 items-center">
        <div className="w-[100px]">
          <Input
            leftIcon={
              <div>
                <span>#</span>
              </div>
            }
            value={inputValue}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="000000"
            maxLength={7} // Allows pasting hex code with #
            isDisabled={isDisabled}
          />
        </div>
        {!defaultColorOptions.includes(inputValue) && isValid && (
          <div
            className="w-8 h-8 rounded-md ring-2 ring-offset-2 ring-blue-500"
            style={{ backgroundColor: `#${inputValue}` }}
          />
        )}

        {defaultColorOptions.map((colorOption) => (
          <div
            key={colorOption}
            style={{ backgroundColor: `#${colorOption}` }}
            className={`w-8 h-8 rounded-md ${
              inputValue === colorOption
                ? "ring-2 ring-offset-2 ring-blue-500"
                : ""
            }`}
            onClick={() => !isDisabled && handleColorTileClick(colorOption)}
          />
        ))}
      </div>
      {!isValid && (
        <div className="text-red-500 text-sm mt-3">
          Please enter a valid color hex code.
        </div>
      )}
      {hexCode !== color && isValid && (
        <SaveAndCancelButtons
          onSave={() => {
            if (isValidHex(inputValue)) {
              onChange(hexCode);
            } else {
              setIsValid(false);
            }
          }}
          onCancel={() => setInputValue(colorWithoutHash)}
        />
      )}
    </div>
  );
};
