import { ExclamationTriangleIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { InterviewCheatingRiskSummary, InterviewLoaded } from "app-types";
import { FC } from "react";
import {
  getOrdinalForNumber,
  InfoTooltip,
  Pill,
  PillColorsEnum,
  SizesEnum,
} from "@repo/ui";
import { InterviewWithContactAndActivities } from "../../app/admin_client_types";
import { useAppSelector } from "../../hooks/hook";
import { selectIsCheatingDetectionEnabled } from "../company/companySlice";

// The minimum number of window blur events to qualify as possible cheating risk.
const MIN_WINDOW_BLUR_EVENTS = 3;

interface CheatingRiskSummaryProps {
  interview: InterviewLoaded | InterviewWithContactAndActivities;
  cheatingRiskSummary: InterviewCheatingRiskSummary | null;
}

export const CheatingRiskSummary: FC<CheatingRiskSummaryProps> = ({
  interview,
  cheatingRiskSummary,
}) => {
  const isCheatingDetectionEnabled = useAppSelector(
    selectIsCheatingDetectionEnabled,
  );

  const calls = interview.metadata?.calls;

  if (!isCheatingDetectionEnabled || !cheatingRiskSummary) return null;

  const getCheatingRiskTooltipContent = () => {
    const commentary: string[] = [];

    // Fullscreen mode exits
    if (cheatingRiskSummary.fullscreen_exits.length > 0) {
      const event = cheatingRiskSummary.fullscreen_exits[0];
      const timeStr = getRelativeTimeString(event.seconds_from_start);
      const callContext = getCallContextString(event.call_id, calls);
      commentary.push(`Fullscreen mode was exited${callContext} at ${timeStr}`);
    }

    // Window blurs
    // 3 or more blur events (10+ seconds each), or a single blur event longer than 30 seconds
    const veryLongBlurEvent = cheatingRiskSummary.window_blurs.some(
      (e) => e.duration > 30,
    );
    if (
      cheatingRiskSummary.window_blurs.length >= MIN_WINDOW_BLUR_EVENTS ||
      veryLongBlurEvent
    ) {
      const events = cheatingRiskSummary.window_blurs.slice(0, 5);
      const callContext = getCallContextString(events[0].call_id, calls);
      const eventStrings = events.map((event) => {
        const timeStr = getRelativeTimeString(event.seconds_from_start);
        return `${timeStr} (for ${event.duration} seconds)`;
      });
      commentary.push(
        `Candidate left the interview window${callContext} at ${formatTimesListString(
          eventStrings,
        )}`,
      );
    }

    // Long pauses in audio before responding. 3 or more events results in a flag.
    if (cheatingRiskSummary.long_pauses.length >= 3) {
      const events = cheatingRiskSummary.long_pauses.slice(0, 5);
      const callContext = getCallContextString(events[0].call_id, calls);
      const eventStrings = events.map((event) => {
        const timeStr = getRelativeTimeString(event.seconds_from_start);
        return `${timeStr} (for ${event.duration} seconds)`;
      });
      commentary.push(
        `A long pause occurred before answering a question${callContext} at ${formatTimesListString(
          eventStrings,
        )}`,
      );
    }

    return commentary;
  };

  const cheatingRiskSummaryLines = getCheatingRiskTooltipContent();

  return (
    <>
      {/* Render flag for number of calls as a neutral indicator */}
      {cheatingRiskSummary.num_significant_calls > 1 && (
        <InfoTooltip
          id={`cheating-risk-num-calls-${interview.id}`}
          place="bottom"
          content={getNumCallsDisplayText(
            cheatingRiskSummary.num_significant_calls,
          )}
        >
          <Pill
            color={PillColorsEnum.GRAY}
            label={
              <div className="flex items-center gap-1">
                <PhoneIcon className="h-4 w-4" />
                <span>{cheatingRiskSummary.num_significant_calls} calls</span>
              </div>
            }
            size={SizesEnum.MEDIUM}
          />
        </InfoTooltip>
      )}
      {/* Render flag for other cheating risks if necessary */}
      {cheatingRiskSummaryLines.length > 0 && (
        <InfoTooltip
          id={`cheating-risk-commentary-${interview.id}`}
          place="bottom"
          content={
            <ul className="list-disc pl-4">
              {cheatingRiskSummaryLines.map((line, i) => (
                <li key={i}>{line}</li>
              ))}
            </ul>
          }
        >
          <Pill
            color={PillColorsEnum.YELLOW}
            label={
              <div className="flex items-center gap-1">
                <ExclamationTriangleIcon className="h-4 w-4" />
                <span>Possible cheating risk</span>
              </div>
            }
            size={SizesEnum.MEDIUM}
          />
        </InfoTooltip>
      )}
    </>
  );
};

function getNumCallsDisplayText(numCalls: number) {
  return `This interview was conducted over ${numCalls} separate calls`;
}

function formatTimesListString(items: string[]): string {
  if (items.length === 0) return "";
  if (items.length === 1) return items[0];
  if (items.length === 2) return `${items[0]} and ${items[1]}`;
  return `${items.slice(0, -1).join(", ")}, and ${items[items.length - 1]}`;
}

// Returns a string like "0:35" or "2:05"
function getRelativeTimeString(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
}

function getCallContextString(callId: string, calls?: { call_id: string }[]) {
  if (!calls || calls.length <= 1) return "";
  return ` during the ${getOrdinalForNumber(
    calls.findIndex((c) => c.call_id === callId) + 1,
  )} call`;
}
