import {
  ArrowUpOnSquareStackIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { Button, ButtonVariantsEnum, SizesEnum } from "@repo/ui";
import { DraggableItem } from "@repo/ui/DraggableList/draggableItem";
import { useDraggableList } from "@repo/ui/hooks/useDraggableList";
import { PermissionActions, PermissionSubjects } from "app-types";
import { FC } from "react";
import { Can } from "../../../helpers/teammateAuthorizationContext";
import { QuestionsManagerCell } from "./questionsManagerCell";
import {
  questionsActions,
  type QuestionsDispatch,
  type QuestionWithTemporaryId,
} from "./questionsStore";

export interface QuestionsManagerTableProps {
  questions: readonly QuestionWithTemporaryId[];
  onQuestionsChange: QuestionsDispatch;
  onImportClick?: () => void;
}

const MAX_INTERVIEW_QUESTIONS = 25;

export const QuestionsManagerTable: FC<QuestionsManagerTableProps> = (
  props,
) => {
  const {
    handleDragStart,
    handleDragOver,
    handleDrop,
    handleDragEnd,
    handleDragLeave,
    dragOverIndex,
  } = useDraggableList({
    items: props.questions,
    onReorder: (newQuestions) =>
      props.onQuestionsChange(questionsActions.reordered(newQuestions)),
  });

  return (
    <Can
      I={PermissionActions.UPDATE}
      a={PermissionSubjects.JOB_OPENINGS}
      passThrough={true}
    >
      {(allowed) => (
        <div>
          {props.questions.map((question, index) => (
            <DraggableItem
              dragOverIndex={dragOverIndex}
              index={index}
              isDraggable={allowed}
              key={question.id}
              onDragEnd={handleDragEnd}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDragStart={handleDragStart}
              onDrop={handleDrop}
            >
              <QuestionsManagerCell
                onChange={(basicQuestion) =>
                  props.onQuestionsChange(
                    questionsActions.updated({
                      index,
                      basicQuestion,
                    }),
                  )
                }
                onEnterKeyDown={() =>
                  props.onQuestionsChange(
                    questionsActions.inserted({ index: index + 1 }),
                  )
                }
                onRemove={() =>
                  props.onQuestionsChange(questionsActions.deleted({ index }))
                }
                question={question}
                questionNumber={index + 1}
              />
            </DraggableItem>
          ))}

          <div className="m-3 flex flex-row justify-between space-x-3">
            <Button
              icon={<PlusIcon className="w-4 h-4" />}
              isDisabled={
                props.questions.length >= MAX_INTERVIEW_QUESTIONS || !allowed
              }
              onClick={() => props.onQuestionsChange(questionsActions.added())}
              size={SizesEnum.MEDIUM}
              variant={ButtonVariantsEnum.Secondary}
            >
              Add question
            </Button>
            {props.onImportClick ? (
              <Button
                icon={<ArrowUpOnSquareStackIcon className="w-4 h-4" />}
                onClick={props.onImportClick}
                size={SizesEnum.MEDIUM}
                variant={ButtonVariantsEnum.Secondary}
                isDisabled={!allowed}
              >
                Bulk import
              </Button>
            ) : null}
          </div>
        </div>
      )}
    </Can>
  );
};
