import { ColorPicker, type ColorPickerProps } from "@repo/ui";
import { PermissionActions, PermissionSubjects } from "app-types";
import { FC } from "react";
import { Can } from "../../../helpers/teammateAuthorizationContext";

interface CompanyColorPickerProps extends ColorPickerProps {}

export const CompanyColorPicker: FC<CompanyColorPickerProps> = ({
  color,
  onChange,
}) => {
  return (
    <Can
      I={PermissionActions.UPDATE}
      a={PermissionSubjects.COMPANY_SETTINGS}
      passThrough={true}
    >
      {(allowed) => (
        <ColorPicker color={color} onChange={onChange} isDisabled={!allowed} />
      )}
    </Can>
  );
};
