import {
  Button,
  ButtonVariantsEnum,
  Input,
  Modal,
  ModalHeader,
} from "@repo/ui";
import { LoadingText } from "@repo/ui/Buttons/loadingText";
import { buildProjectUrlPath, JobTypeDB, LoadingStatesEnum } from "app-types";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { JobTypePicker } from "../../jobTypes/jobTypePicker";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { PhoneNumberSelect } from "../../phoneNumbers/phoneNumberSelect";
import { selectAllPhoneNumbers } from "../../phoneNumbers/phoneNumbersSlice";
import { selectTeammate } from "../../teammate/teammateSlice";
import {
  JobDescriptionTextArea,
  MAX_JD_LENGTH,
  MIN_JD_LENGTH,
} from "../projectSettings/jobDescriptionSetting";
import { JobOpeningDetailsInputs } from "../projectSettings/recruiting/jobOpeningSettings";
import { createRecruitingProject } from "../projectsSlice";

export interface CreateRecruitingProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateRecruitingProjectModal: FC<
  CreateRecruitingProjectModalProps
> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const teammate = useAppSelector(selectTeammate);
  if (!teammate) {
    throw new Error("Teammate not found");
  }

  const [jobType, setJobType] = useState<JobTypeDB | null>(null);
  const [jobOpeningName, setJobOpeningName] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [selectedPhoneNumberId, setSelectedPhoneNumberId] = useState<string>();
  const [location, setLocation] = useState("");
  const [pay, setPay] = useState("");
  const [hours, setHours] = useState("");
  const phoneNumbers = useAppSelector(selectAllPhoneNumbers);

  const [createProjectStatus, setCreateProjectStatus] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADED);

  const handleLocationBlur = () => {
    if (jobType && location && !jobOpeningName) {
      setJobOpeningName(`${jobType.name} - ${location}`);
    }
  };

  const onClickCreate = async () => {
    setCreateProjectStatus(LoadingStatesEnum.LOADING);

    try {
      const newProject = await dispatch(
        createRecruitingProject({
          name: jobOpeningName,
          jobDescription: jobDescription || undefined,
          phoneNumberId: selectedPhoneNumberId,
          jobTypeId: jobType?.id ?? undefined,
          location: location ? { description: location } : undefined,
          pay: pay ? { description: pay } : undefined,
          hours: hours ? { description: hours } : undefined,
        }),
      ).unwrap();

      const newProjectId = newProject.id;

      showNotification(dispatch, {
        id: `project-created-${new Date().getTime().toString()}`,
        primaryMessage: `Job opening successfully created`,
        type: NotificationTypeEnum.SUCCESS,
      });

      setCreateProjectStatus(LoadingStatesEnum.LOADED);
      navigate(buildProjectUrlPath(newProjectId));
    } catch (err) {
      console.error("error creating project", err);
      setCreateProjectStatus(LoadingStatesEnum.ERROR);
    }
  };

  return (
    <Modal isOpen={isOpen} onCancel={onClose}>
      <ModalHeader>Create job opening</ModalHeader>
      <div className="mt-4 flex flex-col space-y-3">
        <div>
          <div className="w-[300px]">
            <JobTypePicker
              jobTypeId={jobType?.id ?? null}
              onChange={setJobType}
              tabIndex={-1}
            />
          </div>
          <div className="mt-2 text-sm text-gray-500">
            (Optional) Choose an existing Job Type to share interview questions
            and criteria across multiple Job Openings.
          </div>
        </div>
        {jobType ? (
          <JobOpeningDetailsInputs
            location={location}
            pay={pay}
            hours={hours}
            onLocationChange={setLocation}
            onPayChange={setPay}
            onHoursChange={setHours}
            onLocationBlur={handleLocationBlur}
          />
        ) : (
          <div>
            <JobDescriptionTextArea
              description="Paste a job description that describes the key responsibilities and requirements (no need to worry about text formatting)."
              onChange={setJobDescription}
              value={jobDescription}
              height="150px"
            />
          </div>
        )}
        {phoneNumbers.length > 1 ? (
          <div>
            <PhoneNumberSelect
              onChange={setSelectedPhoneNumberId}
              phoneNumberId={selectedPhoneNumberId}
            />
          </div>
        ) : null}
        <div>
          <Input
            autoFocus
            label="Job opening name"
            onChange={(evt) => {
              setJobOpeningName(evt.target.value);
            }}
            placeholder="Store Manager - San Francisco"
            required={Boolean(jobType)}
            value={jobOpeningName}
          />
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <div className="ml-3">
          <Button
            isDisabled={
              !jobOpeningName ||
              (!jobType &&
                (!jobDescription ||
                  jobDescription.length < MIN_JD_LENGTH ||
                  jobDescription.length > MAX_JD_LENGTH)) ||
              createProjectStatus === LoadingStatesEnum.LOADING
            }
            isLoading={createProjectStatus === LoadingStatesEnum.LOADING}
            onClick={() => void onClickCreate()}
            variant={ButtonVariantsEnum.Primary}
          >
            <LoadingText
              isLoading={
                !!jobDescription &&
                createProjectStatus === LoadingStatesEnum.LOADING
              }
              stages={[
                "Analyzing job description...",
                "Generating interview questions...",
                "Generating assessment criteria...",
                "Improving interview depth...",
                "Creating job opening...",
              ]}
            >
              Create job opening
            </LoadingText>
          </Button>
        </div>
        <Button onClick={onClose} variant={ButtonVariantsEnum.Secondary}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
