import { Textarea } from "@repo/ui";
import { BasicQuestion } from "app-types";
import { FC } from "react";

export const MAX_CHARACTERS_PER_QUESTION = 300;

interface BasicQuestionEditorProps {
  question: BasicQuestion;
  onChange: (question: BasicQuestion) => void;
}

export const BasicQuestionEditor: FC<BasicQuestionEditorProps> = ({
  question,
  onChange,
}) => {
  return (
    <div>
      <Textarea
        label="Question"
        value={question.question}
        placeholder="Add an open-ended interview question..."
        onChange={(newValue) => {
          onChange({
            ...question,
            question: newValue,
          });
        }}
        shouldDisableNewline
        maxLength={MAX_CHARACTERS_PER_QUESTION}
        description="Users will answer this question out loud and may see 1-2 clarifying questions based on their response."
        autoFocus={!question.question} // Auto-focus if input is empty (when creating new question)
      />
    </div>
  );
};
