import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import {
  BalanceTransaction,
  Company,
  LoadingStatesEnum,
  PaymentMethodInfo,
} from "app-types";
import { FC, useEffect, useState } from "react";
import {
  Button,
  ButtonVariantsEnum,
  DefaultSkeleton,
  InfoBanner,
  InfoTooltip,
  Label,
  SizesEnum,
} from "@repo/ui";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  fetchGiftCardBalance,
  selectBalanceInfo,
} from "../../billing/billingSlice";
import { AutoRefillModal } from "./autoRefillModal";
import { GiftCardBalanceIncreaseModal } from "./giftCardBalanceIncreaseModal";
import { GiftCardBalanceTable } from "./giftCardBalanceTable";
import { UnitAmountText } from "./unitAmountText";

interface GiftCardBalanceSectionProps {
  company: Company;
  defaultPaymentMethod: PaymentMethodInfo | null;
  onClickAddPaymentMethod: () => void;
  billingPortalLoadingState: LoadingStatesEnum;
}

export const GiftCardBalanceSection: FC<GiftCardBalanceSectionProps> = ({
  company,
  defaultPaymentMethod,
  onClickAddPaymentMethod,
  billingPortalLoadingState,
}) => {
  const dispatch = useAppDispatch();
  const balanceInfo = useAppSelector(selectBalanceInfo);
  const { balanceUnitAmount, pendingBalanceUnitAmount } = balanceInfo;
  const balanceLoadingState = balanceInfo.status;

  const [autoRefillModalIsOpen, setAutoRefillModalIsOpen] = useState(false);
  const [balanceIncreaseModalIsOpen, setBalanceIncreaseModalIsOpen] =
    useState(false);
  const [balanceTransactionsLoadingState, setBalanceTransactionsLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADING);
  const [balanceTransactions, setBalanceTransactions] = useState<
    BalanceTransaction[]
  >([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  const companyIsInTrial =
    company.trial_expires_at && new Date(company.trial_expires_at) > new Date();

  useEffect(() => {
    dispatch(fetchGiftCardBalance());
  }, []);

  const fetchBalanceTransactions = async () => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      const { data } = await axios.get(`/billing/balance-transactions`, {
        params: {
          page: page,
        },
      });

      setBalanceTransactions(data.balance_transactions);
      setPage(data.page);
      setTotalPages(data.total_pages);
      setBalanceTransactionsLoadingState(LoadingStatesEnum.LOADED);
    } catch (err) {
      setBalanceTransactionsLoadingState(LoadingStatesEnum.ERROR);
    }
  };
  useEffect(() => {
    fetchBalanceTransactions();
  }, [page]);

  const renderCta = () => {
    // If we dont have a stripe customer ID or payment method, show a prompt to add payment method
    if (!company.billing_company_id || !defaultPaymentMethod)
      return (
        <div>
          <Button
            onClick={onClickAddPaymentMethod}
            variant={ButtonVariantsEnum.Primary}
            isLoading={billingPortalLoadingState === LoadingStatesEnum.LOADING}
            isDisabled={billingPortalLoadingState === LoadingStatesEnum.LOADING}
          >
            Add a payment method
          </Button>
        </div>
      );

    // Otherwise, show buttons to add to balance
    const autoRefillIsEnabled =
      company.settings.balance_refill_threshold_unit_amount &&
      company.settings.balance_refill_unit_amount;

    return (
      <>
        <div className="flex flex-row space-x-3">
          <Button
            onClick={() => {
              setBalanceIncreaseModalIsOpen(true);
            }}
            variant={ButtonVariantsEnum.Primary}
          >
            Add to Gift Card Balance
          </Button>
          <Button
            variant={ButtonVariantsEnum.Secondary}
            onClick={() => {
              setAutoRefillModalIsOpen(true);
            }}
            icon={
              autoRefillIsEnabled ? (
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
              ) : (
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              )
            }
          >
            {autoRefillIsEnabled
              ? "Auto-refill enabled"
              : "Auto-refill disabled"}
          </Button>
        </div>
      </>
    );
  };

  if (balanceTransactionsLoadingState === LoadingStatesEnum.ERROR)
    return (
      <div className="mt-2 text-sm text-orange-600">
        An error occurred while loading your gift card balance. Please refresh
        and try again.
      </div>
    );

  if (balanceLoadingState === LoadingStatesEnum.LOADING)
    return (
      <div className="mt-4">
        <DefaultSkeleton />
      </div>
    );

  return (
    <div>
      <div className="mt-2 mb-5 flex flex-row space-x-4">
        <div className="overflow-hidden rounded-lg bg-white px-4 py-3 shadow">
          <dt className="truncate text-sm font-medium text-gray-500">
            {"Gift Card Balance"}
          </dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
            {typeof balanceUnitAmount === "number" ? (
              <UnitAmountText unitAmount={balanceUnitAmount} />
            ) : (
              "-"
            )}
          </dd>
        </div>
        {pendingBalanceUnitAmount ? (
          <div className="overflow-hidden rounded-lg bg-white px-4 py-3 shadow">
            <dt className="text-sm font-medium text-gray-500 flex flex-row space-x-1 align-middle items-center">
              <span>{"Pending Balance"}</span>
              <InfoTooltip
                id="pending-balance-tooltip"
                place="bottom"
                content={
                  "This balance will become available for use after credit card payments are successfully processed (usually within minutes). Please ensure that you have a valid credit card added."
                }
              >
                <div>
                  <InformationCircleIcon className="h-4 w-4 flex-shrink-0" />
                </div>
              </InfoTooltip>
            </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
              <UnitAmountText unitAmount={pendingBalanceUnitAmount} />
            </dd>
          </div>
        ) : null}
      </div>
      <div className="mb-5 flex flex-col space-y-4">
        {renderCta()}
        <div className="text-sm text-gray-600">
          {`Your balance is used to send gift cards to interview participants after they complete an interview. `}
          {!companyIsInTrial && company.billing_usage_fee_percent > 0 && (
            <>
              <InfoTooltip
                id="usage-fee-tooltip"
                place="bottom"
                content={`${company.billing_usage_fee_percent}% of gift card volume allows Alpharun to provide payment processing, gift card sending, AI-powered interviews & analysis, and fraud prevention.`}
              >
                <span className="underline">Usage fees</span>
              </InfoTooltip>{" "}
              are applied when you add funds to your balance.
            </>
          )}
        </div>
        <InfoBanner
          title="Balance Protection Built-in"
          description={`To protect your account from fraud, each completed interview is human-reviewed for authenticity
            before a gift card is sent using your balance.`}
          icon={<ShieldCheckIcon className="h-5 w-5 text-blue-400" />}
        />
      </div>
      <div className="flex flex-col space-y-2 my-6">
        <Label size={SizesEnum.SMALL}>Balance Activity</Label>
        <GiftCardBalanceTable
          loadingState={balanceTransactionsLoadingState}
          balanceTransactions={balanceTransactions}
          onClickNextPage={() => setPage(page + 1)}
          onClickPreviousPage={() => setPage(page - 1)}
          page={page}
          totalPages={totalPages}
        />
      </div>
      <GiftCardBalanceIncreaseModal
        isOpen={balanceIncreaseModalIsOpen}
        onClose={() => {
          setBalanceIncreaseModalIsOpen(false);
        }}
        onAddToBalance={() => {
          fetchGiftCardBalance();
          fetchBalanceTransactions();
        }}
      />
      <AutoRefillModal
        isOpen={autoRefillModalIsOpen}
        onClose={() => {
          setAutoRefillModalIsOpen(false);
        }}
        company={company}
      />
    </div>
  );
};
