import { z } from "zod";
import { llmFeedbackPrioritySchema } from "./llmFeedback";

export const jobAssessmentFeedbackSchema = z.object({
  suggestions: z.array(
    z.object({
      suggestion: z.string(),
      priority: llmFeedbackPrioritySchema,
    }),
  ),
});

export type JobAssessmentFeedback = z.infer<typeof jobAssessmentFeedbackSchema>;
