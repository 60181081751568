import { FC } from "react";
import { useProjectJobQuestionsFeedbackByIdQuery } from "../../../api/redux";
import { SuggestionsWidget } from "../feedback/suggestionsWidget";

export interface QuestionsSuggestionsWidgetProps {
  projectId: string;
}

export const QuestionsSuggestionsWidget: FC<QuestionsSuggestionsWidgetProps> = (
  props,
) => {
  const feedback = useProjectJobQuestionsFeedbackByIdQuery(props.projectId);

  return (
    <SuggestionsWidget
      isLoading={feedback.isLoading}
      isFetching={feedback.isFetching}
      suggestions={feedback.data?.suggestions.map(
        (suggestion) => suggestion.suggestion,
      )}
      onRefreshClick={feedback.refetch}
    />
  );
};
