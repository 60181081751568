import { AssessmentEnum } from "app-types";
import { FC } from "react";
import { Pill, PillColorsEnum, SizesEnum } from "@repo/ui";

export const scoreToDetails: Record<
  number,
  { color: PillColorsEnum; text: string }
> = {
  1: {
    color: PillColorsEnum.RED,
    text: AssessmentEnum.VeryPoor,
  },
  2: {
    color: PillColorsEnum.YELLOW,
    text: AssessmentEnum.Poor,
  },
  3: {
    color: PillColorsEnum.GRAY,
    text: AssessmentEnum.Acceptable,
  },
  4: {
    color: PillColorsEnum.LIME,
    text: AssessmentEnum.Good,
  },
  5: {
    color: PillColorsEnum.GREEN,
    text: AssessmentEnum.Excellent,
  },
};

interface AssessmentScoreProps {
  score: number;
  size?: SizesEnum;
}

export const AssessmentScore: FC<AssessmentScoreProps> = ({
  score,
  size = SizesEnum.SMALL,
}) => {
  const scoreDetails = scoreToDetails[score];

  return (
    <div className="w-fit">
      <Pill
        color={scoreDetails.color}
        label={`${score} · ${scoreDetails.text}`}
        size={size}
      />
    </div>
  );
};
