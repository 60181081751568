import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { InfoTooltip, Label, SizesEnum } from "@repo/ui";
import {
  Company,
  getInterviewLinkForProject,
  getProjectAndJobTypeProperty,
  JobTypePropertyEnum,
  parseMultilineContent,
  ProjectUpdate,
  ProjectWithInterviewCount,
} from "app-types";
import { useUpdateJobTypeMutation } from "../../../../api/redux";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../../notificationsOverlay/notificationsSlice";
import { AppearanceSettingsSection } from "../appearanceSettingsSection";
import { AutoAdvanceSetting } from "../autoAdvanceSetting";
import { InterviewLanguageSetting } from "../interviewLanguageSetting";
import { InterviewLinkCopybox } from "../interviewLinkCopybox";
import { MessagingAutomationSection } from "../messagingAutomationSection";
import { PhoneNumberDisplaySection } from "../phoneNumberDisplaySection";
import { ProjectNotificationsSettingsSection } from "../projectNotificationsSettingsSection";
import { JobOpeningSettings } from "./jobOpeningSettings";
import { JobTypeContext } from "./jobTypeContext";
import { JobTypeName } from "./jobTypeName";
import { RecruitingOtherSettings } from "./recruitingOtherSettings";
import { RecruitingSettingsQuestionsSection } from "./recruitingSettingsQuestionsSection";

interface RecruitingProjectSettingsProps {
  project: ProjectWithInterviewCount;
  company: Company;
  onSaveProject: (project: ProjectUpdate) => Promise<void>;
  onClickSharingTemplates: () => void;
}

export const RecruitingProjectSettings = ({
  project,
  company,
  onSaveProject,
  onClickSharingTemplates,
}: RecruitingProjectSettingsProps) => {
  const dispatch = useAppDispatch();
  const teammate = useAppSelector((state) => state.teammate.teammate);
  const [updateJobType] = useUpdateJobTypeMutation();

  const language = getProjectAndJobTypeProperty(
    project,
    JobTypePropertyEnum.Language,
  );

  const voice = getProjectAndJobTypeProperty(
    project,
    JobTypePropertyEnum.LanguageVoice,
  );

  return (
    <div className="flex flex-col space-y-6">
      <div>
        <div className="bg-white border border-gray-200 rounded-lg px-4 py-3">
          <div className="flex flex-row space-x-4">
            <div className="flex-shrink-0">
              <InterviewLinkCopybox
                inviteLinkUrl={getInterviewLinkForProject(
                  import.meta.env.VITE_INTERVIEW_APP_BASE_URL,
                  company,
                  project,
                )}
                project={project}
                onCopyInterviewLink={() => {
                  showNotification(dispatch, {
                    id: `project-${
                      project.id
                    }-link-copied-${new Date().getTime()}`,
                    primaryMessage: `Interview link copied to clipboard`,
                    type: NotificationTypeEnum.SUCCESS,
                  });
                }}
              />
            </div>
            <div className="flex-shrink-0">
              <Label size={SizesEnum.SMALL}>Phone number</Label>
              <PhoneNumberDisplaySection projectId={project.id} />
            </div>
          </div>
          <div className="text-sm mb-2 text-slate-800">
            Send the interview link or phone number to candidates or add it to
            your job posting (
            <span
              onClick={onClickSharingTemplates}
              className="cursor-pointer font-medium text-blue-600 hover:text-blue-500"
            >
              See Templates
            </span>
            ). Links work on desktop or mobile browsers and can be customized
            with a custom logo and messaging.
          </div>
        </div>
      </div>

      <div>
        {project.job_type ? (
          <>
            <div className="mb-2 flex flex-row space-x-1 items-center">
              <Label size={SizesEnum.SMALL} variant="secondary">
                Job Type
              </Label>
              <InfoTooltip
                id="job-type-tooltip"
                place="right"
                content={`Job Types define interview questions and assessment criteria and can be reused across multiple job openings. For example, you may have a "Store Manager" Job Type and a Job Opening for each store location.`}
              >
                <div>
                  <InformationCircleIcon className="h-4 w-4 flex-shrink-0 text-gray-500" />
                </div>
              </InfoTooltip>
            </div>
            <div>
              <JobTypeName jobType={project.job_type} projectId={project.id} />
            </div>
          </>
        ) : (
          <Label size={SizesEnum.LARGE}>Interview settings</Label>
        )}
        <div className="flex flex-col space-y-3">
          <div>
            <RecruitingSettingsQuestionsSection
              language={language}
              project={project}
              onSaveProject={(p) => {
                void onSaveProject(p);

                // Double write to the job type
                if (project.job_type) {
                  void updateJobType({
                    id: project.job_type.id,
                    questions: p.questions,
                    job_description: p.job_description
                      ? parseMultilineContent(p.job_description, {
                          parsesVariables: false,
                        })
                      : undefined,
                  });
                }
              }}
            />
          </div>
          <JobTypeContext
            context={getProjectAndJobTypeProperty(
              project,
              JobTypePropertyEnum.PromptContext,
            )}
            hasJobType={Boolean(project.job_type)}
            onSave={(newContext) => {
              void onSaveProject({
                id: project.id,
                settings: {
                  ...project.settings,
                  prompt_context: newContext ?? null,
                },
              });

              // Double write to the job type
              if (project.job_type) {
                void updateJobType({
                  id: project.job_type.id,
                  prompt_context: newContext
                    ? parseMultilineContent(newContext, {
                        parsesVariables: false,
                      })
                    : null,
                });
              }
            }}
          />
          <InterviewLanguageSetting
            language={language}
            voice={voice}
            onSave={(newLanguage, newVoice) => {
              void onSaveProject({
                id: project.id,
                settings: {
                  ...project.settings,
                  interview_language: newLanguage,
                  language_voice: newVoice,
                },
              });

              // Double write to the job type
              if (project.job_type) {
                void updateJobType({
                  id: project.job_type.id,
                  settings: {
                    ...project.job_type.settings,
                    interview_languages: [newLanguage],
                    language_voice: newVoice,
                  },
                });
              }
            }}
          />

          <RecruitingOtherSettings
            onSaveProject={(p) => void onSaveProject(p)}
            project={project}
          />
          <AppearanceSettingsSection
            onSaveProject={(p) => void onSaveProject(p)}
            company={company}
            project={project}
          />
          <AutoAdvanceSetting
            autoAdvanceAssessmentScoreThreshold={getProjectAndJobTypeProperty(
              project,
              JobTypePropertyEnum.AutoAdvanceAssessmentScoreThreshold,
            )}
            onSave={(newThreshold) => {
              void onSaveProject({
                id: project.id,
                settings: {
                  ...project.settings,
                  auto_advance_assessment_score_threshold: newThreshold,
                },
              });

              // Double write to the job type
              if (project.job_type) {
                void updateJobType({
                  id: project.job_type.id,
                  settings: {
                    ...project.job_type.settings,
                    auto_advance_assessment_score_threshold: newThreshold,
                  },
                });
              }
            }}
          />
          {project.job_type ? (
            <div className="pt-4">
              <Label size={SizesEnum.SMALL} variant="secondary">
                Job Opening
              </Label>
            </div>
          ) : null}

          <div className="flex flex-col space-y-4">
            {project.job_type ? (
              <JobOpeningSettings
                project={project}
                onSaveProject={onSaveProject}
              />
            ) : null}
            <MessagingAutomationSection project={project} />
            <ProjectNotificationsSettingsSection
              project={project}
              onSaveProject={(p) => void onSaveProject(p)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
